import i18next from 'i18next';
import { BiddingTypes } from './constants';
import { getEnvironment } from '../configs/environment';
import { translates } from './translates';

export const enum CountryEnum {
    LT = 'LT',
    PL = 'PL',
    PT = 'PT',
    ES = 'ES',
    HU = 'HU',
    CZ = 'CZ',
    RO = 'RO'
}

export const enum LanguageEnum {
    EN = 'EN',
    ES = 'ES',
    LT = 'LT',
    LV = 'LV',
    PL = 'PL',
    PT = 'PT',
    RU = 'RU',
    EE = 'EE',
    HU = 'HU',
    CZ = 'CZ',
    RO = 'RO',
    CA = 'CA',
}

interface Coordinates {
    lat: number;
    lng: number;
}

interface Urls {
    api: string;
    notifications: string;
    notificationsWs: string;
    main: string;
    externalMain: string;
    externalServices: string;
}

interface CountryDefaults {
    country: string;
    language: string;
    coordinates: Coordinates;
    currency: string;
    phoneCode: string;
    email: string;
    urls: Urls;
    isPersonalCodeEnabled?: boolean;
    isPersonalCodeMandatory?: boolean;
    defaultBiddingType: number;
    showAddress: boolean;
}

const countriesDefaults: CountryDefaults[] = [
    {
        country: CountryEnum.LT,
        language: LanguageEnum.LT,
        coordinates: {
            lat: 55.169438,
            lng: 23.881275,
        },
        currency: '€',
        phoneCode: '+370',
        email: 'info@portalpro.lt',
        defaultBiddingType: BiddingTypes.standard,
        urls: {
            api: window.ENVIRONTMENT_VARIABLES.REACT_APP_LT_API_URL,
            notifications: window.ENVIRONTMENT_VARIABLES.REACT_APP_LT_NOTIFICATIONS_URL,
            notificationsWs: window.ENVIRONTMENT_VARIABLES.REACT_APP_LT_NOTIFICATIONS_WS_URL,
            main: 'https://client.portalpro.lt',
            externalMain: 'https://portalpro.lt',
            externalServices: 'https://portalpro.lt/paslaugos',
        },
        showAddress: false,
    },
    {
        country: CountryEnum.PL,
        language: LanguageEnum.PL,
        coordinates: {
            lat: 51.919438,
            lng: 19.145136,
        },
        currency: ' zł',
        phoneCode: '+48',
        email: 'kontakt@portalpro.pl',
        defaultBiddingType: BiddingTypes.standard,
        urls: {
            api: window.ENVIRONTMENT_VARIABLES.REACT_APP_PL_API_URL,
            notifications: window.ENVIRONTMENT_VARIABLES.REACT_APP_PL_NOTIFICATIONS_URL,
            notificationsWs: window.ENVIRONTMENT_VARIABLES.REACT_APP_PL_NOTIFICATIONS_WS_URL,
            main: 'https://client.portalpro.pl',
            externalMain: 'https://portalpro.pl',
            externalServices: 'https://portalpro.pl',
        },
        showAddress: true,
    },
    {
        country: CountryEnum.PT,
        language: LanguageEnum.PT,
        coordinates: {
            lat: 40.040717,
            lng: -8.024429,
        },
        currency: ' Eur',
        phoneCode: '+351',
        email: 'info@portalpro.pt',
        defaultBiddingType: BiddingTypes.standard,
        urls: {
            api: window.ENVIRONTMENT_VARIABLES.REACT_APP_PT_API_URL,
            notifications: window.ENVIRONTMENT_VARIABLES.REACT_APP_PT_NOTIFICATIONS_URL,
            notificationsWs: window.ENVIRONTMENT_VARIABLES.REACT_APP_PT_NOTIFICATIONS_WS_URL,
            main: 'https://client.portalpro.pt',
            externalMain: 'https://portalpro.pt',
            externalServices: 'https://portalpro.pt',
        },
        showAddress: true,
        isPersonalCodeEnabled: true,
        isPersonalCodeMandatory: true,
    },
    {
        country: CountryEnum.ES,
        language: LanguageEnum.ES,
        coordinates: {
            lat: 40.463667,
            lng: -3.74922,
        },
        currency: '€',
        phoneCode: '+34',
        email: 'info@portalpro.es',
        defaultBiddingType: BiddingTypes.standard,
        urls: {
            api: window.ENVIRONTMENT_VARIABLES.REACT_APP_ES_API_URL,
            notifications: window.ENVIRONTMENT_VARIABLES.REACT_APP_ES_NOTIFICATIONS_URL,
            notificationsWs: window.ENVIRONTMENT_VARIABLES.REACT_APP_ES_NOTIFICATIONS_WS_URL,
            main: 'https://client.portalpro.es',
            externalMain: 'https://portalpro.es',
            externalServices: 'https://portalpro.es',
        },
        showAddress: true,
        isPersonalCodeEnabled: true,
    },
    {
        country: CountryEnum.HU,
        language: LanguageEnum.HU,
        coordinates: {
            lat: 47.162494,
            lng: 19.503304,
        },
        currency: 'Ft',
        phoneCode: '+36',
        email: 'info@portalpro.hu',
        defaultBiddingType: BiddingTypes.standard,
        urls: {
            api: window.ENVIRONTMENT_VARIABLES.REACT_APP_HU_API_URL,
            notifications: window.ENVIRONTMENT_VARIABLES.REACT_APP_HU_NOTIFICATIONS_URL,
            notificationsWs: window.ENVIRONTMENT_VARIABLES.REACT_APP_HU_NOTIFICATIONS_WS_URL,
            main: 'https://client.portalpro.hu',
            externalMain: 'https://portalpro.hu',
            externalServices: 'https://portalpro.hu',
        },
        showAddress: true,
    },
    {
        country: CountryEnum.CZ,
        language: LanguageEnum.CZ,
        coordinates: {
            lat: 40.463667,
            lng: -3.74922,
        },
        currency: 'Kč',
        phoneCode: '+420',
        email: 'info@portalpro.cz',
        defaultBiddingType: BiddingTypes.standard,
        urls: {
            api: window.ENVIRONTMENT_VARIABLES.REACT_APP_CZ_API_URL,
            notifications: window.ENVIRONTMENT_VARIABLES.REACT_APP_CZ_NOTIFICATIONS_URL,
            notificationsWs: window.ENVIRONTMENT_VARIABLES.REACT_APP_CZ_NOTIFICATIONS_WS_URL,
            main: 'https://client.portalpro.cz',
            externalMain: 'https://portalpro.cz',
            externalServices: 'https://portalpro.cz',
        },
        showAddress: true,
    },
    {
        country: CountryEnum.RO,
        language: LanguageEnum.RO,
        coordinates: {
            lat: 45.943161,
            lng: 24.96676,
        },
        currency: 'lei',
        phoneCode: '+40',
        email: 'info@portalpro.ro',
        defaultBiddingType: BiddingTypes.standard,
        urls: {
            api: window.ENVIRONTMENT_VARIABLES.REACT_APP_RO_API_URL,
            notifications: window.ENVIRONTMENT_VARIABLES.REACT_APP_RO_NOTIFICATIONS_URL,
            notificationsWs: window.ENVIRONTMENT_VARIABLES.REACT_APP_RO_NOTIFICATIONS_WS_URL,
            main: 'https://client.portalpro.ro',
            externalMain: 'https://portalpro.ro',
            externalServices: 'https://portalpro.ro',
        },
        showAddress: true,
    },
];

export const getCountries = () => {
    return countriesDefaults.map((c) => c.country);
};

export const getCountry = (): CountryEnum => {
    if (getEnvironment() === 'DEV') {
        return CountryEnum.LT;
    }

    return window.location.hostname.split('.').pop()!.toUpperCase() as CountryEnum;
};

export const getCurrencySymbol = (currency: string): string => {
    switch (currency) {
    case 'PLN':
        return ' zł';
    case 'EUR':
        return '€';
    case 'RUB':
        return '₽';
    case 'CZK':
        return 'Kč';
    case 'HUF':
        return 'ft';
    default:
        return 'Unknown currency';
    }
};

const getCountryValueOrDefault = <TValue>(
    country: string,
    getValue: (country: CountryDefaults) => TValue,
    defualtValue: TValue
): TValue => {
    const countryDefaults = countriesDefaults.find((c) => c.country === country);
    return countryDefaults ? getValue(countryDefaults) : defualtValue;
};

export const getCountryPhoneCode = (country: string): string => {
    return getCountryValueOrDefault(country, (c) => c.phoneCode, '');
};

export const allCountryPhoneCodes: string[] = countriesDefaults.map((c) => c.phoneCode);

export const getCountryCoordinates = (country: string): Coordinates => {
    return getCountryValueOrDefault(country, (c) => c.coordinates, { lng: 0, lat: 0 });
};

export const getCountryCurrency = (country: string, price: number, hourly: boolean): string => {
    const hoursLabel = i18next.t(translates.GlobalLabelsTimeUnitsHours);
    const symbol = getCountryValueOrDefault(country, (c) => c.currency, '');
    return `${price}${symbol}${hourly ? ` / ${hoursLabel}` : ''}`;
};

export const getCurrencyLabel = (currency: string, price: number, hourly: boolean): string => {
    const hoursLabel = i18next.t(translates.GlobalLabelsTimeUnitsHours);
    const symbol = getCurrencySymbol(currency);
    return `${price}${symbol}${hourly ? ` / ${hoursLabel}` : ''}`;
};

export const getCountryCurrencyLabel = (country: string) => {
    return getCountryValueOrDefault(country, (c) => c.currency, '');
};

export const getCountryUrl = (country: string, key: keyof Urls): string => {
    return getCountryValueOrDefault(country, (c) => c.urls[key], '');
};

export const getCountryLanguage = (country: string): string => {
    return getCountryValueOrDefault(country, (c) => c.language, '');
};

export const getCountryEmail = (country: string): string => {
    return getCountryValueOrDefault(country, (c) => c.email, '');
};

export const getCountryApiUrl = (country: string): string => {
    return getCountryValueOrDefault(country, (c) => c.urls.api, '');
};

export const getCountryNotificationsUrl = (country: string): string => {
    return getCountryValueOrDefault(country, (c) => c.urls.notifications, '');
};

export const getCountryNotificationsWsUrl = (country: string): string => {
    return getCountryValueOrDefault(country, (c) => c.urls.notificationsWs, '');
};

export const getIsAddressEnabled = (country: string): boolean => {
    return getCountryValueOrDefault(country, (c) => c.showAddress, false) || false;
};

export const getIsPersonalCodeEnabled = (country: string): boolean => {
    return getCountryValueOrDefault(country, (c) => c.isPersonalCodeEnabled, false) || false;
};

export const getIsPersonalCodeManadatory = (country: string): boolean => {
    return getCountryValueOrDefault(country, (c) => c.isPersonalCodeMandatory, false) || false;
};

export const getDefaultBiddingType = (country: string): number => {
    return getCountryValueOrDefault(country, (c) => c.defaultBiddingType, BiddingTypes.standard);
};

export const getPriceListEnabled = (country: string): boolean => {
    return [CountryEnum.ES, CountryEnum.LT, CountryEnum.PT, CountryEnum.PL, CountryEnum.CZ, CountryEnum.HU, CountryEnum.RO].includes(country?.toUpperCase() as CountryEnum);
};

export const getIsIncompleteProfileModalForced = (country: string) => {
    return [CountryEnum.ES, CountryEnum.PL].includes(country.toUpperCase() as CountryEnum);
};

export const getDefaultBiddingTypeForCompany = (country: string, isCompany: boolean): number => {
    if (country !== CountryEnum.LT && isCompany) {
        return BiddingTypes.standard;
    }
    return BiddingTypes.fixed;
};
