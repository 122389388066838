import { FormikHelpers, FormikValues } from 'formik';
import { FC, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import i18next from 'i18next';
import styled from 'styled-components';

import { translates } from '../../../../utilities';
import { Errors } from '../../../../utilities/constants';
import { routerPaths } from '../../../../configs';
import { ConfigContext, OrderingContext } from '../../../../contexts';
import { Alert, Form, FormFieldInput, Button, Text } from '../../../../components';
import { useApi } from '../../../../hooks/useApi';
import { ApiErrorModel } from '../../../../types/api';
import { LoginByEmailPageType } from '..';
import { useUrlQuery } from '../../../../hooks/useUrlQuery';
import { LoginKeyboardListener } from '../utilities';

interface LoginByEmailSubmitModel {
    email: string;
    password: string;
    rememberMe: boolean;
}

interface LoginByEmailProps {
    handleChangeLoginByEmailPage: (page: LoginByEmailPageType) => void;
    handleAfterLogin: Function
}

const RegisterLinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: .875rem;
`;

const StyledLink = styled(Link)`
    font-size: .875rem;
    color: ${({ theme }) => theme.colors.primary};
`;

const StyledHorzontalLine = styled.div`
    /* To ignore parent padding */
    width: calc(100% + 32px * 2);
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.grey1};
`;

const StyledText = styled(Text).attrs({
    color: 'black',
    type: 'body2',
})`
    font-weight: 500;
    margin-bottom: 1rem;
`;

export const LoginByEmail: FC<LoginByEmailProps> = ({ handleChangeLoginByEmailPage, handleAfterLogin }) => {
    const navigate = useNavigate();
    const query = useUrlQuery();
    const { country, contactInfo } = useContext(ConfigContext);
    const { orderId } = useContext(OrderingContext);
    const [error, setError] = useState('');
    const fetchApi = useApi();

    const submit = async (values: LoginByEmailSubmitModel, helpers: FormikHelpers<FormikValues>) => {
        try {
            const email = values.email.trim();

            await fetchApi({
                url: 'auth/login',
                data: { ...values, email, orderId, country },
                method: 'POST'
            });

            handleAfterLogin();
        } catch (error: any) {
            if ((error as ApiErrorModel)?.errorMessage === Errors.EmailNeedsConfirmation) {
                navigate(routerPaths.registrationSuccess.route);
                return;
            }

            setError(error?.errorMessage);
        } finally {
            helpers.setSubmitting(false);
        }
    };
    const schema = Yup.object()
        .shape({
            email: Yup.string().trim()
                .email(translates.FormsErrorsInvalidEmail)
                .required(translates.FormsErrorsRequired),
            password: Yup.string()
                .required(translates.FormsErrorsRequired)
        });
    const { email } = contactInfo || {};
    const initialValues = {
        email,
        password: '',
        rememberMe: true
    };

    return (
        <>
            <Form
                submit={submit}
                submitText={i18next.t(translates.LoginButtonsSubmit)}
                schema={schema}
                values={initialValues}
                validateOnBlur={false}
            >
                <LoginKeyboardListener />
                <FormFieldInput
                    name="email"
                    label={i18next.t(translates.LoginLabelsEmail)}
                    labelAlwaysOnTop={true}
                    autoFocus
                    autoComplete="username"
                    autoCapitalize="off"
                />
                <FormFieldInput
                    name="password"
                    type="password"
                    label={i18next.t(translates.LoginLabelsPassword)}
                    labelAlwaysOnTop={true}
                    autoComplete="password"
                    autoCapitalize="off"
                />
                <div>
                    <StyledLink
                        to={routerPaths.resetPassword.route}
                    >
                        {i18next.t(translates.LoginButtonsReset)}
                    </StyledLink>
                </div>
                <Alert
                    content={i18next.t(error)}
                    visible={!!error}
                />
            </Form>
            <RegisterLinkWrapper>
                <StyledHorzontalLine/>
                <StyledText>
                    {i18next.t(translates.LoginTextsRegister)}
                </StyledText>
                <Button
                    styleType='primary-outline'
                    title={i18next.t(translates.LoginButtonsRegister)}
                    onClick={() => handleChangeLoginByEmailPage('registration')}
                />
            </RegisterLinkWrapper>
        </>
    );
};
