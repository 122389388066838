import styled from 'styled-components';
import i18next from 'i18next';
import { Text } from '../../components';
import { translates } from '../../utilities';

const FooterContainer = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: ${({ theme }) => theme.colors.darkBlack};
`;

export const Footer = () => {
    return (
        <FooterContainer>
            <Text type="body2" color="white">{i18next.t(translates.FooterLabelsCopyright)}</Text>
        </FooterContainer>
    );
};
