import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import i18next from 'i18next';

import { translates } from '../utilities';
import { routerPaths } from '../configs';
import { AuthContext, ConfigContext } from '../contexts';
import { ApiErrorModel } from '../types/api';
import { Toast } from '../components/toast';
import { getCountry, getCountryApiUrl } from '../utilities/countryDefaults';

const country = getCountry();
const apiUrl = getCountryApiUrl(country);

export const useApi = () => {
    const { setAuth } = useContext(AuthContext);
    const { country, language } = useContext(ConfigContext) || {};
    const navigate = useNavigate();

    const api = useCallback(async <T>(params: AxiosRequestConfig) => {
        try {
            const response: AxiosResponse<T> = await axios({
                ...params,
                baseURL: params.baseURL ?? apiUrl,
                url: params.url,
                withCredentials: true,
                headers: {
                    'X-Api-Country': country,
                    languageCode: language
                }
            });

            if (response.status === 204) {
                return null as unknown as T;
            }

            return response.data;
        } catch (e) {
            const error = e as AxiosError<ApiErrorModel>;

            if (error?.response?.status === 401 || error?.response?.status === 403) {
                setAuth(null);
                localStorage.removeItem('auth');
                navigate(routerPaths.login.route, { replace: true });
            }

            if (error?.response?.status === 500) {
                Toast.error(i18next.t(translates.GlobalMessagesServerError));
            }

            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw error?.response?.data;
        }
    }, [country, language, navigate, setAuth]);

    return api;
};
