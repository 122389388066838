import { FC } from 'react';
import { PartnerServiceExperienceModel } from '../../../../../types/api';
import { FeedbackComment } from './FeedbackComment';

interface PartnerCommentsTabProps {
    experience: PartnerServiceExperienceModel;
}

export const PartnerCommentsTab: FC<PartnerCommentsTabProps> = ({ experience }) => {
    return (
        <>
            {experience.feedback!.map((feed, index) => (
                <FeedbackComment key={index} feedback={feed} />
            ))}
        </>
    );
};
