import i18next from 'i18next';
import { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { PartnerIcon } from '../../../../../components/partnerCard/PartnerIcon';
import { ConfigContext } from '../../../../../contexts';
import { ApiErrorModel, PartnerServiceExperienceModel } from '../../../../../types/api';
import { Modal } from '../../../../../components/modal';
import { ButtonProps } from '../../../../../components';
import { Toast } from '../../../../../components/toast';
import { useApi } from '../../../../../hooks/useApi';
import { PartnerTabs } from './PartnerTabs';
import { PartnerCardContent } from '../../../../../components/partnerCard/partnerCardContent';

const ModalStyled = styled(Modal).attrs({
    modalHeight: 'calc(87vh - 120px)',
})``;

const PartnerCardPartnerIconLarge = styled(PartnerIcon)`
    margin-top: -150px;
    position: absolute;
    width: 120px;
    height: 120px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
`;

const ModalTopContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 30px;
`;

const HeaderSpace = styled.div`
    height: 30px;
`;

interface PartnerExperienceModalProps {
    id: number;
    serviceId: number | string;
    name: string;
    partnerPictureUrl?: string | null;
    priceLabel?: string;
    price?: string | null;
    plannedMaterialExpenses?: string;
    plannedStartDate?: string | null;
    onClose: () => void;
    open: boolean;
    buttonProps?: ButtonProps[] | ButtonProps;
    shouldShowCommentsTab?: boolean;
    doneWorksAmount?: number | null;
    doneWorksAmountLabel?: string;
}

export const PartnerExperienceModal: FC<PartnerExperienceModalProps> = ({
    id,
    serviceId,
    name,
    partnerPictureUrl,
    priceLabel,
    price,
    plannedMaterialExpenses,
    plannedStartDate,
    onClose,
    open,
    buttonProps,
    shouldShowCommentsTab,
    doneWorksAmount,
    doneWorksAmountLabel,
}) => {
    const api = useApi();
    const { country } = useContext(ConfigContext);
    const { data, refetch, isLoading } = useQuery<PartnerServiceExperienceModel, ApiErrorModel>(
        `/partners/${id}/services/${serviceId}/${country}/experience`,
        () =>
            api<PartnerServiceExperienceModel>({
                url: `/partners/${id}/services/${serviceId}/${country}/experience`,
                method: 'GET',
            }),
        {
            enabled: false,
            select(data) {
                return data || {};
            },
            onError(err) {
                Toast.error(i18next.t(err.errorMessage!));
            },
        }
    );

    useEffect(() => {
        if (open) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const ModalHeader = () => {
        return <HeaderSpace />;
    };

    return (
        <ModalStyled open={open} isLoading={isLoading} Header={ModalHeader} onClose={() => onClose()}>
            <ModalTopContent>
                <PartnerCardPartnerIconLarge partnerPictureUrl={partnerPictureUrl} />
                <PartnerCardContent
                    name={name}
                    priceLabel={priceLabel}
                    price={price}
                    totalVotes={data?.totalVotes!}
                    rating={data?.rating!}
                    plannedMaterialExpenses={plannedMaterialExpenses}
                    plannedStartDate={plannedStartDate}
                    buttonProps={buttonProps}
                    commentsCount={data?.totalComments!}
                    shouldShowComments={false}
                    doneWorksAmount={doneWorksAmount}
                    doneWorksAmountLabel={doneWorksAmountLabel}
                />
            </ModalTopContent>
            <PartnerTabs shouldShowCommentsTab={shouldShowCommentsTab} experience={data!} />
        </ModalStyled>
    );
};
