import { FC, useState } from 'react';
import styled from 'styled-components';

const TabsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};
`;

interface TabsListItemProps {
    first?: boolean
    last?: boolean
    active: boolean
}

const TabsListItem = styled.div<TabsListItemProps>`
  background: ${({ active, theme }) => active ? theme.colors.white : theme.colors.grey2};
  color: ${({ active, theme }) => active ? theme.colors.black : theme.colors.grey6};
  border-top-left-radius: ${({ first }) => first ? 10 : 0}px;
  border-top-right-radius: ${({ last }) => last ? 10 : 0}px;
  padding: 16px 24px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.grey1};
  border-bottom: 0;
  margin-bottom: -1px;

  @media ${({ theme }) => theme.query.small} {
    padding: 10px;
    display: flex;
    align-items: center;
  }
`;

const Tab = styled.div`
  margin-top: 2rem;
`;

interface TabsProps {
    tabs: {
        title: string
        render(): JSX.Element | null
    }[],
    activeTabIndex?: number,
}

export const Tabs: FC<TabsProps> = ({ tabs, activeTabIndex }) => {
    const [tabIndex, setTabIndex] = useState(activeTabIndex || 0);

    return (
        <div>
            <TabsList>
                {tabs.map((tab, i) => (
                    <TabsListItem
                        key={i}
                        active={i === tabIndex}
                        first={i === 0}
                        last={i === tabs.length - 1}
                        onClick={() => setTabIndex(i)}
                    >
                        {tab.title}
                    </TabsListItem>
                ))}
            </TabsList>
            <Tab>
                {tabs[tabIndex]?.render?.()}
            </Tab>
        </div>
    );
};
