import { useEffect } from 'react';
import { useLoginHandler } from '../utilities';

export const GoogleAuth = () => {
    const [afterLogin] = useLoginHandler('google');

    useEffect(() => {
        afterLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<></>);
};
