import { useState } from 'react';
import { Modal, ModalProps } from '../components/modal';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
type OpenModalData = Optional<Omit<ModalProps, 'open'>, 'onClose'>;

export const useModal = () => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<OpenModalData>();

    const openModal = (data: OpenModalData) => {
        setOpen(true);
        setData(data);
    };

    const handleClose = (cancelButtonClicked?: boolean) => {
        setOpen(false);
        data?.onClose?.(cancelButtonClicked);
    };

    const handleOkClick = () => {
        setOpen(false);
        data?.onOk?.();
    };

    const ModalComponent = (
        <Modal
            {...data}
            open={open}
            onClose={handleClose}
            onOk={handleOkClick}
        >
            {data?.children}
        </Modal>
    );

    return [openModal, ModalComponent] as const;
};
