import { FC } from 'react';

interface FieldTitleProps {
    label: string | JSX.Element | undefined;
    focused?: boolean;
}

export const FieldTitle: FC<FieldTitleProps> = ({ label, focused }) => {
    if (!label) {
        return null;
    }

    return <div className={`form_item_label ${focused ? 'focused' : ''}`}>{label}</div>;
};
