import { FC, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { useField } from 'formik';

import { FormFieldUpload, RadioButtonGroup } from '../../../../components';
import { translates, WorkTypes } from '../../../../utilities';
import { DescriptionField, Title } from '../components';
import { isStandardBiddingOnly, useLogEvent } from '../utilities';
import { ServiceGroupModel } from '../../../../types/apigw';
import { ConfigContext } from '../../../../contexts';
import { theme } from '../../../../theme';
import { PopoverButton } from '../components/PopoverButton';
import { useGetServiceItems } from '../queries';

const StyledFormFieldUpload = styled(FormFieldUpload)`
    margin-bottom: 16px;
`;

const RadioItemContainer = styled.div`
    border-radius: 10px;
    border: ${({ theme }) => `1px solid ${theme.colors.grey8}`};
    display: flex;
    flex: 1;
    min-height: 113px;
    padding: 23px 16px 23px 26px;
    margin-bottom: 16px;
    ${({ theme }) => `background-color: ${theme.colors.white}`};

    #radioButtonContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    span {
        color: ${({ theme }) => theme.colors.black};
    }
`;

const PopoverContentLabel = styled.span`
    color: ${({ theme }) => theme.colors.white};
`;

const MaterialsContainer = styled.div`
    display: inline-flex;
    align-items: center;
`;

interface InformationStepProps {
    servicesGroups: ServiceGroupModel[];
}

export const InformationStep: FC<InformationStepProps> = ({ servicesGroups }) => {
    const [{ value: workTypeValue }] = useField('workType');
    const { country, language } = useContext(ConfigContext);
    const [{ value: serviceIdValue }] = useField('serviceId');
    const [cv1, cv2, { setValue: setCountryVat }] = useField('countryVat');
    const { data } = useGetServiceItems(serviceIdValue, language);
    useLogEvent('information-step-step3');
    useLogEvent(
        workTypeValue === WorkTypes.STANDARD ? 'change-jobtype-price-list-step2' : 'change-jobtype-custom-step2'
    );

    useEffect(() => {
        setCountryVat(data.countryVat);
    }, [data?.countryVat]);

    const expectedTimeOptions = useMemo(
        () => [
            { label: i18next.t(translates.OrderStepsLabelsMaterialsSelectHandyman), value: true },
            {
                label: i18next.t(translates.OrderStepsLabelsMaterialsSelectClient),
                value: false,
            },
        ],
        [language]
    );

    const Description = useMemo(() => {
        if (isStandardBiddingOnly(country)) {
            return <DescriptionField tooltip servicesGroups={servicesGroups} />;
        }

        return undefined;
    }, [workTypeValue, country, servicesGroups]);

    const PopoverContent = (
        <PopoverContentLabel>
            {i18next.t(translates.OrderStepsLabelsMaterialsSelectHelp)}
        </PopoverContentLabel>
    );

    return (
        <>
            <Title>{i18next.t(translates.OrderStepsLabelsPhotos)}</Title>
            <StyledFormFieldUpload name="files" />
            {Description}
            <MaterialsContainer>
                <Title>{i18next.t(translates.OrderStepsLabelsMaterialsSelect)}</Title>
                <PopoverButton>{PopoverContent}</PopoverButton>
            </MaterialsContainer>
            <RadioItemContainer>
                <RadioButtonGroup name="receiveMaterialsFromPartner" options={expectedTimeOptions} />
            </RadioItemContainer>
        </>
    );
};
