import { Location } from 'react-router-dom';
import { CreateOrderFormModel } from '../pages/public/createOrder/utilities';
import { getCountryCurrencyLabel } from './countryDefaults';

export const logPageView = (location: Location) => {
    if (!window.ga?.getAll) {
        return;
    }

    const [tracker] = window.ga.getAll();
    if (tracker) {
        tracker.set('page', location.pathname + location.search);
        tracker.send('pageview');
    }
};

export const logEvent = (name: string, data?: Record<string, any>) => {
    if (!window.dataLayer || process.env.NODE_ENV === 'development') {
        return;
    }

    window.dataLayer.push({
        event: name,
        ...data,
    });
};

export const purchaseData = (order: CreateOrderFormModel) => {
    return {
        ecommerce: {
            transaction_id: order.orderId,
            value: order.fullPrice,
            currency: getCountryCurrencyLabel(order.country),
            coupon: '',
            items: Object.values(order.serviceItems || {}).map((items) => ({
                item_id: items.id,
                item_name: items.description,
                price: items.price,
                quantity: items.quantity,
            })),
        },
    };
};

export const getExperimentVariant = (id: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const variantFromQuery = urlSearchParams.get(id);
    const variantFromOptimize = window.google_optimize ? window.google_optimize.get(id) : null;
    return variantFromQuery || variantFromOptimize;
};

export const PARTNERLESS_SUMMARY_EXPERIMENT_ID = 'k5uUzqL0QkiIP7gO4Qj1Lg';
