import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import debounce from 'lodash.debounce';
import { getLocalContactInfo, getPostLoginNavigationOption, NavigationOptionsEnum, translates, validateUserData, CreateButtonStatuses } from '../../../utilities';
import { useNotification } from '../../../hooks/useNotification';
import { Button, ContentLoading, Spinner } from '../../../components';
import { theme } from '../../../theme';
import { IncompleteProfileAlert } from './components/IncompleteProfileAlert';
import { routerPaths } from '../../../configs';
import { AuthContext, ConfigContext } from '../../../contexts';
import { IncompleteProfileModal } from '../../../pages/auth/profile';
import { FetchTypes, useOrderFetch } from '../../../hooks/useOrderFetch';
import { initialTabbedOrdersModel, InitialTabbedOrdersModelType, OrderCardRef } from './components/orderUtils';
import { OrderCard } from './components/OrderCard';
import PlusIcon from '../../../assets/images/plus-icon.svg';
import { NoOrders } from './components/NoOrders';
import { useApi } from '../../../hooks/useApi';
import { OrdersHeader } from './components/OrdersHeader';
import { getIsPersonalCodeEnabled, getIsPersonalCodeManadatory } from '../../../utilities/countryDefaults';

const StyledIncompleteProfileAlert = styled(IncompleteProfileAlert)`
    margin-top: 16px;
`;

const OrderCardStyled = styled(OrderCard)`
    margin: 32px 0;
`;

const LoadMoreContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-bottom: 32px;
`;

const NoOrdersMessage = styled.div`
    margin: 32px 0;
    text-align: center;
`;

type OrderListStatusType = { [k: string]: number };

export const OrdersContainer = () => {
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [selectedTabKey, setSelectedTabKey] = useState<keyof InitialTabbedOrdersModelType>(initialTabbedOrdersModel.allOrders.key);
    const [orderListStatus, setOrderListStatus] = useState<OrderListStatusType | null>(null);
    const [searchValue, setSearchValue] = useState('');
    const [isReady, setIsReady] = useState(false);
    const { auth } = useContext(AuthContext);
    const { country } = useContext(ConfigContext);
    const fetchApi = useApi();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery({
        query: theme.query.small,
    });
    const lang = i18next.language;

    const orderCardsRef = useRef<OrderCardRef[]>([]);

    const { orders, loading, canLoadMore, ordersSearchValue, fetch, setActiveKey } = useOrderFetch();
    const debouncedSearch = useMemo(() => (
        debounce((search: string) => {
            fetch(selectedTabKey, FetchTypes.SEARCH, { search });
        }, 350)
    ), [selectedTabKey, fetch]);

    const getOrderListStatus = useCallback(() => {
        fetchApi<OrderListStatusType>({
            url: 'orders/status'
        }).then(setOrderListStatus);
    }, [fetchApi]);

    const showCreateOrderSuggestion = useMemo(() => {
        return orders.length === 0 || !orders.some((item) => CreateButtonStatuses.includes(item.statusLabel!));
    }, [orders]);

    const tabsCount = useMemo(() => {
        if (!orderListStatus) {
            return null;
        }

        return Object.keys(initialTabbedOrdersModel).reduce((pre, cur) => {
            const count = Object.keys(orderListStatus)
                .filter((status) => initialTabbedOrdersModel[cur as keyof InitialTabbedOrdersModelType].filter.includes(status))
                .map((key) => orderListStatus[key])
                .reduce((pre, cur) => pre + cur, 0);

            return Object.assign(pre, { [cur]: count });
        }, {} as { [key in keyof InitialTabbedOrdersModelType]: number });
    }, [orderListStatus]);

    // Extra work needed to keep tab titles translated
    const ordersTabs = useMemo(() => {
        const values = Object.values(initialTabbedOrdersModel);
        return values.map((props) => ({ ...props, title: i18next.t(props.translateKey) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    const reloadOrders = useCallback(async () => {
        return Promise.all([
            fetch(selectedTabKey, FetchTypes.RELOAD, { search: searchValue }),
        ]);
    }, [fetch, selectedTabKey, searchValue]);

    useEffect(() => {
        fetch(selectedTabKey, FetchTypes.INITIAL).then(() => setIsReady(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const navOption = getPostLoginNavigationOption(auth, country);
        switch (navOption) {
        case NavigationOptionsEnum.ORDER_SUCCESS:
            navigate(routerPaths.orderSuccessPartner.route, { replace: true });
            break;
        case NavigationOptionsEnum.ORDER_CREATED:
            setShowProfileModal(true);
            break;
        default:
            break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getOrderListStatus();
    }, [getOrderListStatus]);

    useEffect(() => {
        fetch(selectedTabKey, FetchTypes.TAB_CHANGE, { search: searchValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTabKey]);

    useEffect(() => {
        orderCardsRef.current = orderCardsRef.current.slice(0, orders.length);
    }, [orders]);

    const refreshExpandedOrders = useCallback(() => {
        const expandedOrders = orderCardsRef.current.filter((ref) => ref.expanded);
        expandedOrders.forEach((expanded) => expanded.getOrderData());
    }, [orderCardsRef]);

    useNotification(() => {
        reloadOrders();
        refreshExpandedOrders();
        getOrderListStatus();
    }, 'ReloadOrders');

    useEffect(() => {
        if (location.state?.displayIncompleteProfileModal) {
            setShowProfileModal(true);
        }
    }, [location.state]);

    const handleProfileCompletion = () => {
        const contactInfo = getLocalContactInfo();
        if (contactInfo.email === auth?.email || contactInfo.phone === auth?.phone) {
            navigate(routerPaths.orderSuccessPartner.route, { replace: true });
        }
    };

    const validateCurrentUser = async () => {
        try {
            if (auth && auth.clientId && !auth.phone) {
                navigate(routerPaths.profileEdit.route);
                return;
            }
            if (auth?.email || auth?.phone) {
                await validateUserData(auth!, country);
            }
        } catch (error) {
            setShowProfileModal(true);
            throw error;
        }
    };

    const handleCreateClick = async () => {
        await validateCurrentUser();
        navigate(routerPaths.createOrder.route);
    };

    const handleLoadMore = () => {
        fetch(selectedTabKey, FetchTypes.LOAD, { search: searchValue });
    };

    const handleTabChange = (key: keyof InitialTabbedOrdersModelType) => {
        setSelectedTabKey(key);
        setActiveKey(key);
    };

    const handleSearch = (input: string) => {
        if (input.length >= 3 || (input.length < searchValue.length)) {
            debouncedSearch(input);
        }
        setSearchValue(input);
    };

    const handleSearchClear = () => {
        debouncedSearch('');
        setSearchValue('');
    };

    const handleSearchClick = () => {
        debouncedSearch(searchValue);
    };

    if (!isReady) {
        return <ContentLoading />;
    }

    let showIncompleteProfileAlert: boolean;

    if (auth?.isCompany) {
        showIncompleteProfileAlert = !auth?.companyName || !auth?.companyCode || !auth?.phone || !auth?.companyRegistrationAddressText;
    } else {
        const isPersonalCodeMandatory = getIsPersonalCodeManadatory(country);

        showIncompleteProfileAlert = !auth?.firstName || !auth?.lastName || (isPersonalCodeMandatory && !auth?.personalCode);
    }

    return (
        <>
            {showIncompleteProfileAlert && <StyledIncompleteProfileAlert />}
            {auth?.isCompany && (
                <OrdersHeader
                    searchValue={searchValue}
                    onSearch={handleSearch}
                    onClearSearch={handleSearchClear}
                    onClickSearch={handleSearchClick}
                    filterOptions={ordersTabs}
                    activeFilterKey={selectedTabKey}
                    onChangeFilter={handleTabChange}
                    tabsCount={tabsCount}
                />
            )}
            {showCreateOrderSuggestion && <NoOrders onCreateClick={handleCreateClick} />}
            <PullToRefresh
                refreshingContent={<Spinner />}
                pullingContent={undefined}
                onRefresh={reloadOrders}
                isPullable={isMobile}
            >
                <>
                    {!orders.length && !loading && (
                        <NoOrdersMessage>
                            {ordersSearchValue
                                ? i18next.t(translates.OrdersLabelsNoFoundOrders)
                                : i18next.t(translates.OrdersLabelsNoOrders)
                            }
                        </NoOrdersMessage>
                    )}
                    {orders.map((order, idx) => (
                        <OrderCardStyled
                            // eslint-disable-next-line no-return-assign
                            ref={(el) => orderCardsRef.current[idx] = el as OrderCardRef}
                            key={order.orderId}
                            outterOrder={order}
                        />
                    ))}
                    {canLoadMore && !loading && (
                        <LoadMoreContainer>
                            <Button
                                iconLeft={PlusIcon}
                                styleType="primary-inverted"
                                title={i18next.t(translates.OrdersButtonsLoadMore)}
                                loading={loading}
                                onClick={handleLoadMore}
                            />
                        </LoadMoreContainer>
                    )}
                </>
            </PullToRefresh>
            <IncompleteProfileModal showModal={showProfileModal} setShowModal={setShowProfileModal} onOK={handleProfileCompletion} />
        </>
    );
};
