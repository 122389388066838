import { FC } from 'react';
import styled from 'styled-components';

interface ButtonContainerProps {
    $selected?: boolean;
    $disabled?: boolean;
}

const ButtonContainer = styled.div<ButtonContainerProps>`
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 10px;
    background-color: ${({ $disabled, $selected, theme }) => ($disabled || !$selected) ? theme.colors.grey12 : theme.colors.white};
`;

interface IconProps {
    $url?: string;
}

const Icon = styled.img.attrs({
    width: 12,
    height: 13,
})<IconProps>`
    margin-right: 8px;
`;

const RadioItemMainInner = styled.div``;

interface RadioItemDescProps {
    $disabled?: boolean;
    $selected?: boolean;
}

const RadioItemLabel = styled.span<RadioItemDescProps>`
    font-size: .875rem;
    ${({ $selected }) => $selected && 'font-weight: 500'};
    color: ${({ $selected, theme }) => $selected ? theme.colors.primary : theme.colors.grey5};
    margin-bottom: .938rem;
    font-size: .75rem;
`;

export interface RadioIconButtonProps {
    onClick: () => void;
    disabled?: boolean;
    selected?: boolean;
    label?: string;
    icon?: string;
    iconSelected?: string;
}

export const RadioIconButton: FC<RadioIconButtonProps> = ({ onClick, disabled, selected, label, icon, iconSelected }) => {
    return (
        <ButtonContainer onClick={() => !disabled && onClick()} $selected={selected} $disabled={disabled}>
            {Boolean(icon) && (
                <Icon src={(iconSelected && selected) ? iconSelected : icon} />
            )}
            <RadioItemMainInner>
                {label && (
                    <RadioItemLabel $selected={selected} $disabled={disabled}>
                        {label}
                    </RadioItemLabel>
                )}
            </RadioItemMainInner>
        </ButtonContainer>
    );
};
