import { useState, useContext, useEffect, FC, useMemo } from 'react';
import i18next from 'i18next';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { useIsFetching, useQueries } from 'react-query';

import { Collapse } from 'antd';
import NumberIcon from '../../../../assets/images/number-icon.svg';
import LocationIcon from '../../../../assets/images/location-icon.svg';
import InfoIcon from '../../../../assets/images/info-icon.svg';
import PriceIcon from '../../../../assets/images/price-green.svg';

import { AuthContext, ConfigContext } from '../../../../contexts';
import { useApi } from '../../../../hooks/useApi';
import { OrderSummaryResult, PartnerModel, ApiErrorModel } from '../../../../types/api';
import { BiddingTypes, WorkTypes, content, translates } from '../../../../utilities';
import { CountryEnum, getCountryCurrency } from '../../../../utilities/countryDefaults';
import { ContentLoading, FormFieldCheckbox, HtmlContent, FormFieldPhone } from '../../../../components';
import { CreateOrderFormModel, useLogEvent } from '../utilities';
import { ServiceGroupModel } from '../../../../types/apigw';
import { Toast } from '../../../../components/toast';
import { useModal } from '../../../../hooks/useModal';
import { MetaItem } from '../components';
import { SelectBiddingType } from '../components/SelectBiddingType';
import { useGetFavoritePartner } from '../queries';

const Title = styled.p`
    color: ${({ theme }) => theme.colors.black};
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
`;

const MetaItemLabel = styled.span`
    font-size: .875rem;
    color: ${({ theme }) => theme.colors.grey6};
`;

const InfoText = styled.p`
    font-size: .875rem;
    color: ${({ theme }) => theme.colors.grey6};
`;

const SummaryInfoContainer = styled.div`
    margin-bottom: calc(29px - 1rem);
    & div:nth-last-child(1) > .metaItemLabelContainer {
        border-bottom: none;
    }
`;

const PanelTextContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.grey7}; 
    border: solid 1px ${({ theme }) => theme.colors.grey9};
    border-radius: 10px;
    padding: 1rem;
`;

const PreliminaryPriceLabel = styled.span`
    color: ${({ theme }) => theme.colors.white};
`;

const StyledColapse = styled(Collapse)`
    .ant-collapse-header {
        padding: 0 !important;
    }
    margin-top: 14px;
`;

interface SeperatorProps {
    $spaceBelow?: number;
}

const Seperator = styled.div<SeperatorProps>`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grey8};
    ${({ $spaceBelow }) => $spaceBelow && `margin-bottom: ${$spaceBelow}px`};
`;

const PanelHeader = (
    <Title>{i18next.t(translates.OrderStepsLabelsSummaryStepNextTitle)}</Title>
);

interface SummaryStepProps {
    servicesGroups: ServiceGroupModel[];
}

export const SummaryStep: FC<SummaryStepProps> = ({ servicesGroups }) => {
    const { values, setFieldValue } = useFormikContext<CreateOrderFormModel>();
    const [openAcceptModal, AcceptModal] = useModal();
    const { country } = useContext(ConfigContext);
    const { auth } = useContext(AuthContext);
    const api = useApi();
    useLogEvent('order-review-step-step5');
    const [assignablePartner, setAssignablePartner] = useState<PartnerModel | null>(null);
    const isLoading = useIsFetching();
    const { data } = useGetFavoritePartner(values.serviceId, values.longitude, values.latitude);

    const isTermsVisible = values.biddingType !== BiddingTypes.standard;

    const fetchAssignablePartner = () => {
        const searchParams = new URLSearchParams({
            longitude: String(values.longitude),
            latitude: String(values.latitude),
        });

        Object.values(values.serviceItems || {}).forEach((serviceItem) => {
            searchParams.append('ServiceItemsIds', serviceItem.id!);
        });

        return api<{ result: PartnerModel }>({
            baseURL: window.ENVIRONTMENT_VARIABLES.REACT_APP_API_GW_URL,
            url: `services/${values.serviceId}/partners/job-assignable?${searchParams.toString()}`,
        });
    };

    useEffect(() => {
        if (data.length > 0) {
            const [partner] = data;
            setFieldValue('isPartnerSelectedFromFavorites', true);
            setFieldValue('partnerId', partner.partnerId!);
            setFieldValue('biddingType', BiddingTypes.fixed);
            if (country !== CountryEnum.LT && auth?.isCompany) {
                setFieldValue('biddingType', BiddingTypes.standard);
            }
        }
    }, [data.length]);

    const fetchSummary = () => {
        const serviceItemsObj = Object.values(values.serviceItems!);

        return api<OrderSummaryResult>({
            url: '/orders/summary',
            data: {
                countryVat: values.countryVat,
                serviceItems: serviceItemsObj,
                description: values.description,
                expectedPrice: null,
                countryCode: country,
                expectedTime: values.expectedTime,
                materialsDelivery: values.receiveMaterialsFromPartner
            },
            method: 'POST',
        });
    };

    const handleError = (err: unknown) => {
        const error = err as ApiErrorModel;
        if (error.errorMessage) {
            Toast.error(error.errorMessage);
        }
    };

    const [assignablePartnerResult, summaryResult] = useQueries([
        { queryKey: 'job-assignable', queryFn: fetchAssignablePartner, onError: handleError },
        { queryKey: 'summary', queryFn: fetchSummary, onError: handleError },
    ]);

    useEffect(() => {
        if (assignablePartnerResult.data) {
            const { result: assignablePartner } = assignablePartnerResult.data;
            setAssignablePartner(assignablePartner);
        }
    }, [assignablePartnerResult.data]);

    useEffect(() => {
        if (summaryResult.data) {
            const { description, totalPrice } = summaryResult.data;
            setFieldValue('fixedDescription', description);
            setFieldValue('fullPrice', totalPrice);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryResult.data]);

    useEffect(() => {
        if ((!assignablePartner || (values.biddingType !== BiddingTypes.fixed)) && !values.isPartnerSelectedFromFavorites) {
            setFieldValue('partnerId', null);
            return;
        }

        if (!values.isPartnerSelectedFromFavorites) {
            setFieldValue('partnerId', assignablePartner?.partnerId);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignablePartner, values.biddingType, values.isPartnerSelectedFromFavorites]);

    const metaItems = useMemo(() => {
        const items = [
            {
                label: i18next.t(translates.OrderStepsLabelsSummaryStepFixedPriceMetaItem),
                icon: PriceIcon,
                value: !values.fullPrice ? i18next.t(translates.OrderCardLabelsOrderAgreedPricing) : getCountryCurrency(country, values.fullPrice!, false),
                highlightedLabel: true,
                infoText: (
                    <PreliminaryPriceLabel>
                        {i18next.t(translates.OrderStepsSubtitlesSummaryStep)}
                    </PreliminaryPriceLabel>
                ),
            },
            {
                label: i18next.t(translates.OrderStepsLabelsSummaryStepLocationMetaItem),
                icon: LocationIcon,
                value: values.address,
            },
            {
                label: i18next.t(translates.OrderStepsLabelsSummaryStepInfoMetaItem),
                icon: InfoIcon,
                value: values.fixedDescription ?? values.description,
            },
            {
                label: i18next.t(translates.OrderStepsLabelsSummaryStepServiceMetaItem),
                icon: NumberIcon,
                value: servicesGroups.find((sg) => sg.defaultServiceId.toString() === values.serviceId)?.serviceGroupCodeTranslation,
            },
        ];

        return items;
    }, [values.address, values.fixedDescription, values.description, values.serviceId, values.fullPrice, servicesGroups, country]);

    const getPanelHelpLabel = () => {
        if (values.biddingType === BiddingTypes.fixed && values.fullPrice !== 0) {
            return translates.OrderProgressSubtitlesStatusPricedHelp;
        }
        if (values.biddingType === BiddingTypes.standard) {
            return translates.OrderProgressSubtitlesStatusStandardHelp;
        }
        return translates.OrderProgressSubtitlesStatusHelp;
    };

    const PanelText = (
        <PanelTextContainer>
            <MetaItemLabel>{i18next.t(getPanelHelpLabel())}</MetaItemLabel>
        </PanelTextContainer>
    );

    const handleOpenContractTerms = () => {
        openAcceptModal({
            children: <HtmlContent contentKey={content.OrderAcceptBidAgreeWithTerms}/>,
            cancelButtonTitle: i18next.t(translates.OrderCardButtonsAgreeModalCancel),
            okButtonTitle: i18next.t(translates.OrderCardButtonsAgreeModalOk),
            onOk: () => setFieldValue('contractTerms', true),
            onClose: () => setFieldValue('contractTerms', false),
        });
    };

    if (isLoading) return <ContentLoading />;

    return (
        <>
            {!auth && (
                <>
                    <Title>
                        {i18next.t(translates.OrderStepsLabelsSummaryStepPublicUserHeader)}
                    </Title>
                    <InfoText>
                        {i18next.t(translates.OrderStepsLabelsSummaryStepPublicUserInfo)}
                    </InfoText>
                    <FormFieldPhone
                        name="phone"
                        namePrefix="phoneCode"
                        label={i18next.t(translates.RegisterLabelsPhone)}
                        size="large"
                    />
                </>
            )}
            <Title>
                {i18next.t(translates.OrderStepsLabelsSummaryStepTitle)}
            </Title>
            <SummaryInfoContainer>
                {metaItems.map((item, idx) => (
                    <MetaItem
                        key={`${item.label}-${idx}`}
                        icon={item.icon}
                        highlighted={item.highlightedLabel}
                        label={item.label}
                        infoText={item.infoText}
                    >
                        {item.value}
                    </MetaItem>
                ))}
            </SummaryInfoContainer>
            <Seperator $spaceBelow={14} />
            <SelectBiddingType partners={data} />
            <Seperator />
            <StyledColapse expandIconPosition="right" ghost={true}>
                <Collapse.Panel header={<Title>{i18next.t(translates.OrderStepsLabelsSummaryStepNextTitle)}</Title>} key="info-text-panel">{PanelText}</Collapse.Panel>
            </StyledColapse>
            <Seperator $spaceBelow={24} />
            {!auth && (
                <FormFieldCheckbox
                    name="additionalInfo"
                    labelCheckbox={i18next.t(translates.OrderStepsLabelsSummaryStepUpdatesCheckboxLabel) as string}
                />
            )}
            {isTermsVisible && (
                <FormFieldCheckbox
                    name="contractTerms"
                    labelCheckbox={i18next.t(translates.OrderStepsLabelsSummaryStepCheckboxLabel) as string}
                    clickableLabel={i18next.t(translates.OrderStepsLabelsSummaryStepPressableCheckboxLabel)}
                    onClickLabel={handleOpenContractTerms}
                />
            )}
            {AcceptModal}
        </>
    );
};
