import i18next from 'i18next';
import React, { FC, useMemo } from 'react';

import { OrderDocumentResultItem, ImageModel, OrderBidModel, OrderModel, OrderSelfModel, MTCModel } from '../../../../types/api';
import { CanceledOrderStatuses, translates } from '../../../../utilities';
import { DocumentsTab } from './DocumentsTab';
import { OrderTabsInformationTab } from './OrderTabsInformationTab';
import { ProgressTab } from './ProgressTab';

import { Tabs } from './Tabs';

interface OrderTabsProps {
    order: OrderModel & OrderSelfModel;
    clientImages: ImageModel[];
    partnerImages: ImageModel[];
    materialsImages: ImageModel[];
    acceptBid: (partnerId: number) => void;
    approvedBid?: OrderBidModel;
    bidCount?: number;
    documents?: OrderDocumentResultItem[];
    mtcDetails?: MTCModel
}

export const OrderTabs: FC<OrderTabsProps> = ({
    order,
    clientImages,
    partnerImages,
    materialsImages,
    acceptBid,
    approvedBid,
    bidCount,
    documents,
    mtcDetails,
}) => {
    const informationTab = useMemo(() => ({
        title: i18next.t(translates.OrderTabsTitlesInformation),
        render: () => (
            <OrderTabsInformationTab order={order} images={clientImages} />
        )
    }), [order, clientImages]);

    const progressTab = useMemo(() => ({
        title: i18next.t(translates.OrderTabsTitlesProgress),
        render: () => (
            <ProgressTab
                acceptBid={acceptBid}
                approvedBid={approvedBid}
                bidCount={bidCount}
                clientImages={clientImages}
                partnerImages={partnerImages}
                materialsImages={materialsImages}
                order={order}
            />
        )
    }), [order, partnerImages, clientImages, materialsImages, bidCount, approvedBid, acceptBid]);

    const invoiceTotalPrice = order.clientInvoices
        .sort((a, b) => {
        // Fallback to a large number to ensure undefined dates are sorted last
            const dateATimestamp = a.createdAt ? new Date(a.createdAt).getTime() : Number.MAX_SAFE_INTEGER;
            const dateBTimestamp = b.createdAt ? new Date(b.createdAt).getTime() : Number.MAX_SAFE_INTEGER;

            return dateBTimestamp - dateATimestamp;
        })[0]?.totalPrice;

    const documentsTab = useMemo(() => ({
        title: i18next.t(translates.OrderTabsTitlesDocuments),
        render: () => (
            <DocumentsTab
                orderId={order.orderId!}
                documents={documents}
                mtcDetails={mtcDetails}
                materialPrice={order?.pricing?.materialPrice}
                invoiceTotalPrice={invoiceTotalPrice}
                orderWorkPrice={order.pricing?.workPrice}
                isPaymentNoteEnabled={order.isPaymentNoteEnabled}
                isPaymentSplitEnabled={order.isPaymentSplitEnabled}
            />
        )
    }), [order.orderId, order.pricing?.materialPrice, order.pricing?.workPrice, order.isPaymentNoteEnabled, order.isPaymentSplitEnabled, documents, mtcDetails, invoiceTotalPrice]);

    const tabs = useMemo(() => {
        const toBeShown: typeof documentsTab[] = [];
        if (!CanceledOrderStatuses.includes(order.statusLabel!)) {
            toBeShown.push(progressTab);
        }

        toBeShown.push(informationTab);
        if (documents && documents.length > 0) {
            toBeShown.push(documentsTab);
        }

        return toBeShown;
    }, [order, progressTab, informationTab, documentsTab, documents]);

    return (
        <Tabs tabs={tabs} />
    );
};
