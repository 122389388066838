import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import styled from 'styled-components';

import { routerPaths } from '../../../configs';
import { translates } from '../../../utilities';
import { AuthContext, ConfigContext } from '../../../contexts';
import EditIcon from '../../../assets/images/edit-icon-green.svg';
import { getIsAddressEnabled, getIsPersonalCodeEnabled, CountryEnum } from '../../../utilities/countryDefaults';
import { Button } from '../../../components';
import { Card } from '../../../components/cards';
import { Checkbox } from '../../../components/checkbox';

const ValuesContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey9};
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.grey6};
    font-size: .875rem;

    > div:nth-child(2) {
        color: ${({ theme }) => theme.colors.grey11};
    }
`;

const ButtonContainer = styled.div`
    margin: 16px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CheckboxContainer = styled.div`
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
`;

interface ProfileRowProps {
    label: string;
    value?: string | number | undefined | null;
    hidden?: boolean;
}

const ProfileRow: FC<ProfileRowProps> = ({ hidden, ...props }) => {
    if (hidden) {
        return null;
    }

    return (
        <ValuesContainer>
            <div>{props.label}</div>
            <div>{props.value}</div>
        </ValuesContainer>
    );
};

const StyledProfileRow = styled(ProfileRow)`
    color: ${({ theme }) => theme.colors.grey6};
    width: 100%;
`;

export const ProfileContainer = () => {
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();
    const { country } = useContext(ConfigContext);

    return (
        <Card titleText={i18next.t(translates.ProfileTitlesUpdateForm)}>
            <StyledProfileRow label={i18next.t(translates.ProfileLabelsEmail)} value={auth!.email} />
            <StyledProfileRow label={i18next.t(translates.ProfileLabelsName)} value={auth!.firstName} />
            <StyledProfileRow label={i18next.t(translates.ProfileLabelsSurname)} value={auth!.lastName} />
            {!auth!.isCompany && getIsAddressEnabled(country) && (
                <StyledProfileRow label={i18next.t(translates.RegisterLabelsInvoiceAddress)} value={auth!.invoiceAddress} />
            )}
            {!auth!.isCompany && getIsPersonalCodeEnabled(country) && <>
                <StyledProfileRow label={i18next.t(translates.PersonalCodeLabel)} value={auth!.personalCode} />
            </>}
            <StyledProfileRow label={i18next.t(translates.ProfileLabelsPhone)} value={auth!.phone} />

            {auth!.isCompany && (<>
                <StyledProfileRow label={i18next.t(translates.ProfileLabelsCompanyName)} value={auth!.companyName} />
                <StyledProfileRow hidden={country !== CountryEnum.LT} label={i18next.t(translates.ProfileLabelsCompanyVatCode)} value={auth!.companyVatCode} />
                <StyledProfileRow label={i18next.t(translates.ProfileLabelsCompanyCode)} value={auth!.companyCode} />
                <StyledProfileRow label={i18next.t(translates.RegisterLabelsCompanyRegistrationAddress)} value={auth!.companyRegistrationAddressText} />
            </>)}

            <CheckboxContainer>
                <Checkbox
                    label={i18next.t(translates.ProfileLabelsAllowMarketing) as string}
                    value={!!auth!.allowToUseEmailForMarketingPurposes}
                    disabled
                />
                <Checkbox
                    label={i18next.t(translates.ProfileLabelsAllowRecieveSmsNotifications) as string}
                    value={!!auth!.recieveSmsNotifications}
                    disabled
                />
                <Checkbox
                    label={i18next.t(translates.ProfileLabelsAllowRecieveEmailNotifications) as string}
                    value={!!auth!.recieveEmailNotifications}
                    disabled
                />
            </CheckboxContainer>

            <ButtonContainer>
                <Button
                    iconLeft={EditIcon}
                    loading={false}
                    styleType='text-primary'
                    title={i18next.t(translates.ProfileButtonsEdit)}
                    onClick={() => navigate(routerPaths.profileEdit.route)}
                />
            </ButtonContainer>
        </Card>
    );
};
