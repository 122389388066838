export const ConstantsOrderStatus = {
    AwaitingApproval: 'Order.Statuses.AwaitingApproval',
    AwaitingCompletion: 'Order.Statuses.AwaitingCompletion',
    Cancelled: 'Order.Statuses.StatusCancelled',
    CancelInitiated: 'Order.Statuses.CancelInitiated',
    Completed: 'Order.Statuses.Completed',
    Created: 'Order.Statuses.StatusCreated',
    InProgress: 'Order.Statuses.InProgress',
    Resolved: 'Order.Statuses.Resolved',
    ReadyForPayment: 'Order.Statuses.ReadyForPayment',
    Paid: 'Order.Statuses.Paid',
    CreatedAsPreorder: 'Order.Statuses.StatusCreatedAsPreorder',
    WaitingConfirmation: 'Order.Statuses.WaitingConfirmation',
    Primary: 'Order.Statuses.Primary',
};

export const Errors = {
    EmailNeedsConfirmation: 'Login.Errors.EmailNotConfirmed'
};

export const CanceledOrderStatuses = [
    ConstantsOrderStatus.Cancelled,
    ConstantsOrderStatus.CancelInitiated,
];

const waitingForPaymentStatuses = [
    ConstantsOrderStatus.AwaitingCompletion,
    ConstantsOrderStatus.Completed,
    ConstantsOrderStatus.ReadyForPayment,
];

export const WaitingForActionStatuses = [
    ...waitingForPaymentStatuses,
    ConstantsOrderStatus.Resolved,
    ConstantsOrderStatus.AwaitingApproval,
];

export const WaitingForBidStatuses = [
    ConstantsOrderStatus.Created,
    ConstantsOrderStatus.AwaitingApproval,
];

export const InProgressStatuses = [
    ConstantsOrderStatus.InProgress,
];

export const ResolvedStatuses = [
    ...waitingForPaymentStatuses,
    ConstantsOrderStatus.Resolved,
];

export const CompletedOrderStatuses = [
    ConstantsOrderStatus.Resolved,
    ConstantsOrderStatus.AwaitingCompletion,
    ConstantsOrderStatus.Completed,
    ConstantsOrderStatus.ReadyForPayment,
    ConstantsOrderStatus.Paid,
];

export const CreateButtonStatuses = [
    ConstantsOrderStatus.AwaitingCompletion,
    ConstantsOrderStatus.Completed,
    ConstantsOrderStatus.ReadyForPayment,
    ConstantsOrderStatus.Resolved,
    ConstantsOrderStatus.AwaitingApproval,
    ConstantsOrderStatus.Created,
    ConstantsOrderStatus.InProgress,
];

export const Features = {
    fiveSecondsBidding: 'fiveSecondsBidding',
    partnerProfileShowDateOfLatestCompletedJob: 'partnerProfileShowDateOfLatestCompletedJob',
    orderEdit: 'orderEdit',
    showPartnerProfile: 'showPartnerProfile',
    orderTrackingUrl: 'orderTrackingUrl'
};

export const BiddingTypes = {
    contest: 1,
    factual: 2,
    standard: 3,
    fixed: 4,
};

export const DEFAULT_ORDER_TAKE = 10;

export const LOCAL_STORAGE_KEYS = {
    ORDER_CREATED: 'OrderCreated',
    AUTO_BID: 'AutoBid',
    ORDER_ID: 'orderId',
};

export enum WorkTypes {
    STANDARD,
    NON_STANDARD,
}

export enum ServiceReceiverTypes {
    SELECTED,
    USER,
}

export enum ContactPersonTypes {
    SELECTED,
    USER,
}

export enum AssignedPartnerStatusTypes {
    UNDEFINED,
    PENDING,
    ACCEPTED,
}

export enum SelectExtras {
    ADD_SERVICE_RECEIVER,
}
