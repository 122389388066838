import { FC } from 'react';
import { useField } from 'formik';
import { Upload, UploadProps } from '../upload';

interface FormFieldUploadProps extends Partial<UploadProps> {
    name: string;
}

export const FormFieldUpload: FC<FormFieldUploadProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);

    return <Upload {...props} value={field.value} onChange={helpers.setValue} />;
};
