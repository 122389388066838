import { FC } from 'react';
import styled from 'styled-components';

interface RadioItemContainerProps {
    $selected?: boolean;
    $disabled?: boolean;
}

const RadioItemContainer = styled.div<RadioItemContainerProps>`
    border-radius: 10px;
    border: ${({ $selected, theme }) => $selected ? `2px solid ${theme.colors.primary}` : `1px solid ${theme.colors.grey8}`};
    display: flex;
    flex: 1;
    min-height: 113px;
    padding: 23px 16px 23px 26px;
    margin-bottom: 16px;
    ${({ $disabled, theme }) => $disabled && `background-color: ${theme.colors.grey2}`};
`;

const GreyDot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.grey2};
    margin-top: calc((22px - 15px) / 2);
`;

const GreenDot = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    width: 9px;
    height: 9px;
    border-radius: 15px;
`;

const RadioItemLeftInner = styled.div`
    margin-right: 16px;
`;

const RadioItemMainInner = styled.div`
    display: flex;
    flex-direction: column;
`;

interface RadioItemTitleProps {
    $disabled?: boolean;
}

const RadioItemTitle = styled.span<RadioItemTitleProps>`
    font-size: .875rem;
    font-weight: 500;
    color: ${({ $disabled, theme }) => $disabled ? theme.colors.grey4 : theme.colors.black};
    margin-bottom: .938rem;
`;

interface RadioItemDescProps {
    $disabled?: boolean;
}

const RadioItemDesc = styled.span<RadioItemDescProps>`
    font-size: .75rem;
    color: ${({ $disabled, theme }) => $disabled ? theme.colors.grey4 : theme.colors.grey10};
`;

export interface RadioTitleButtonProps {
    onClick: () => void;
    disabled?: boolean;
    selected?: boolean;
    title?: string;
    label?: string;
}

export const RadioTitleButton: FC<RadioTitleButtonProps> = ({ onClick, disabled, selected, title, label }) => {
    return (
        <RadioItemContainer onClick={() => !disabled && onClick()} $selected={selected} $disabled={disabled}>
            <RadioItemLeftInner>
                <GreyDot>
                    {selected && (
                        <GreenDot />
                    )}
                </GreyDot>
            </RadioItemLeftInner>
            <RadioItemMainInner>
                <RadioItemTitle $disabled={disabled}>
                    {title}
                </RadioItemTitle>
                {label && (
                    <RadioItemDesc $disabled={disabled}>
                        {label}
                    </RadioItemDesc>
                )}
            </RadioItemMainInner>
        </RadioItemContainer>
    );
};
