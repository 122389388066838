import { FC, forwardRef, ReactElement, useEffect, useState } from 'react';
import ReactSelect, { components, SelectComponentsConfig } from 'react-select';
import i18next from 'i18next';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as CaretUpIcon } from '../../assets/images/caret-up-icon.svg';
import { ReactComponent as CaretDownIcon } from '../../assets/images/caret-down-icon.svg';
import { theme } from '../../theme';
import { translates } from '../../utilities';

const DropdownIndicator = (props: any) => {
    if (props.customIcon) {
        return (
            <components.DropdownIndicator {...props}>
                <props.customIcon/>
            </components.DropdownIndicator>
        );
    }
    return (
        <components.DropdownIndicator {...props}>
            {props.selectProps.menuIsOpen ? (
                <CaretUpIcon style={{ width: 8 }} />
            ) : (
                <CaretDownIcon style={{ width: 8 }} />
            )}
        </components.DropdownIndicator>
    );
};

const Input = (props: any) => <components.Input {...props} isHidden={false} />;

export interface Option {
    value: string;
    label: string;
    extra?: number
}

interface SelectProps {
    name?: string;
    inputValue?: string;
    error?: boolean;
    options: Option[]
    value: string;
    disabled?: boolean;
    controlStyle?: any;
    onChange(value: string): void;
    onSearch?(value: string): void;
    onFocus?(): void;
    onBlur?: (e: any) => void;
    customIcon?: ReactElement;
    isClearable?: boolean;
    customComponents?: SelectComponentsConfig<Option, false, any>,
    loading?: boolean;
}

export const Select = forwardRef<any, SelectProps>(({
    error,
    options,
    value,
    disabled,
    controlStyle,
    onSearch,
    onChange,
    onFocus,
    onBlur,
    customIcon,
    isClearable,
    customComponents,
    loading,
}, ref) => {
    const defaultValue = options.find((o) => o.value === value) || {
        label: value,
        value
    };

    const [query, setQuery] = useState(defaultValue.value || '');
    const isMobile = useMediaQuery({
        query: theme.query.medium,
    });

    useEffect(() => {
        setQuery(defaultValue.value || '');
    }, [value]);

    const handleInputChange = (value: string, reason: any) => {
        if (reason.action === 'input-blur') {
            setQuery(defaultValue ? defaultValue.value : '');
        }

        if (reason.action === 'input-change') {
            setQuery(value);
            onSearch?.(value);
        }
    };

    const handleChange = (value: string) => {
        setQuery(value);
        onChange(value);
    };

    return (
        <ReactSelect
            ref={ref}
            theme={(t) => ({
                ...t,
                colors: {
                    ...t.colors,
                    primary: theme.colors.primary,
                    primary50: theme.colors.primary,
                    primary25: theme.colors.greenLight,
                },
            })}
            styles={{
                control: (provided: any, state: any) => {
                    return ({
                        ...provided,
                        height: 48,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        // eslint-disable-next-line no-nested-ternary
                        borderColor: `${error ? theme.colors.danger : state.menuIsOpen ? theme.colors.primary : theme.colors.grey12} !important`,
                        borderRadius: 10,
                        minWidth: 105,
                        paddingLeft: 6,
                        fontSize: '0.875rem',
                        boxShadow: 'none',
                        ...controlStyle,
                    });
                },
                singleValue: (provided: any) => ({
                    ...provided,
                    color: theme.colors.black,
                }),
                menuPortal: (provided: any) => ({
                    ...provided,
                    zIndex: 9999,
                }),
                option: (provided: any, props: any) => ({
                    ...provided,
                    fontSize: '0.875rem',
                    minHeight: '36px',
                    zIndex: 9999,
                    display: 'flex',
                    color: `${props.data?.extra !== undefined ? theme.colors.success : provided.color}`
                }),
                indicatorSeparator: () => ({}),
                indicatorsContainer: (provided: any) => ({
                    ...provided,
                    paddingRight: '1rem',
                })
            }}
            isSearchable={!!onSearch}
            onInputChange={handleInputChange}
            filterOption={() => true}
            components={{
                Input,
                DropdownIndicator: (dropdownIndicatorProps: any) => (
                    <DropdownIndicator {...dropdownIndicatorProps} customIcon={customIcon} />
                ),
                ...customComponents,
            }}
            blurInputOnSelect
            placeholder=""
            inputValue={onSearch ? query : undefined}
            menuPortalTarget={document.body}
            menuPlacement="auto"
            maxMenuHeight={isMobile ? 150 : undefined}
            isDisabled={disabled}
            options={options}
            defaultValue={defaultValue}
            value={defaultValue}
            noOptionsMessage={() => i18next.t(translates.GlobalLabelsNoOptions)}
            onChange={(option: any) => handleChange(option?.value)}
            onFocus={onFocus}
            isClearable={isClearable}
            onBlur={onBlur}
            controlShouldRenderValue={!onSearch}
            isLoading={loading}
            loadingMessage={() => i18next.t(translates.GlobalLabelsLoading)}
        />
    );
});
