import i18next from 'i18next';
import { OrderModel, OrderSelfModel, PriceRange } from '../../../../types/api';
import {
    CanceledOrderStatuses,
    CompletedOrderStatuses,
    ConstantsOrderStatus,
    formatDate,
    InProgressStatuses,
    ResolvedStatuses,
    translates,
    WaitingForActionStatuses,
    WaitingForBidStatuses,
} from '../../../../utilities';

export const getPlannedMaterialExpensesText = (currency: string, priceRange?: PriceRange) => {
    if (!priceRange) {
        return i18next.t(translates.PartnerCardLabelsMissingPlannedMaterialExpenses);
    }

    return `${priceRange.from} - ${priceRange.to}${currency}`;
};

export const getPlannedStartDate = (date: string | null | undefined) => {
    if (!date) {
        return i18next.t(translates.PartnerCardLabelsNotSpecifiedPlannedStartDate);
    }

    return formatDate(date, true);
};

export const hasPreliminaryPricing = (order: OrderSelfModel) => {
    const hasValues = order.preliminaryHourlyRate !== null ||
        ((ConstantsOrderStatus.InProgress === order.statusLabel || ConstantsOrderStatus.CreatedAsPreorder === order.statusLabel)
            && order.pricing?.fullPrice !== null && order.pricing?.fullPrice !== undefined);
    const isFinished = CompletedOrderStatuses.includes(order.statusLabel!);

    return !isFinished && hasValues;
};

export interface OrderCardRef {
    expanded: boolean;
    getOrderData: () => Promise<OrderModel | null>;
}

export const initialTabbedOrdersModel = {
    allOrders: {
        key: 'allOrders' as const,
        translateKey: translates.OrdersTabsTitlesAllOrders,
        title: i18next.t(translates.OrdersTabsTitlesAllOrders),
        filter: Object.values(ConstantsOrderStatus),
        orders: [],
        skip: 0,
        canLoadMore: false,
        loading: false,
        searchValue: '',
        shouldReload: false,
    },
    waitingForAction: {
        key: 'waitingForAction' as const,
        translateKey: translates.OrdersTabsTitlesWaitingForAction,
        title: i18next.t(translates.OrdersTabsTitlesWaitingForAction),
        filter: WaitingForActionStatuses,
        displayCount: true,
        orders: [],
        skip: 0,
        canLoadMore: false,
        loading: false,
        searchValue: '',
        shouldReload: false,
    },
    waitingForBids: {
        key: 'waitingForBids' as const,
        translateKey: translates.OrdersTabsTitlesWaitingForBids,
        title: i18next.t(translates.OrdersTabsTitlesWaitingForBids),
        filter: WaitingForBidStatuses,
        orders: [],
        skip: 0,
        canLoadMore: false,
        loading: false,
        searchValue: '',
        shouldReload: false,
    },
    inProgress: {
        key: 'inProgress' as const,
        translateKey: translates.OrdersTabsTitlesInProgress,
        title: i18next.t(translates.OrdersTabsTitlesInProgress),
        filter: InProgressStatuses,
        orders: [],
        skip: 0,
        canLoadMore: false,
        loading: false,
        searchValue: '',
        shouldReload: false,
    },
    resolved: {
        key: 'resolved' as const,
        translateKey: translates.OrdersTabsTitlesResolved,
        title: i18next.t(translates.OrdersTabsTitlesResolved),
        filter: ResolvedStatuses,
        orders: [],
        skip: 0,
        canLoadMore: false,
        loading: false,
        searchValue: '',
        shouldReload: false,
    },
    paid: {
        key: 'paid' as const,
        translateKey: translates.OrdersTabsTitlesPaid,
        title: i18next.t(translates.OrdersTabsTitlesPaid),
        filter: [ConstantsOrderStatus.Paid],
        orders: [],
        skip: 0,
        canLoadMore: true,
        loading: false,
        searchValue: '',
        shouldReload: false,
    },
    canceled: {
        key: 'canceled' as const,
        translateKey: translates.OrdersTabsTitlesCanceled,
        title: i18next.t(translates.OrdersTabsTitlesCanceled),
        filter: [ConstantsOrderStatus.Cancelled],
        orders: [],
        skip: 0,
        canLoadMore: false,
        loading: false,
        searchValue: '',
        shouldReload: false,
    },
    createdAsPreorder: {
        key: 'createdAsPreorder' as const,
        translateKey: translates.OrdersTabsTitlesCreatedAsPreorder,
        title: i18next.t(translates.OrdersTabsTitlesCreatedAsPreorder),
        filter: [ConstantsOrderStatus.CreatedAsPreorder],
        orders: [],
        skip: 0,
        canLoadMore: false,
        loading: false,
        searchValue: '',
        shouldReload: false,
    },
    primary: {
        key: 'primary' as const,
        translateKey: translates.OrdersTabsTitlesPrimary,
        title: i18next.t(translates.OrdersTabsTitlesPrimary),
        filter: [ConstantsOrderStatus.Primary],
        orders: [],
        skip: 0,
        canLoadMore: false,
        loading: false,
        searchValue: '',
        shouldReload: false,
    }
};

export type InitialTabbedOrdersModelType = {
    [k in keyof typeof initialTabbedOrdersModel]: {
        key: k;
        translateKey: string;
        title: string,
        filter: string[],
        displayCount?: boolean;
        orders: OrderSelfModel[];
        skip: number;
        canLoadMore: boolean;
        loading: boolean;
        searchValue: string;
        shouldReload: boolean;
    }
};
