import { useState } from 'react';
import { useMutation } from 'react-query';
import i18next from 'i18next';
import { useApi } from './useApi';
import { Toast } from '../components/toast';
import { ApiErrorModel } from '../types/api';

const useCallPhone = () => {
    const [isCallPressed, setIsCallPressed] = useState(false);
    const api = useApi();

    const clientCall = useMutation((orderId: string) =>
        api({
            url: `orders/${orderId}/client-call`,
            method: 'POST',
        })
    );

    const callPhone = (phone?: string | null, orderId?: string | null) => {
        if (!phone) {
            return;
        }
        if (!orderId) {
            setIsCallPressed(true);
            window.location.href = `tel:${phone}`;
        } else {
            clientCall.mutate(orderId, {
                onError(e) {
                    Toast.error(i18next.t((e as ApiErrorModel).errorMessage!));
                },
                onSuccess() {
                    setIsCallPressed(true);
                    window.location.href = `tel:${phone}`;
                },
            });
        }
    };

    return { isCallPressed, callPhone };
};

export default useCallPhone;
