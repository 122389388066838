import camelcase from 'camelcase';
import { FieldErrorsModel } from '../types/api';

export const transformFormErrors = (errors: FieldErrorsModel) => {
    if (!errors || !Array.isArray(errors)) {
        return {};
    }

    return errors.reduce((acc, curr) => {
        acc[camelcase(curr.field)] = curr.errors;

        return acc;
    }, {});
};
