import { FC, useEffect } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { Card, Button } from '../../../../components';
import { translates } from '../../../../utilities';
import { Toast } from '../../../../components/toast';
import { ApiErrorModel } from '../../../../types/api';
import { useApi } from '../../../../hooks/useApi';

const ButtonsContainer = styled.div`
    margin: 3rem 0 1rem;
    display: flex;
    flex-direction: column;
`;

interface OutsideProviderProps {
    orderId: string;
    onClick: () => void;
}

export const OutsideProvider: FC<OutsideProviderProps> = ({ orderId, onClick }) => {
    const api = useApi();
    const manual = useMutation(() => api({
        url: `orders/${orderId}/payments/manual`,
        method: 'POST'
    }));

    useEffect(() => {
        manual.mutate({} as any, {
            onError(error) {
                Toast.error(i18next.t((error as ApiErrorModel).errorMessage!));
            },
        });
    }, [api, orderId]);

    return (
        <Card
            titleText={i18next.t(translates.PaymentTitlesOutsideProvider)}
            subtitleText={i18next.t(translates.PaymentLabelsOutsideProvider)}
        >
            <ButtonsContainer>
                <Button styleType="primary" onClick={onClick} title={i18next.t(translates.PaymentButtonsOutsideProviderNext)} />
            </ButtonsContainer>
        </Card>
    );
};
