import * as Yup from 'yup';
import { translates } from './translates';
import { CountryEnum, getIsPersonalCodeEnabled, getIsPersonalCodeManadatory } from './countryDefaults';

const cyrillicRegext = /[^а-яА-ЯЁё|0-9]/gi;

export const profileSchema = (country: CountryEnum | string) => {
    const isPersonalCodeEnabled = getIsPersonalCodeEnabled(country);
    const isPersonalCodeMandatory = getIsPersonalCodeManadatory(country);

    let personalCodeSchema;
    if (isPersonalCodeEnabled) {
        personalCodeSchema = Yup.string()
            .nullable()
            .when('isCompany', {
                is: false,
                then: isPersonalCodeMandatory
                    ? Yup.string().required(translates.FormsErrorsRequired)
                    : Yup.string().nullable(),
            });
    }

    return ({
        acceptedPortalProContractTerms: Yup.boolean().isTrue(translates.RegisterErrorsTermsRequired),
        acceptedPortalProPrivacy: Yup.boolean().isTrue(translates.RegisterErrorsPrivacyRequired),
        email: Yup.string().nullable().email(translates.FormsErrorsInvalidEmail),
        isCompany: Yup.boolean(),
        firstName: Yup.string()
            .nullable()
            .matches(cyrillicRegext, { message: translates.YupCyrillicErrorMessage })
            .when('isCompany', {
                is: false,
                then: Yup.string()
                    .required(translates.FormsErrorsRequired)
                    .matches(cyrillicRegext, { message: translates.YupCyrillicErrorMessage }),
            }),
        lastName: Yup.string()
            .nullable()
            .matches(cyrillicRegext, { message: translates.YupCyrillicErrorMessage })
            .when('isCompany', {
                is: false,
                then: Yup.string()
                    .required(translates.FormsErrorsRequired)
                    .matches(cyrillicRegext, { message: translates.YupCyrillicErrorMessage }),
            }),
        ...(personalCodeSchema ? { personalCode: personalCodeSchema } : {}),
        phoneCode: Yup.string(),
        phone: Yup.string()
            .nullable()
            .required(translates.FormsErrorsRequired)
            .phone(translates.FormsErrorsPhoneNumberInvalid),
        company: Yup.object()
            .shape({
                name: Yup.string(),
                code: Yup.string(),
                ...(country === CountryEnum.LT ? { vatCode: Yup.string() } : {}),
                registrationAddressText: Yup.string(),
                registrationAddressLongitude: Yup.number(),
                registrationAddressLatitude: Yup.number(),
            })
            .nullable()
            .when('isCompany', {
                is: true,
                then: Yup.object().shape({
                    name: Yup.string().required(translates.FormsErrorsRequiredForCompany),
                    code: Yup.string().required(translates.FormsErrorsRequiredForCompany).max(50, translates.FormsErrorsMaxLengthForCompanyCode),
                    ...(country === CountryEnum.LT ? { vatCode: Yup.string() } : {}),
                    registrationAddressText: Yup.string().required(translates.FormsErrorsRequiredForCompany),
                    registrationAddressLongitude: Yup.number().required(translates.FormsErrorsRequiredForCompany),
                    registrationAddressLatitude: Yup.number().required(translates.FormsErrorsRequiredForCompany),
                }),
            })
    });
};
