import { MutableRefObject, useEffect } from 'react';

export const useDetectOutsideClick = (ref: MutableRefObject<any>, cb: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                cb();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};
