import { RcFile } from 'rc-upload/lib/interface';

const PDF_FILE_TYPE = 'application/pdf';
const PDF_FILE_EXT = '.pdf';
const SUPPORTED_MIME_TYPES = [
    'image/jpeg',
    'image/png',
    'image/heic',
    'application/pdf',
];

export const isPdfFileType = (type: string) => type === PDF_FILE_TYPE;

const isSupportedFileType = (type: string) => {
    if (SUPPORTED_MIME_TYPES.includes(type)) {
        return true;
    }

    return false;
};

export const extname = (url: string) => {
    const temp = url.split('/');
    const filename = temp[temp.length - 1];
    const filenameWithoutSuffix = filename.split(/#|\?/)[0];
    return (/\.[^./\\]*$/.exec(filenameWithoutSuffix) || [''])[0];
};

export const isFileExtPdf = (fileName?: string | null) => {
    if (!fileName) {
        return false;
    }

    return extname(fileName) === PDF_FILE_EXT;
};

export const fileCheck = (file: RcFile) => {
    if (file.type) {
        return isSupportedFileType(file.type);
    }

    const extension = extname(file.name ?? '');

    return /(png|jpg|jpeg|heic|pdf)$/i.test(extension);
};
