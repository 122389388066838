import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { useField } from 'formik';
import debounce from 'lodash.debounce';

import { translates } from '../../../../utilities';
import { JobItem, ServiceJobModel } from './JobItem';
import { ServiceItem } from '../../../../types/api';
import { FormFieldInput } from '../../../../components';
import SearchIcon from '../../../../assets/images/search.svg';

const JobListError = styled.p`
    color: ${({ theme }) => theme.colors.danger};
`;

const StyledSearch = styled.div`
 margin-top: 8px;
`;

interface JobListProps {
    serviceJobs: ServiceItem[];
}

export const JobList: FC<JobListProps> = ({ serviceJobs }) => {
    const [serviceItemsField, serviceItemsMeta, serviceItemsHelper] = useField<{ [id: string]: ServiceItem }>('serviceItems');
    const [searchValue, setSearchValue] = useState('');

    const handleQuantityChange = (job: ServiceItem, quantity: number) => {
        const keys = Object.keys(serviceItemsField.value || {});
        const key = job.id!;
        const isInList = keys.includes(key);

        if (isInList && quantity <= 0) {
            const cp = { ...serviceItemsField.value };
            delete cp[key];
            serviceItemsHelper.setValue(cp);
            return;
        }

        if (isInList) {
            serviceItemsHelper.setValue({
                ...serviceItemsField.value,
                [key]: {
                    ...serviceItemsField.value[key],
                    ...job,
                    quantity,
                }
            });
            return;
        }

        serviceItemsHelper.setValue({
            ...serviceItemsField.value,
            [key]: {
                ...job,
                quantity,
            }
        });
    };

    const filteredJobs = useMemo(() => {
        return serviceJobs.filter((item) => item.translation?.toLowerCase().includes(searchValue.toLowerCase()));
    }, [serviceJobs, searchValue]);

    const handleItemsSearch = debounce((e: string) => {
        if (e?.length >= 3) {
            setSearchValue(e);
        } else {
            setSearchValue('');
        }
    }, 300);

    if (!Array.isArray(serviceJobs) || !serviceJobs?.length) {
        return (
            <JobListError>
                {i18next.t(translates.OrderStepsLabelsJobsStepEmptyJobList)}
            </JobListError>
        );
    }

    return (
        <>
            <StyledSearch>
                <FormFieldInput name="search" label={i18next.t(translates.OrderStepsServiceSearch)} icon={SearchIcon} onChange={handleItemsSearch} />
            </StyledSearch>
            <JobListError id="form-item-serviceItems">
                {serviceItemsMeta?.touched && i18next.t(serviceItemsMeta?.error || '')}
            </JobListError>
            {filteredJobs.map((job, idx) => {
                const activeJob = { ...serviceItemsField.value };
                return (
                    <JobItem
                        key={idx}
                        job={job}
                        jobInfoTitle={job.translation || ''}
                        onQuantityChange={handleQuantityChange}
                        quantity={activeJob[job.id!]?.quantity ?? 0}
                        unitOfMeasure={job.unitOfMeasure}
                        description={job.description}
                    />
                );
            })}
        </>
    );
};

export type {
    ServiceJobModel,
};
