import { useFeatures } from '@paralleldrive/react-feature-toggles';
import i18next from 'i18next';
import moment from 'moment';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { ConfigContext } from '../../../../../contexts';
import { PartnerServiceExperienceModel } from '../../../../../types/api';
import { formatDate, translates } from '../../../../../utilities';
import { getCountryCurrency } from '../../../../../utilities/countryDefaults';

const ContentText = styled.p`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.grey6};
    margin-bottom: 28px;
`;

const DetailsRow = styled.div`
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey8};
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.grey6};

    > div:nth-child(2) {
        color: ${({ theme }) => theme.colors.grey11};
    }
`;

const formatDaysWorking = (d: number) => {
    const now = moment();
    const start = moment(moment().subtract('days', d));
    const duration = moment.duration(moment(now).diff(moment(start)));
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();

    const yearsLabel = i18next.t(translates.GlobalLabelsTimeUnitsYears);
    const monthsLabel = i18next.t(translates.GlobalLabelsTimeUnitsMonths);
    const daysLabel = i18next.t(translates.GlobalLabelsTimeUnitsDays);

    if (years) {
        return `${years}${yearsLabel} ${months > 0 ? `${months}${monthsLabel}` : ''}`;
    }

    if (months) {
        return `${Math.round(duration.asMonths())}${monthsLabel}`;
    }

    if (days) {
        return `${Math.round(duration.asDays())}${daysLabel}`;
    }

    return `1${daysLabel}`;
};

interface PartnerInformationTabProps {
    experience: PartnerServiceExperienceModel;
}

export const PartnerInformationTab: FC<PartnerInformationTabProps> = ({ experience }) => {
    const { country } = useContext(ConfigContext);
    const features = useFeatures();

    return (
        <>
            <ContentText>{experience?.description}</ContentText>
            <DetailsRow>
                <div>{i18next.t(translates.PartnerCardLabelsAveragePrice)}</div>
                <div>{getCountryCurrency(country, experience?.averageWorkPrice!, false)}</div>
            </DetailsRow>
            <DetailsRow>
                <div>{i18next.t(translates.PartnerCardLabelsTotalWorkCount)}</div>
                <div>{experience?.totalJobsFinished}</div>
            </DetailsRow>
            <DetailsRow>
                <div>{i18next.t(translates.PartnerCardLabelsAverageWorkDuration)}</div>
                <div>
                    {experience?.averageWorkDurationInHours}
                    {i18next.t(translates.PartnerCardLabelsHour)}
                </div>
            </DetailsRow>
            <DetailsRow>
                <div>{i18next.t(translates.PartnerCardLabelsExperience)}</div>
                <div>{formatDaysWorking(experience?.daysWorking!)}</div>
            </DetailsRow>
            {features.includes('partnerProfileShowDateOfLatestCompletedJob') &&
                experience?.dateOfLatestCompletedJob !== null && (
                <DetailsRow>
                    <div>{i18next.t(translates.PartnerCardLabelsLastWorkDate)}</div>
                    <div>{formatDate(experience?.dateOfLatestCompletedJob!)}</div>
                </DetailsRow>
            )}
        </>
    );
};
