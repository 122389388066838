import { FC, useContext, useState } from 'react';
import * as Yup from 'yup';
import i18next from 'i18next';
import styled, { css } from 'styled-components';
import { FormikHelpers, FormikValues, FieldArray } from 'formik';
import { useMutation } from 'react-query';
import PlusIcon from '../../assets/images/plus-icon.svg';
import Close from '../../assets/images/close-sharp.svg';
import { phoneFormatter, transformFormErrors, translates } from '../../utilities';
import { ConfigContext } from '../../contexts';
import { Alert, FormFieldAutocompleteGoogleMaps, FormFieldInput, FormFieldPhone, Form } from '../../components';
import { useApi } from '../../hooks/useApi';
import { getCountryPhoneCode } from '../../utilities/countryDefaults';
import { AddressBookResponseModel, ApiErrorModel, CreateAddressBookModel, UpdateAddressBookModel } from '../../types/api';
import { Title } from '../title';
import { Button } from '../button';

const FormFieldContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-right: -16px;
    margin-left: -16px;
`;

interface FormFieldInputWrapperProps {
    $fullWidth?: boolean;
}

const FormFieldInputWrapper = styled.div<FormFieldInputWrapperProps>`
    padding-right: 16px;
    padding-left: 16px;
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
    ${({ $fullWidth }) =>
        $fullWidth &&
        css`
            flex: 0 0 100%;
            max-width: 100%;
        `};

    @media ${({ theme }) => theme.query.medium} {
        flex: 0 0 100%;
        max-width: 100%;
    }
`;

const Line = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grey12};
    height: 1px;
    margin: 10px 0px;
`;

const Right = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin: 0px 20px;
`;

type PhoneCode = { phoneCode: string };

type AddressBookModel = (UpdateAddressBookModel | CreateAddressBookModel) & PhoneCode;
export type NewAddressBookModel = AddressBookModel & AddressBookResponseModel;

interface AddressBookFormProps {
    isEditing?: boolean;
    addressBook?: UpdateAddressBookModel | null;
    onSuccess: (payload: NewAddressBookModel) => void;
    onCancel: () => void;
}

export const AddressBookForm: FC<AddressBookFormProps> = ({ isEditing, addressBook, onSuccess, onCancel }) => {
    const [error, setError] = useState('');
    const { country } = useContext(ConfigContext);
    const api = useApi();
    const address = useMutation<AddressBookResponseModel, ApiErrorModel, AddressBookModel>((data: any) => api<AddressBookResponseModel>({
        url: 'address-books',
        method: isEditing ? 'PUT' : 'POST',
        data,
    }));

    const submit = async (values: AddressBookModel, helpers: FormikHelpers<FormikValues>) => {
        helpers.setSubmitting(true);
        const oldContacts = values?.contacts ?? [];

        const contacts = oldContacts.map((c) => ({
            ...c,
            phoneNumber: c.phoneNumber && c.phoneCode + c.phoneNumber
        })).filter((e) => e.fullName);

        const payload = {
            ...values,
            phone: values.phone && values.phoneCode + values.phone,
            contacts,
        };

        address.mutate(payload, {
            onSuccess(data) {
                onSuccess({ ...payload, ...data } as NewAddressBookModel);
            },
            onError(e) {
                if (e?.errorMessage) {
                    setError(e?.errorMessage);
                }
                if (e?.errors) {
                    helpers.setErrors(transformFormErrors(e?.errors as any));
                }
            },
            onSettled() {
                helpers.setSubmitting(false);
            }
        });
    };

    const schema = Yup.object().shape({
        address: Yup.string().required(translates.FormsErrorsRequired),
        companyCode: Yup.string()
            .required(translates.FormsErrorsRequired)
            .max(50, translates.FormsErrorsMaxLengthForCompanyCode),
        companyName: Yup.string().required(translates.FormsErrorsRequired),
        companyZipCode: Yup.string().required(translates.FormsErrorsRequired),
        email: Yup.string().required(translates.FormsErrorsRequired).email(translates.FormsErrorsInvalidEmail),
        latitude: Yup.number().required(translates.FormsErrorsRequired),
        longitude: Yup.number().required(translates.FormsErrorsRequired),
        phone: Yup.string().required(translates.FormsErrorsRequired).phone(translates.FormsErrorsPhoneNumberInvalid),
        phoneCode: Yup.string(),
        iban: Yup.string().nullable(),
        contacts: Yup.array(Yup.object({
            email: Yup.string().nullable().email(translates.FormsErrorsInvalidEmail),
            fullName: Yup.string().required(translates.FormsErrorsRequired),
            phoneNumber: Yup.string().required(translates.FormsErrorsRequired).phone(translates.FormsErrorsPhoneNumberInvalid),
            position: Yup.string().required(translates.FormsErrorsRequired),
            phoneCode: Yup.string(),
        }))
    });

    const emptyContact = {
        email: '',
        fullName: '',
        phoneNumber: '',
        phoneCode: getCountryPhoneCode(country),
        position: '',
    };

    const emptyInitialValues: CreateAddressBookModel & PhoneCode = {
        address: '',
        companyCode: '',
        companyName: '',
        iban: '',
        companyZipCode: '',
        email: '',
        latitude: 0,
        longitude: 0,
        phone: '',
        phoneCode: getCountryPhoneCode(country),
        contacts: [],
    };

    const phoneCode = phoneFormatter(addressBook?.phone, country);

    const filledInitialValues: UpdateAddressBookModel & PhoneCode = {
        ...addressBook!,
        phoneCode,
        phone: addressBook?.phone?.replace(phoneCode, ''),
        contacts: addressBook?.contacts?.map((c) => ({
            ...c,
            phoneNumber: c.phoneNumber?.replace(phoneFormatter(c?.phoneNumber, country), ''),
            phoneCode: phoneFormatter(c?.phoneNumber, country),
        }))
    };

    return (
        <Form
            key="address-book-form"
            submit={submit}
            submitText={i18next.t(translates.AddressBookButtonsSubmit)}
            cancel={onCancel}
            cancelText={i18next.t(translates.AddressBookButtonsCancel)}
            values={isEditing ? filledInitialValues : emptyInitialValues}
            schema={schema}>
            <FormFieldContainer>
                <FormFieldInputWrapper>
                    <FormFieldInput
                        name="companyName"
                        label={i18next.t(translates.AddressBookLabelsCompanyName)}
                        maxLength={127}
                    />
                </FormFieldInputWrapper>
                <FormFieldInputWrapper>
                    <FormFieldInput
                        name="companyCode"
                        label={i18next.t(translates.AddressBookLabelsCompanyCode)}
                        maxLength={127}
                    />
                </FormFieldInputWrapper>
                <FormFieldInputWrapper>
                    <FormFieldInput
                        name="email"
                        label={i18next.t(translates.AddressBookLabelsEmail)}
                        autoComplete="username"
                        maxLength={255}
                    />
                </FormFieldInputWrapper>
                <FormFieldInputWrapper>
                    <FormFieldPhone
                        name="phone"
                        namePrefix="phoneCode"
                        label={i18next.t(translates.AddressBookLabelsPhone)}
                        size="large"
                    />
                </FormFieldInputWrapper>
            </FormFieldContainer>
            <FormFieldAutocompleteGoogleMaps
                longitudeFieldName="longitude"
                latitudeFieldName="latitude"
                name="address"
                label={i18next.t(translates.AddressBookLabelsAddress)}
                country={country}
            />
            <FormFieldContainer>
                <FormFieldInputWrapper>
                    <FormFieldInput
                        name="companyZipCode"
                        label={i18next.t(translates.AddressBookLabelsCompanyZipCode)}
                        maxLength={127}
                    />
                </FormFieldInputWrapper>
                <FormFieldInputWrapper>
                    <FormFieldInput
                        name="iban"
                        label={i18next.t(translates.AddressBookLabelsIBAN)}
                        maxLength={127}
                    />
                </FormFieldInputWrapper>
            </FormFieldContainer>
            <Alert content={i18next.t(error) as string} visible={!!error} />
            <FieldArray
                name="contacts"
                render={(helper) => (
                    <>
                        {helper.form.values.contacts.length > 0 && (
                            <>
                                <Line />
                                <Title
                                    type="header"
                                    titleText={i18next.t(translates.ContactsFormTitle)}
                                />
                                {helper.form.values.contacts.map((c, i) => (
                                    <FormFieldContainer key={i}>
                                        <FormFieldInputWrapper>
                                            <FormFieldInput name={`contacts.${i}.position`} label={i18next.t(translates.ContactsFormPosition)} maxLength={100} />
                                        </FormFieldInputWrapper>
                                        <FormFieldInputWrapper>
                                            <FormFieldInput name={`contacts.${i}.fullName`} label={i18next.t(translates.ContactsFormName)} maxLength={100} />
                                        </FormFieldInputWrapper>
                                        <FormFieldInputWrapper>
                                            <FormFieldInput name={`contacts.${i}.email`} label={i18next.t(translates.ContactsFormEmail)} maxLength={255} />
                                        </FormFieldInputWrapper>
                                        <FormFieldInputWrapper>
                                            <FormFieldPhone
                                                name={`contacts.${i}.phoneNumber`}
                                                namePrefix={`contacts.${i}.phoneCode`}
                                                label={i18next.t(translates.AddressBookLabelsPhone)}
                                                size="large"
                                            />
                                        </FormFieldInputWrapper>
                                        <Right>
                                            <Button
                                                styleType="text-secondary"
                                                title={i18next.t(translates.ContactsFormDelete)}
                                                iconLeft={Close}
                                                onClick={() => helper.remove(i)}
                                            />
                                        </Right>
                                        <Line />
                                    </FormFieldContainer>
                                ))}
                            </>
                        )}
                        <Button
                            styleType="text-primary"
                            title={i18next.t(translates.ContactsFormAdd)}
                            iconLeft={PlusIcon}
                            onClick={() => helper.push(emptyContact)}
                        />
                    </>
                )}
            />
        </Form>
    );
};
