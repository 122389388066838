import styled, { keyframes } from 'styled-components';
import { Spinner } from '../spinner';

const showLoader = keyframes`
    to {
        visibility: visible;
    }
`;

const Content = styled.div`
    padding: 10%;
    text-align: center;
    animation: ${showLoader} 0s 0.25s forwards;
    visibility: hidden;
`;

export const ContentLoading = () => {
    return (
        <Content>
            <Spinner size={80} />
        </Content>
    );
};
