import { FC, useRef, useState } from 'react';
import RcUpload from 'rc-upload';
import styled from 'styled-components';
import { RcFile } from 'rc-upload/lib/interface';
import i18next from 'i18next';
import { Toast } from '../toast';
import { fileCheck, translates, resizeImage } from '../../utilities';
import { UploadItems } from './components/UploadItems';

interface WrapperProps {
    isDragActive: boolean;
}

const Wrapper = styled.div<WrapperProps>`
    border: ${({ theme, isDragActive }) => `${isDragActive ? '3px solid' : '1px dashed'} ${theme.colors.primary}`};
    padding: 12px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
`;

interface UploadProps {
    value: RcFile[]
    onChange(value: RcFile[]): void
    additionalFileValidation?: (file: RcFile) => RcFile | boolean
}

export const Upload: FC<UploadProps> = ({
    value = [],
    onChange,
    additionalFileValidation,
    ...props
}) => {
    const ref = useRef<any>();
    const [isDragActive, setIsDragActive] = useState(false);

    const beforeUpload = async (file: RcFile) => {
        if (isDragActive) {
            setIsDragActive(false);
        }

        if (value.length >= 10) {
            Toast.error(i18next.t(translates.UploadErrorTooManyFiles));
            return false;
        }

        if (!fileCheck(file)) {
            Toast.error(i18next.t(translates.UploadErrorUnsupportedFile));
            return false;
        }

        if (typeof additionalFileValidation === 'function') {
            return additionalFileValidation(file);
        }

        if (file.type.startsWith('image/')) {
            const resizedImage = await resizeImage(file, 800, 800);
            const resizedFile = new File([resizedImage], file.name, { type: file.type });
            return resizedFile;
        }

        return file;
    };

    const handleSuccess = async (file: RcFile) => {
        onChange([...value, file]);
    };

    const handleAddButtonClick = () => {
        ref.current?.uploader?.onClick();
    };

    const handleRemoveButtonClick = (id: string) => {
        onChange(value.filter((v) => v.uid !== id));
    };

    const handleDragEnter = () => {
        setIsDragActive(true);
    };

    const handleDragLeave = () => {
        setIsDragActive(false);
    };

    return (
        <RcUpload
            ref={ref}
            customRequest={(e) => {
                e.onSuccess?.(true, new XMLHttpRequest());
            }}
            multiple={false}
            onSuccess={async (_, file) => handleSuccess(file)}
            openFileDialogOnClick={isDragActive}
            beforeUpload={beforeUpload}
        >
            <Wrapper
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                isDragActive={isDragActive}
                {...props}
            >
                <UploadItems
                    items={value}
                    onRemove={handleRemoveButtonClick}
                    onAdd={handleAddButtonClick}
                />
            </Wrapper>
        </RcUpload>
    );
};

export type { UploadProps };
