import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { ImageModel, OrderModel } from '../../../../types/api';
import { ImagesSlider } from './ImagesSlider';
import { OrderPdfItems } from './OrderPdfItems';
import { isFileExtPdf } from '../../../../utilities';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px;
`;

const Description = styled.p`
    color: ${({ theme }) => theme.colors.grey6};
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: 0;
`;

const ImagesSliderStyled = styled(ImagesSlider)`
    margin-top: 16px;
`;

const OrderPdfItemsStyled = styled(OrderPdfItems)`
    margin-top: 16px;
`;

interface OrderTabsInformationTabProps {
    order: OrderModel
    images: ImageModel[]
}

export const OrderTabsInformationTab: FC<OrderTabsInformationTabProps> = ({ order, images }) => {
    const { fileArr, imageArr } = useMemo(() => {
        const fileArr: ImageModel[] = [];
        const imageArr: ImageModel[] = [];

        images.forEach((image) => {
            if (isFileExtPdf(image.fileName)) {
                fileArr.push(image);
                return;
            }

            imageArr.push(image);
        });

        return { fileArr, imageArr };
    }, [images]);

    return (
        <Content>
            <Description>
                {order.description}
            </Description>

            {imageArr.length > 0 && (
                <ImagesSliderStyled images={imageArr} />
            )}

            {fileArr.length > 0 && (
                <OrderPdfItemsStyled items={fileArr} />
            )}
        </Content>
    );
};
