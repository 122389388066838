import { FC, ReactNode } from 'react';

interface AlertProps {
    content: ReactNode;
    visible: boolean;
}

export const Alert: FC<AlertProps> = ({ content, visible }) => {
    if (!visible) {
        return null;
    }

    return (
        <div className='color-danger'>{content}</div>
    );
};
