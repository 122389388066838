import i18next from 'i18next';
import * as Yup from 'yup';
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers, FormikValues } from 'formik';

import { useMutation, useQuery } from 'react-query';
import { Alert, Form, FormFieldTextarea, Spinner, StarRating, Text } from '../../../../components';
import { Card } from '../../../../components/cards/Card';
import { useApi } from '../../../../hooks/useApi';
import { useUrlQuery } from '../../../../hooks/useUrlQuery';
import { ApiErrorModel, OrderWithImagesModel } from '../../../../types/api';
import { transformFormErrors, translates } from '../../../../utilities';
import { PartnerIcon } from '../../../../components/partnerCard/PartnerIcon';
import { routerPaths } from '../../../../configs';
import { OrderingContext } from '../../../../contexts';

const PartnerIconStyled = styled(PartnerIcon)`
    margin: auto;
    width: 120px;
    height: 120px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    margin-bottom: 1rem;
`;

const StarRatingStyled = styled(StarRating)`
    margin: 0 auto 1.5rem;
`;

const Row = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const TextStyled = styled(Text)`
    margin-bottom: 1.5rem;
`;

interface FeedbackFormModel {
    comment: string;
}

export const Feedback = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const { orderId } = useUrlQuery();
    const [rating, setRating] = useState(0);
    const { recentPaidOrders, setRecentPaidOrders } = useContext(OrderingContext);
    const api = useApi();
    const feedback = useMutation((data: any) => api({
        url: `orders/${orderId}/feedback`,
        data,
        method: 'POST'
    }));
    const { isLoading, data } = useQuery<OrderWithImagesModel, ApiErrorModel>(`orders/${orderId}`, () =>
        api({
            url: `orders/${orderId}`,
        }), {
        onSuccess(data) {
            if (data.order?.hasFeedback) {
                navigate(routerPaths.orders.route, { replace: true });
            }
        },
        onError(e) {
            setError((e as any)?.errorMessage);
        },
    });

    const isMounted = useRef(true);

    useEffect(() => () => { isMounted.current = false; }, []);

    useEffect(() => {
        recentPaidOrders[orderId as string] = new Date();
        setRecentPaidOrders(recentPaidOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = async (values: FeedbackFormModel, helpers: FormikHelpers<FormikValues>) => {
        feedback.mutate({ ...values,
            rating }, {
            onSuccess() {
                navigate(routerPaths.orders.route, { replace: true });
            },
            onError(e) {
                setError((e as any)?.errorMessage);
                helpers.setErrors(transformFormErrors((e as any)?.errors));
            },
            onSettled() {
                if (isMounted) {
                    helpers.setSubmitting(false);
                }
            }
        });
    };

    const schema = Yup.object()
        .shape({
            comment: Yup.string()
        });

    const initialValues: FeedbackFormModel = {
        comment: ''
    };

    const approvedBid = data?.order?.bids?.find((item) => (item.isApproved));

    return (
        <Card>
            {isLoading && <Row><Spinner /></Row>}
            {(!isLoading && data) && <>
                <Row>
                    <PartnerIconStyled partnerPictureUrl={approvedBid?.partnerPictureUrl} />
                </Row>
                <Row>
                    <TextStyled
                        type='body2'
                        color='dark'>
                        {approvedBid?.partner?.fullName}
                    </TextStyled>
                </Row>
                <Row>
                    <TextStyled
                        type='title2'
                        color='black'>
                        {i18next.t(translates.OrderFeedbackTitle)}
                    </TextStyled>
                </Row>
                <Row>
                    <StarRatingStyled rating={rating} setRating={setRating} />
                </Row>

                {!!rating && <Form
                    submit={submit}
                    submitText={i18next.t(translates.OrderFeedbackSubmit)}
                    schema={schema}
                    values={initialValues}
                >
                    <FormFieldTextarea
                        name='comment'
                        label={i18next.t(translates.OrderFeedbackComment)}
                        rows={3}
                        maxLength={150}
                    />
                    <Alert
                        content={i18next.t(error)}
                        visible={!!error}
                    />
                </Form>}
            </>}
        </Card>
    );
};
