import React, { FC, Fragment } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { Button, ButtonProps } from '../..';
import { translates } from '../../../utilities';
import { features } from '../../../utilities/features';
import { PartnerRating } from './PartnerRating';
import { PartnerComments } from './PartnerComments';
import { ReactComponent as GoldStarIcon } from '../../../assets/images/star-filled-gold.svg';

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const MetaInfoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 -0.5rem;
    flex-wrap: wrap;

    div {
        margin: 0 0.5rem;
    }
`;

const Title = styled.span`
    font-size: 0.9375rem;
    font-weight: 500;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
    text-decoration: ${({ onClick }) => (onClick ? 'underline' : 'none')};

    &:hover {
        text-decoration: none;
    }
`;

const Row = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.colors.grey6};
    text-align: center;

    > strong {
        color: ${({ theme }) => theme.colors.grey6};
    }
`;

const PriceRow = styled(Row)`
    margin-top: 1rem;
    > strong {
        color: ${({ theme }) => theme.colors.primary};
    }
`;

const DoneWorkAmountRow = styled(PriceRow)`
    margin-top: 0;
    margin-bottom: 1rem;
`;

const ButtonStyled = styled(Button)`
    margin-top: 1.5rem;
    width: 100%;
`;

const ButtonContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

const NameContainer = styled.div`
    display: flex;
    align-items: center;
`;

const FavoriteStarIcon = styled(GoldStarIcon)`
    margin-right: 3px;
    width: 22px;
    height: 22px;
`;

const JobScope = styled.a`
 color: ${({ theme }) => theme.colors.primary};
`;

interface PartnerCardContentProps {
    name: string;
    priceLabel?: string;
    price?: string | null;
    totalVotes: number;
    rating: number;
    plannedMaterialExpenses?: string;
    plannedStartDate?: string | null;
    buttonProps?:ButtonProps[] | ButtonProps;
    commentsCount: number;
    openModal?: (showCommentsTab: boolean) => void;
    shouldShowComments?: boolean;
    doneWorksAmount?: number | null;
    doneWorksAmountLabel?: string;
    isFavoritePartner?: boolean;
    openJobScope?: () => void;
}

export const PartnerCardContent: FC<PartnerCardContentProps> = ({
    name,
    priceLabel,
    price,
    buttonProps,
    totalVotes,
    rating,
    commentsCount,
    plannedMaterialExpenses,
    plannedStartDate,
    openModal,
    shouldShowComments = true,
    doneWorksAmount,
    doneWorksAmountLabel,
    isFavoritePartner,
    openJobScope,
    ...props
}) => {
    const renderButton = () => {
        if (Array.isArray(buttonProps)) {
            return (
                <ButtonContainer>
                    {buttonProps.map((b, i) => (
                        <Fragment key={i}>
                            <ButtonStyled {...b} />
                        </Fragment>
                    ))}
                </ButtonContainer>
            );
        }
        if (buttonProps?.title) {
            return <ButtonStyled {...buttonProps} />;
        }
        return null;
    };

    return (
        <>
            <Container {...props}>
                <MetaInfoContainer>
                    <PartnerRating rating={rating} totalVotes={totalVotes} showPartner={openModal && (() => openModal(true)) }/>
                    {shouldShowComments && openModal && <PartnerComments showPartner={() => openModal(true)} commentsCount={commentsCount} />}
                </MetaInfoContainer>
                <NameContainer>
                    {isFavoritePartner && (
                        <FavoriteStarIcon />
                    )}
                    <Title onClick={features.includes('showPartnerProfile') && openModal ? () => openModal(false) : undefined}>
                        {name}
                    </Title>
                </NameContainer>
                {price && (
                    <PriceRow>
                        <span>{priceLabel}:&nbsp;</span>
                        <strong>{price}</strong>
                    </PriceRow>
                )}
                {openJobScope && (
                    <JobScope onClick={openJobScope}>
                        {i18next.t(translates.BidJobScopePartner)}
                    </JobScope>
                )}
                {Number.isFinite(doneWorksAmount) && (
                    <DoneWorkAmountRow>
                        <span>{doneWorksAmountLabel}:&nbsp;</span>
                        <strong>{doneWorksAmount}</strong>
                    </DoneWorkAmountRow>
                )}
                {plannedMaterialExpenses && (
                    <Row>
                        <span>{i18next.t(translates.PartnerCardLabelsPlannedMaterialExpenses)}:&nbsp;</span>
                        <strong>{plannedMaterialExpenses}</strong>
                    </Row>
                )}
                {plannedStartDate && (
                    <Row>
                        <span>{i18next.t(translates.PartnerCardLabelsPlannedStartDate)}:&nbsp;</span>
                        <strong>{plannedStartDate}</strong>
                    </Row>
                )}
                {renderButton()}
            </Container>
        </>
    );
};
