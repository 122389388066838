import { FC, ReactNode } from 'react';

interface TabProps {
    title: string;
    children: ReactNode;
}

export const Tab: FC<TabProps> = ({ children }) => (
    <div>{children}</div>
);
