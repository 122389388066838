import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as Hamburger } from '../../assets/images/hamburger-icon.svg';
import { ReactComponent as HamburgerClose } from '../../assets/images/hamburger-close-icon.svg';

const Container = styled.div`
  width: 27px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface HamburgerMenuIconProps {
    open: boolean
    onClick(): void
}

export const HamburgerMenuIcon: FC<HamburgerMenuIconProps> = ({ open, onClick, ...props }) => {
    return (
        <Container onClick={onClick} {...props}>
            {open ? (
                <HamburgerClose />
            ) : (
                <Hamburger />
            )}
        </Container>
    );
};
