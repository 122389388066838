import { FC } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { translates } from '../../../../utilities';
import { Button } from '../../../../components';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding: 40px 80px 40px 30px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  position: relative;
  margin-bottom: 30px;

  @media ${({ theme }) => theme.query.small} {
    flex-direction: column;
    padding: 170px 16px 32px;
    margin-top: 45px;
  }
`;

const Content = styled.div`
  flex: 1;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 500;
`;

const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  margin: 0;
  padding-right: 120px;

  @media ${({ theme }) => theme.query.small} {
    margin: 10px 0 26px;
  }
`;

interface BannerProps {
    onCreateClick(): void
}

export const Banner: FC<BannerProps> = ({ onCreateClick }) => (
    <Container>
        <Content>
            <Title>{i18next.t(translates.InvitePartnerBannerTitle)}</Title>
            <Subtitle>{i18next.t(translates.InvitePartnerBannerSubtitle)}</Subtitle>
        </Content>
        <Button
            styleType="primary-inverted"
            title={i18next.t(translates.InvitePartnerBannerButton)}
            onClick={onCreateClick}
        />
    </Container>
);
