import { FC, Fragment, useMemo } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import clipboard from 'copy-to-clipboard';
import { DocumentName, MTCModel, OrderDocumentResultItem } from '../../../../types/api';
import { ReactComponent as CopyIcon } from '../../../../assets/images/copy.svg';
import { translates } from '../../../../utilities';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px;
`;

const DocumentLink = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    color: ${({ theme }) => theme.colors.grey6};
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
    margin: 0;
    text-decoration: underline;
    &:hover {
        text-decoration: underline;
    }
`;

const MtcTitle = styled.p`
    font-weight: bold;
    margin-top: 1rem;
`;

const MtcMeta = styled.p`
    color: ${({ theme }) => theme.colors.grey6};
    margin-right: 0.2rem;
    margin-bottom: 0;
`;

const MtcDetails = styled.p`
    margin-bottom: 0;
`;

const Row = styled.div`
    flex-direction: row;
    align-items: center;
    display: flex;
    margin-bottom: 0.5em;
`;

const Copy = styled.div`
    width: 10px;
    height: 10px;
    margin-left: 0.2rem;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    & svg {
        height: 12px;
        position: absolute;
        top: -5px;
        left: -5px;
    }
`;

interface DocumentsTabProps {
    orderId: string;
    documents: OrderDocumentResultItem[] | undefined;
    mtcDetails?: MTCModel;
    materialPrice?: number | undefined;
    invoiceTotalPrice?: number;
    orderWorkPrice?: number;
    isPaymentNoteEnabled?: boolean;
    isPaymentSplitEnabled?: boolean;
}

export const DocumentsTab: FC<DocumentsTabProps> = ({ documents, materialPrice, mtcDetails, invoiceTotalPrice, orderWorkPrice, isPaymentNoteEnabled, isPaymentSplitEnabled }) => {
    const renderMtcMeta = useMemo(() => {
        const meta = {
            companyName: {
                title: i18next.t(translates.MtcCompanyName),
                copy: true,
            },
            companyCode: {
                title: i18next.t(translates.MtcCompanyCode),
                copy: false,
            },
            companyVat: {
                title: i18next.t(translates.MtcCompanyVat),
                copy: false,
            },
            companyIban: {
                title: i18next.t(translates.MtcCompanyIban),
                copy: true,
            },
            materialPrice: {
                title: i18next.t(translates.MtcMaterialPrice),
                copy: true,
            },
        };

        return Object.entries({ ...(mtcDetails || {}), materialPrice }).map(([k, v]) => {
            return (
                <Row key={k}>
                    <MtcMeta>{meta[k].title}</MtcMeta>
                    <MtcDetails>{v}</MtcDetails>
                    {meta[k]?.copy ? (
                        <Copy onClick={() => v && clipboard(v.toString())}>
                            <CopyIcon />
                        </Copy>
                    ) : null}
                </Row>
            );
        });
    }, [materialPrice, mtcDetails]);

    return (
        <Content>
            {documents &&
                documents.map((doc) => {
                    let documentLabel = i18next.t(`Document.${doc.name}`);

                    if ([DocumentName.invoice, DocumentName.creditInvoice].includes(doc.name) && doc.number) {
                        documentLabel = `${documentLabel} ${doc.number}`;
                    }

                    return (
                        <DocumentLink href={doc.url!} key={doc.name}>
                            {documentLabel}
                        </DocumentLink>
                    );
                })}
            {isPaymentSplitEnabled === false && isPaymentNoteEnabled === false && materialPrice && invoiceTotalPrice === orderWorkPrice && renderMtcMeta.length > 1 ? (
                <Fragment>
                    <MtcTitle>{i18next.t(translates.MtcTitle)}</MtcTitle>
                    {renderMtcMeta}
                    <MtcMeta>{i18next.t(translates.MtcInvoiceInfo)}</MtcMeta>
                </Fragment>
            ) : null}
        </Content>
    );
};
