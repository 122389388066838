import { FC, useState } from 'react';
import { useField, useFormikContext } from 'formik';

import { FieldWrapper } from './components/wrapper';
import { allCountryPhoneCodes } from '../../utilities/countryDefaults';
import { Input } from '../input';
import { Select } from '../select';

interface FormFieldPhoneProps {
    label: string;
    name: string;
    namePrefix: string;
    size?: string;
    autoComplete?: string;
    disabled?: boolean;
}

export const FormFieldPhone: FC<FormFieldPhoneProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const [fieldPrefix, metaPrefix, helpersPrefix] = useField(props.namePrefix);
    const [filled, setFilled] = useState(!!field.value);
    const [focused, setFocused] = useState(false);
    const { isSubmitting } = useFormikContext();

    const handleFocus = () => {
        setFilled(true);
        setFocused(true);
    };

    const handleBlur = (e: any) => {
        setFilled(!!e.target.value);
        helpers.setTouched(true);
        setFocused(false);
    };

    return (
        <FieldWrapper
            group
            name={props.name}
            label={props.label}
            filled={filled}
            submitting={isSubmitting}
            focused={focused}>
            <Input
                name={props.name}
                addonBefore={
                    <Select
                        onChange={(code) => {
                            helpersPrefix.setValue(code);
                        }}
                        options={allCountryPhoneCodes.map((e) => {
                            return {
                                value: e,
                                label: e,
                            };
                        })}
                        value={fieldPrefix.value}
                        disabled={props.disabled || isSubmitting}
                        controlStyle={{
                            border: 0,
                            background: 'transparent',
                        }}
                    />
                }
                active={focused}
                error={meta.touched && !!meta.error}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(value) => helpers.setValue(value)}
                value={field.value}
                disabled={props.disabled || isSubmitting}
                type="tel"
            />
        </FieldWrapper>
    );
};
