import { useField } from 'formik';
import { FC, useEffect, useState } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { FormFieldTextarea } from '../../../../components';
import { translates } from '../../../../utilities';
import * as apiResponses from '../../../../types/apigw';

const StyledTextArea = styled(FormFieldTextarea).attrs({
    name: 'description',
})`
    height: 8rem;
`;

interface DescriptionContainerProps {
    $tooltip?: boolean;
}

const DescriptionContainer = styled.div<DescriptionContainerProps>`
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0px; 
    @media ${({ theme }) => theme.query.small} {
        ${({ $tooltip }) => $tooltip && 'margin-bottom: 16px'};
        padding: 0;
    }
`;

const TextFieldContainer = styled.div`
    flex: 1;
`;

const DescriptionTooltip = styled.div`
    position: relative;
    padding: 1rem;
    margin-left: 2rem;
    width: 12rem;
    background-color: ${({ theme }) => theme.colors.grey2};
    border: solid 1px ${({ theme }) => theme.colors.grey9};
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.grey10};
    font-size: 0.75rem;
    margin-bottom: 16px;

    @media ${({ theme }) => theme.query.small} {
        flex-basis: 100%;
        margin: 0;
    }
`;

interface DescriptionFieldProps {
    servicesGroups?: apiResponses.ServiceGroupModel[];
    tooltip?: boolean;
    maxLength?: number;
    labelKey?: string;
}

export const DescriptionField: FC<DescriptionFieldProps> = ({
    servicesGroups,
    tooltip,
    maxLength = 2000,
    labelKey = translates.CreateOrderLabelsDescription,
}) => {
    const [descriptionTooltip, setDescriptionTooltip] = useState('');
    // getting currently selected service ID;
    const [field] = useField('serviceId');

    useEffect(() => {
        if (!tooltip) {
            return;
        }

        const selectedService = servicesGroups!.find((service) => {
            return service.defaultServiceId.toString() === field.value;
        });
        setDescriptionTooltip(selectedService?.hintText || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [servicesGroups, tooltip]);

    const isTooltipVisible = Boolean(descriptionTooltip && tooltip);

    return (
        <DescriptionContainer $tooltip={isTooltipVisible}>
            <TextFieldContainer>
                <StyledTextArea
                    label={i18next.t(labelKey)}
                    maxLength={maxLength}
                />
            </TextFieldContainer>
            {isTooltipVisible && (
                <DescriptionTooltip>
                    {descriptionTooltip}
                </DescriptionTooltip>
            )}
        </DescriptionContainer>

    );
};
