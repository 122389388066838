import { FC, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';

import { FieldWrapper } from './components/wrapper';
import { Input } from '../input';

interface FormFieldInputProps {
    autoCapitalize?: string;
    autoComplete?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    icon?: string;
    label: string;
    labelAlwaysOnTop?: boolean;
    maxLength?: number;
    name: string;
    resetFormFlag?: number;
    size?: string;
    type?: string;
    onChange?: (value: string) => void;
    isLoading?: boolean;
}

export const FormFieldInput: FC<FormFieldInputProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const [filled, setFilled] = useState(props.labelAlwaysOnTop || !!field.value);
    const [focused, setFocused] = useState(!!props.autoFocus);
    const { isSubmitting } = useFormikContext();

    useEffect(() => {
        if (props.resetFormFlag) {
            helpers.setValue('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resetFormFlag]);

    const handleFocus = () => {
        setFilled(true);
        setFocused(true);
    };

    const handleBlur = (e: any) => {
        setFilled(props.labelAlwaysOnTop || !!e.target.value);
        helpers.setTouched(true);
        setFocused(false);
    };

    const handleChange = (value: string) => {
        if (props.onChange) {
            props.onChange(value);
        }
        helpers.setValue(value);
    };

    return (
        <FieldWrapper name={props.name} label={props.label} filled={filled} submitting={isSubmitting} focused={focused}>
            <Input
                active={focused}
                autoCapitalize={props.autoCapitalize}
                autoComplete={props.autoComplete}
                autoFocus={!!props.autoFocus}
                disabled={props.disabled || isSubmitting}
                error={meta.touched && !!meta.error}
                icon={props.icon}
                maxLength={props.maxLength}
                name={props.name}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                type={props.type}
                value={field.value}
                isLoading={props.isLoading}
            />
        </FieldWrapper>
    );
};
