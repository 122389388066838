import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/images/alert-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/help.svg';

const Container = styled.div`
    padding: 1.25rem;
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.grey7};
    border: 1px solid ${({ theme }) => theme.colors.grey8};
    color: ${({ theme }) => theme.colors.grey6};
    line-height: 1.5rem;
    display: flex;
    margin-bottom: 1em;
`;

const IconContainer = styled.div`
    flex-basis: auto;
    height: 1.5rem;
    margin-right: 1.5rem;
    display: flex;
    align-items: center;
`;

const ContentContainer = styled.div`
    flex-basis: auto;
`;

type PanelType = 'alert' | 'info';

interface InfoPanelProps {
    children: ReactNode;
    type: PanelType;
}

const getIcon = (type: PanelType) => {
    switch (type) {
    case 'alert':
        return <AlertIcon />;
    case 'info':
        return <InfoIcon />;
    default:
        return <></>;
    }
};

export const InfoPanel: FC<InfoPanelProps> = ({ type, children }) => (
    <Container>
        <IconContainer>{getIcon(type)}</IconContainer>
        <ContentContainer>{children}</ContentContainer>
    </Container>
);
