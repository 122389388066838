import { forwardRef, ReactElement, useCallback, useEffect, useImperativeHandle, useRef, useState, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import i18next from 'i18next';
import qs from 'query-string';
import debounce from 'lodash.debounce';

import { FieldWrapper } from './components/wrapper';
import { Toast } from '../toast';
import { Select, Option } from '../select';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import { useApi } from '../../hooks/useApi';
import { ApiErrorModel, AddressBookContactModel } from '../../types/api';
import { CustomOption } from './components/serviceReceiverCustomOption';
import { SelectExtras, translates } from '../../utilities';

interface ServiceReceiverSearchModel {
    addressBookId: string;
    clientId: string;
    companyName : string;
    companyCode: string;
    email: string;
    phone: string;
    address : string;
    longitude: number;
    latitude: number;
    companyZipCode: string;
    companyVatCode: string;
    contacts: AddressBookContactModel[];
}

const useSearchServiceReceivers = () => {
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [serviceReceivers, setServiceReceivers] = useState<ServiceReceiverSearchModel[]>([]);
    const fetchApi = useApi();

    const fetchReceivers = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetchApi<never[]>({
                url: 'address-books/search',
                params: {
                    companyName: search,
                },
                paramsSerializer: (params:Record<string, any>) => qs.stringify(params, { skipEmptyString: true }),
            });
            setServiceReceivers(response);
        } catch (e) {
            const error = e as ApiErrorModel;
            Toast.error(i18next.t(error?.errorMessage!));
        } finally {
            setLoading(false);
        }
    }, [search, fetchApi]);

    useEffect(() => {
        fetchReceivers();
    }, [fetchReceivers]);

    return { serviceReceivers, setSearch, loading, fetchReceivers };
};

export interface FormFieldSelectServiceReceiverRef {
    reloadReceivers: () => void;
}

interface FormFieldSelectServiceReceiverProps {
    name: string;
    label: string;
    addressFieldName: string;
    longitudeFieldName: string;
    latitudeFieldName: string;
    addressBookIdFieldName: string;
    serviceReceiverContactsFieldName: string;
    locationUpdated?: () => void;
    onAddClick?: () => void;
}

export const FormFieldSelectServiceReceiver = forwardRef<FormFieldSelectServiceReceiverRef, FormFieldSelectServiceReceiverProps>(({
    name,
    label,
    addressFieldName,
    longitudeFieldName,
    latitudeFieldName,
    addressBookIdFieldName,
    serviceReceiverContactsFieldName,
    locationUpdated,
    onAddClick,
}, ref) => {
    const selectRef = useRef<any>(null);
    const [field, meta, helpers] = useField(name);
    const { isSubmitting, values, setFieldValue } = useFormikContext();
    const getValue = (path: string) => {
        return path.split('.').reduce((o, i) => (o as any)[i], values);
    };
    const [filled, setFilled] = useState(!!getValue(name));
    const [focused, setFocused] = useState(false);
    const { serviceReceivers, setSearch, loading, fetchReceivers } = useSearchServiceReceivers();
    const selectData: Option[] = useMemo(() => {
        const options: Option[] = [{
            value: 'add-new',
            label: i18next.t(translates.SelectServiceReceiverLabelsAddReceiver),
            extra: SelectExtras.ADD_SERVICE_RECEIVER,
        }];
        serviceReceivers.map((item, idx) => (options.push({
            value: String(idx),
            label: item.companyName,
        })));

        return options;
    }, [serviceReceivers]);

    const handleSearch = debounce((value: string) => {
        setSearch(value);
    }, 300);

    const setMultipleValues = (companyName: string, address: string, lat: number, lng: number, addressBookId: string, contacts: AddressBookContactModel[]) => {
        helpers.setValue(companyName);
        setFieldValue(addressFieldName, address);
        setFieldValue(latitudeFieldName, lat);
        setFieldValue(longitudeFieldName, lng);
        setFieldValue(addressBookIdFieldName, addressBookId);
        setFieldValue(serviceReceiverContactsFieldName, contacts);
    };

    const handleAddReceiver = () => {
        if (typeof onAddClick === 'function') {
            onAddClick();
            if (selectRef.current) {
                selectRef.current.blur();
            }
        }
    };

    const handleChange = async (value: string) => {
        if (!value) {
            setMultipleValues('', '', 0, 0, '', []);
            return;
        }
        if (value === selectData.find((item) => item.extra === SelectExtras.ADD_SERVICE_RECEIVER)?.value) {
            handleAddReceiver();
            return;
        }
        helpers.setTouched(true);
        const selectedItem = serviceReceivers[parseInt(value, 10)];
        setMultipleValues(
            selectedItem.companyName,
            `${selectedItem.address} ${selectedItem.companyZipCode}`,
            selectedItem.latitude,
            selectedItem.longitude,
            selectedItem.addressBookId,
            selectedItem.contacts
        );
        if (locationUpdated) {
            locationUpdated();
        }
    };

    const handleFocus = () => {
        setFilled(true);
        setFocused(true);
        const el = document.getElementById(`form-item-${name}`);
        const additionalPaddings = 40;
        window.scrollTo({
            top: (el?.offsetTop ?? 0) - (el?.offsetHeight ?? 0) - additionalPaddings,
            behavior: 'smooth',
        });
    };

    const handleBlur = () => {
        setFilled(!!getValue(name));
        setTimeout(() => {
            helpers.setTouched(true);
        }, 500);
        setFocused(false);
    };

    useImperativeHandle(ref, () => ({
        reloadReceivers: fetchReceivers
    }));

    return (
        <FieldWrapper name={name} label={label} filled={filled} submitting={isSubmitting} focused={focused}>
            <Select
                ref={selectRef}
                name={name}
                error={meta.touched && !!meta.error}
                options={selectData}
                value={getValue(name) as string}
                onSearch={handleSearch}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                customIcon={SearchIcon as unknown as ReactElement}
                customComponents={{
                    Option: CustomOption,
                }}
                loading={loading}
            />
        </FieldWrapper>
    );
});
