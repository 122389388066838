import { FC } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import i18next from 'i18next';

const Wrapper = styled.div`
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.fieldError};
`;

interface FieldErrorProps {
    name: string;
}

export const FieldError: FC<FieldErrorProps> = (props) => {
    const [field, meta] = useField(props.name);

    if (!meta.touched || !meta.error) {
        return null;
    }

    return (
        <Wrapper>
            {meta.touched && meta.error && i18next.t(meta.error)}
        </Wrapper>
    );
};
