import { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import i18next from 'i18next';
import { ReactComponent as PlusIcon } from '../../../../assets/images/plus-icon-standard.svg';
import { ReactComponent as MinusIcon } from '../../../../assets/images/minus-icon-standard.svg';
import { theme } from '../../../../theme';
import { translates } from '../../../../utilities';
import { ServiceItem } from '../../../../types/api';
import { PopoverButton } from './PopoverButton';
import { Modal } from '../../../../components';

interface JobElementProps {
    $column?: boolean;
}

const JobElement = styled.div<JobElementProps>`
    display: flex;
    ${({ $column }) => $column && 'flex-direction: column'};
    margin-bottom: 16px;
`;

const JobInfoItem = styled.div`
    display: flex;
    flex: 2;
    border: 1px solid ${({ theme }) => theme.colors.grey8};
    padding: 10px 2px;
    border-radius: 6px;
    min-height: 48px;
    align-items: center;
`;

const JobInfoTitle = styled.p`
    color: ${({ theme }) => theme.colors.darkBlack};
    margin-bottom: 0;
    margin-left: 10px;
`;

const Description = styled.a`
    color: ${({ theme }) => theme.colors.grey5};
    margin-bottom: 0;
    margin-left: 10px;
`;

const JobAmountRow = styled.div<{ $isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 1 0;

    ${({ $isMobile }) => {
        if ($isMobile) {
            return 'margin-top: 0.5rem';
        }
        return '';
    }}
`;

const JobAmountLabelContainer = styled.div`
    display: flex;
    align-items: center;
`;

const JobAmountLabel = styled.span`
    color: ${({ theme }) => theme.colors.grey6};
    margin-right: 2rem;
    margin-left: 0.5rem;
`;

const JobAmountContainer = styled.div`
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.grey8};
    border-radius: 6px;
    width: 148px;
    height: 48px;
    flex-shrink: 0;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

interface JobAmountActionButtonProps {
    $right?: boolean;
}

const JobAmountActionButton = styled.div<JobAmountActionButtonProps>`
    cursor: pointer;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border: 0 solid ${({ theme }) => theme.colors.grey8};
    ${({ $right }) => ($right ? 'border-left-width: 1px' : 'border-right-width: 1px')};
    background-color: ${({ theme }) => theme.colors.grey2};
`;

const JobAmountTextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;
`;

const JobAmountInput = styled.input.attrs({
    type: 'tel',
    min: '0',
})`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }
    -webkit-user-select: text !important;

    height: 100%;
    width: 100%;
    text-align: center;
    border: none;
    user-select: none;
`;

const Relative = styled.div`
 position: relative;
`;

const JobContainer = styled.div`
    position: absolute;
    top: 0.1rem;
    right: 0.5rem;
`;

const PopoverContentLabel = styled.span`
    color: ${({ theme }) => theme.colors.white};
`;

interface ServiceJobModel {
    id: string;
    calculatedPricePerItem: number;
    coefficient: number;
    margin: number;
    price: number;
    translation?: string;
    unitOfMeasure: string;
    quantity?: number;
}

interface JobItemProps {
    job: ServiceItem;
    jobInfoTitle: string;
    onQuantityChange: (job: ServiceItem, quantity: number) => void;
    unitOfMeasure?: string | null;
    description?: string | null;
    quantity: number;
}

export const JobItem: FC<JobItemProps> = ({
    job,
    jobInfoTitle,
    onQuantityChange,
    unitOfMeasure,
    quantity,
    description,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const isMobile = useMediaQuery({
        query: theme.query.small,
    });
    const [show, setShow] = useState<boolean>(false);

    const handleAmountInput = (event: any) => {
        event?.preventDefault();
        const {
            target: { value },
        } = event;
        const amount = parseInt(value, 10);
        if (Number.isFinite(amount)) {
            onQuantityChange(job, amount);
            return;
        }

        onQuantityChange(job, value);
    };

    const handleActionButton = (change: number) => {
        if (quantity === 0 && change === -1) {
            return;
        }

        onQuantityChange(job, (quantity as number) + change);
    };

    const handleBlur = () => {
        if (!Number.isFinite(quantity) || !(quantity >= 0)) {
            onQuantityChange(job, 0);
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.code === 'Enter') {
            handleBlur();
            inputRef.current?.blur();
        }
    };

    const PopoverContent = (
        <PopoverContentLabel>{i18next.t(translates.OrderStepsLabelsJobsStepInfoLabel)}</PopoverContentLabel>
    );

    const toggleModal = () => {
        setShow((p) => !p);
    };

    return (
        <>
            <JobElement $column={isMobile}>
                <JobInfoItem>
                    <Column>
                        <JobInfoTitle>{jobInfoTitle}</JobInfoTitle>
                        {description && (
                            <Description onClick={toggleModal}>{i18next.t(translates.OrderStepsLabelsJobsStepReadMoreLabel)}</Description>
                        )}
                    </Column>
                </JobInfoItem>
                <JobAmountRow $isMobile={isMobile}>
                    <JobAmountLabelContainer>
                        <Relative>
                            <JobContainer>
                                <PopoverButton>{PopoverContent}</PopoverButton>
                            </JobContainer>
                            <JobAmountLabel>
                                {i18next.t(translates.OrderStepsLabelsJobsStepJobListAmountLabel, { units: unitOfMeasure })}
                            </JobAmountLabel>
                        </Relative>
                    </JobAmountLabelContainer>
                    <JobAmountContainer>
                        <JobAmountActionButton onClick={() => handleActionButton(-1)}>
                            <MinusIcon />
                        </JobAmountActionButton>
                        <JobAmountTextContainer>
                            <JobAmountInput
                                ref={inputRef}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                                value={quantity as number}
                                onChange={handleAmountInput}
                            />
                        </JobAmountTextContainer>
                        <JobAmountActionButton $right onClick={() => handleActionButton(1)}>
                            <PlusIcon />
                        </JobAmountActionButton>
                    </JobAmountContainer>
                </JobAmountRow>
            </JobElement>
            <Modal open={show} onClose={toggleModal}>
                <p>{description}</p>
            </Modal>
        </>
    );
};

export type { ServiceJobModel };
