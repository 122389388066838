import i18next from 'i18next';
import styled from 'styled-components';
import { useField, useFormikContext } from 'formik';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormFieldAutocompleteGoogleMaps } from '../../../../components/formField/autocompleteGoogleMaps';
import { getCountry, getCountryEmail } from '../../../../utilities/countryDefaults';
import { translates } from '../../../../utilities/translates';
import {
    FormFieldCheckbox,
    FormFieldInput,
    FormFieldSelect,
    FormFieldSelectServiceReceiver,
    FormFieldSelectServiceReceiverRef,
    RadioButtonGroup,
} from '../../../../components';
import { InfoPanel } from '../../../../components/infoPanel';
import { AdditionalInfo, Title } from '../components';
import { logEvent } from '../../../../utilities/analytics';
import { ContactPersonTypes, ServiceReceiverTypes } from '../../../../utilities';
import { CreateOrderFormModel, useLogEvent } from '../utilities';
import { AuthContext } from '../../../../contexts';
import { AddressBookModal } from '../components/AddressBookModal';
import { NewAddressBookModel } from '../../../../components/addressBookForm';
import { useGetAvailability } from '../queries';

const Container = styled.div`
    padding-top: 6px;
`;

const ContactContainer = styled.div`
    font-size: 0.75rem;
    margin-top: 1rem;
`;

const RadioContainer = styled.div`
    margin-bottom: 18px;
`;

const PickerContainer = styled.div`
    margin-top: 0.5rem;
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

interface DetailsStepProps {
    onValidationChange(valid: boolean): void;
}

export const DetailsStep: FC<DetailsStepProps> = ({ onValidationChange }) => {
    const selectServiceReceiverRef = useRef<FormFieldSelectServiceReceiverRef | null>(null);
    const country = getCountry();
    const { auth } = useContext(AuthContext);
    const { values, setFieldValue } = useFormikContext<CreateOrderFormModel>();
    const [field, meta] = useField('companyName');
    const [locationUpdated, setLocationUpdated] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isAddressFieldEmpty, setIsAddressFieldEmpty] = useState(true);

    const isEnabled = !isAddressFieldEmpty;
    const isSelectedServiceReceiver = values.serviceReceiver === ServiceReceiverTypes.SELECTED;
    const isSelectedContactPerson = values.contactPerson === ContactPersonTypes.SELECTED;

    const { isAvailable } = useGetAvailability(values.serviceId, values.longitude, values.latitude, isEnabled);

    useLogEvent('details-step-step4');

    useEffect(() => {
        onValidationChange(isAvailable);
        if (!isAvailable) {
            logEvent('details-step-step4-error', {
                country,
                address: values.address,
                serviceId: values.serviceId,
                longitude: values.longitude,
                latitude: values.latitude,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAvailable]);

    useEffect(() => {
        if (!values.address || !values.latitude || !values.longitude) {
            setIsAddressFieldEmpty(true);
            return;
        }
        setIsAddressFieldEmpty(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationUpdated]);

    const setServiceReceiverFields = (payload?: NewAddressBookModel) => {
        setFieldValue('address', payload?.address ?? values.address);
        setFieldValue('longitude', payload?.longitude ?? values.latitude);
        setFieldValue('latitude', payload?.latitude ?? values.longitude);
        setFieldValue('companyName', payload?.companyName ?? values.companyName);
        setFieldValue('addressBookItemId', payload?.addressBookId ?? values.addressBookItemId);
        setFieldValue('serviceReceiverContacts', payload?.contacts || values.serviceReceiverContacts);
        setFieldValue('contactTelephone', values.contactTelephone || '');
        setFieldValue('contactPerson', values?.contactPerson ?? ContactPersonTypes.SELECTED);
    };

    const contactTelephoneOptions = useMemo(() => {
        if (!values.serviceReceiverContacts) {
            return [];
        }

        return values.serviceReceiverContacts.map((item) => {
            return ({
                label: `${item.position} | ${item.fullName}`,
                value: item.phoneNumber,
            });
        });
    }, [values.serviceReceiverContacts]);

    const contactOptions = useMemo(() => {
        if (!values.serviceReceiverContacts) {
            return [];
        }

        return values.serviceReceiverContacts.map((item) => ({
            phoneLabel: `${item.position} | ${item.fullName}`,
            phoneNumber: item.phoneNumber,
            email: item.email
        }));
    }, [values.serviceReceiverContacts]);

    useEffect(() => {
        if (values.serviceReceiver !== ServiceReceiverTypes.USER) {
            setFieldValue('shouldSendOrderTrackingUrlByEmail', !!values.contactEmail);
        }
    }, [values.contactEmail, values.serviceReceiver]);

    useEffect(() => {
        const selectedContact = contactOptions.find((opt) => opt.phoneNumber === values.contactTelephone);
        if (selectedContact) {
            setFieldValue('contactEmail', selectedContact.email);
        }
    }, [values.contactTelephone, contactOptions, setFieldValue]);

    useEffect(() => {
        if (values.serviceReceiver === ServiceReceiverTypes.SELECTED && (!values.email || !values.phone)) {
            setFieldValue('email', auth?.email);
            setFieldValue('phone', auth?.phone?.replace(values.phoneCode, ''));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.serviceReceiver]);

    useEffect(() => {
        if (meta.touched) {
            if (values.serviceReceiverContacts?.length === 0) {
                setFieldValue('contactPerson', ContactPersonTypes.USER);
            } else if (values.serviceReceiverContacts && values.serviceReceiverContacts?.length > 0) {
                setFieldValue('contactPerson', ContactPersonTypes.SELECTED);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta.touched, values.companyName, values.serviceReceiverContacts]);

    useEffect(() => {
        if (values.serviceReceiver === ServiceReceiverTypes.USER) {
            setFieldValue('companyName', '');
            setFieldValue('contactTelephone', '');
            setFieldValue('contactEmail', '');
            setFieldValue('shouldSendOrderTrackingUrlByEmail', false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.serviceReceiver]);

    useEffect(() => {
        if (values.contactPerson === ContactPersonTypes.USER) {
            setFieldValue('contactTelephone', '');
            setFieldValue('contactEmail', '');
            setFieldValue('shouldSendOrderTrackingUrlByEmail', false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.contactPerson]);

    const handleUpdatedLocation = () => {
        setLocationUpdated((prevState) => prevState + 1);
    };

    const handleAddServiceReceiver = () => {
        setIsModalVisible(true);
    };

    const handleOnAddServiceReceiver = (payload: NewAddressBookModel) => {
        if (selectServiceReceiverRef.current) {
            selectServiceReceiverRef.current.reloadReceivers();
        }

        setServiceReceiverFields(payload);
    };

    return (
        <Container>
            <AddressBookModal
                showModal={isModalVisible}
                setShowModal={setIsModalVisible}
                onSuccess={handleOnAddServiceReceiver}
            />
            {auth?.isCompany && (
                <RadioContainer>
                    <RadioButtonGroup
                        inline
                        type='iconButton'
                        name="serviceReceiver"
                        options={[
                            {
                                label: i18next.t(translates.OrderStepsLabelsServiceReceiverSelected),
                                value: ServiceReceiverTypes.SELECTED,
                            },
                            {
                                label: i18next.t(translates.OrderStepsLabelsServiceReceiverUser),
                                value: ServiceReceiverTypes.USER,
                            },
                        ]}
                    />
                </RadioContainer>
            )}
            {isSelectedServiceReceiver && (
                <>
                    <Title>{i18next.t(translates.OrderStepsLabelsContacts)}</Title>
                    <PickerContainer>
                        <FormFieldSelectServiceReceiver
                            ref={selectServiceReceiverRef}
                            name="companyName"
                            addressFieldName="address"
                            longitudeFieldName="longitude"
                            latitudeFieldName="latitude"
                            addressBookIdFieldName="addressBookItemId"
                            serviceReceiverContactsFieldName="serviceReceiverContacts"
                            label={i18next.t(translates.CreateOrderLabelsSelectServiceReceiver)}
                            locationUpdated={handleUpdatedLocation}
                            onAddClick={handleAddServiceReceiver}
                        />
                    </PickerContainer>
                </>
            )}
            {!!values.companyName && contactTelephoneOptions.length > 0 && (
                <RadioContainer>
                    <RadioButtonGroup
                        inline
                        name="contactPerson"
                        options={[
                            {
                                label: i18next.t(translates.OrderStepsLabelsContactPersonSelected),
                                value: ContactPersonTypes.SELECTED,
                            },
                            {
                                label: i18next.t(translates.OrderStepsLabelsContactPersonUser),
                                value: ContactPersonTypes.USER,
                            },
                        ]}
                    />
                </RadioContainer>
            )}
            {isSelectedContactPerson && !!values.companyName && contactTelephoneOptions.length > 0 && (
                <>
                    <FormFieldSelect
                        name="contactTelephone"
                        label={i18next.t(translates.OrderStepsLabelsContactPersonInput)}
                        options={contactTelephoneOptions}
                    />
                    <CheckboxContainer>
                        <FormFieldCheckbox
                            name='shouldSendOrderTrackingUrlBySms'
                            labelCheckbox={i18next.t(translates.OrderStepsLabelsSendTrackingBySms) as string}
                        />
                        <FormFieldCheckbox
                            name='shouldSendOrderTrackingUrlByEmail'
                            labelCheckbox={i18next.t(translates.OrderStepsLabelsSendTrackingByEmail) as string}
                            disabled={!values.contactEmail}
                        />
                    </CheckboxContainer>
                </>
            )}
            {(!isSelectedServiceReceiver || !!values.companyName) && (
                <>
                    <Title>{i18next.t(translates.OrderStepsLabelsAddress)}</Title>
                    <PickerContainer>
                        <FormFieldAutocompleteGoogleMaps
                            name="address"
                            country={country}
                            label={i18next.t(translates.CreateOrderLabelsAddress)}
                            longitudeFieldName="longitude"
                            latitudeFieldName="latitude"
                            restrictByCountry
                            locationUpdated={handleUpdatedLocation}
                        />
                    </PickerContainer>

                </>
            )}
            {(!isSelectedServiceReceiver || !!values.companyName) && (
                <FormFieldInput
                    name="additionalInformation"
                    label={i18next.t(translates.CreateOrderLabelsAdditionalInformation)}
                    maxLength={200}
                />
            )}
            {!isAvailable && (
                <InfoPanel type="info">
                    <div>
                        {i18next.t(translates.OrderStepsLabelsLocationStepServiceUnavailableTitle)}
                        &nbsp;{i18next.t(translates.OrderStepsLabelsLocationStepServiceUnavailableSubtitle)}
                    </div>
                    <ContactContainer>
                        {i18next.t(translates.OrderStepsLabelsLocationStepServiceUnavailableInfo)}
                        &nbsp;
                        <a href={`mailto:${getCountryEmail(country)}`}>
                            {i18next.t(translates.OrderStepsLabelsLocationStepServiceUnavailableAnchor)}
                        </a>
                    </ContactContainer>
                </InfoPanel>
            )}
            <AdditionalInfo />
        </Container>
    );
};
