import { useEffect, useState, useContext, FC } from 'react';
import parse from 'html-react-parser';

import { Method } from 'axios';

import { ConfigContext } from '../../contexts';
import { useLocalizationApi } from '../../hooks/useLocalizationApi';

const getHtmlContent = (country: string, language: string, contentKey: string) => {
    return {
        method: 'GET' as Method,
        url: `content/html/${country}/${language}/Client/${contentKey}`
    };
};

interface HtmlContentProps {
    contentKey: string;
}

export const HtmlContent: FC<HtmlContentProps> = ({ contentKey }) => {
    const api = useLocalizationApi();
    const { language, country } = useContext(ConfigContext);
    const [content, setContent] = useState('');

    useEffect(() => {
        api(getHtmlContent(country, language, contentKey))
            .then((res) => {
                setContent(res as string);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentKey, language, country]);

    return (
        <>
            {parse(content)}
        </>
    );
};
