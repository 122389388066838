import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { routerPaths } from './configs';
import { Header, Footer } from './layout';
import { AuthContext, ConfigContext } from './contexts';
import { Container } from './components/container';
import { getPlatform } from './configs/Platform';
import { logEvent, logPageView } from './utilities/analytics';
import { getLocalContactInfo, validateUserData } from './utilities';
import { useApi } from './hooks/useApi';
import { ClientReadModel, OrdersInfo } from './types/api';
import { useNotification } from './hooks/useNotification';
import { getIsIncompleteProfileModalForced } from './utilities/countryDefaults';
import { useUrlQuery } from './hooks/useUrlQuery';

const Main = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const MainInner = styled(Container)`
    flex-grow: 1;
`;

export const AppRouter = () => {
    const { auth, setAuth } = useContext(AuthContext);
    const {
        country,
    }: {
        country: string;
        setLanguage: (language: string) => void;
    } = useContext(ConfigContext);
    const navigate = useNavigate();
    const location = useLocation();
    const query = useUrlQuery();
    const [isLoading, setIsLoading] = useState(true);
    const fetchApi = useApi();

    useNotification((clientId: string) => {
        if (auth?.clientId === clientId) {
            fetchApi<ClientReadModel>({
                url: 'clients/self',
                method: 'GET',
            }).then(setAuth);
        }
    }, 'RefreshProfileInformation');

    useEffect(() => {
        logPageView(location);
        logEvent('optimize.activate');
    }, [location]);

    const checkUser = async () => {
        try {
            if (auth?.email || auth?.phone) {
                await validateUserData(auth!, country);
            }
            return;
        } catch (error) {
            navigate('orders');
        }
    };

    const goToCreateOrder = (q = '') => {
        navigate(`${routerPaths.createOrder.route}${q}`, { replace: true });
    };

    const goToOrders = () => {
        navigate(routerPaths.orders.route, { replace: true, });
    };

    const handleRoutes = useCallback(async () => {
        if (location.pathname === routerPaths.createOrder.route && (auth?.email || auth?.phone)) {
            checkUser();
        }

        const authRemovePaths = [routerPaths.login.route, routerPaths.google.route];
        if (auth && authRemovePaths.includes(location.pathname)) {
            try {
                const ordersInfo = await fetchApi<OrdersInfo>({
                    url: 'orders/info',
                    method: 'GET',
                });

                const contactInfo = getLocalContactInfo();

                if (query?.serviceId) {
                    goToCreateOrder(location.search);
                } else if (ordersInfo.hasOrders || contactInfo.email === auth.email || contactInfo.phone === auth.phone) {
                    goToOrders();
                } else {
                    goToCreateOrder();
                }
            } catch {
                goToOrders();
            }
        }

        const url = new URL(window.location.href);
        const countryUrl = url.searchParams.get('country');
        if (countryUrl) {
            localStorage.setItem('country', countryUrl);
        }

        if (
            !auth &&
            !localStorage.getItem('country') &&
            getPlatform() === 'APP' &&
            routerPaths.selectCountry.route !== location.pathname
        ) {
            const camefrom = `${location.pathname}${location.search}`;
            navigate(`${routerPaths.selectCountry.route}?camefrom=${camefrom}`, { replace: true });
        }
        setIsLoading(false);
    }, [auth, location, navigate, query]);

    useEffect(() => {
        handleRoutes();
    }, [handleRoutes]);

    const appRoutes = useMemo(() => {
        return Object.values(routerPaths).map((route, i) => {
            if (route.isAuth) {
                return (
                    <Route
                        key={i}
                        path={route.route}
                        element={auth ? <route.component /> : <Navigate to={routerPaths.login.route} replace />}
                    />
                );
            }
            return <Route key={i} path={route.route} element={<route.component />} />;
        });
    }, [auth]);

    return (
        <Main>
            <Header />
            {!isLoading && (
                <>
                    <MainInner>
                        <Routes>
                            {appRoutes}
                        </Routes>
                    </MainInner>
                    <Footer />
                </>
            )}
        </Main>
    );
};
