import moment from 'moment';

import { createContext, FC, useReducer } from 'react';
import { LOCAL_STORAGE_KEYS } from '../utilities';

type InitialStatetype = {
    orderId: string | null;
    setOrderId: (value: string | null) => void;
    recentPaidOrders: RecentPaidOrderModel;
    setRecentPaidOrders: (value: RecentPaidOrderModel) => void;
};

const enum Actions {
    SET_ORDER_ID = 'SET_ORDER_ID',
    SET_RECENT_ORDERS = 'SET_RECENT_ORDERS',
}

type Action =
    { type: Actions.SET_ORDER_ID; value: string | null } |
    { type: Actions.SET_RECENT_ORDERS; value: RecentPaidOrderModel };

interface RecentPaidOrderModel {
    [orderId: string]: Date
}

const removeOldPaidOrders = (orders: RecentPaidOrderModel): RecentPaidOrderModel => {
    const newOrders = orders;
    const orderIds = Object.keys(orders);
    orderIds.forEach((id) => {
        if (moment().diff(moment(orders[id]), 'seconds') > 120) {
            delete newOrders[id];
        }
    });
    localStorage.setItem('recentPaidOrders', JSON.stringify(newOrders));
    return newOrders;
};

const getRecentPaidOrders = (): RecentPaidOrderModel => {
    if (localStorage.getItem('recentPaidOrders')) {
        const orders: RecentPaidOrderModel = JSON.parse(localStorage.getItem('recentPaidOrders')!);
        return removeOldPaidOrders(orders);
    }
    return {};
};

const reducer = (state: InitialStatetype, action: Action) => {
    switch (action.type) {
    case Actions.SET_ORDER_ID:
        localStorage.setItem(LOCAL_STORAGE_KEYS.ORDER_ID, JSON.stringify(action.value));
        return {
            ...state,
            orderId: action.value
        };
    case Actions.SET_RECENT_ORDERS:
        localStorage.setItem('recentPaidOrders', JSON.stringify(action.value));
        return {
            ...state,
            recentPaidOrders: action.value
        };
    default:
        return state;
    }
};

const resloveInitialOrderId = (): string | null => {
    const orderId = localStorage.getItem(LOCAL_STORAGE_KEYS.ORDER_ID);
    return orderId ? JSON.parse(orderId) : null;
};

const initialState = {
    orderId: resloveInitialOrderId(),
    setOrderId: () => {},
    recentPaidOrders: getRecentPaidOrders(),
    setRecentPaidOrders: () => {},
};

export const OrderingContext = createContext<InitialStatetype>({
    orderId: null,
    setOrderId: () => {},
    recentPaidOrders: {},
    setRecentPaidOrders: () => {},
});

export const OrderingProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {
        orderId: state.orderId,
        recentPaidOrders: state.recentPaidOrders,
        setOrderId: (value: string | null) => {
            dispatch({
                type: Actions.SET_ORDER_ID,
                value
            });
        },
        setRecentPaidOrders: (value: RecentPaidOrderModel) => {
            dispatch({
                type: Actions.SET_RECENT_ORDERS,
                value
            });
        }
    };
    return (
        <OrderingContext.Provider value={value}>{children}</OrderingContext.Provider>
    );
};
