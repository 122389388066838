import styled from 'styled-components';
import { CSSProperties, useRef, useState } from 'react';
import { ReactComponent as CaretUpIcon } from '../../assets/images/caret-up-grey-icon.svg';
import { ReactComponent as CaretDownIcon } from '../../assets/images/caret-down-grey-icon.svg';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick';

interface DropdownContentContainerProps {
    $open: boolean
}

const DropdownContentContainer = styled.div<DropdownContentContainerProps>`
  padding-top: 20px;
  display: ${({ $open }) => $open ? 'block' : 'none'};
  position: absolute;
  right: 0;
  z-index: 10;
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: relative;
`;

const DropdownContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 10px ${({ theme }) => theme.colors.shadow};
  border-radius: 10px;
  cursor: pointer;
`;

const DropdownContentItem = styled.div`
  padding: 10px 22px;
  display: flex;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.grey8};
  }
`;

const CaretContainer = styled.div`
  margin-left: 8px;
`;

const CaretUpIconStyled = styled(CaretUpIcon)`
  width: 10px;
`;

const CaretDownIconStyled = styled(CaretDownIcon)`
  width: 10px;
`;

interface DropdownItem {
    onClick(): void
}

interface MenuDropdownProps<T> {
    items: T[]
    renderItem(item: T): JSX.Element
    children: JSX.Element
    style?: CSSProperties
}

export const MenuDropdown = <T extends DropdownItem>({ items, renderItem, children, ...props }: MenuDropdownProps<T>) => {
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    useDetectOutsideClick(ref, () => setOpen(false));

    const handleClick = (item: T) => {
        setOpen(false);
        item.onClick();
    };

    return (
        <Dropdown {...props} ref={ref}>
            <ChildrenContainer onClick={() => setOpen(!open)}>
                {children}
                <CaretContainer>
                    {open ? (
                        <CaretUpIconStyled />
                    ) : (
                        <CaretDownIconStyled />
                    )}
                </CaretContainer>
            </ChildrenContainer>
            <DropdownContentContainer $open={open}>
                <DropdownContent>
                    {items.map((item, i) => (
                        <DropdownContentItem key={i} onClick={() => handleClick(item)}>
                            {renderItem(item)}
                        </DropdownContentItem>
                    ))}
                </DropdownContent>
            </DropdownContentContainer>
        </Dropdown>
    );
};
