import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import axios from 'axios';
import { getEnvironment } from '../configs/environment';
import { CountryEnum, getCountry, getCountryLanguage, LanguageEnum } from './countryDefaults';

const apiLocalizationUrl = window.ENVIRONTMENT_VARIABLES.REACT_APP_API_LOCALIZATION_URL;
const language = localStorage.getItem('language');

const fetchTranslations = async (country: CountryEnum, language: LanguageEnum, ns: string) => {
    const responses = await Promise.all([
        axios.get(`${apiLocalizationUrl}translations/${language}/${ns}/labels`),
        axios.get(`${apiLocalizationUrl}content/${country}/${language}/Services`)
    ]);
    return responses.reduce((acc, res) => ({ ...acc, ...res.data }), {});
};

export const i18n = i18next.use(initReactI18next).use(Backend).init({
    backend: {
        loadPath: '{{lng}}/{{ns}}',
        request: (options, url, payload, callback) => {
            const country = getCountry();
            const [language, ns] = url.split('/');
            fetchTranslations(country, language as LanguageEnum, ns).then((data) => {
                callback(null, { status: 200, data: data as string });
            });
        },
    },
    lng: language || getCountryLanguage(getCountry()),
    fallbackLng: false,
    defaultNS: 'Client',
    ns: 'Client',
    debug: getEnvironment() === 'DEV',
    interpolation: {
        escapeValue: false
    },
    react: {
        useSuspense: true
    }
}, (error) => {
    if (error) {
        console.error('Translation error', error);
    }
});
