import { FC, useState } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';

import WarningIcon from '../../../../assets/images/warning-icon.svg';
import { translates } from '../../../../utilities';
import { Button } from '../../../../components';
import { IncompleteProfileModal } from '../../profile';

const Container = styled.div`
    background: ${({ theme }) => theme.colors.white};
    padding: 32px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    position: relative;

    @media ${({ theme }) => theme.query.small} {
        flex-direction: column;
        padding: 32px 16px;
    }
`;

const Content = styled.div`
    flex: 1;

    @media ${({ theme }) => theme.query.small} {
        text-align: center;
    }
`;

const MainTitle = styled.p`
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.125rem;
    line-height: 1.575rem;
    font-weight: 500;
    margin: 0;
`;

const Subtitle = styled.p`
    color: ${({ theme }) => theme.colors.black};
    font-size: 0.875rem;
    margin: 0;

    @media ${({ theme }) => theme.query.small} {
        margin: 10px 0 26px;
    }
`;

const Icon = styled.img`
    margin-right: 20px;
    width: 18px;
    min-height: 1.25rem;
    position: relative;
    top: -12px;

    @media ${({ theme }) => theme.query.small} {
        margin-right: 0;
    }
`;

const ProfileEditButton = styled(Button)``;

export const IncompleteProfileAlert: FC = (props) => {
    const [showProfileModal, setShowProfileModal] = useState(false);

    return (
        <>
            <Container {...props}>
                <Icon src={WarningIcon} />
                <Content>
                    <MainTitle>{i18next.t(translates.OrderIncompleteProfileTitlesMain)}</MainTitle>
                    <Subtitle>{i18next.t(translates.OrderIncompleteProfileSubtitlesMain)}</Subtitle>
                </Content>
                <ProfileEditButton
                    type="button"
                    styleType="primary"
                    onClick={() => setShowProfileModal(true)}
                    title={i18next.t(translates.OrderIncompleteProfileButtonsUpdate)}
                />
            </Container>
            <IncompleteProfileModal showModal={showProfileModal} setShowModal={setShowProfileModal} />
        </>
    );
};
