import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const ignoredQueryKeys = [
    'CxJgWyfDRJWJLmDWvta9mQ',
    'utm_campaign',
    'utm_medium',
    'utm_source'
];

export const useUrlQuery = () => {
    const location = useLocation();

    return useMemo(() => {
        const parsedLocation = queryString.parse(location.search);
        const filteredKeys = Object.keys(parsedLocation).filter((search) => !ignoredQueryKeys.includes(search));
        return Object.assign({}, ...filteredKeys.map((key) => ({ [key]: parsedLocation[key] })));
    }, [location]);
};
