import React, { FC, useEffect } from 'react';
import { Formik, FormikErrors, FormikValues } from 'formik';
import { Form as ANTDForm } from 'formik-antd';
import styled from 'styled-components';

import { Button } from '../button';
import { useScrollToFieldError } from '../../hooks/useScrollToFieldError';

interface FormikOnChangeHelperProps {
    values: FormikValues;
    onChange?: Function;
    children: React.ReactElement;
}

const FormikOnChangeHelper = ({ values, onChange, children }: FormikOnChangeHelperProps) => {
    useEffect(() => {
        onChange?.(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return children;
};

interface FormikValidatorHelperProps {
    children: React.ReactElement;
    isValid: boolean;
    setIsValid?: (isValid: boolean) => void;
    schema: Object | undefined;
    validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>
    validateOnChange?: boolean;
}

const FormikValidatorHelper = ({ children, isValid, setIsValid, schema, validateForm, validateOnChange }: FormikValidatorHelperProps) => {
    useEffect(() => {
        setIsValid?.(isValid);
        if (validateOnChange) {
            validateForm();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid, schema]);

    return children;
};

interface FormikValidationScrollProps {
    children: React.ReactElement;
    scrollOnValidate?: boolean;
    scrollElement?: HTMLDivElement | null;
}

const FormikValidationScroll = ({ scrollOnValidate, scrollElement, children }: FormikValidationScrollProps) => {
    useScrollToFieldError(scrollOnValidate, scrollElement);
    return children;
};

const ButtonsContainer = styled.div`
    margin: 3rem 0 1rem;
    display: flex;
    flex-direction: column;
`;

const ButtonStyled = styled(Button)`
    &:nth-child(2) {
        height: 48px;
        margin-top: 1rem;
    }
`;

interface FormProps {
    schema?: Object;
    submit: Function;
    submitText?: string;
    cancelText?: string;
    cancel?: Function;
    values: FormikValues;
    onChange?: Function;
    iconRight?: string;
    setIsValid?: (isValid: boolean) => void;
    validateOnMount?: boolean;
    validateOnChange?: boolean;
    validateOnBlur?: boolean;
    disableEnterSubmit?: boolean;
    scrollOnValidate?: boolean;
    scrollElement?: HTMLDivElement | null;
    children: React.ReactNode;
}

export const Form: FC<FormProps> = ({
    children,
    schema,
    submit,
    submitText,
    cancelText,
    cancel,
    values,
    onChange,
    iconRight,
    setIsValid,
    validateOnMount,
    validateOnChange,
    validateOnBlur = true,
    disableEnterSubmit,
    scrollOnValidate,
    scrollElement,
    ...props
}) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter' && disableEnterSubmit) {
            event.preventDefault();
        }
    };

    return (
        <Formik
            initialValues={values}
            validationSchema={schema}
            onSubmit={(values, helpers) => submit(values, helpers)}
            validateOnMount={validateOnMount}
            validateOnBlur={validateOnBlur}
        >
            {({ values, isSubmitting, isValid, validateForm, submitForm }) => (
                <FormikValidatorHelper isValid={isValid} setIsValid={setIsValid} schema={schema} validateForm={validateForm} validateOnChange={validateOnChange}>
                    <FormikOnChangeHelper values={values} onChange={onChange}>
                        <FormikValidationScroll scrollOnValidate={scrollOnValidate} scrollElement={scrollElement}>
                            <ANTDForm layout="vertical" onKeyDown={handleKeyDown} {...props}>
                                {children}
                                {(!!submitText || !!cancelText) && <ButtonsContainer>
                                    {!!submitText && (
                                        <ButtonStyled
                                            iconRight={iconRight}
                                            onClick={submitForm}
                                            styleType="primary"
                                            title={submitText}
                                            loading={isSubmitting}
                                        />
                                    )}
                                    {!!cancelText && (
                                        <ButtonStyled styleType="text-secondary" title={cancelText} onClick={cancel} />
                                    )}
                                </ButtonsContainer>}
                            </ANTDForm>
                        </FormikValidationScroll>
                    </FormikOnChangeHelper>
                </FormikValidatorHelper>
            )}
        </Formik>
    );
};
