import { FormikHelpers, FormikValues } from 'formik';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import i18next from 'i18next';
import styled from 'styled-components';

import { transformFormErrors, translates } from '../../../../utilities';
import {
    Form,
    FormFieldInput,
    Button,
    Text
} from '../../../../components';
import { routerPaths } from '../../../../configs';
import { useApi } from '../../../../hooks/useApi';
import { OrderingContext, ConfigContext } from '../../../../contexts';
import { LoginByEmailPageType } from '../..';
import { RegisterModel } from '../../../../types/api';
import { logEvent } from '../../../../utilities/analytics';

interface RegistrationSubmitModel extends RegisterModel {
    isCompany: boolean;
    phoneCode: string;
    personalCodeType: any;
}

interface RegistrationByEmailProps {
    handleChangeLoginByEmailPage: (page: LoginByEmailPageType) => void;
}

const LoginLinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const StyledHorzontalLine = styled.div`
    /* To ignore parent padding */
    width: calc(100% + 32px * 2);
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.grey1};
`;

const StyledText = styled(Text).attrs({
    color: 'black',
    type: 'body2',
})`
    font-weight: 500;
    margin-bottom: 1rem;
`;

export const RegistrationContainer: FC<RegistrationByEmailProps> = ({ handleChangeLoginByEmailPage }) => {
    const navigate = useNavigate();
    const { orderId, setOrderId } = useContext(OrderingContext);
    const { language, country, contactInfo } = useContext(ConfigContext);
    const fetchApi = useApi();
    const { email } = contactInfo || {};

    const submit = (values: RegistrationSubmitModel, helpers: FormikHelpers<FormikValues>) => {
        fetchApi({
            url: 'auth/register',
            data: {
                ...values,
                orderId,
                language,
                country
            },
            method: 'POST'
        }).then((e) => {
            logEvent('sign_up', {
                event: 'sign_up',
                method: 'email',
                user_id: e,
            });
            setOrderId(null);
            navigate(routerPaths.registrationSuccess.route, { replace: true });
        }).catch((e) => {
            helpers.setErrors(transformFormErrors(e?.errors));
        }).finally(() => {
            helpers.setSubmitting(false);
        });
    };

    const schema = Yup.object().shape({
        email: Yup.string().email(translates.FormsErrorsInvalidEmail).required(translates.FormsErrorsRequired),
        password: Yup.string().required(translates.FormsErrorsRequired),
        passwordRepeat: Yup.string().required(translates.FormsErrorsRequired).oneOf([Yup.ref('password'), null], translates.FormsErrorsPasswordMustMatch),
        language: Yup.string()
    });

    const initialValues: any = {
        email: email || '',
        password: '',
        passwordRepeat: '',
        language
    };

    return (
        <>
            <Form
                submit={submit}
                submitText={i18next.t(translates.RegisterButtonsSubmit)}
                schema={schema}
                values={initialValues}
                validateOnBlur={false}
            >
                <FormFieldInput
                    name="email"
                    label={i18next.t(translates.RegisterLabelsEmail)}
                    autoComplete="username"
                />
                <FormFieldInput
                    name="password"
                    type="password"
                    label={i18next.t(translates.RegisterLabelsPassword)}
                    autoComplete="password"
                />
                <FormFieldInput
                    name="passwordRepeat"
                    type="password"
                    label={i18next.t(translates.RegisterLabelsPasswordRepeat)}
                    autoComplete="password-repeat"
                />
            </Form>
            <LoginLinkWrapper>
                <StyledHorzontalLine/>
                <StyledText>
                    {i18next.t(translates.RegisterButtonsHaveAccount)}
                </StyledText>
                <Button
                    styleType='primary-outline'
                    title={i18next.t(translates.RegisterButtonsLogin1)}
                    onClick={() => handleChangeLoginByEmailPage('login')}
                />
            </LoginLinkWrapper>
        </>
    );
};
