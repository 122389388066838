import { Popover, PopoverProps } from 'antd';
import { CSSProperties, FC, ReactNode } from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from '../../../../assets/images/help.svg';
import { Colors } from '../../../../theme';

interface StyledPopoverProps {
    $color?: Colors;
    $overlayStyle?: CSSProperties;
}

const StyledPopover = styled(Popover).attrs<StyledPopoverProps>(({ theme, $color, $overlayStyle }) => ({
    color: $color || theme.colors.dark,
    trigger: 'click',
    overlayStyle: $overlayStyle || { maxWidth: '300px' },
}))<StyledPopoverProps>``;

const Inner = styled.div`
    cursor: pointer;
`;

interface PopoverButtonProps extends PopoverProps {
    onClick?: () => void;
    children: ReactNode;
    /** @type {Colors} @default "dark" */
    color?: Colors
}

export const PopoverButton: FC<PopoverButtonProps> = ({
    children,
    onClick,
    color,
    overlayStyle,
    style,
    ...popoverProps
}) => {
    const handleClick = () => {
        onClick?.();
    };

    return (
        <StyledPopover content={children} $color={color} $overlayStyle={overlayStyle} {...popoverProps}>
            <Inner onClick={handleClick} style={style}>
                <InfoIcon />
            </Inner>
        </StyledPopover>
    );
};
