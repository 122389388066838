import { FC, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import i18next from 'i18next';
import { PartnerIcon } from '../../../../components/partnerCard/PartnerIcon';
import { PartnerExperienceModal } from '../../../auth/orders/components/partnerExperienceModal/PartnerExperienceModal';
import { Button, ButtonProps } from '../../../../components';
import { PartnerCardContent } from '../../../../components/partnerCard/partnerCardContent';
import { theme } from '../../../../theme';
import { PartnerCard } from '../../../../components/partnerCard';
import { translates } from '../../../../utilities';

const PartnerCardContainer = styled.div<{ $selected: boolean }>`
    padding: 21px 32px;
    ${({ $selected, theme }) => $selected ? `border: 1px solid ${theme.colors.primary}` : `border: 1px solid ${theme.colors.grey9}`};
    border-radius: 10px;
    margin-bottom: 24px;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: ${({ theme }) => theme.colors.white};
`;

const PartnerCardPartnerIcon = styled(PartnerIcon)`
    width: 82px;
    height: 82px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    margin-right: 1.25rem;
`;

const Selected = styled.p`
  color: ${({ theme }) => theme.colors.primary};
`;

const ButtonContainer = styled.div`
    margin-left: auto;
`;

const PartnerCardContentStyled = styled(PartnerCardContent)`
    align-items: flex-start;

    p {
        margin-top: 10px;
    }
`;

const PartnerCardStyled = styled(PartnerCard)<{ $selected: boolean }>`
    margin-bottom: 24px;
    ${({ $selected, theme }) => $selected ? `border: 1px solid ${theme.colors.primary}` : `border: 1px solid ${theme.colors.grey9}`};
`;

interface OrderStepsPartnerCardProps {
    id: number;
    name: string;
    partnerPictureUrl?: string | null;
    priceLabel?: string;
    price?: string;
    serviceId: number | string;
    buttonProps?: ButtonProps;
    totalVotes: number;
    rating: number;
    commentsCount: number;
    doneWorksAmount?: number | null;
    doneWorksAmountLabel?: string;
    selected: boolean
}

export const OrderStepsPartnerCard: FC<OrderStepsPartnerCardProps> = ({
    id,
    name,
    partnerPictureUrl,
    priceLabel,
    price,
    serviceId,
    totalVotes,
    rating,
    buttonProps,
    commentsCount,
    doneWorksAmount,
    doneWorksAmountLabel,
    selected,
    ...props
}) => {
    const [showModal, setShowModal] = useState(false);
    const [shouldShowCommetsTab, setShouldShowCommetsTab] = useState(false);
    const isMobile = useMediaQuery({ query: theme.query.medium });

    const openModal = (showCommentsTab: boolean) => {
        setShowModal(true);
        setShouldShowCommetsTab(showCommentsTab);
    };

    if (isMobile) {
        return (
            <>
                <PartnerCardStyled
                    id={id}
                    serviceId={serviceId}
                    name={name}
                    priceLabel={priceLabel}
                    price={price}
                    totalVotes={totalVotes}
                    rating={rating}
                    commentsCount={commentsCount}
                    doneWorksAmount={doneWorksAmount}
                    doneWorksAmountLabel={doneWorksAmountLabel}
                    partnerPictureUrl={partnerPictureUrl}
                    selected={selected}
                    $selected={selected}
                    useSelected
                />
            </>
        );
    }

    return (
        <>
            <PartnerCardContainer $selected={selected} {...props}>
                <PartnerCardPartnerIcon partnerPictureUrl={partnerPictureUrl} />
                <PartnerCardContentStyled
                    name={name}
                    priceLabel={priceLabel}
                    price={price}
                    totalVotes={totalVotes}
                    rating={rating}
                    openModal={openModal}
                    commentsCount={commentsCount}
                    doneWorksAmount={doneWorksAmount}
                    doneWorksAmountLabel={doneWorksAmountLabel}
                />
                {buttonProps && (
                    <ButtonContainer>
                        <Button {...buttonProps} />
                    </ButtonContainer>
                )}

                <ButtonContainer>
                    <Selected>
                        {i18next.t(
                            selected
                                ? translates.CreateOrderFavoritePartnerSelected
                                : translates.CreateOrderFavoritePartnerSelect
                        )}
                    </Selected>
                </ButtonContainer>
            </PartnerCardContainer>
            <PartnerExperienceModal
                id={id}
                serviceId={serviceId}
                name={name}
                partnerPictureUrl={partnerPictureUrl}
                priceLabel={priceLabel}
                price={price}
                open={showModal}
                shouldShowCommentsTab={commentsCount > 0 ? shouldShowCommetsTab : false}
                onClose={() => setShowModal(false)}
                buttonProps={buttonProps}
                doneWorksAmount={doneWorksAmount}
                doneWorksAmountLabel={doneWorksAmountLabel}
            />
        </>
    );
};
