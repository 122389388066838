import { HubConnectionBuilder } from '@microsoft/signalr';
import { useContext } from 'react';
import { ConfigContext } from '../contexts';
import { getCountry, getCountryNotificationsUrl } from '../utilities/countryDefaults';

export const useInitSignalR = () => {
    const { setSignalRConnection } = useContext(ConfigContext);
    const country = getCountry();
    const notificationsUrl = getCountryNotificationsUrl(country);

    return async () => {
        const connection = new HubConnectionBuilder()
            .withUrl(notificationsUrl)
            .withAutomaticReconnect()
            .build();
        await connection.start();
        setSignalRConnection(connection);
    };
};
