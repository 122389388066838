import React, { FC, ReactNode, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { HelpPopover, Text } from '../../../../components';
import { theme } from '../../../../theme';
import HelpImg from '../../../../assets/images/help.svg';

const InfoButton = styled.span`
    cursor: pointer;
    margin-left: 6px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Container = styled.div`
    position: relative;
`;

const StyledText = styled(Text)`
    font-weight: 700;
    margin-bottom: 8px;
`;

interface TitleProp {
    help?: string;
    children: ReactNode;
}

export const Title: FC<TitleProp> = (props) => {
    const { children, help } = props;
    const [showHelp, setShowHelp] = useState(false);
    const isMobile = useMediaQuery({
        query: theme.query.medium,
    });

    return (
        <Container>
            <Wrapper>
                <StyledText color="black" type="body2">
                    {children}
                </StyledText>
                {!!help && (
                    <InfoButton>
                        <img src={HelpImg} alt="info" width={24} height={24} onClick={() => setShowHelp(!showHelp)} />
                    </InfoButton>
                )}
            </Wrapper>

            {showHelp && (
                <HelpPopover isMobile={isMobile} onClose={() => setShowHelp(false)}>
                    {help}
                </HelpPopover>
            )}
        </Container>
    );
};
