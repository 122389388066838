/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddressBookContactResponseModel {
  /** @format uuid */
  id?: string;
  position?: string | null;
  fullName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
}

export interface AddressBookResponseModel {
  /** @format uuid */
  addressBookId?: string;
  companyName?: string | null;
  companyCode?: string | null;
  address?: string | null;
  iban?: string | null;
  companyZipCode?: string | null;

  /** @format double */
  longitude?: number | null;

  /** @format double */
  latitude?: number | null;
  email?: string | null;
  phone?: string | null;
  contacts?: AddressBookContactResponseModel[] | null;
}

export interface AddressBookContactModel {
  /** @format uuid */
  id?: string | null;
  position: string;
  fullName: string;
  phoneNumber?: string;
  email?: string | null;
  phoneCode?: string;
}

export interface CreateAddressBookModel {
  companyName: string;
  companyCode: string;
  email?: string | null;
  phone?: string | null;
  address: string;

  /** @format double */
  longitude: number;

  /** @format double */
  latitude: number;
  companyZipCode: string;
  iban?: string | null;
  contacts?: AddressBookContactModel[] | null;
}

export interface FieldErrorsModel {
  field?: string;
  errors?: string[] | null;
}

export interface ApiErrorModel {
  success?: boolean;
  errors?: FieldErrorsModel[] | null;
  errorMessage?: string | null;
}

export interface UpdateAddressBookModel {
  /** @format uuid */
  addressBookId: string;
  companyName: string;
  companyCode: string;
  email?: string | null;
  phone?: string | null;
  companyZipCode: string;
  iban?: string | null;
  address: string;

  /** @format double */
  longitude: number;

  /** @format double */
  latitude: number;
  contacts?: AddressBookContactModel[] | null;
}

export interface ReplayModel {
  handlers?: string[] | null;
  events?: string[] | null;
}

export interface Company {
  name: string;
  code: string;
  vatCode?: string | null;
  registrationAddressText: string;

  /** @format double */
  registrationAddressLongitude?: number;

  /** @format double */
  registrationAddressLatitude?: number;
  iban?: string | null;
}

export interface UpdateClientTypeModel {
  isCompany?: boolean;
  company?: Company;
}

export interface RegisterModel {
  email?: string;
  password: string;

  /** @format uuid */
  orderId?: string | null;
  language: string;
  country: string;
}

export interface ConfirmRegistrationModel {
  /** @format uuid */
  clientId: string;
  token: string;
}

export interface SendPasswordResetModel {
  email?: string;
}

export interface ValidateTokenModel {
  email?: string;
  token: string;
}

export interface TokenValidationResultModel {
  isTokenValid?: boolean;
}

export interface ResetPasswordModel {
  email?: string;
  password: string;
  token: string;
}

export interface LoginModel {
  email?: string;
  password: string;
  rememberMe?: boolean;

  /** @format uuid */
  orderId?: string | null;
  country: string;
}

export interface UpdateClientModel {
  firstName?: string | null;
  lastName?: string | null;
  phone?: string;
  email?: string | null;
  allowToUseEmailForMarketingPurposes: boolean;
  recieveEmailNotifications: boolean;
  recieveSmsNotifications: boolean;
  acceptedPortalProPrivacy?: boolean;
  acceptedPortalProContractTerms?: boolean;

  /** @format date-time */
  birthday?: string | null;
  invoiceAddress?: string | null;
  company?: Company;
  personalCode?: string | null;
}

export interface PatchClientModel {
  language?: string | null;
}

export interface ClientReadModel {
  /** @format uuid */
  clientId?: string;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;

  /** @format date-time */
  birthday?: string | null;
  phone?: string | null;
  allowToUseEmailForMarketingPurposes?: boolean;
  acceptedPortalProPrivacy?: boolean;
  acceptedPortalProContractTerms?: boolean;
  recieveEmailNotifications?: boolean;
  recieveSmsNotifications?: boolean;
  invoiceAddress?: string | null;
  isCompany?: boolean;
  companyCode?: string | null;
  companyName?: string | null;
  companyVatCode?: string | null;
  companyRegistrationAddressText?: string | null;

  /** @format double */
  companyRegistrationAddressLongitude?: number;

  /** @format double */
  companyRegistrationAddressLatitude?: number;
  companyIban?: string | null;
  language?: string | null;
  country?: string | null;
  personalCode?: string | null;
  lastNameShortened?: string | null;
}

export interface InvitePartnerRequest {
  partnerPhoneNumber: string;
  invitationText?: string | null;
}

export interface FavoritePartnerModelService {
  name?: string | null;
  regions?: string[] | null;
}

export interface FavoritePartnerModel {
  /** @format int64 */
  partnerId?: number;
  partnerName?: string | null;
  imageUrl?: string | null;
  services?: FavoritePartnerModelService[] | null;
}

export interface OrderNotification {
  token?: string;

  /** @format uuid */
  platformJobId: string;
  changeType: string;

  /** @format int32 */
  changeTypeId: number;
}

export interface InvoiceNotification {
  token?: string;

  /** @format uuid */
  invoiceId: string;
  invoiceType: string;
  changeType: string;

  /** @format int32 */
  changeTypeId: number;
}

export interface Notification {
  token?: string;
}

/**
 * @format int32
 */
export type BiddingType = 1 | 2 | 3 | 4;

export interface SelectedServiceItem {
  /** @format uuid */
  id?: string;

  /** @format int32 */
  quantity?: number;
}

export interface InvoiceModel {
  /** @format double */
  createdAt?: string;
  totalPrice?: number;
  currency?: string;
}

export interface PricingModel {
  /** @format double */
  fullPrice?: number;

  /** @format double */
  workPrice?: number;

  /** @format double */
  materialPrice?: number;
}

export interface OrderSelfModel {
  /** @format uuid */
  orderId?: string;

  /** @format uuid */
  clientId?: string;

  /** @format int64 */
  serviceId?: number;
  serviceName?: string;
  serviceGroup?: string;
  address?: string;
  statusLabel?: string;
  orderCode?: string;
  paymentStatusLabel?: string | null;
  currency?: string;
  invoice?: InvoiceModel;
  isClientEligibleForInvoice?: boolean;
  

  /** @format int32 */
  bidCount?: number;

  /** @format date-time */
  biddingDeadline?: string;
  approvedPartnerPhone?: string | null;
  pricing?: PricingModel;

  /** @format double */
  preliminaryHourlyRate?: number | null;
  biddingType?: BiddingType;
  inAgreementWithPartnerText?: string | null;
  isBiddingReportGenerated?: boolean;
}

export interface OrdersInfo {
  hasOrders?: boolean;
}

export interface BidPartnerModel {
  fullName?: string | null;
  companyName?: string | null;
  bankAccount?: string;
  phone?: string;
  email?: string;
}

export interface PriceRange {
  /** @format double */
  from?: number;

  /** @format double */
  to?: number;
}

export interface OrderBidModel {
  /** @format int64 */
  partnerId?: number;
  partnerPictureUrl?: string | null;
  partnerStatus?: string | null;
  partner?: BidPartnerModel;

  /** @format double */
  price?: number;

  /** @format date-time */
  date?: string;
  isApproved?: boolean;
  isFavoritePartner?: boolean;
  plannedMaterialExpenses?: PriceRange;

  /** @format date-time */
  plannedStartDate?: string | null;

  /** @format double */
  rating?: number;

  /** @format int32 */
  totalVotes?: number;

  /** @format int32 */
  totalComments?: number;

  /** @format int32 */
  totalJobsFinished?: number;
  jobScope?: {
    description: string
    fileName: string
  };
  isAutoGenerated?: boolean;
}

/**
 * @format int32
 */
export type AssignedPartnerStatus = 0 | 1 | 2;

export interface PriceChangeModel {
  comment?: string | null;
}

export interface ImageModel {
  /** @format uuid */
  id?: string | null;
  url?: string;
  smallUrl?: string;
  isAttached?: boolean;
  fileName?: string | null;
  attachmentType?: string | null;
}

export interface OrderModel {
  /** @format uuid */
  orderId?: string;

  /** @format uuid */
  clientId?: string;
  email?: string | null;
  phone?: string | null;
  description?: string;
  additionalInformation?: string | null;

  /** @format int64 */
  serviceId?: number;
  serviceName?: string;
  serviceGroup?: string;
  address?: string;

  /** @format double */
  longitude?: number;

  /** @format double */
  latitude?: number;
  statusLabel?: string;
  cancelReason?: string | null;
  partnerComment?: string | null;

  /** @format date-time */
  deadline?: string;

  /** @format date-time */
  biddingDeadline?: string;
  orderCode?: string;
  paymentStatusLabel?: string | null;
  biddingType?: BiddingType;
  currency?: string;
  invoice?: InvoiceModel;
  pricing?: PricingModel;
  bids?: OrderBidModel[];
  assignedPartnerStatus?: AssignedPartnerStatus;
  priceChanges?: PriceChangeModel[];
  hasFeedback?: boolean;
  isClientEligibleForInvoice?: boolean;

  /** @format int32 */
  assignedPartnersCount?: number;
  isBiddingReportGenerated?: boolean;
  partnerImages?: ImageModel[] | null;
  jobScope?: {
    description: string
    fileName: string
  }
  clientInvoices: InvoiceModel[];
  isPaymentNoteEnabled?: boolean;
  isPaymentSplitEnabled?: boolean;
}

export interface MTCModel {
    companyCode?: string
    companyIban?: string
    companyName?: string
    companyVat?: string
}

export interface OrderWithImagesModel {
  order?: OrderModel;
  clientImages?: ImageModel[] | null;
  partnerImages?: ImageModel[] | null;
  materialsImages?: ImageModel[] | null;
  mtcDetails?: MTCModel | null;
}

export interface ServiceItem {
  id?: string;
  /** @format int32 */
  quantity?: number;

  /** @format double */
  price?: number;

  /** @format double */
  margin?: number;

  /** @format double */
  coefficient?: number;
  translation?: string | null;
  unitOfMeasure?: string | null;
  description?: string | null;
}

export interface OrderSummaryRequest {
  countryCode?: string | null;

  /** @format double */
  countryVat?: number;
  serviceItems?: ServiceItem[] | null;
  description?: string | null;

  /** @format double */
  expectedPrice?: number | null;
  expectedTime?: string | null;
}

export interface OrderSummaryResult {
  /** @format double */
  totalPrice?: number;
  description?: string | null;
}

export enum DocumentName  {
  biddingReport = 'BiddingReport',
  invoice = 'Invoice',
  creditInvoice = 'CreditInvoice',
}

export interface OrderDocumentResultItem {
  name: DocumentName;
  url: string | null;
  number: string | null;
}

export interface LeaveFeedbackModel {
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  rating: number;
  comment?: string | null;
}

export interface CreateOtpModel {
  phone?: string;
  country: string;
  language: string;
}

export interface CreateOtpResponseModel {
  /** @format int32 */
  expirationInSeconds?: number;

  /** @format date-time */
  createdAt?: string;
}

export interface VerifyOtpModel {
  phone?: string;
  code: string;

  /** @format uuid */
  orderId?: string | null;
  language: string;
  country: string;
}

export interface PartnerFeedback {
  clientFirstName?: string | null;
  clientLastName?: string | null;
  comment?: string | null;

  /** @format int32 */
  rating?: number;
  doesRecommend?: boolean;
}

export interface PartnerServiceExperienceModel {
  description?: string | null;

  /** @format int32 */
  daysWorking?: number;

  /** @format date-time */
  dateOfLatestCompletedJob?: string | null;

  /** @format int32 */
  totalJobsFinished?: number;

  /** @format int32 */
  numberOfClientsServiced?: number;

  /** @format int32 */
  averageWorkDurationInHours?: number;

  /** @format double */
  averageWorkPrice?: number;
  status?: string | null;

  /** @format int32 */
  statusValue?: number;
  feedback?: PartnerFeedback[] | null;

  /** @format int32 */
  totalComments?: number;

  /** @format double */
  rating?: number;

  /** @format int32 */
  totalVotes?: number;

  /** @format double */
  hourlyRate?: number;
}

export interface InitiatePaymentResponseModel {
  paymentId?: string | null;
  confirmLink?: string | null;
}

export interface PaymentProviderAvailabilityResponse {
  isPaymentProviderAvailable?: boolean;
}

export interface PaymentUpdateModel {
  id?: string | null;
  statusGroup?: string | null;
}

export interface PaymentModel {
  paymentId?: string | null;
  paymentStatus?: string | null;
}

export interface ServiceModel {
  /** @format int64 */
  id?: number;
  group?: string;
  name?: string;
}

export interface ServiceAvailabilityResponseModel {
  /** @format int64 */
  serviceId?: number;
  isAvailable?: boolean;
}

export interface PartnerModel {
  /** @format int64 */
  partnerId?: number;
  partnerPictureUrl?: string | null;
  fullName?: string;

  /** @format double */
  fullHourlyRate?: number;
  currency?: string;
  status?: string | null;

  /** @format double */
  rating?: number;

  /** @format int32 */
  totalVotes?: number;

  /** @format int32 */
  totalComments?: number;

  /** @format int32 */
  totalJobsFinished?: number;
}

export interface PartnerAvailabilityResponseModel {
  /** @format int64 */
  serviceId?: number;
  partners?: PartnerModel[];
}
