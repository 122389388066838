import i18next from 'i18next';
import { FC } from 'react';
import styled from 'styled-components';

import { Button } from '..';
import { Card, CardImage } from '.';

const ButtonsContainer = styled.div`
    margin: 3rem 0 1rem;
    display: flex;
    flex-direction: column;
`;

interface SuccessCardProps {
    title: string;
    subTitle: string;
    buttonTitle: string;
    onClick: Function;
    showButton: boolean;
}

export const SuccessCard: FC<SuccessCardProps> = ({ title, subTitle, buttonTitle, onClick, showButton }) => {
    return (
        <Card titleText={i18next.t(title)} subtitleText={i18next.t(subTitle)}>
            <CardImage type='email' />
            <ButtonsContainer>
                {showButton && <Button
                    type="button"
                    styleType="primary"
                    onClick={onClick}
                    title={i18next.t(buttonTitle) as string}
                />}
            </ButtonsContainer>
        </Card>
    );
};
