import { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Text } from '..';
import { Arrow } from '../icons';

const StyledDropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
`;

const StyledDropdownText = styled(Text).attrs({
    color: 'black',
    type: 'body2',
})`
    margin-right: 0.5em;
`;

const ArrowContainer = styled.div`
    padding-top: 2px;
`;

interface StyledDropdownContentProps {
    $isOpened: boolean;
}

const StyledDropdownContent = styled.div<StyledDropdownContentProps>`
    display: ${({ $isOpened }) => $isOpened ? 'flex' : 'none'};
    flex-direction: column;
`;

interface DropdownContainerProps {
    children: ReactNode;
    title: string;
    onChange?: () => void;
}

export const DropdownContainer: FC<DropdownContainerProps> = ({ title, children, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen && onChange) {
            onChange();
        }
    }, [isOpen, onChange]);

    const handleTextClick = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <StyledDropdownContainer>
            <StyledTextContainer onClick={handleTextClick}>
                <StyledDropdownText>
                    {title}
                </StyledDropdownText>
                <ArrowContainer>
                    <Arrow type="solid" direction={isOpen ? 'up' : 'down'} color="primary" size={0.5} />
                </ArrowContainer>
            </StyledTextContainer>

            <StyledDropdownContent $isOpened={isOpen}>
                {children}
            </StyledDropdownContent>
        </StyledDropdownContainer>
    );
};
