import { FC } from 'react';
import styled from 'styled-components';

const ServiceCardStyled = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const CardDescription = styled.div`
    margin-left: 1rem;
    line-height: 1.15;
    font-size: .875rem;
`;

const IconStyled = styled.img`
    max-width: 54px;
`;

interface ServiceCardProps {
    icon?: string | null;
    text: string;
    id: string;
}

export const ServiceCard: FC<ServiceCardProps> = ({ icon, text, ...props }) => {
    return <ServiceCardStyled {...props}>
        {
            icon && <IconStyled src={icon} alt="service" />
        }
        <CardDescription>{text}</CardDescription>
    </ServiceCardStyled>;
};
