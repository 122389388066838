import { FC, useState } from 'react';
import styled from 'styled-components';
import ImagesViewer from 'react-images-viewer';
import { useMediaQuery } from 'react-responsive';
import { Slider } from '../../../../components/slider';
import { ImageModel } from '../../../../types/api';
import { Spinner } from '../../../../components';
import { theme } from '../../../../theme';
import { Image } from './Image';

const Container = styled.div`
  max-width: 580px;
`;

interface ImagesSliderProps {
    images: ImageModel[]
}

export const ImagesSlider: FC<ImagesSliderProps> = ({ images, ...props }) => {
    const [openImageIndex, setOpenImageIndex] = useState<number>();
    const isMobileOrTablet = useMediaQuery({
        query: theme.query.medium,
    });

    const handleSlideClick = (index: number) => {
        if (index >= 0 && images[index].isAttached) {
            setOpenImageIndex(index);
        }
    };

    return (
        <Container {...props}>
            <Slider
                spaceBetween={20}
                slidesPerView={isMobileOrTablet ? 3 : 5}
                onClick={(swiper) => handleSlideClick(swiper.clickedIndex)}
            >
                {images.map((image) => (
                    <Image key={image.id}
                        src={image.smallUrl!}
                        loading={!image.isAttached} />
                ))}
            </Slider>

            <ImagesViewer
                preloadNextImg={false}
                imgs={images.map((image) => ({ src: image.url! }))}
                currImg={openImageIndex}
                isOpen={openImageIndex !== undefined}
                spinner={Spinner}
                onClickNext={() => setOpenImageIndex(openImageIndex! + 1)}
                onClickPrev={() => setOpenImageIndex(openImageIndex! - 1)}
                onClose={() => setOpenImageIndex(undefined)}
            />
        </Container>
    );
};
