import { components, OptionProps } from 'react-select';
import styled from 'styled-components';

import PlusIcon from '../../../assets/images/plus-icon.svg';
import { SelectExtras } from '../../../utilities';
import { Option } from '../../select';

const PlusSign = styled.img.attrs({
    src: PlusIcon,
    alt: 'Plus icon',
})`
    margin-right: 1rem;
`;

export const CustomOption = (props: OptionProps<Option>) => {
    if (props.data.extra === SelectExtras.ADD_SERVICE_RECEIVER) {
        return (
            <components.Option {...props}>
                <PlusSign />
                {props.children}
            </components.Option>
        );
    }
    return <components.Option {...props} />;
};
