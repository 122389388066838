import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as StarFilledIcon } from '../../../assets/images/star-filled-icon.svg';

const Rating = styled.div`
    color: ${({ theme }) => theme.colors.grey6};
    display: flex;
    align-items: center;
`;

const StarFilledIconStyled = styled(StarFilledIcon)`
    margin-right: 3px;
    width: 24px;
    height: 24px;
`;

const Count = styled.span`
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
    text-decoration: ${({ onClick }) => (onClick ? 'underline' : 'none')};

    &:hover {
        text-decoration: none;
    }
`;

interface PartnerRatingProps {
    rating: number;
    totalVotes: number;
    showPartner?: () => void;
}

export const PartnerRating: FC<PartnerRatingProps> = ({ rating, totalVotes, showPartner }) => {
    if (!Number.isFinite(rating) || !Number.isFinite(totalVotes)) {
        return null;
    }
    return (
        <Rating>
            <StarFilledIconStyled />
            {rating.toFixed(1)} (<Count onClick={showPartner && (() => showPartner())}>{totalVotes}</Count>)
        </Rating>
    );
};
