import { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';

import { RadioCheckbox, RadioCheckboxProps } from './components/radioCheckbox';
import { RadioTitleButton, RadioTitleButtonProps } from './components/radioTitleButton';
import { RadioPartnerButton, RadioPartnerButtonProps } from './components/radioPartnerButton';
import { RadioIconButton } from './components/radioIconButton';

const HiddenInput = styled.input`
    display: none;
`;

type Types = 'titleButton' | 'iconButton' | 'checkbox' | 'partnerButton';

interface RadioButtonContainerProps {
    $inline?: boolean;
    $type: Types;
}

const RadioButtonContainer = styled.div<RadioButtonContainerProps>`
    ${({ $inline }) => $inline && css`
        display: flex;
        flex-direction: row;
    `};
    ${({ $type, theme }) => $type === 'iconButton' && css`
        border-radius: 10px;
        display: flex;
        flex-direction: row; 
        background-color: ${theme.colors.grey12};
        justify-content: space-between;
        padding: 4px;
    `}
`;

interface ItemProps extends RadioCheckboxProps {
    type: Types;
}

const Item: FC<ItemProps & RadioTitleButtonProps & RadioPartnerButtonProps> = ({ type, ...props }) => {
    switch (type) {
    case 'iconButton':
        return <RadioIconButton {...props} />;
    case 'titleButton':
        return <RadioTitleButton {...props} />;
    case 'partnerButton':
        return <RadioPartnerButton {...props} />;
    default:
        return <RadioCheckbox {...props} />;
    }
};

interface RadioButtonGroupProps {
    inline?: boolean;
    options: { title?: string, label: string, value: unknown, icon?: string, iconSelected?: string }[];
    name: string;
    type?: Types;
    disabled?: boolean
}

export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({ inline, name, options, disabled, type = 'checkbox' }) => {
    const [field, meta, helpers] = useField({ name });
    const { isSubmitting } = useFormikContext();
    return (
        <>
            <HiddenInput {...field}/>
            <RadioButtonContainer $type={type} $inline={inline} id={'radioButtonContainer'}>
                {options.map((option, index) =>
                    <Item
                        type={type}
                        key={`${index}-${option.value}`}
                        onClick={() => {
                            if (isSubmitting) {
                                return;
                            }
                            helpers.setValue(option.value);
                        }}
                        disabled={isSubmitting}
                        selected={field.value === option.value && !disabled}
                        {...option}/>)}
            </RadioButtonContainer>
        </>
    );
};
