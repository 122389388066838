import { FC } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { Card, Button, Form, RadioButtonGroup } from '../../../../components';
import { translates } from '../../../../utilities';

export enum PaymentTypes {
    PROVIDER,
    CASH,
}

const RadioContainer = styled.div`
    margin-bottom: 12px;
`;

const ButtonsContainer = styled.div`
    margin: 3rem 0 1rem;
    display: flex;
    flex-direction: column;
`;

const SubmitButton = styled(Button).attrs({
    styleType: 'primary',
    type: 'submit',
})``;

const CancelButton = styled(Button).attrs({
    styleType: 'primary-inverted',
})`
    margin-top: 0.5rem;
`;

const initialValues = {
    paymentType: PaymentTypes.PROVIDER,
};

interface TypeSubmitModel {
    paymentType: PaymentTypes;
}

interface TypeSelectionProps {
    onCancel: () => void;
    onSubmit: (payload: TypeSubmitModel) => void;
}

export const TypeSelection: FC<TypeSelectionProps> = ({ onCancel, onSubmit }) => {
    return (
        <Card titleText={i18next.t(translates.PaymentTitlesTypeSelection)}>
            <Form submit={onSubmit} values={initialValues}>
                <RadioContainer>
                    <RadioButtonGroup
                        type='titleButton'
                        name='paymentType'
                        options={[
                            {
                                title: i18next.t(translates.PaymentLabelsPaymentTypeProvider),
                                label: i18next.t(translates.PaymentLabelsPaymentTypeProviderSubtitle),
                                value: PaymentTypes.PROVIDER,
                            }, {
                                title: i18next.t(translates.PaymentLabelsPaymentTypeCash),
                                label: i18next.t(translates.PaymentLabelsPaymentTypeCashSubtitle),
                                value: PaymentTypes.CASH,
                            }
                        ]}
                    />
                </RadioContainer>
                <ButtonsContainer>
                    <SubmitButton title={i18next.t(translates.PaymentButtonsTypeSelectionNext)} />
                    <CancelButton onClick={onCancel} title={i18next.t(translates.PaymentButtonsTypeSelectionCancel)} />
                </ButtonsContainer>
            </Form>
        </Card>
    );
};

export type { TypeSubmitModel };
