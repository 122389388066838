import i18next from 'i18next';
import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

interface ProgressStepContainerProps {
    $mobileHeight: number;
}

const ProgressStepContainer = styled.div<ProgressStepContainerProps>`
    position: relative;
    margin-bottom: 4.25rem;

    @media ${({ theme }) => theme.query.medium} {
        height: ${({ $mobileHeight }) => `${$mobileHeight}px`};
        margin-bottom: 0;
    }
`;

const calculateHiddenStepProgress = (progressPercent: number, stepsCount: number) => {
    const realStepPercentSize = 100 / (stepsCount - 1);
    const currentStep = progressPercent / realStepPercentSize;
    const hiddenStepPercentSize = 100 / (stepsCount - 2);
    return currentStep * hiddenStepPercentSize > 100 ? 100 : currentStep * hiddenStepPercentSize;
};

interface ProgressBarProps {
    $progress: number;
    $stepsCount: number;
    $isLastStepHidden?: boolean;
}

const ProgressBar = styled.div<ProgressBarProps>`
    background-color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    top: 44%;
    left: 0;
    height: 4px;
    width: ${({ $progress, $stepsCount, $isLastStepHidden }) =>
        $isLastStepHidden ? `${calculateHiddenStepProgress($progress, $stepsCount)}%` : $progress};
    z-index: 1;

    @media ${({ theme }) => theme.query.medium} {
        width: 4px;
        height: ${({ $progress, $stepsCount, $isLastStepHidden }) => $isLastStepHidden ? `${calculateHiddenStepProgress($progress, $stepsCount)}%` : $progress};
        left: 13px;
        top: 0;
    }
`;

const ProgressList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;

    @media ${({ theme }) => theme.query.medium} {
        flex-direction: column;
        height: 100%;
    }

    &:before {
        content: '';
        background-color: ${({ theme }) => theme.colors.grey8};
        position: absolute;
        top: 44%;
        left: 0;
        height: 4px;
        width: 100%;
        z-index: 0;

        @media ${({ theme }) => theme.query.medium} {
            width: 4px;
            height: 100%;
            left: 13px;
            top: 0;
        }
    }
`;

interface StepProps {
    $isComplete: boolean;
    $isActive: boolean;
    $labelWidth: number;
}

const Step = styled.li<StepProps>`
    border-radius: 100%;
    width: 29px;
    height: 29px;
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    z-index: 2;

    &:before {
        content: '';
        border: 4px solid transparent;
        background-clip: padding-box;
        background-color: ${({ theme }) => theme.colors.grey8};
        position: absolute;
        border-radius: 100%;
        width: 24px;
        height: 24px;
        top: 9%;
        left: 9%;

        ${({ theme, $isActive }) => $isActive && `
            border-color: ${theme.colors.primary};
            background-color: ${theme.colors.white};
        `};

        ${({ theme, $isComplete }) => $isComplete && `
            border-color: ${theme.colors.primary};
            background-color: ${theme.colors.primary};
            background-clip: border-box;
        `};
    }

    &:first-of-type {
        span {
            left: 0rem;

            @media ${({ theme }) => theme.query.medium} {
                left: 37px;
            }
        }
    }

    &:last-of-type {
        span {
            left: -4rem;

            @media ${({ theme }) => theme.query.medium} {
                left: 37px;
            }
        }
    }

    span {
        white-space: nowrap;
        position: absolute;
        top: 3rem;
        left: ${({ $labelWidth }) => `calc(${$labelWidth}px * -0.33)`};
        color: ${({ theme }) => theme.colors.grey13};

        ${({ theme, $isComplete }) => $isComplete && `
            color: ${theme.colors.primary};
        `};

        ${({ theme, $isActive }) => $isActive && `
            color: ${theme.colors.black};
        `};

        @media ${({ theme }) => theme.query.medium} {
            top: 4px;
            left: 37px;
        }
    }
`;

interface ProgressStepLineProps {
    progress: number;
    steps: { label: string }[];
    isLastStepHidden?: boolean;
}

const isActive = (progress: number, index: number, numberOfSteps: number) => {
    return index / numberOfSteps === progress / 100;
};

const isComplete = (progress: number, index: number, numberOfSteps: number) => {
    return progress === 100 ? true : index / numberOfSteps < progress / 100;
};

export const ProgressStepLine: FC<ProgressStepLineProps> = ({ progress, steps, isLastStepHidden }) => {
    const [spanWidths, setSpanWidths] = useState<number[]>([]);

    const onRefChange = useCallback((ref: HTMLLIElement | null) => {
        if (!ref || !ref!.children[0]) {
            return;
        }
        const spanWidht = (ref!.children[0] as HTMLElement).offsetWidth;
        setSpanWidths((prevValue) => [...prevValue, spanWidht]);
    }, []);

    return (
        <ProgressStepContainer $mobileHeight={29 + 52 * (steps.length - 1)}>
            <ProgressBar
                $stepsCount={steps.length}
                $progress={progress}
                $isLastStepHidden={isLastStepHidden}></ProgressBar>
            <ProgressList>
                {steps.map((step, index) =>
                    isLastStepHidden && steps.length - 1 === index ? null : (
                        <Step
                            ref={onRefChange}
                            $isComplete={isComplete(progress, index, steps.length - 1)}
                            $isActive={isActive(progress, index, steps.length - 1)}
                            $labelWidth={spanWidths[index] || 0}
                            key={index}>
                            <span>{i18next.t(step.label)}</span>
                        </Step>
                    )
                )}
            </ProgressList>
        </ProgressStepContainer>
    );
};
