import { FC } from 'react';
import styled from 'styled-components';

const StyledPath = styled.path`
    fill:none;
    stroke: ${({ theme, color }) => color ? theme.colors[color] : theme.colors.darkBlack};
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:4px;
`;

type Direction = 'up' | 'down';
type Type = 'outlined' | 'solid';

interface PolygonProps {
    direction: Direction
}

const StyledPolygon = styled.polygon.attrs<PolygonProps>(({ direction }) => ({
    points: '15,0 30,15 0,15',
    transform: direction === 'up' ? '' : 'translate(30, 15) rotate(-180)',
}))`
    fill: ${({ theme, color }) => color ? theme.colors[color] : theme.colors.darkBlack};
`;

interface ArrowProps {
    type?: Type;
    direction: Direction;
    size: number;
    color: string;
}

export const Arrow: FC<ArrowProps> = ({ direction, size, type = 'outlined', ...props }) => {
    const sizeAdjusted = (size ?? 1);
    const renderArrow = () => {
        if (type === 'solid') {
            return <StyledPolygon direction={direction} {...props} />;
        }

        switch (direction) {
        case 'up':
            return <StyledPath
                {...props}
                d="M0,13.127,13.124,0,26.251,13.127"
                transform="translate(1.414 1)"
            />;
        case 'down':
        default:
            return <StyledPath
                {...props}
                d="M13.127,0,0,13.124,13.127,26.251"
                transform="translate(1.414 14.541) rotate(-90)"
            />;
        }
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={`${29.079 * sizeAdjusted}`}
            height={`${15.541 * sizeAdjusted}`}
            viewBox="0 0 30 15.541"
        >
            {renderArrow()}
        </svg>
    );
};
