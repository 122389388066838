import { FC } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { theme } from '../../theme';

interface ProgressLineProps {
    completed: number;
}

export const ProgressLine: FC<ProgressLineProps> = ({ completed }) => {
    return (
        <ProgressBar
            completed={completed}
            isLabelVisible={false}
            bgColor={theme.colors.primary}
            baseBgColor={theme.colors.grey3}
            borderRadius="0"
            height="4px"
        />
    );
};
