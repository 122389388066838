import { FC, useMemo, useState } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { useQuery } from 'react-query';
import { translates } from '../../../utilities';
import {
    Card,
    Spinner,
    Text,
} from '../../../components';
import { useApi } from '../../../hooks/useApi';
import { routerPaths } from '../../../configs';
import { ApiErrorModel } from '../../../types/api';
import { Toast } from '../../../components/toast';

const StyledSpinner = styled(Spinner)`
    display: block;
    margin: 1rem auto;
`;

const EmptyListLabel = styled(Text).attrs({
    color: 'greyText',
})`
    display: block;
    text-align: center;
    margin: 1rem auto;
`;

const AddressBookItemContainer = styled.div`
    padding: 1rem 0;
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: ${({ theme }) => theme.colors.grey1};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const AddAddressBookButton = styled(AddressBookItemContainer)`
    border-bottom-style: none;
    cursor: pointer;
    justify-content: center;
`;

const AddressBookItemLabel = styled(Text).attrs({
    color: 'greyText',
})``;

const AddressBookItemRight = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-left: 10px;
`;

const EditIcon = styled(EditOutlined)`
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fonts.body1.size};
`;

const AddIcon = styled(PlusCircleOutlined)`
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fonts.body1.size};
`;

const HighlightedLabel = styled(Text).attrs({
    color: 'success',
    cursor: 'pointer',
})`
    margin-left: 0.5rem;
`;

interface AddAddressBookProps {
    onClick: () => void;
}

const AddAddressBook: FC<AddAddressBookProps> = ({ onClick }) => (
    <AddAddressBookButton onClick={() => onClick()}>
        <AddIcon />
        <HighlightedLabel>
            {i18next.t(translates.AddressBookLabelsAdd)}
        </HighlightedLabel>
    </AddAddressBookButton>
);

interface AddressBookItemProps {
    label: string;
    onClick: () => void;
    loading: boolean;
}

const AddressBookItem: FC<AddressBookItemProps> = ({ label, onClick, loading }) => (
    <AddressBookItemContainer onClick={() => onClick()}>
        <AddressBookItemLabel>
            {label}
        </AddressBookItemLabel>
        <AddressBookItemRight>
            {loading ? <Spinner size={14} /> : <EditIcon />}
            <HighlightedLabel>
                {i18next.t(translates.AddressBookLabelsEdit)}
            </HighlightedLabel>
        </AddressBookItemRight>
    </AddressBookItemContainer>
);

interface AddressBooksListProps {
    loading?: boolean;
    loadingItem: string | null;
    list: AddressBookModel[];
    onSelect: (addressBookId: string) => void;
    onAdd: () => void;
}

const AddressBooksList: FC<AddressBooksListProps> = ({ loading, loadingItem, list, onSelect, onAdd }) => {
    const isListAvailable = useMemo(() => !!list.length, [list]);

    if (loading) {
        return <StyledSpinner />;
    }

    return (
        <>
            {!isListAvailable && (
                <EmptyListLabel>
                    {i18next.t(translates.AddressBookLabelsEmptyList)}
                </EmptyListLabel>
            )}
            {isListAvailable && list.map((item, idx) => (
                <AddressBookItem
                    key={`${item.addressBookId}-${idx}`}
                    loading={loadingItem === item.addressBookId}
                    label={item.companyName}
                    onClick={() => !loadingItem && onSelect(item.addressBookId)}
                />
            ))}
            <AddAddressBook onClick={onAdd}/>
        </>
    );
};

interface AddressBookModel {
    addressBookId: string
    companyName: string,
    address: string,
    longitude: number,
    latitude: number,
}

export const AddressBooks = () => {
    const navigate = useNavigate();
    const api = useApi();
    const [id, setId] = useState<string>('');
    const { data, isLoading } = useQuery('address-books', () => api<AddressBookModel[]>({ url: 'address-books' }), {
        onError(e) {
            Toast.error(i18next.t((e as ApiErrorModel)?.errorMessage!));
        },
    });

    useQuery({
        queryKey: `address-books/${id}`,
        queryFn: () => api({ url: `address-books/${id}` }),
        enabled: Boolean(id.length),
        onError(e) {
            Toast.error(i18next.t((e as ApiErrorModel)?.errorMessage!));
        },
        onSuccess(data) {
            navigate(routerPaths.manageAddressBook.route, {
                state: {
                    addressBook: data,
                },
            });
        },
    });

    const handleAddressBookSelect = (id: string) => {
        setId(id);
    };

    const handleAddressBookAdd = () => {
        navigate(routerPaths.manageAddressBook.route);
    };

    return (
        <Card seperator titleText={i18next.t(translates.AddressBookTitlesPreview)}>
            <AddressBooksList
                loading={isLoading}
                loadingItem={id}
                list={data || []}
                onSelect={handleAddressBookSelect}
                onAdd={handleAddressBookAdd}
            />
        </Card>
    );
};
