import { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as StarFilledIcon } from '../../../../../assets/images/star-filled-icon.svg';

const CommentContainer = styled.div`
    padding-bottom: 1.5rem;
    margin-top: 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey8};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const Name = styled.span`
    flex-basis: auto;
    font-size: 0.938rem;
`;

const Rating = styled.div`
    flex-basis: auto;
    color: ${({ theme }) => theme.colors.grey6};
`;

const StarFilledIconStyled = styled(StarFilledIcon)`
    flex-basis: auto;
    width: 24px;
    height: 24px;
    margin-left: 1rem;
`;

const Comment = styled.p`
    flex-basis: 100%;
    color: ${({ theme }) => theme.colors.grey6};
    margin-bottom: 0;
    margin-top: 0.5rem;
`;

interface FeedbackCommentProps {
    feedback: any;
}

export const FeedbackComment: FC<FeedbackCommentProps> = ({ feedback, ...props }) => {
    return (
        <CommentContainer {...props}>
            <Name>{feedback.clientFirstName} {feedback.clientLastName}</Name>
            <StarFilledIconStyled />
            <Rating>{feedback.rating}</Rating>
            {feedback.comment && <Comment>{feedback.comment}</Comment>}
        </CommentContainer>
    );
};
