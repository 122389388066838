import React, { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CaretUpIcon } from '../../assets/images/caret-up-grey-icon.svg';
import { ReactComponent as CaretDownIcon } from '../../assets/images/caret-down-grey-icon.svg';

const Container = styled.div`
  position: fixed;
  top: 110px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: ${({ theme }) => theme.colors.white};
  z-index: 5;

  @media ${({ theme }) => theme.query.small} {
        top: 85px;
    }
`;

const MenuItem = styled.div`
  padding: 32px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkBlack};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey8};
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    border-bottom: 0;
  }
`;

const SubMenuItem = styled(MenuItem)`
  padding: 16px 32px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  background-color: ${({ theme }) => theme.colors.grey7};
`;

const CaretContainer = styled.div`
  margin-left: 8px;
`;

const CaretUpIconStyled = styled(CaretUpIcon)`
  width: 10px;
`;

const CaretDownIconStyled = styled(CaretDownIcon)`
  width: 10px;
`;

export interface Item {
    component?: ReactElement | string | undefined;
    onClick?(): void
    children?: Item[]
}

interface MobileMenuProps {
    items: Item[]
    onClose(): void
}

export const MobileMenu: FC<MobileMenuProps> = ({ items, onClose }) => {
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

    const expandToggle = (index: number) => {
        setExpanded({
            ...expanded,
            [index]: !expanded[index]
        });
    };

    const handleClick = (item: Item) => {
        onClose();
        item.onClick?.();
    };

    return (
        <Container>
            {items.map((item, i) => (
                <React.Fragment key={i}>
                    {item.component && (
                        <>
                            <MenuItem onClick={() => item.children ? expandToggle(i) : handleClick(item)}>
                                {item.component}
                                {item.children && (
                                    <CaretContainer>
                                        {expanded[i] ? (
                                            <CaretUpIconStyled />
                                        ) : (
                                            <CaretDownIconStyled />
                                        )}
                                    </CaretContainer>
                                )}
                            </MenuItem>

                            {expanded[i] && item.children?.map((item, index) => (
                                <React.Fragment key={`${i}-${index}`}>
                                    {item.component && (
                                        <SubMenuItem onClick={() => handleClick(item)}>
                                            {item.component}
                                        </SubMenuItem>
                                    )}
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </React.Fragment>
            ))}
        </Container>
    );
};
