import { useEffect } from 'react';
import i18next from 'i18next';
import * as Yup from 'yup';
import moment from 'moment';
import { RcFile } from 'rc-upload/lib/interface';
import { UseMutationResult } from 'react-query';

import { TitleTextProps } from '../../../components';
import { BiddingTypes, ContactPersonTypes, ServiceReceiverTypes, translates, WorkTypes } from '../../../utilities';
import { ServiceItem, BiddingType, AddressBookContactModel } from '../../../types/api';
import { logEvent } from '../../../utilities/analytics';
import { getPriceListEnabled } from '../../../utilities/countryDefaults';
import { ServiceGroupItemResponseModel } from '../../../types/apigw';

export enum OrderSteps {
    ServiceStep = 1,
    JobsStep = 2,
    InformationStep = 3,
    DetailsStep = 4,
    SummaryStep = 5,
}

export enum ExpectedTimeEnum {
    WorkingHours = 'WorkingHours',
    AfterWork = 'AfterWork',
    Weekend = 'OnWeekend',
    Anytime = 'Anytime',
}

type ServiceItemsType = { [key: string]: ServiceItem };

interface CreateOrderFormTempPropsModel {
    fixedDescription?: string;
    workType: number;
    fullPrice?: number;
    countryVat?: number;
    search: string;
    standardBiddingOnly?: boolean;
    expectedTime?: ExpectedTimeEnum | null;
    serviceReceiver?: ServiceReceiverTypes;
    companyName?: string | null;
    contactPerson?: ContactPersonTypes;
    serviceReceiverContacts?: AddressBookContactModel[];
}

interface CreateOrderFormModel extends CreateOrderFormTempPropsModel {
    serviceId: string;
    deadline: string;
    description: string;
    biddingType: number | null;
    address: string;
    latitude: number,
    longitude: number,
    additionalInformation: string;
    email: string;
    phoneCode: string;
    phone: string;
    images: RcFile[];
    partnerId: number | null;
    serviceItems?: ServiceItemsType;
    contractTerms: boolean;
    addressBookItemId?: string;
    shouldSendOrderTrackingUrlBySms: boolean;
    shouldSendOrderTrackingUrlByEmail: boolean;
    receiveMaterialsFromPartner: boolean;
    contactTelephone: string;
    contactEmail: string;
    isPartnerSelectedFromFavorites: boolean;
}

export const getTitleText = (step: OrderSteps): TitleTextProps => {
    switch (step) {
    case OrderSteps.ServiceStep:
        return {
            titleText: translates.OrderStepsTitlesServiceStep,
            subtitleText: translates.OrderStepsSubtitlesServiceStep,
        };
    case OrderSteps.JobsStep:
        return {
            titleText: translates.OrderStepsTitlesJobsStep,
            subtitleText: translates.OrderStepsSubtitlesJobsStep,
        };
    case OrderSteps.InformationStep:
        return {
            titleText: translates.OrderStepsTitlesInformationStep,
            subtitleText: translates.OrderStepsSubtitlesInformationStep,
        };
    case OrderSteps.DetailsStep:
        return {
            titleText: translates.OrderStepsTitlesDetailsStep,
            subtitleText: translates.OrderStepsSubtitlesDetailsStep,
        };
    case OrderSteps.SummaryStep:
        return {
            titleText: translates.OrderStepsTitlesSummaryStep,
            subtitleText: translates.OrderStepsSubtitlesSummaryStep,
        };
    default:
        return {
            titleText: '',
            subtitleText: '',
        };
    }
};

export const getSubmitButtonText = (step: OrderSteps): string => {
    switch (step) {
    case OrderSteps.ServiceStep:
        return translates.OrderStepsButtonsServiceStep;
    case OrderSteps.JobsStep:
        return translates.OrderStepsButtonsJobsStep;
    case OrderSteps.InformationStep:
        return translates.OrderStepsButtonsInformationStep;
    case OrderSteps.DetailsStep:
        return translates.OrderStepsButtonsDetailsStep;
    case OrderSteps.SummaryStep:
        return translates.OrderStepsButtonsSummaryStep;
    default:
        return '';
    }
};

export const getMobileSubmitButtonText = (step: OrderSteps): string => {
    if (step === OrderSteps.SummaryStep) {
        return translates.OrderStepsButtonsSummaryStep;
    }

    return translates.OrderStepsButtonsNext;
};

const descriptionReqIfWorkType = (type: WorkTypes) => {
    return Yup.string().nullable().when('workType', {
        is: (workType: WorkTypes) => workType === type,
        then: Yup.string().required(translates.FormsErrorsRequired),
    });
};

export const getCurrentSchema = (step: OrderSteps, isAuth: boolean) => {
    switch (step) {
    case OrderSteps.ServiceStep:
        return Yup.object().shape({
            serviceId: Yup.string().required(),
            search: Yup.string()
        });
    case OrderSteps.JobsStep:
        return Yup.object().shape({
            description: descriptionReqIfWorkType(WorkTypes.NON_STANDARD),
            serviceItems: Yup.object().nullable().when('workType', {
                is: (workType: WorkTypes) => workType === WorkTypes.STANDARD,
                then: Yup.object().transform((serviceItems) => {
                    if (!Object.keys(Object(serviceItems)).length) {
                        return null;
                    }
                    return serviceItems;
                }).nullable().required(translates.OrderStepsErrorsJobItemNotSelected),
            }),
        });
    case OrderSteps.DetailsStep:
        return Yup.object().shape({
            address: Yup.string().required(translates.FormsErrorsRequired),
            latitude: Yup.number().required(translates.FormsErrorsRequired),
            longitude: Yup.number().required(translates.FormsErrorsRequired),
            additionalInformation: Yup.string().max(300, translates.FormsErrorsTooLong),
            description: Yup.string().nullable().when('standardBiddingOnly', {
                is: true,
                then: Yup.string().required(translates.FormsErrorsRequired),
            }),
            companyName: Yup.string().nullable().when('serviceReceiver', {
                is: (value: ServiceReceiverTypes) => value === ServiceReceiverTypes.SELECTED,
                then: Yup.string().required(translates.FormsErrorsRequired),
            }),
            biddingType: Yup.string().required(translates.FormsErrorsRequired),
            contactTelephone: Yup.string().nullable().when(['contactPerson', 'serviceReceiver'], {
                is: (cp: ContactPersonTypes, sr: ServiceReceiverTypes) => cp === ContactPersonTypes.SELECTED && sr === ServiceReceiverTypes.SELECTED,
                then: Yup.string().required(translates.FormsErrorsRequired)
            }),
            expectedTime: Yup.string().nullable().required(translates.FormsErrorsRequired),
        });
    case OrderSteps.SummaryStep:
        return Yup.object().shape({
            contractTerms: Yup.bool().nullable().when('biddingType', {
                is: (value: BiddingType) => value === BiddingTypes.fixed,
                then: (schema) => schema.isTrue(translates.FormsErrorsMustAgree).required(translates.FormsErrorsRequired),
            }),
            phoneCode: Yup.string(),
            phone: isAuth ? Yup.string().nullable() : Yup.string().required(translates.FormsErrorsRequired).phone(translates.FormsErrorsPhoneNumberInvalid)
        });
    default:
        return Yup.object().shape({});
    }
};

export const isOverflowEnabled = (step: OrderSteps) => {
    switch (step) {
    case OrderSteps.DetailsStep:
    case OrderSteps.JobsStep:
    case OrderSteps.InformationStep:
    case OrderSteps.ServiceStep:
    case OrderSteps.SummaryStep:
        return true;
    default:
        return false;
    }
};

export const useLogEvent = (message: string | boolean, data?: Record<any, any>) => {
    useEffect(() => {
        if (typeof message === 'boolean' || !message) {
            return;
        }

        logEvent(message, data);
    }, [message, data]);
};

export const createDeadline = (amount: moment.DurationInputArg1, unit: moment.unitOfTime.DurationConstructor) => {
    return moment()
        // Setting deadline to end of work day (6pm)
        // Since .set method adds offset 19 is correct magic number
        .set('hour', 19).set('minute', 0).set('second', 0)
        .set('millisecond', 0)
        .add(amount, unit)
        .toISOString();
};

export const generateDeadlineOptions = () => ([
    { label: i18next.t(translates.OrderStepsLabelsDeadlineDurationWeek), value: createDeadline(7, 'days') },
    { label: i18next.t(translates.OrderStepsLabelsDeadlineDurationTwoWeeks), value: createDeadline(14, 'days') },
    { label: i18next.t(translates.OrderStepsLabelsDeadlineDurationMonth), value: createDeadline(1, 'month') },
    { label: i18next.t(translates.OrderStepsLabelsDeadlineDurationSixMonths), value: createDeadline(6, 'months') },
]);

export const isStandardBiddingOnly = (country: string) => {
    const enabled = getPriceListEnabled(country);

    return !enabled;
};

export const getDefaultWorkType = (country: string, isCompany: boolean | undefined, query: Record<string, string | undefined>) => {
    if (!getPriceListEnabled(country)) {
        return WorkTypes.NON_STANDARD;
    }
    if (![query.serviceId, query.itemId].includes(undefined)) {
        return WorkTypes.STANDARD;
    }
    if (isCompany) {
        return WorkTypes.NON_STANDARD;
    }

    return WorkTypes.STANDARD;
};

export const getQueryServiceItem = async (query: Record<string, string | undefined>, serviceItemQuery: UseMutationResult<ServiceGroupItemResponseModel, unknown, string>) => {
    if (![query.serviceId, query.itemId, query.quantity].includes(undefined)) {
        const { result } = await serviceItemQuery.mutateAsync(query.itemId!);

        if (result === undefined) {
            return result;
        }

        const serviceItem = result?.serviceItem || {} as ServiceItem;
        return {
            [query.itemId!]: {
                ...serviceItem,
                quantity: parseInt(query.quantity!, 10) || 1,
            } as ServiceItem
        };
    }

    return {};
};

export const getQueryIsServiceAvailable = async (query: Record<string, string | undefined>, serviceQuery: UseMutationResult<boolean, unknown, string>) => {
    if (![query.serviceId].includes(undefined)) {
        const result = await serviceQuery.mutateAsync(query.serviceId!);
        return result;
    }

    return false;
};

export type {
    CreateOrderFormModel,
};
