import { FC } from 'react';
import SpinnerImg from '../../assets/images/spinner.svg';

interface SpinnerProps {
    size?: string | number
}

export const Spinner: FC<SpinnerProps> = ({ size, ...props }) => {
    return <img src={SpinnerImg} alt="loading" width={size || 48} height={size || 48} {...props} />;
};
