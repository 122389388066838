import { useCallback, useState, useContext, useEffect, FC } from 'react';
import { useFormikContext } from 'formik';
import { useInitSignalR } from '../../../hooks/useInitSignalR';
import { ClientReadModel } from '../../../types/api';
import { useApi } from '../../../hooks/useApi';
import { AuthContext, ConfigContext, ContactInfoModel } from '../../../contexts';
import { logEvent } from '../../../utilities/analytics';

export const useLoginHandler = (type: string) => {
    const fetchApi = useApi();
    const initSignalR = useInitSignalR();
    const [error, setError] = useState('');
    const { setAuth } = useContext(AuthContext);
    const { setLanguage, setContactInfo } = useContext(ConfigContext);

    const fetch = useCallback(() => {
        return {
            client: fetchApi<ClientReadModel>({
                url: 'clients/self',
                method: 'GET',
            }),
        };
    }, [fetchApi]);

    const loginHandler = useCallback(async () => {
        try {
            const clientResponse = await fetch().client;
            logEvent('login', {
                event: 'login',
                method: type,
                user_id: clientResponse.clientId,
            });
            setContactInfo({} as ContactInfoModel);
            // reinitializing signalR with user ID
            initSignalR();

            setAuth(clientResponse);

            if (clientResponse.language) {
                setLanguage(clientResponse.language);
            }
        } catch (error: any) {
            setError(error?.errorMessage);
        }
    }, [fetch, setContactInfo, initSignalR, setAuth, setLanguage, type]);

    const returns = [loginHandler, error] as const;

    return returns;
};

export const LoginKeyboardListener: FC = () => {
    const { submitForm } = useFormikContext();

    const submit = useCallback((event: KeyboardEvent) => {
        if (event.code === 'Enter') {
            submitForm();
        }
    }, [submitForm]);

    useEffect(() => {
        document.addEventListener('keypress', submit);

        return () => {
            document.removeEventListener('keypress', submit);
        };
    }, [submit]);

    return null;
};
