import { FC, HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Colors, Fonts } from '../../theme';

type Cursor = 'default' | 'auto' | 'text' | 'pointer';

interface SpanProps {
    $type: Fonts,
    $color: Colors,
    $cursor: Cursor,
}

const Span = styled.span<SpanProps>`
    color: ${({ theme, $color }) => theme.colors[$color]};
    cursor: ${({ $cursor }) => $cursor};
    ${({ theme, $type }) => {
        return css`
            font-size: ${theme.fonts[$type].size};
            ${theme.fonts[$type].weight && css`
                font-weight: ${theme.fonts[$type].weight};
            `}
            ${theme.fonts[$type].mobile && css`
                @media ${theme.query.small} {
                    font-size: ${theme.fonts[$type].mobile.size};
                }
            `}
        `;
    }};
`;

interface TextProps {
    type?: Fonts,
    color: Colors,
    cursor?: Cursor,
    className?: string,
    children: ReactNode,
}

export const Text: FC<TextProps & HTMLAttributes<HTMLSpanElement>> = ({ className, children, type = 'default', color, cursor, ...props }) => (
    <Span
        $type={type}
        $color={color}
        $cursor={cursor || 'text'}
        className={className}
        {...props}
    >
        {children}
    </Span>
);
