import { FC } from 'react';
import styled from 'styled-components';
import { Spinner } from '../../../../components/spinner';

interface ImageProps {
    src: string,
    loading: boolean
}

interface StyledImageProps {
    src: string,
    $loading: boolean
}

const StyledImage = styled.div<StyledImageProps>`
    background: no-repeat url(${({ src }) => src}) center center / cover;
    max-width: 200px;
    padding-top: 100%;
    border-radius: 5px;
    position: relative;
    cursor: ${({ $loading }) => $loading ? '' : 'pointer'};
`;

const IconContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Image: FC<ImageProps> = ({ src, loading }) => {
    return (
        <StyledImage src={src} $loading={loading}>
            {loading && (
                <IconContainer>
                    <Spinner size={'50%'} />
                </IconContainer>
            )}
        </StyledImage>
    );
};
