import { FC, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { useMediaQuery } from 'react-responsive';
import { Slider } from '../../../../components/slider';
import { PartnerCard } from '../../../../components/partnerCard';
import { BiddingTypes, translates } from '../../../../utilities';
import { ImageModel, OrderBidModel, OrderModel } from '../../../../types/api';
import { theme } from '../../../../theme';
import { AuthContext, ConfigContext } from '../../../../contexts';
import { getCountryCurrency, getCountryCurrencyLabel, getCurrencyLabel } from '../../../../utilities/countryDefaults';
import { getPlannedMaterialExpensesText, getPlannedStartDate } from './orderUtils';
import useCallPhone from '../../../../hooks/useCallPhone';

const PartnerContainer = styled.div`
    margin-top: 1rem;
`;

const Title = styled.p`
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 20px;
`;

interface OrderPartnersProps {
    order: OrderModel;
    clientImages: ImageModel[];
    acceptBid: (partnerId: number) => void;
}

export const OrderPartners: FC<OrderPartnersProps> = ({ order, clientImages, acceptBid }) => {
    const { country } = useContext(ConfigContext);
    const { auth } = useContext(AuthContext);
    const { isCallPressed, callPhone } = useCallPhone();

    const isTablet = useMediaQuery({
        query: theme.query.medium,
    });

    const isMobile = useMediaQuery({
        query: theme.query.small,
    });

    const slidesPerView = useMemo(() => {
        if (isMobile) {
            return 1;
        }
        return isTablet ? 2 : 4;
    }, [isMobile, isTablet]);

    const getPrice = useCallback((bid: OrderBidModel) => {
        if (order.biddingType === BiddingTypes.fixed && !order.pricing) {
            return null;
        }

        if (order.currency) {
            return getCurrencyLabel(order.currency, bid.price!, order.biddingType === BiddingTypes.factual);
        }

        return getCountryCurrency(country, bid.price!, order.biddingType === BiddingTypes.factual);
    }, [order, country]);

    const isNullOrWhiteSpace = (value?: string) => {
        return !value || !value.trim();
    };

    return (
        <PartnerContainer>
            <Title>{i18next.t(translates.OrderCardLabelsPartnersOffers)}</Title>
            <Slider
                navigation
                spaceBetween={44}
                slidesPerView={slidesPerView}
            >
                {order.bids!.map((bid) => (
                    <PartnerCard
                        key={bid.partnerId}
                        id={bid.partnerId!}
                        partnerPictureUrl={bid.partnerPictureUrl}
                        name={isNullOrWhiteSpace(bid.partner?.fullName!) ? bid.partner?.companyName! : bid.partner?.fullName!}
                        priceLabel={i18next.t(translates.PartnerCardLabelsUnconfirmedOrderPrice)}
                        price={getPrice(bid)}
                        jobScope={bid.jobScope}
                        plannedMaterialExpenses={getPlannedMaterialExpensesText(getCountryCurrencyLabel(country), bid.plannedMaterialExpenses)}
                        plannedStartDate={getPlannedStartDate(bid.plannedStartDate)}
                        rating={bid.rating!}
                        totalVotes={bid.totalVotes!}
                        commentsCount={bid.totalComments!}
                        buttonProps={auth?.isCompany && !bid.isAutoGenerated ? [{
                            styleType: 'primary',
                            title: i18next.t(translates.PartnerCardButtonsAccept),
                            onClick: () => acceptBid(bid.partnerId!),
                            loading: clientImages.some((img) => !img.isAttached)
                        }, {
                            styleType: 'primary',
                            title: isCallPressed ? bid.partner?.phone! : i18next.t(translates.PartnerCardButtonsCall),
                            onClick: () => callPhone(bid.partner?.phone!),
                        }] : {
                            styleType: 'primary',
                            title: i18next.t(translates.PartnerCardButtonsAccept),
                            onClick: () => acceptBid(bid.partnerId!),
                            loading: clientImages.some((img) => !img.isAttached)
                        }}
                        serviceId={order.serviceId!}
                        doneWorksAmount={bid.totalJobsFinished!}
                        doneWorksAmountLabel={i18next.t(translates.PartnerCardLabelsDoneWorksAmount)}
                        isFavoritePartner={bid.isFavoritePartner}
                        orderId={order?.orderId}
                    />
                ))}
            </Slider>
        </PartnerContainer>
    );
};
