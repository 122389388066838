import i18next from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { translates } from '../../../utilities';
import { Card } from '../../../components';
import { routerPaths } from '../../../configs';
import { AddressBookForm } from '../../../components/addressBookForm';
import { UpdateAddressBookModel } from '../../../types/api';

export const ManageAddressBook = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const isEditing = typeof state?.addressBook === 'object';
    const addressBook: UpdateAddressBookModel | null = isEditing ? state.addressBook : null;

    const handleCancel = () => {
        navigate(routerPaths.addressBooks.route);
    };

    const handleSuccess = () => {
        navigate(-1);
    };

    const titleTranslation = isEditing
        ? translates.AddressBookTitlesUpdate
        : translates.AddressBookTitlesCreate;

    return (
        <Card titleText={i18next.t(titleTranslation)}>
            <AddressBookForm
                isEditing={isEditing}
                addressBook={addressBook}
                onCancel={handleCancel}
                onSuccess={handleSuccess}
            />
        </Card>
    );
};
