import { FC } from 'react';
import styled, { css } from 'styled-components';
import i18next from 'i18next';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as PartnerIcon } from '../../../assets/images/partner-placeholder.svg';
import { translates } from '../../../utilities';
import { theme } from '../../../theme';

interface RadioItemContainerProps {
    $selected?: boolean;
    $disabled?: boolean;
    $isMobile?: boolean;
}

const RadioItemContainer = styled.div<RadioItemContainerProps>`
    border-radius: 10px;
    border: ${({ $selected, theme }) =>
        $selected ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.grey8}`};
    display: flex;
    flex: 1;
    min-height: 113px;
    padding: 23px 16px 23px 26px;
    margin-bottom: ${({ $isMobile }) => ($isMobile ? '25px' : '16px')};
    ${({ $disabled, theme }) => $disabled && `background-color: ${theme.colors.grey2}`};
    position: relative;
    align-items: center;
    ${({ $isMobile }) => {
        if ($isMobile) {
            return `
             align-items:center;
             justify-content: center;
             flex-direction: column;
             display: flex;
            `;
        }
        return '';
    }}
`;

const SelectedContainer = styled.div<{ $isMobile?: boolean }>`
    ${({ $isMobile }) => {
        if ($isMobile) {
            return `
            margin-left: -10px;
            position: absolute;
            bottom: 0px;
            
            & p {
               margin: 0px 0px !important;
            }
            `;
        }
        return `
        margin-left: auto;
        margin-right: 1rem;
        align-self: center;
        `;
    }}
`;

const Selected = styled.p`
  color: ${({ theme }) => theme.colors.primary};
`;

const RadioItemLeftInner = styled.div<{ $isMobile: boolean }>`
    margin-right: 16px;
    background: white;
    ${({ $isMobile }) => {
        if ($isMobile) {
            return css`
                position: absolute;
                top: -24px;
                left: 50%;
                margin-left: -24px;
                width: 48px;
                height: 48px;

                & svg {
                    width: 48px;
                    height: 48px;
                }
            `;
        }
        return '';
    }}
`;

const RadioItemMainInner = styled.div<{ $isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    max-width: ${({ $isMobile }) => ($isMobile ? '100%' : '75%')};
`;

interface RadioItemTitleProps {
    $disabled?: boolean;
}

const RadioItemTitle = styled.span<RadioItemTitleProps>`
    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ $disabled, theme }) => ($disabled ? theme.colors.grey4 : theme.colors.black)};
`;

interface RadioItemDescProps {
    $disabled?: boolean;
}

const RadioItemDesc = styled.span<RadioItemDescProps>`
    font-size: 0.75rem;
    color: ${({ $disabled, theme }) => ($disabled ? theme.colors.grey4 : theme.colors.grey10)};
`;

export interface RadioPartnerButtonProps {
    onClick: () => void;
    disabled?: boolean;
    selected?: boolean;
    title?: string;
    label?: string;
}

export const RadioPartnerButton: FC<RadioPartnerButtonProps> = ({ onClick, disabled, selected, title, label }) => {
    const isMobile = useMediaQuery({
        query: theme.query.medium,
    });

    return (
        <RadioItemContainer
            $isMobile={isMobile}
            onClick={() => !disabled && onClick()}
            $selected={selected}
            $disabled={disabled}>
            <RadioItemLeftInner $isMobile={isMobile}>
                <PartnerIcon />
            </RadioItemLeftInner>
            <RadioItemMainInner $isMobile={isMobile}>
                <RadioItemTitle $disabled={disabled}>{title}</RadioItemTitle>
                {label && <RadioItemDesc $disabled={disabled}>{label}</RadioItemDesc>}
            </RadioItemMainInner>
            <SelectedContainer $isMobile={isMobile}>
                <Selected>
                    {i18next.t(
                        selected
                            ? translates.CreateOrderFavoritePartnerSelected
                            : translates.CreateOrderFavoritePartnerSelect
                    )}
                </Selected>
            </SelectedContainer>
        </RadioItemContainer>
    );
};
