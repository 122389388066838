import i18next from 'i18next';
import { FC } from 'react';
import { OrderModel, OrderSelfModel } from '../../../../types/api';
import { OrderMetaItem } from './OrderMetaItem';
import { BiddingTypes, translates } from '../../../../utilities';
import { getCurrencyLabel, getCurrencySymbol } from '../../../../utilities/countryDefaults';
import GreenCreditCardIcon from '../../../../assets/images/cc-green-icon.svg';
import { hasPreliminaryPricing } from './orderUtils';

interface OrderPricingProps {
    order: OrderSelfModel & OrderModel
}

export const OrderPricingMetaItem: FC<OrderPricingProps> = ({ order }) => {
    if (!order.invoice && !order.pricing && order.biddingType !== BiddingTypes.fixed) {
        return <></>;
    }
    const preliminaryPricing = hasPreliminaryPricing(order);
    const fullPrice = order.pricing?.fullPrice;
    const currency = getCurrencySymbol(order.currency!);

    const workLabel = i18next.t(translates.OrderCardLabelsOrderPricingWork);
    const materialsLabel = i18next.t(translates.OrderCardLabelsOrderPricingMaterials);

    const fullValue = `${fullPrice}${currency}`;

    const getDetailedValue = () => {
        if (order.biddingType === BiddingTypes.fixed && !order.pricing) {
            return i18next.t(translates.OrderCardLabelsOrderAgreedPricing);
        }

        if (preliminaryPricing) {
            const isHourly = order.preliminaryHourlyRate !== null;
            const pricingLabel = getCurrencyLabel(
                order.currency!,
                order.preliminaryHourlyRate ?? order.pricing?.fullPrice!,
                isHourly
            );
            return pricingLabel;
        }

        if (order.pricing) {
            return `${fullValue} (${workLabel}: ${order.pricing.workPrice}${currency}, ${materialsLabel}: ${order.pricing.materialPrice}${currency})`;
        }

        return fullValue;
    };

    const itemLabel = preliminaryPricing
        ? translates.OrderCardLabelsOrderPreliminaryPricing
        : translates.OrderCardLabelsOrderPricing;

    const detailedValue = getDetailedValue();

    return (<OrderMetaItem
        icon={GreenCreditCardIcon}
        label={i18next.t(itemLabel)}
        value={detailedValue}
    />);
};
