import i18next from 'i18next';
import { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Modal } from '../../../../components/modal';
import { useApi } from '../../../../hooks/useApi';
import { FormFieldPhone, FormFieldTextarea, Form, Button } from '../../../../components';
import { phoneFormatter, translates } from '../../../../utilities';
import { ConfigContext } from '../../../../contexts';
import { ReactComponent as SuccessIcon } from '../../../../assets/images/order-success.svg';
import { Toast } from '../../../../components/toast';
import { ApiErrorModel, FavoritePartnerModel } from '../../../../types/api';
import { FavoritePartnerCard } from '../../../../components/partnerCard';

const ModalStyled = styled(Modal).attrs({
    modalHeight: 'calc(87vh - 120px)',
})``;

interface TitleProps {
    $centered?: boolean;
}

const Title = styled.p<TitleProps>`
    margin: 25px 0px;
    font-size: 1.2rem;
    font-weight: 500;
    ${({ $centered }) => $centered && 'text-align: center'};
`;

interface SubtitleProps {
    $centered?: boolean;
}

const Subtitle = styled.span<SubtitleProps>`
    font-size: 0.975rem;
    ${({ $centered }) => $centered && 'text-align: center'};
`;

const FoundPartnerSubtitle = styled.span`
    font-size: 0.875rem;
    margin-top: -20px;
`;

const SuccessImg = styled(SuccessIcon)`
    margin: 50px 0 70px;
`;

const SuccessContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const PartnerFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PartnerCardContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 25px;
`;

const CloseButton = styled(Button).attrs({
    styleType: 'primary',
})`
    width: 100%;
`;

interface SuccessContentProps {
    onClick(): void;
}

const SuccessContent: FC<SuccessContentProps> = ({ onClick }) => {
    return (
        <SuccessContainer>
            <Title $centered>{i18next.t(translates.InvitePartnerSuccessTitle)}</Title>
            <Subtitle $centered>{i18next.t(translates.InvitePartnerSuccessSubtitle)}</Subtitle>
            <SuccessImg />
            <CloseButton title={i18next.t(translates.InvitePartnerSuccessButton)} onClick={onClick} />
        </SuccessContainer>
    );
};

const invitationFormSchema = Yup.object({
    phone: Yup.string().required(translates.FormsErrorsRequired).phone(translates.FormsErrorsPhoneNumberInvalid),
    invitationText: Yup.string().max(60, translates.FormsErrorsTooLong).nullable(),
});

interface InvitationFormModel {
    phone: string;
    invitationText: string;
    phoneCode: string;
}

interface ModalContentProps {
    currentPartnersList: number[];
    onCloseModal(): void;
    onSuccessInvite(): void;
}

const ModalContent: FC<ModalContentProps> = ({ currentPartnersList, onCloseModal, onSuccessInvite }) => {
    const api = useApi();
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isPartnerFound, setIsPartnerFound] = useState(false);
    const [foundPartnerData, setFoundPartnerData] = useState<FavoritePartnerModel | null>(null);
    const { country } = useContext(ConfigContext);
    const initialValues = {
        phone: '',
        invitationText: '',
        phoneCode: phoneFormatter(null, country),
    };

    const handleInvitePartner = async (data: InvitationFormModel) => {
        try {
            await api({
                url: 'favorite-partners/invite',
                method: 'POST',
                data: {
                    partnerPhoneNumber: data.phoneCode + data.phone,
                    invitationText: data.invitationText,
                },
            });

            onSuccessInvite();
            setIsSuccessVisible(true);
        } catch (err) {
            const error = err as ApiErrorModel;
            const errorMessage = error.errorMessage ? error.errorMessage : translates.ExternalErrorUnexpected;
            Toast.error(i18next.t(errorMessage));
        }
    };

    const handleSearchPartner = async (data: InvitationFormModel) => {
        try {
            const response = await api<FavoritePartnerModel | null>({
                url: 'favorite-partners/search',
                params: {
                    phoneNumber: data.phoneCode + data.phone,
                },
            });

            if (response === null) {
                handleInvitePartner(data);
                return;
            }

            setIsPartnerFound(true);
            setFoundPartnerData(response);
        } catch (err) {
            const error = err as ApiErrorModel;
            const errorMessage = error.errorMessage ? error.errorMessage : translates.ExternalErrorUnexpected;
            Toast.error(i18next.t(errorMessage));
        }
    };

    const handleInviteExistingPartner = async (partnerId: number) => {
        try {
            await api<unknown | null>({
                url: `favorite-partners/${partnerId}/invite`,
                method: 'POST',
            });
            onSuccessInvite();
            onCloseModal();
        } catch (err) {
            const error = err as ApiErrorModel;
            const errorMessage = error.errorMessage ? error.errorMessage : translates.ExternalErrorUnexpected;
            Toast.error(i18next.t(errorMessage));
        }
    };

    const partnerFoundDescriptionLabel = foundPartnerData?.partnerName
        ? translates.InvitePartnerDescriptionFoundPartner
        : translates.InvitePartnerDescriptionFoundUnavailablePartner;

    if (isSuccessVisible) {
        return <SuccessContent onClick={onCloseModal} />;
    }

    return (
        <>
            <Title>{i18next.t(translates.InvitePartnerTitlesInvitation)}</Title>
            <Form
                submit={handleSearchPartner}
                submitText={!isPartnerFound ? i18next.t(translates.InvitePartnerButtonsInvite) : undefined}
                values={initialValues}
                schema={invitationFormSchema}
            >
                <FormFieldPhone name="phone" namePrefix="phoneCode" label={i18next.t(translates.InvitePartnerLabelsPhoneInput)} />
                <FormFieldTextarea name="invitationText" label={i18next.t(translates.InvitePartnerLabelsTextInput)} maxLength={60} />
            </Form>
            {isPartnerFound && (
                <PartnerFoundContainer>
                    <Title>{i18next.t(translates.InvitePartnerTitlesFoundPartner)}</Title>
                    <FoundPartnerSubtitle>{i18next.t(partnerFoundDescriptionLabel)}</FoundPartnerSubtitle>
                    {foundPartnerData && (
                        <PartnerCardContainer>
                            <FavoritePartnerCard
                                name={foundPartnerData.partnerName!}
                                partnerPictureUrl={foundPartnerData.imageUrl}
                                services={foundPartnerData.services}
                                onInvitePress={() => handleInviteExistingPartner(foundPartnerData.partnerId!)}
                                onDismissPress={onCloseModal}
                                isAlreadyInvited={currentPartnersList.includes(foundPartnerData.partnerId!)}
                            />
                        </PartnerCardContainer>
                    )}
                </PartnerFoundContainer>
            )}
        </>
    );
};

interface InvitationModalProps {
    onClose: () => void;
    open: boolean;
    handleRefreshPartnersList(): void;
    currentPartnersList: number[];
}

export const InvitationModal: FC<InvitationModalProps> = ({
    onClose,
    open,
    handleRefreshPartnersList,
    currentPartnersList,
}) => {
    return (
        <ModalStyled open={open} isLoading={false} onClose={() => onClose()}>
            <ModalContent onSuccessInvite={handleRefreshPartnersList} onCloseModal={onClose} currentPartnersList={currentPartnersList} />
        </ModalStyled>
    );
};
