import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '../text';
import CloseImg from '../../assets/images/close-sharp.svg';

interface HelpPopoverProps {
    children: ReactNode;
    onClose: () => void;
    isMobile?: boolean;
}

interface HelpPopoverContainer {
    isMobile?: boolean;
}

const HelpPopverContainer = styled.div<HelpPopoverContainer>`
    position: absolute;
    ${({ isMobile }) =>
        isMobile
            ? css`
                  // 48px of select input | 24px of info icon
                  bottom: calc(48px + 24px);
              `
            : css`
                  top: -16px;
                  left: 30%;
              `}
    right: 0;
    padding: 16px 24px 16px 16px;
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.16);
    z-index: 3;
    white-space: pre-wrap;
`;

const CloseButton = styled.span`
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
`;

export const HelpPopover: FC<HelpPopoverProps> = ({ children, onClose, isMobile }) => (
    <HelpPopverContainer isMobile={isMobile}>
        <Text type="body2" color="greyText">
            {children}
        </Text>
        <CloseButton onClick={onClose}>
            <img src={CloseImg} alt="close" width={8} height={8} />
        </CloseButton>
    </HelpPopverContainer>
);
