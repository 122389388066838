import { FormikHelpers, FormikValues } from 'formik';
import i18next from 'i18next';
import { FC, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
    Modal, Title, Form, FormFieldInput, FormFieldAutocompleteGoogleMaps, FormFieldPhone, FormFieldCheckbox, RadioButtonGroup, FormAgreeModal, Alert,
} from '../../../components';
import { AuthContext, ConfigContext } from '../../../contexts';
import { useApi } from '../../../hooks/useApi';
import { phoneFormatter, transformFormErrors, translates, getPersonalCodeSchema, content, getInvoiceAddressSchema, profileSchema } from '../../../utilities';
import { CountryEnum, getIsAddressEnabled, getIsPersonalCodeEnabled } from '../../../utilities/countryDefaults';
import HomeGrey from '../../../assets/images/home-grey.svg';
import HomeGreen from '../../../assets/images/home-green.svg';
import PersonIconGrey from '../../../assets/images/person-grey.svg';
import PersonIconGreen from '../../../assets/images/person-green.svg';
import { ClientReadModel } from '../../../types/api';

interface IncompleteProfileModalProps {
    showModal: boolean;
    setShowModal: (shouldShow: boolean) => void;
    onOK?: () => void;
    hideClose?: boolean
}

const RadioButtonContainer = styled.div`
    padding-bottom: 16px;
`;

export const IncompleteProfileModal: FC<IncompleteProfileModalProps> = ({ showModal, setShowModal, onOK, hideClose }) => {
    const { auth } = useContext(AuthContext);
    const { language, country } = useContext(ConfigContext);
    const [showCompanyRegistration, setShowCompanyRegistration] = useState(false);
    const [error, setError] = useState('');
    const fetchApi = useApi();
    const { phone, email } = auth || {};
    const modalRef = useRef<HTMLDivElement>(null);

    const submit = async (values: any, helpers: FormikHelpers<FormikValues>) => {
        try {
            const data = {
                ...values,
                company: values.isCompany ? values.company : null,
                phone: values.phone && values.phoneCode + values.phone
            };

            await fetchApi<ClientReadModel>({
                url: 'clients/self',
                method: 'PUT',
                data,
            });

            if (onOK) {
                onOK();
            }

            setShowModal(false);
        } catch (e: any) {
            setError(e?.errorMessage);
            helpers.setErrors(transformFormErrors(e?.errors));
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const baseSchema = Yup.object().shape({
        ...profileSchema(country),
        language: Yup.string()
    });

    const phoneCode = phoneFormatter(phone, country);

    const initialValues: any = {
        acceptedPortalProContractTerms: auth?.acceptedPortalProContractTerms ?? false,
        acceptedPortalProPrivacy: auth?.acceptedPortalProPrivacy ?? false,
        allowToUseEmailForMarketingPurposes: auth?.allowToUseEmailForMarketingPurposes ?? false,
        recieveSmsNotifications: true,
        recieveEmailNotifications: true,
        email: email || null,
        firstName: auth?.firstName ?? '',
        lastName: auth?.lastName ?? '',
        phone: phone ? phone.replace(phoneCode, '') : null,
        phoneCode,
        isCompany: auth?.isCompany ?? country !== CountryEnum.LT,
        company: {
            name: auth?.companyName ?? '',
            code: auth?.companyCode ?? '',
            ...(country === CountryEnum.LT ? { vatCode: auth?.companyVatCode ?? '' } : {}),
            registrationAddressText: auth?.companyRegistrationAddressText ?? '',
            registrationAddressLongitude: auth?.companyRegistrationAddressLongitude ?? 0,
            registrationAddressLatitude: auth?.companyRegistrationAddressLatitude ?? 0,
        },
        language
    };

    let personalCodeSchema = Yup.object();
    let invoiceAddressSchema = Yup.object();
    let schema = baseSchema;

    if (getIsAddressEnabled(country)) {
        initialValues.invoiceAddress = '';
        invoiceAddressSchema = getInvoiceAddressSchema(country as CountryEnum, schema);
        schema = baseSchema.concat(invoiceAddressSchema);
    }

    if (getIsPersonalCodeEnabled(country)) {
        personalCodeSchema = getPersonalCodeSchema(country as CountryEnum, schema);
        initialValues.personalCode = '';
        schema = baseSchema.concat(personalCodeSchema);
    }
    const companyCountries = [CountryEnum.PL, CountryEnum.ES, CountryEnum.PT];
    if (companyCountries.includes(country as CountryEnum)) {
        initialValues.isCompany = true;
    }

    const companyRadioGroupValues = [{
        label: i18next.t(translates.RegisterTypesIndividual),
        value: false,
        icon: PersonIconGrey,
        iconSelected: PersonIconGreen,
    }, {
        label: i18next.t(translates.RegisterTypesEntity),
        value: true,
        icon: HomeGrey,
        iconSelected: HomeGreen,
    }];

    return (
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            modalHeight='97vh'
            hideClose={[CountryEnum.ES, CountryEnum.PL].includes(country.toUpperCase() as CountryEnum)}
            Header={() =>
                <Title
                    type="modal"
                    titleText={i18next.t(translates.ProfileTitlesUpdateForm)}
                    subtitleText={i18next.t(translates.ProfileTitlesInfoNeeded)} />
            }
            ref={modalRef}
        >
            <>
                <Form
                    submit={submit}
                    submitText={i18next.t(translates.ProfileButtonsSubmit)}
                    schema={schema}
                    values={initialValues}
                    onChange={(values: any) => {
                        setShowCompanyRegistration(values.isCompany);
                    }}
                    scrollElement={modalRef.current}
                >
                    <RadioButtonContainer>
                        <RadioButtonGroup
                            inline
                            type='iconButton'
                            name="isCompany"
                            options={companyRadioGroupValues}
                        />
                    </RadioButtonContainer>
                    {showCompanyRegistration && (<>
                        <FormFieldInput
                            name="company.name"
                            label={i18next.t(translates.RegisterLabelsCompanyName)}
                            autoComplete="company-name"
                        />
                        {country === CountryEnum.LT && (
                            <FormFieldInput
                                name="company.vatCode"
                                label={i18next.t(translates.RegisterLabelsCompanyVatCode)}
                                autoComplete="vat-code"
                            />
                        )}
                        <FormFieldInput
                            name="company.code"
                            label={i18next.t(translates.RegisterLabelsCompanyCode)}
                            autoComplete="company-code"
                        />
                        <FormFieldAutocompleteGoogleMaps
                            longitudeFieldName="company.registrationAddressLongitude"
                            latitudeFieldName="company.registrationAddressLatitude"
                            name="company.registrationAddressText"
                            label={i18next.t(translates.RegisterLabelsCompanyRegistrationAddress)}
                            country={country}
                        />
                    </>)}
                    <FormFieldInput
                        name="firstName"
                        label={i18next.t(translates.RegisterLabelsFirstName)}
                        autoComplete="first-name"
                        maxLength={25}
                    />
                    <FormFieldInput
                        name="lastName"
                        label={i18next.t(translates.RegisterLabelsLastName)}
                        autoComplete="last-name"
                        maxLength={25}
                    />
                    <FormFieldInput
                        name="email"
                        label={i18next.t(translates.RegisterLabelsEmail)}
                        autoComplete="username"
                        disabled={!!email}
                    />
                    {(!showCompanyRegistration && getIsAddressEnabled(country)) && (
                        <FormFieldInput
                            name="invoiceAddress"
                            label={i18next.t(translates.RegisterLabelsInvoiceAddress)}
                            autoComplete="address"
                        />
                    )}
                    <FormFieldPhone
                        name="phone"
                        namePrefix="phoneCode"
                        label={i18next.t(translates.RegisterLabelsPhone)}
                        autoComplete="phone"
                        disabled={!!phone}
                    />
                    {!showCompanyRegistration && getIsPersonalCodeEnabled(country) && <>
                        <FormFieldInput
                            name="personalCode"
                            label={i18next.t(translates.PersonalCodeLabel)}
                        />
                    </>}
                    <FormFieldCheckbox
                        name="acceptedPortalProPrivacy"
                        labelCheckbox={
                            <>
                                {i18next.t(translates.RegisterLabelsPrivacy)}&nbsp;
                                <FormAgreeModal
                                    name="acceptedPortalProPrivacy"
                                    contentKey={content.RegisterContentsPrivacy}
                                    linkText={i18next.t(translates.RegisterButtonsPrivacy)}
                                />
                            </>
                        }
                    />
                    <FormFieldCheckbox
                        name="acceptedPortalProContractTerms"
                        labelCheckbox={
                            <>
                                {i18next.t(translates.RegisterLabelsTerms)}&nbsp;
                                <FormAgreeModal
                                    name="acceptedPortalProContractTerms"
                                    contentKey={content.RegisterContentsTerms}
                                    linkText={i18next.t(translates.RegisterButtonsTerms)}
                                />
                            </>
                        }
                    />
                    <FormFieldCheckbox
                        name="allowToUseEmailForMarketingPurposes"
                        labelCheckbox={i18next.t(translates.RegisterLabelsMarketing) as string}
                    />
                    <Alert
                        content={i18next.t(error)}
                        visible={!!error}
                    />
                </Form>
            </>
        </Modal>
    );
};
