import { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

export const theme = {
    colors: {
        primary: '#02C172',
        greenLight: '#DEF7EC',
        darkBlack: '#231f20',
        dark: '#969696',
        greyText: '#909090',
        light: '#d3d3d3',
        lightSuccess: '#b4faa1',
        success: '#20cb7a',
        danger: '#FF4958',
        dangerLight: '#FFDADD',
        grey1: '#F2F2F2',
        grey2: '#F3F6F4',
        grey3: '#E3E3E3',
        grey4: '#C4C4C4',
        grey5: '#A2A2A2',
        grey6: '#6C6C6C',
        grey7: '#F8F8F8',
        grey8: '#ECECEC',
        grey9: '#DEDEDE',
        grey10: '#858688',
        grey11: '#424345',
        grey12: '#EDEDED',
        grey13: '#BFBFBF',
        white: '#ffffff',
        black: '#000000',
        shadow: 'rgba(117, 117, 117, 0.16)',
        none: 'transparent',
        fieldError: '#ed4203',
        gold: '#ffc800',
    },
    button: {
        size: {
            small: '40px',
            medium: '56px',
            large: '64px'
        }
    },
    defaults: {
        spacing: '32px',
        spacingHalf: '16px',
        radius: '16px',
        margin: '32px',
        maringHalf: '16px',
        marginQuarter: '8px',
        padding: '32px',
        paddingHalf: '16px'
    },
    fonts: {
        heading1: {
            weight: 700,
            size: '4.4375rem',
            mobile: {
                size: '2rem'
            }
        },
        heading2: {
            weight: 700,
            size: '3.4375rem',
            mobile: {
                size: '2rem'
            }
        },
        title1: {
            weight: 700,
            size: '2.6875rem',
            mobile: {
                size: '1.75rem'
            }
        },
        title2: {
            size: '1.4375rem',
            mobile: {
                size: '1.1875rem'
            }
        },
        title3: {
            weight: 700,
            size: '1.75rem'
        },
        title4: {
            weight: 700,
            size: '1.25rem'
        },
        priceTitle: {
            size: '2rem'
        },
        priceSubtitle: {
            size: '1rem'
        },
        body1: {
            size: '1.25rem'
        },
        body2: {
            size: '1rem'
        },
        small: {
            size: '0.75rem'
        },
        button: {
            size: '1.1875rem',
            weight: 700
        },
        buttonSmall: {
            size: '0.8125rem',
            weight: 500
        },
        default: {
            size: '0.875rem',
        },
    },
    query: {
        small: '(max-width: 768px)',
        medium: '(max-width: 1024px)'
    }
};

interface ThemeProps {
    children: ReactNode;
}

const Theme: FC<ThemeProps> = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export type Colors = keyof typeof theme.colors;
export type Fonts = keyof typeof theme.fonts;

export default Theme;
