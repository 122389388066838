import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import i18next from 'i18next';
import { UseMutateFunction } from 'react-query';
import { ClickableCardForm, FormFieldInput } from '../../../../components';
import { ServiceCard } from '../components';
import * as apiResponses from '../../../../types/apigw';
import { translates } from '../../../../utilities';
import { ConfigContext } from '../../../../contexts';
import SearchIcon from '../../../../assets/images/search.svg';
import { OrderSteps, getTitleText } from '../utilities';

const Container = styled.div`
    padding-top: 6px;
`;

const SubtitleStyled = styled.p`
    color: ${({ theme }) => theme.colors.dark};
    font-size: .875rem;
`;

interface ServiceStepProps {
    servicesGroups: apiResponses.ServiceGroupModel[];
    goForward: () => void;
    onSearch: UseMutateFunction<apiResponses.ServiceGroupResponseModel, Error, string>;
    isLoading: boolean;
}

export const ServiceStep: FC<ServiceStepProps> = ({ servicesGroups, goForward, onSearch, isLoading }) => {
    const [field, form, helper] = useField('search');
    const [{ value: serviceIdValue }] = useField('serviceId');
    const [si1, si2, { setValue: setServiceItems }] = useField('serviceItems');
    const { language } = useContext(ConfigContext);
    const prevLang = useRef(language);
    const prevServiceId = useRef(serviceIdValue);

    const search = useMemo(() =>
        debounce((e: string) => {
            if (e?.length >= 3) {
                onSearch(e);
            } else {
                onSearch('');
            }
        }, 300),
    [onSearch]);

    useEffect(() => {
        if (prevLang.current === language) {
            return;
        }

        helper.setValue('');
        prevLang.current = language;
    }, [prevLang, language]);

    useEffect(() => {
        if (prevServiceId !== serviceIdValue) {
            setServiceItems({});
        }
    }, [serviceIdValue, prevServiceId]);

    return (
        <Container>
            <FormFieldInput name="serviceSearch" label={i18next.t(translates.OrderStepsServiceSearch)} icon={SearchIcon} isLoading={isLoading} onChange={search} />
            {field?.value?.length >= 3 && (<SubtitleStyled>{i18next.t(translates.OrderStepsServiceSearchResults)}</SubtitleStyled>)}
            <p>{i18next.t(getTitleText(OrderSteps.ServiceStep).subtitleText)}</p>
            <ClickableCardForm bindId="id" name="serviceId" onSelect={goForward}>
                {servicesGroups.map((service) => (
                    <ServiceCard
                        text={service.serviceGroupCodeTranslation}
                        icon={service.imageUrl}
                        key={service.serviceGroupCode}
                        id={service.defaultServiceId.toString()}></ServiceCard>
                ))}
            </ClickableCardForm>
        </Container>
    );
};
