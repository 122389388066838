import { FC, useContext, useMemo } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { useFormikContext } from 'formik';
import { BiddingTypes, WorkTypes, translates } from '../../../../utilities';
import { RadioButtonGroup } from '../../../../components';
import { AuthContext, ConfigContext } from '../../../../contexts';
import { SelectFavoritePartner } from './SelectFavoritePartner';
import { PartnerModel } from '../../../../types/api';
import { CreateOrderFormModel } from '../utilities';

const Title = styled.p`
    color: ${({ theme }) => theme.colors.black};
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
`;

const RadioContainer = styled.div`
    margin-bottom: 12px;
`;

interface SelectBiddingTypeProps {
    partners: PartnerModel[];
}

export const SelectBiddingType: FC<SelectBiddingTypeProps> = ({ partners }) => {
    const { language } = useContext(ConfigContext);
    const { auth } = useContext(AuthContext);
    const { values, setFieldValue } = useFormikContext<CreateOrderFormModel>();
    const visibleBidding = values.workType === WorkTypes.NON_STANDARD && !!auth?.isCompany;

    const radioOptions = useMemo(
        () => [
            (partners.length > 0 || visibleBidding) && {
                title: i18next.t(translates.OrderStepsInfoJobsStepFactual),
                label: i18next.t(translates.OrderStepsLabelsJobsStepFactualRadioLabel, { defaultValue: '' }),
                value: BiddingTypes.fixed,
            },
            visibleBidding && {
                title: i18next.t(translates.OrderStepsInfoJobsStepStandard),
                label: i18next.t(translates.OrderStepsLabelsJobsStepStandardRadioLabel, { defaultValue: '' }),
                value: BiddingTypes.standard,
            },
        ].filter(Boolean),
        // In order to translate labels language dependancy is needed
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [language, auth, partners, values.workType]
    );

    const handleSelectBiddingType = () => {
        setFieldValue('isPartnerSelectedFromFavorites', false);
    };

    if (!radioOptions.length) {
        return null;
    }

    return (
        <>
            {<Title>{i18next.t(translates.OrderStepsLabelsHandyman)}</Title>}
            <SelectFavoritePartner partners={partners} />
            <RadioContainer onClick={handleSelectBiddingType}>
                <RadioButtonGroup
                    type="partnerButton"
                    name="biddingType"
                    options={radioOptions}
                    disabled={values.isPartnerSelectedFromFavorites}
                />
            </RadioContainer>

        </>
    );
};
