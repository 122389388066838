import { ReactElement, FC } from 'react';
import styled from 'styled-components';

import { TabTitle } from './TabTitle';

interface TabsProps {
    children: ReactElement[];
    selectedTab: number;
    setSelectedTab: (index: number) => void;
    areTabButtonsVisible?: boolean;
}

const TabsContainer = styled.ul`
    list-style-type: none;
    margin-bottom: 3rem;
    display: flex;
    padding: 0;
    border: 1px solid ${({ theme }) => theme.colors.grey7};
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.grey7};
    padding: 5px;
`;

const TabTitleStyled = styled(TabTitle)`
    flex-basis: 0;
    flex: 1;
    max-width: 100%;

    &:not(:last-child) {
        margin-right: 8px;
    }
`;

export const Tabs: FC<TabsProps> = ({ children, selectedTab, setSelectedTab, areTabButtonsVisible = true }) => {
    return (
        <div>
            {areTabButtonsVisible && <TabsContainer>
                {children.map((item, index) => (
                    <TabTitleStyled
                        key={index}
                        title={item.props.title}
                        index={index}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                ))}
            </TabsContainer>}
            {children[selectedTab]}
        </div>
    );
};
