import { FC } from 'react';
import styled from 'styled-components';
import { PopoverButton } from './PopoverButton';

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
`;

const Icon = styled.img`
    margin-right: .5rem;
    width: 16px;
    height: 16px;
    margin-top: 2.5px;
`;

const LabelContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey8};
    padding-bottom: 15px;
`;

const Flex = styled.div`
    display: flex;
`;

const MetaItemLabel = styled.span`
    font-size: .875rem;
    color: ${({ theme }) => theme.colors.grey6};
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
`;

const MetaValueLabel = styled(MetaItemLabel)`
    color: ${({ theme }) => theme.colors.black};
    margin: 0;
    white-space: pre-wrap; 
`;

const MetaValueLabelBright = styled(MetaItemLabel)`
    color: ${({ theme }) => theme.colors.primary};
    margin: 0;
`;

const StyledPopover = styled(PopoverButton)`
    margin-left: 6px;
`;

interface MetaItemProps {
    icon?: string;
    label: string;
    highlighted?: boolean;
    infoText?: string | JSX.Element;
    children?: React.ReactNode
}

export const MetaItem: FC<MetaItemProps> = ({ icon, highlighted, label, infoText, children }) => {
    return (
        <Container>
            <Icon src={icon} />
            <LabelContainer className="metaItemLabelContainer">
                <MetaItemLabel>
                    {label}
                </MetaItemLabel>
                <Flex>
                    {highlighted ? (
                        <MetaValueLabelBright>{children}</MetaValueLabelBright>
                    ) : (
                        <MetaValueLabel>{children}</MetaValueLabel>
                    )}
                    {Boolean(infoText) && (
                        <StyledPopover>
                            {infoText}
                        </StyledPopover>
                    )}
                </Flex>
            </LabelContainer>
        </Container>
    );
};
