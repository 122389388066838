import i18next from 'i18next';
import moment from 'moment';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { ProgressStepLine } from '../../../../components';
import { OrderModel } from '../../../../types/api';
import { BiddingTypes, ConstantsOrderStatus, translates } from '../../../../utilities';
import { AuthContext } from '../../../../contexts';

const ProgressSectionTitle = styled.p`
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1rem;
`;

const StepTitle = styled.p`
    font-size: 1rem;
    margin: 1.25rem 0 1rem;
`;

const StepSubtitle = styled.p`
    color: ${({ theme }) => theme.colors.grey10};
`;

const progressStatuses = {
    [ConstantsOrderStatus.Created]: 0,
    [ConstantsOrderStatus.AwaitingApproval]: 20,
    [ConstantsOrderStatus.InProgress]: 40,
    [ConstantsOrderStatus.Resolved]: 60,
    [ConstantsOrderStatus.AwaitingCompletion]: 80,
    [ConstantsOrderStatus.Completed]: 80,
    [ConstantsOrderStatus.ReadyForPayment]: 80,
    [ConstantsOrderStatus.Paid]: 100,
};

const getTitle = (order: OrderModel): { title: string; subtitle: string } => {
    const { statusLabel: status, bids, biddingType } = order;

    if (status === ConstantsOrderStatus.Created) {
        if (order.bids?.length === 0) {
            return {
                title: `${i18next.t(translates.OrderProgressTitlesStatusCreatedNoBids)}${order.assignedPartnersCount || 0}`,
                subtitle: i18next.t(translates.OrderProgressSubtitlesStatusCreatedNoBids),
            };
        }
        return {
            title: i18next.t(translates.OrderProgressTitlesStatusCreatedWithBids),
            subtitle: i18next.t(translates.OrderProgressSubtitlesStatusCreatedWithBids),
        };
    }
    if (status === ConstantsOrderStatus.AwaitingApproval) {
        return {
            title: i18next.t(translates.OrderProgressTitlesAwaitingApproval),
            subtitle: i18next.t(translates.OrderProgressSubtitlesAwaitingApproval),
        };
    }
    if (
        status === ConstantsOrderStatus.InProgress &&
        (bids?.length ?? 0) === 0 &&
        biddingType === BiddingTypes.fixed
    ) {
        return {
            title: i18next.t(translates.OrderProgressTitlesSearchingPartner),
            subtitle: i18next.t(translates.OrderProgressSubtitlesSearchingPartner),
        };
    }
    if (status === ConstantsOrderStatus.InProgress) {
        return {
            title: i18next.t(translates.OrderProgressTitlesInProgress),
            subtitle: i18next.t(translates.OrderProgressSubtitlesInProgress),
        };
    }
    if (status === ConstantsOrderStatus.Resolved) {
        return {
            title: i18next.t(translates.OrderProgressTitlesResolved),
            subtitle: i18next.t(translates.OrderProgressSubtitlesResolved),
        };
    }
    if (
        [
            ConstantsOrderStatus.AwaitingCompletion,
            ConstantsOrderStatus.Completed,
            ConstantsOrderStatus.ReadyForPayment,
        ].includes(status!)
    ) {
        return {
            title: i18next.t(translates.OrderProgressTitlesCompleted),
            subtitle: i18next.t(translates.OrderProgressSubtitlesCompleted),
        };
    }
    if (status === ConstantsOrderStatus.Paid) {
        return {
            title: i18next.t(translates.OrderProgressTitlesPaid),
            subtitle: i18next.t(translates.OrderProgressSubtitlesPaid),
        };
    }
    return {
        title: '',
        subtitle: '',
    };
};

const steps = [
    {
        label: translates.OrderProgressLabelsStatusCreated,
    },
    {
        label: translates.OrderProgressLabelsAwaitingApproval,
    },
    {
        label: translates.OrderProgressLabelsInProgress,
    },
    {
        label: translates.OrderProgressLabelsResolved,
    },
    {
        label: translates.OrderProgressLabelsCompleted,
    },
    {
        label: '',
    },
];

const getOrderStatus = (order: OrderModel) => {
    if (
        order.statusLabel! === ConstantsOrderStatus.Created &&
        moment() >= moment(order.biddingDeadline)
    ) {
        return ConstantsOrderStatus.AwaitingApproval;
    }

    return order.statusLabel!;
};

interface OrderProgressProps {
    order: OrderModel;
}

export const OrderProgress: FC<OrderProgressProps> = ({ order }) => {
    const { auth } = useContext(AuthContext);

    return (
        <>
            {(auth?.isCompany) && (
                <>
                    <ProgressSectionTitle>{i18next.t(translates.OrderProgressTitlesMain)}</ProgressSectionTitle>
                    <ProgressStepLine isLastStepHidden={true} steps={steps} progress={progressStatuses[getOrderStatus(order)]} />
                </>
            )}
            <StepTitle>{getTitle(order).title}</StepTitle>
            <StepSubtitle>{getTitle(order).subtitle}</StepSubtitle>
        </>
    );
};
