import { RcFile } from 'antd/lib/upload';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import * as Yup from 'yup';
import { serialize } from 'object-to-formdata';
import { useEffect, useRef, useState } from 'react';
import { FormikHelpers } from 'formik';
import styled from 'styled-components';
import {
    Alert,
    Button,
    Card,
    ContentLoading,
    Form,
    FormFieldTextarea,
    Title,
    FormFieldInput,
    FormFieldUpload,
} from '../../../components';
import { Toast } from '../../../components/toast';
import { useApi } from '../../../hooks/useApi';
import { useUrlQuery } from '../../../hooks/useUrlQuery';
import { ApiErrorModel, ImageModel } from '../../../types/api';
import { transformFormErrors, translates } from '../../../utilities';
import { routerPaths } from '../../../configs';
import ArrowLeft from '../../../assets/images/arrow-left-16.svg';

const CardStyled = styled(Card)`
    padding: 1.5rem;
    margin-top: 1rem;

    @media ${({ theme }) => theme.query.medium} {
        padding: 1rem;
    }

    @media ${({ theme }) => theme.query.small} {
        margin: 0 -1rem -1.5rem;
        border-radius: 0;
    }
`;

const TitleStyled = styled(Title)`
    margin-bottom: 1rem;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
`;

const ButtonSubmitStyled = styled(Button)`
    margin-left: auto;
    width: 33%;

    @media ${({ theme }) => theme.query.medium} {
        width: 50%;
    }
`;

export interface EditOrderModel {
    description: string;
    additionalInformation: string;
    images: RcFile[];
}

export const EditOrderContainer = () => {
    const query = useUrlQuery();
    const { orderId } = query;
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [model, setModel] = useState({} as EditOrderModel);
    const api = useApi();
    const navigate = useNavigate();
    const isMounted = useRef(true);

    const fetchImages = async (images: ImageModel[]) => {
        const data = await Promise.all(images.map((image) => api<Blob>({
            url: image.url,
            responseType: 'blob'
        })));
        return data.map((blob, i) => {
            const { id } = images[i];
            const ext = blob.type.split('/')[1];
            const name = `${id}.${ext}`;
            const file = new File([blob], name, { type: blob.type }) as RcFile;
            file.uid = id!;
            return file;
        });
    };

    const getOrder = async () => {
        try {
            if (!orderId) {
                return;
            }

            const images = query.clientImages ? await fetchImages(JSON.parse(query.clientImages as string)) : [];

            setModel({
                description: (query.description as string) || '',
                additionalInformation: (query.additionalInformation as string) || '',
                images,
            });
        } catch (e) {
            const error = e as ApiErrorModel;
            Toast.error(i18next.t(error?.errorMessage!));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = async (values: EditOrderModel, helpers: FormikHelpers<EditOrderModel>) => {
        try {
            const existingImages = (JSON.parse(query.clientImages as string) as ImageModel[]).map((i) => i.id);
            const newImages = values.images.filter((i) => !existingImages.includes(i.uid));
            const biddingDeadline = moment(query.biddingDeadline).toISOString();
            const deadline = moment(query.deadline).toISOString();

            const data = serialize({
                ...values,
                images: newImages,
                biddingDeadline,
                deadline
            });

            await api({
                url: `orders/${orderId}`,
                data,
                method: 'PUT',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            navigate(routerPaths.orders.route, { replace: true });
        } catch (e: any) {
            setError(e?.errorMessage);
            helpers.setErrors(transformFormErrors(e?.errors));
        } finally {
            if (isMounted) {
                setIsSubmitting(false);
                helpers.setSubmitting(false);
            }
        }
    };

    const schema = Yup.object().shape({
        description: Yup.string().required(translates.FormsErrorsRequired).max(2000, translates.FormsErrorsTooLong),
        additionalInformation: Yup.string().max(200, translates.FormsErrorsTooLong),
    });

    if (isLoading) {
        return <ContentLoading />;
    }

    return (
        <CardStyled size={'medium'}>
            <Form values={model} schema={schema} submit={submit}>
                <TitleStyled
                    type="inner-page"
                    titleText={i18next.t(translates.OrderEditTitlesMain)}
                />

                <FormFieldTextarea
                    name="description"
                    label={i18next.t(translates.CreateOrderLabelsDescription)}
                    maxLength={2000}
                    rows={4}
                />

                <FormFieldInput
                    name="additionalInformation"
                    label={i18next.t(translates.CreateOrderLabelsAdditionalInformation)}
                    maxLength={200}
                />

                <FormFieldUpload name="images" />

                <Alert content={i18next.t(error)} visible={!!error} />

                <ButtonsContainer>
                    <Button
                        iconLeft={ArrowLeft}
                        styleType="text-secondary"
                        title={i18next.t(translates.OrderEditButtonsCancel)}
                        onClick={() => navigate(routerPaths.orders.route, { replace: true })}
                    />
                    <ButtonSubmitStyled
                        type="submit"
                        styleType="primary"
                        title={i18next.t(translates.OrderEditButtonsSave)}
                        loading={isSubmitting}
                    />
                </ButtonsContainer>
            </Form>
        </CardStyled>
    );
};
