import { FC } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import CreateOrderIcon from '../../../../assets/images/create-order-icon.png';
import PlusIcon from '../../../../assets/images/plus-icon.svg';
import { translates } from '../../../../utilities';
import { Button } from '../../../../components';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding: 50px 60px 50px 266px;
  margin-top: 75px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  position: relative;

  @media ${({ theme }) => theme.query.small} {
    flex-direction: column;
    padding: 170px 16px 32px;
    margin-top: 45px;
  }
`;

const Content = styled.div`
  flex: 1;
`;

const Icon = styled.img`
  width: 170px;
  position: absolute;
  left: 16px;
  top: -41px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 500;
  margin: 0;
`;

const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  margin: 0;

  @media ${({ theme }) => theme.query.small} {
    margin: 10px 0 26px;
  }
`;

interface NoOrdersProps {
    onCreateClick(): void
}

export const NoOrders: FC<NoOrdersProps> = ({ onCreateClick }) => (
    <Container>
        <Icon src={CreateOrderIcon} />
        <Content>
            <Title>{i18next.t(translates.NoOrdersTitlesMain)}</Title>
            <Subtitle>{i18next.t(translates.NoOrdersSubtitlesMain)}</Subtitle>
        </Content>
        <Button
            iconLeft={PlusIcon}
            styleType="primary-inverted"
            title={i18next.t(translates.NoOrdersButtonsCreate)}
            onClick={onCreateClick}
        />
    </Container>
);
