import { useCallback, useReducer, useRef, useState } from 'react';
import i18next from 'i18next';
import qs from 'query-string';
import { Toast } from '../components/toast';
import { useApi } from './useApi';
import { DEFAULT_ORDER_TAKE, translates } from '../utilities';
import { OrderSelfModel } from '../types/api';
import { initialTabbedOrdersModel, InitialTabbedOrdersModelType } from '../pages/auth/orders/components/orderUtils';

interface OrdersFetchOptionsModel {
    skip: number;
    take: number;
    search?: string;
    shouldTabsReload?: boolean;
}

export type OrdersFetchFunctionType = (tabKey: keyof InitialTabbedOrdersModelType, options: OrdersFetchOptionsModel) => Promise<void>;

enum ActionTypes {
    SET_INITIAL_ORDERS,
    SET_ORDERS,
    SET_LOADING,
}

export enum FetchTypes {
    TAB_CHANGE,
    INITIAL,
    LOAD,
    RELOAD,
    SEARCH,
}

interface FetchPayload {
    search?: string;
}

interface SetTabsNewDetailsModel {
    skip: number;
    orders: OrderSelfModel[];
    search?: string;
}

interface SetOrdersReducerPayloadModel {
    tabKey: keyof InitialTabbedOrdersModelType;
    orders: OrderSelfModel[];
    loading?: boolean;
    search?: string;
    shouldTabsReload?: boolean;
}

interface LoadingOrdersReducerPayloadModel extends Partial<SetOrdersReducerPayloadModel> {
    tabKey: keyof InitialTabbedOrdersModelType;
    loading: boolean;
}

type ReducerActions =
    { type: ActionTypes.SET_INITIAL_ORDERS, payload: SetOrdersReducerPayloadModel } |
    { type: ActionTypes.SET_ORDERS, payload: SetOrdersReducerPayloadModel } |
    { type: ActionTypes.SET_LOADING, payload: LoadingOrdersReducerPayloadModel };

const tabbedOrdersModelReducer = (state: InitialTabbedOrdersModelType, action: ReducerActions) => {
    const { type, payload } = action;
    switch (type) {
    case ActionTypes.SET_INITIAL_ORDERS:
        return {
            ...state,
            [payload.tabKey]: {
                ...state[payload.tabKey],
                orders: payload.orders,
                skip: payload!.orders!.length,
                canLoadMore: payload!.orders!.length >= DEFAULT_ORDER_TAKE,
                loading: false,
                searchValue: payload.search ?? '',
                shouldReload: false,
            }
        };
    case ActionTypes.SET_ORDERS:
        return {
            ...state,
            [payload.tabKey]: {
                ...state[payload.tabKey],
                orders: [...state[payload.tabKey].orders, ...payload.orders!],
                skip: state[payload.tabKey].skip + payload!.orders!.length,
                canLoadMore: payload!.orders!.length >= DEFAULT_ORDER_TAKE,
                loading: false,
                searchValue: payload.search ?? '',
                shouldReload: false
            }
        };
    case ActionTypes.SET_LOADING:
        if (payload?.shouldTabsReload) {
            return Object.keys(state).reduce((prevState, keyString) => {
                const key = keyString as keyof InitialTabbedOrdersModelType;
                const assignedValue = key === payload.tabKey
                    ? { shouldReload: true, loading: payload.loading }
                    : { shouldReload: true };
                Object.assign(prevState[key], assignedValue);

                return prevState;
            }, state);
        }
        return {
            ...state,
            [payload.tabKey]: {
                ...state[payload.tabKey],
                loading: payload.loading || false,
            },
        };
    default:
        return state;
    }
};

const showFailedFetchAlert = () => {
    Toast.error(i18next.t(translates.OrdersErrorFetch));
};

export const useOrderFetch = () => {
    const fetchApi = useApi();
    const [activeKey, setActiveKey] = useState<keyof InitialTabbedOrdersModelType>(initialTabbedOrdersModel.allOrders.key);
    const [state, dispatch] = useReducer(tabbedOrdersModelReducer, initialTabbedOrdersModel);

    const setTabDetails = useCallback((tabKey: keyof InitialTabbedOrdersModelType, newDetails: SetTabsNewDetailsModel) => {
        dispatch({
            payload: { orders: newDetails.orders, tabKey, search: newDetails.search },
            type: newDetails.skip > 0
                ? ActionTypes.SET_ORDERS
                : ActionTypes.SET_INITIAL_ORDERS,
        });
    }, []);

    const fetchOrders: OrdersFetchFunctionType = useCallback(async (tabKey, options) => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: { tabKey, loading: true, shouldTabsReload: options.shouldTabsReload } });
        try {
            const response = await fetchApi<OrderSelfModel[]>({
                url: 'orders/self',
                params: {
                    skip: options.skip,
                    take: options.take,
                    statusLabels: initialTabbedOrdersModel[tabKey].filter || '',
                    filterString: options.search || ''
                },
                paramsSerializer: qs.stringify,
            });

            setTabDetails(tabKey, { ...options, orders: response });
        } catch {
            showFailedFetchAlert();
        } finally {
            dispatch({ type: ActionTypes.SET_LOADING, payload: { tabKey, loading: false } });
        }
    }, [fetchApi, setTabDetails]);

    // Ugly useRef for conditional readability
    const shouldFetchOnTabChange = useRef((tabKey: keyof InitialTabbedOrdersModelType, state: InitialTabbedOrdersModelType, payload?: FetchPayload) => {
        if (state[tabKey].shouldReload) {
            return true;
        }
        if (state[tabKey].searchValue === (payload?.search || '') && state[tabKey].orders.length > 0) {
            return false;
        }
        return true;
    });

    const fetch = useCallback((tabKey: keyof InitialTabbedOrdersModelType, type: FetchTypes, payload?: FetchPayload) => {
        switch (type) {
        case FetchTypes.INITIAL:
            return fetchOrders(tabKey, { skip: 0, take: DEFAULT_ORDER_TAKE });
        case FetchTypes.RELOAD:
            return fetchOrders(tabKey, {
                skip: 0,
                take: state[tabKey].orders.length,
                search: payload?.search,
                shouldTabsReload: true,
            });
        case FetchTypes.TAB_CHANGE:
            if (shouldFetchOnTabChange.current(tabKey, state, payload)) {
                return fetchOrders(tabKey, {
                    skip: 0,
                    take: DEFAULT_ORDER_TAKE,
                    search: payload?.search,
                });
            }
            return Promise.resolve();
        case FetchTypes.SEARCH:
            return fetchOrders(tabKey, {
                skip: 0,
                take: DEFAULT_ORDER_TAKE,
                search: payload?.search,
            });
        default:
            return fetchOrders(tabKey, {
                skip: state[tabKey].skip,
                take: DEFAULT_ORDER_TAKE,
                search: payload?.search,
            });
        }
    }, [fetchOrders, state]);
    return {
        orders: state[activeKey].orders,
        loading: state[activeKey].loading,
        canLoadMore: state[activeKey].canLoadMore,
        ordersSearchValue: state[activeKey].searchValue,
        fetch,
        setActiveKey,
    };
};
