import { FC } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { useField } from 'formik';
import { Button, InfoPanel } from '../../../../components';
import { translates, WorkTypes } from '../../../../utilities';

const LowerContainer = styled.div`
    margin-top: 1rem;
`;

export const WorkTypeSelect: FC = () => {
    const [{ value: workTypeValue }, w2, { setValue: setWorkType }] = useField('workType');
    const [d1, d2, { setValue: setDescription, setTouched: setDescriptionTouched }] = useField('description');

    const handleWorkTypeToggle = () => {
        setDescription('');
        setDescriptionTouched(false);
        if (workTypeValue === WorkTypes.NON_STANDARD) {
            return setWorkType(WorkTypes.STANDARD);
        }

        return setWorkType(WorkTypes.NON_STANDARD);
    };

    const translations = {
        description: workTypeValue === WorkTypes.NON_STANDARD
            ? translates.OrderStepsLabelsWorkTypeNonStandardInfoDescription
            : translates.OrderStepsLabelsWorkTypeStandardInfoDescription,
        button: workTypeValue === WorkTypes.NON_STANDARD
            ? translates.OrderStepsLabelsWorkTypeNonStandardInfoButton
            : translates.OrderStepsLabelsWorkTypeStandardInfoButton,
    };

    return (
        <InfoPanel type="info">
            <div>
                {i18next.t(translations.description)}
            </div>
            <LowerContainer>
                <Button
                    styleType="primary-outline"
                    title={i18next.t(translations.button)}
                    onClick={handleWorkTypeToggle}
                />
            </LowerContainer>
        </InfoPanel>
    );
};
