import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1570px;
  margin: 0 auto;
  padding: 0 32px;

  @media ${({ theme }) => theme.query.small} {
    padding: 0 16px;
  }
`;
