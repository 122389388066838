import { FormikHelpers, FormikValues } from 'formik';
import i18next from 'i18next';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { FormFieldPhone, Form, Alert } from '../../../../components';
import { phoneFormatter, translates } from '../../../../utilities';
import { useApi } from '../../../../hooks/useApi';
import { ConfigContext } from '../../../../contexts';
import ArrowRight from '../../../../assets/images/arrow-right.svg';
import { CreateOtpResponseModel } from '../../../../types/api';
import { PhoneStateModel } from './phoneStateModel';
import { routerPaths } from '../../../../configs';

interface LoginByPhoneProps {
    handlePhoneState: (phoneState: PhoneStateModel) => void;
    phoneState: PhoneStateModel | null;
}

interface PhoneSubmitModel {
    phone: string;
    phoneCode: string;
}

export const LoginByPhone: FC<LoginByPhoneProps> = ({ phoneState, handlePhoneState }) => {
    const fetchApi = useApi();
    const [error, setError] = useState('');
    const { country, language, contactInfo } = useContext(ConfigContext);
    const { phone } = phoneState || contactInfo || {};
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => () => { isMounted.current = false; }, []);

    const submit = async (values: PhoneSubmitModel, helpers: FormikHelpers<FormikValues>) => {
        try {
            const response: CreateOtpResponseModel = await fetchApi({
                url: '/otp',
                data: {
                    phone: values.phoneCode + values.phone,
                    country,
                    language
                },
                method: 'POST'
            });

            if (response.expirationInSeconds) {
                handlePhoneState({
                    phone: values.phoneCode + values.phone,
                    expirationInSeconds: response.expirationInSeconds,
                });
                navigate(routerPaths.smsConfirmation.route, { state: {
                    phone: values.phoneCode + values.phone,
                    expirationInSeconds: response.expirationInSeconds,
                    prevLocation: location.pathname,
                    postPublicOrderCreation: false,
                } });
            }
        } catch (error: any) {
            setError(error?.errorMessage);
        } finally {
            if (isMounted.current) {
                helpers.setSubmitting(false);
            }
        }
    };

    const phoneCode = phoneFormatter(phone, country);

    const initialValues = {
        phone: phone ? phone.replace(phoneCode, '') : '',
        phoneCode,
    };

    const schema = Yup.object().shape({
        phoneCode: Yup.string(),
        phone: Yup.string()
            .required(translates.FormsErrorsRequired)
            .phone(translates.FormsErrorsPhoneNumberInvalid),
    });

    return (
        <>
            <Form
                submit={submit}
                submitText={i18next.t(translates.LoginButtonsContinue)}
                schema={schema}
                values={initialValues}
                iconRight={ArrowRight}
            >
                <FormFieldPhone
                    name="phone"
                    namePrefix="phoneCode"
                    label={i18next.t(translates.RegisterLabelsPhone)}
                    size="large"
                />
                <Alert
                    content={i18next.t(error)}
                    visible={!!error}
                />
            </Form>
        </>
    );
};
