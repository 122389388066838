import { FC, CSSProperties } from 'react';
import styled from 'styled-components';
import EmailImage from '../../assets/images/success-email.svg';
import FailedCardImage from '../../assets/images/failed-card.svg';

type CardImages = 'email' | 'failedCard';

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

type GetImageProps = (type: CardImages) => { src: string, alt: string };

const getImageProps: GetImageProps = (type) => {
    switch (type) {
    case 'email':
        return {
            src: EmailImage,
            alt: 'Email icon',
        };
    case 'failedCard':
        return {
            src: FailedCardImage,
            alt: 'Failed payment icon',
        };
    default:
        return {
            src: '',
            alt: 'Default'
        };
    }
};

interface CardImageProps {
    type: CardImages
    style?: CSSProperties
    imgStyle?: CSSProperties
}

export const CardImage: FC<CardImageProps> = ({ type, style, imgStyle }) => {
    const imgProps = getImageProps(type);
    return (
        <ImageContainer style={style}>
            <img src={imgProps.src} alt={imgProps.alt} style={imgStyle} />
        </ImageContainer>
    );
};
