import i18next from 'i18next';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { OrderModel, OrderSelfModel } from '../../../../types/api';
import { CanceledOrderStatuses, ConstantsOrderStatus, formatCountdown, translates } from '../../../../utilities';

interface HeaderProps {
    type: 'dark' | 'light'
}

const Header = styled.div<HeaderProps>`
  background: ${({ type, theme }) => type === 'dark' ? theme.colors.greenLight : theme.colors.grey8};
  color: ${({ type, theme }) => type === 'dark' ? theme.colors.primary : theme.colors.black};
  padding: 10px 32px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.query.small} {
    padding: 10px 16px;
    flex-direction: column;
    justify-content: center;
  }
`;

const HeaderTitle = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
`;

const HeaderBadge = styled.span`
  font-size: 0.875rem;

  @media ${({ theme }) => theme.query.small} {
    margin-top: 5px;
    width: 100%;
    text-align: center;
  }
`;

interface OrderCardHeaderProps {
    order: OrderModel & OrderSelfModel;
}

export const OrderCardHeader: FC<OrderCardHeaderProps> = ({ order }) => {
    const [timeLeft, setTimeLeft] = useState(formatCountdown(order.biddingDeadline!));
    const intervalId = useRef<number>();

    useEffect(() => {
        if (order.statusLabel !== ConstantsOrderStatus.Created) {
            window.clearInterval(intervalId.current);
            return undefined;
        }

        intervalId.current = window.setInterval(() => {
            setTimeLeft(formatCountdown(order.biddingDeadline!));
        }, 500);

        return () => {
            window.clearInterval(intervalId.current);
        };
    }, [order.statusLabel, order?.biddingDeadline]);

    const bidCount = useMemo(() => {
        return order?.bids?.length ?? order?.bidCount ?? 0;
    }, [order]);

    const showHeaderBadge =
        [ConstantsOrderStatus.AwaitingApproval, ConstantsOrderStatus.Created].includes(order.statusLabel!) &&
        bidCount > 0 &&
        Boolean(timeLeft);

    return (
        <Header
            type={CanceledOrderStatuses.includes(order.statusLabel!) ? 'light' : 'dark'}
        >
            <HeaderTitle>
                {order.statusLabel === ConstantsOrderStatus.Cancelled ? (
                    i18next.t(order.cancelReason ?? order.statusLabel!)
                ) : (
                    i18next.t(order.statusLabel!)
                )}
            </HeaderTitle>

            {showHeaderBadge && (
                <HeaderBadge>
                    {i18next.t(translates.OrderCardHeaderLabelsOffersCount, { count: bidCount ?? 0 })}
                </HeaderBadge>
            )}
        </Header>
    );
};
