import { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Text } from '../../text';

const popIn = keyframes`
    from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.4);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
`;

interface IndicatorProps {
    $selected: boolean;
    $disabled?: boolean;
}

const Indicator = styled.div<IndicatorProps>`
    width: 15px;
    height: 15px;
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.grey2};
    position: relative;

    &::after {
        position: absolute;
        display: none;
        content: '';
    }

    ${({ $selected, $disabled, theme }) => $selected && css`
            &::after {
                display: block;
                margin: 0;
                background-color: ${$disabled ? theme.colors.dark : theme.colors.success};
                width: 0.6em;
                height: 0.6em;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 1rem;
                animation-name: ${popIn};
                animation-duration: 0.3s;
            }
        `}
`;

const LabelButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    margin-right: ${({ theme }) => theme.defaults.margin};
`;

const IndicatorContainer = styled.div`
    margin-right: ${({ theme }) => theme.defaults.marginQuarter};
`;

const Label = styled(Text).attrs({ type: 'body2', color: 'dark', cursor: 'pointer' })``;

export interface RadioCheckboxProps {
    onClick: () => void;
    selected: boolean;
    disabled?: boolean;
    label?: string;
}

export const RadioCheckbox: FC<RadioCheckboxProps> = ({ label, selected, disabled, onClick }) => {
    return (
        <LabelButtonContainer onClick={onClick}>
            <IndicatorContainer>
                <Indicator $selected={selected} $disabled={disabled} />
            </IndicatorContainer>
            <Label>{label || ''}</Label>
        </LabelButtonContainer>
    );
};
