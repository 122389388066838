import { FC, ReactNode } from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

import { FieldTitle } from './title';
import { FieldError } from './error';

interface FieldWrapperProps {
    children: ReactNode;
    filled?: boolean;
    group?: boolean;
    label: string | JSX.Element | undefined;
    name: string;
    submitting?: boolean;
    focused?: boolean;
    style?: any;
}

export const FieldWrapper: FC<FieldWrapperProps> = ({
    children,
    filled,
    group,
    label,
    name,
    submitting,
    focused,
    ...props
}) => {
    const [field, meta] = useField(name);

    const className = classNames({
        form_item_wrapper: true,
        form_item_filled: !!field.value || filled,
        form_item_focused: !!focused,
        form_item_error: meta.touched && meta.error,
        form_item_group: !!group,
        'ant-form-item-has-error': meta.error && meta.touched,
    });

    return (
        <div className={className} id={`form-item-${name}`} {...props}>
            <FieldTitle label={label} focused={focused} />
            {children}
            <FieldError name={name} />
        </div>
    );
};
