import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { FormikHelpers } from 'formik';
import i18next from 'i18next';
import { useState, useContext, useRef, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { serialize } from 'object-to-formdata';
import { Alert, Button, Card, ContentLoading, Form, ProgressLine, Title } from '../../../components';
import {
    getCurrentSchema,
    getMobileSubmitButtonText,
    getSubmitButtonText,
    getTitleText,
    isOverflowEnabled as isOverflowDisabled,
    OrderSteps,
    CreateOrderFormModel,
    createDeadline,
    isStandardBiddingOnly,
    ExpectedTimeEnum,
    getDefaultWorkType,
    getQueryServiceItem,
    getQueryIsServiceAvailable,
} from './utilities';
import ArrowRight from '../../../assets/images/arrow-right.svg';
import ArrowLeft from '../../../assets/images/arrow-left-16.svg';
import { translates, phoneFormatter, transformFormErrors, BiddingTypes, LOCAL_STORAGE_KEYS, WorkTypes, ServiceReceiverTypes, ContactPersonTypes } from '../../../utilities';
import { DetailsStep, ServiceStep, SummaryStep, JobStep, InformationStep } from './steps';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { AuthContext, ConfigContext, OrderingContext } from '../../../contexts';
import { ServiceModel, CreateOtpResponseModel } from '../../../types/api';
import { routerPaths } from '../../../configs';
import { useUrlQuery } from '../../../hooks/useUrlQuery';
import { getCountryCoordinates, getDefaultBiddingType } from '../../../utilities/countryDefaults';
import * as apiResponses from '../../../types/apigw';
import { logEvent, purchaseData } from '../../../utilities/analytics';
import { useGetServiceGroups, useGetServices, useGetImages, usePostOrder, useGetServiceItem, useIsServiceAvailableById } from './queries';
import { useClearSearchParams } from '../../../hooks/useClearSearchParams';
import { useApi } from '../../../hooks/useApi';

const GlobalComponent = createGlobalStyle`
    body {
        @media ${({ theme }) => (theme as any).query.small} {
            background-color: ${({ theme }) => (theme as any).colors.white};
        }
    }
`;

const CardStyled = styled(Card)`
    padding: 1.5rem;
    margin-top: 1rem;

    @media ${({ theme }) => theme.query.medium} {
        padding: 1rem;
    }

    @media ${({ theme }) => theme.query.small} {
        margin: 0 -1rem -1.5rem;
        border-radius: 0;
    }
`;

const TitleContainer = styled.div`
    background: ${({ theme }) => theme.colors.grey12};
    margin: -1.5rem -1.5rem 1rem;
    padding: 1rem 1.5rem 0;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    @media ${({ theme }) => theme.query.medium} {
        margin: -1rem -1rem 1rem;
        padding: 1rem 1rem 0;
    }

    @media ${({ theme }) => theme.query.small} {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
`;

const TitleStyled = styled(Title)`
    margin-bottom: 1rem;
    display: inline;
`;

const FormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 164px);

    @media ${({ theme }) => theme.query.medium} {
        height: calc(100vh - 156px);
    }

    @media ${({ theme }) => theme.query.small} {
        height: initial;
    }
`;

const StepFooter = styled.div`
    @media ${({ theme }) => theme.query.small} {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 1rem;
        padding-top: 0;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.white};
        z-index: 999;
    }
`;

interface StepContainerProps {
    $isOverflowDisabled: boolean;
    ref: any;
}

const StepContainer = styled.div<StepContainerProps>`
    background: ${({ theme }) => theme.colors.white};
    flex-grow: 1;

    ${({ $isOverflowDisabled }) =>
        $isOverflowDisabled &&
        `
        overflow: auto;
        overflow-x: hidden;
    `}

    @media ${({ theme }) => theme.query.small} {
        overflow: visible;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ButtonSubmitStyled = styled(Button)`
    margin-left: auto;
    width: 33%;

    @media ${({ theme }) => theme.query.medium} {
        width: 50%;
    }
`;

const ProgressLineContainer = styled.div`
    margin: 1rem -1.5rem;

    @media ${({ theme }) => theme.query.medium} {
        margin: 1rem -1rem;
    }

    @media ${({ theme }) => theme.query.small} {
        margin-top: 0;
    }
`;

const SubtitleStyled = styled.h1`
    float: right;
    display: inline;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
`;

const isFirstStep = (step: OrderSteps) => {
    return step === OrderSteps.ServiceStep;
};

const isLastStep = (step: OrderSteps) => {
    return step === OrderSteps.SummaryStep;
};

export const CreateOrderContainer = () => {
    const [error, setError] = useState('');
    const [isStepValid, setIsStepValid] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loadingAssignedPartner, setLoadingAssignedPartner] = useState(false);
    const { state } = useLocation();
    const location = useLocation();
    const [currentStep, setCurrentStep] = useState(state?.orderStep || OrderSteps.ServiceStep);
    const { auth } = useContext(AuthContext);
    const { country, language, setLanguage } = useContext(ConfigContext);
    const prevLang = useRef(language);
    const windowSize = useWindowSize();
    const { setOrderId } = useContext(OrderingContext);
    const navigate = useNavigate();
    const query = useUrlQuery();
    const isMounted = useRef(true);
    const [initialValues, setInitialValues] = useState<CreateOrderFormModel>();
    const biddingDeadline = moment().add(30, 'days').toISOString();
    const stepContainerRef = useRef<HTMLDivElement>();
    const email = query.email as string || auth?.email || '';
    const phoneCode = phoneFormatter(query.phone as string || auth?.phone, country);
    const phone = (query.phone as string || auth?.phone || '').replace(phoneCode, '');
    const { services, isServicesLoading } = useGetServices();
    const { servicesGroups, isServiceGroupsLoading, isInitialServiceGroupsLoading, getServicesGroups } = useGetServiceGroups();
    const { images } = useGetImages();
    const postOrder = usePostOrder();
    const getServiceItem = useGetServiceItem();
    const getIsServiceAvailable = useIsServiceAvailableById();
    const clearSearchParams = useClearSearchParams();
    const fetchApi = useApi();

    useEffect(() => {
        // Fixes scroll to top for mobile
        window.scrollTo(0, 0);

        if (stepContainerRef.current) {
            stepContainerRef.current.scrollTop = 0;
        }
    }, [currentStep]);

    const resolveDefaultService = (
        servicesGroups: apiResponses.ServiceGroupModel[],
        services: ServiceModel[],
        serviceId: string
    ) => {
        const service = services.find((s) => s.id === parseInt(serviceId as string, 10));
        const groupCode = service?.group?.split('.').pop();
        const deaultService = servicesGroups.find((s) => s.serviceGroupCode === groupCode);
        return deaultService?.defaultServiceId.toString() || '';
    };

    const initialize = async () => {
        logEvent('load-multi-step-step1');
        if (!auth && !!query.language) {
            setLanguage(query.language as string);
        }
        const serviceId = resolveDefaultService(servicesGroups, services, query.serviceId as string || '');
        const workType = getDefaultWorkType(country, auth?.isCompany, query);
        const description = query.description as string || '';
        let biddingType = getDefaultBiddingType(country);
        const standardBiddingOnly = isStandardBiddingOnly(country);
        const address = query.address as string || '';
        const latitude = query.latitude ? parseFloat(query.latitude as string) : getCountryCoordinates(country).lat;
        const longitude = query.longitude ? parseFloat(query.longitude as string) : getCountryCoordinates(country).lng;
        const additionalInformation = query.additionalInformation as string || '';
        const id = serviceId || query.serviceId || '';
        const deadline = createDeadline(1, 'month');
        const serviceItems = await getQueryServiceItem(query, getServiceItem);
        const isQueryServiceAvailable = await getQueryIsServiceAvailable(query, getIsServiceAvailable);
        const expectedTime = ExpectedTimeEnum.Anytime;
        const serviceReceiver = auth?.isCompany ? ServiceReceiverTypes.SELECTED : ServiceReceiverTypes.USER;
        const companyName = '';
        const contactPerson = auth?.isCompany ? ContactPersonTypes.SELECTED : ContactPersonTypes.USER;
        const contactTelephone = '';
        const contactEmail = '';

        if (query.serviceId) {
            if (!isQueryServiceAvailable) {
                clearSearchParams(['serviceId', 'itemId', 'quantity']);
                setCurrentStep(OrderSteps.ServiceStep);
            } else if (query.itemId && query.quantity) {
                biddingType = BiddingTypes.fixed;

                if (serviceItems === undefined) {
                    clearSearchParams(['serviceId', 'itemId', 'quantity']);
                    setCurrentStep(OrderSteps.JobsStep);
                } else {
                    setCurrentStep(OrderSteps.InformationStep);
                }
            } else {
                setCurrentStep(isStandardBiddingOnly(country) ? OrderSteps.InformationStep : OrderSteps.JobsStep);
            }
        }

        if (state?.submitedValues) {
            setInitialValues(state.submitedValues);
        } else {
            setInitialValues({
                serviceId: !isQueryServiceAvailable ? '' : id as string,
                search: '',
                workType,
                deadline,
                description,
                biddingType,
                address,
                latitude,
                longitude,
                additionalInformation,
                email,
                phoneCode,
                phone,
                images,
                partnerId: null,
                standardBiddingOnly,
                serviceItems,
                expectedTime,
                contractTerms: false,
                serviceReceiver,
                companyName,
                shouldSendOrderTrackingUrlBySms: true,
                shouldSendOrderTrackingUrlByEmail: false,
                receiveMaterialsFromPartner: false,
                contactPerson,
                contactTelephone,
                contactEmail,
                isPartnerSelectedFromFavorites: false,
            });
        }
    };

    useEffect(() => {
        initialize();
        getServicesGroups('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (prevLang.current === language) {
            return;
        }
        initialize();
        getServicesGroups('');

        prevLang.current = language;
    }, [prevLang, language]);

    const getTimezoneOffsetInMinutes = () => {
        return new Date().getTimezoneOffset() * -1;
    };

    const mutateSubmitValues = (values: CreateOrderFormModel) => {
        const copy = { ...values };
        copy.description = copy.fixedDescription || copy.description;
        delete copy.fixedDescription;
        delete copy.countryVat;
        delete copy.fullPrice;
        delete copy.expectedTime;
        delete copy.companyName;

        if (copy.serviceReceiver === ServiceReceiverTypes.USER) {
            delete copy.addressBookItemId;
        }
        delete copy.serviceReceiver;

        if (copy.contactPerson === ContactPersonTypes.USER) {
            copy.contactTelephone = auth?.phone || copy.phoneCode + copy.phone;
        }
        delete copy.contactPerson;
        delete copy.serviceReceiverContacts;
        return copy;
    };

    const submitWithAuth = async (values: CreateOrderFormModel, helpers: FormikHelpers<CreateOrderFormModel>) => {
        try {
            const response: CreateOtpResponseModel = await fetchApi({
                url: '/otp',
                data: {
                    phone: values.phoneCode + values.phone,
                    country,
                    language
                },
                method: 'POST'
            });

            if (response.expirationInSeconds) {
                navigate(routerPaths.smsConfirmation.route, { state: {
                    phone: values.phoneCode + values.phone,
                    expirationInSeconds: response.expirationInSeconds,
                    submitedValues: values,
                    prevLocation: location.pathname,
                    postPublicOrderCreation: true,
                } });
            }
        } catch (error: any) {
            setError(error?.errorMessage);
        } finally {
            if (isMounted.current) {
                helpers.setSubmitting(false);
            }
        }
    };

    const submitForm = async (values: CreateOrderFormModel, helpers: FormikHelpers<CreateOrderFormModel>) => {
        try {
            const form = { ...mutateSubmitValues(values),
                phone: values.phoneCode + values.phone,
                country,
                biddingDeadline,
                timezoneOffsetInMinutes: getTimezoneOffsetInMinutes(),
                shouldSendOrderTrackingUrlBySms: values.contactPerson === ContactPersonTypes.SELECTED && !!values.companyName
                    ? values.shouldSendOrderTrackingUrlBySms
                    : false,
                shouldSendOrderTrackingUrlByEmail: values.contactPerson === ContactPersonTypes.SELECTED && !!values.companyName
                    ? values.shouldSendOrderTrackingUrlByEmail
                    : false,
                shouldDefinePrice:
                    values.workType === WorkTypes.NON_STANDARD
                    && values.biddingType === BiddingTypes.fixed, };

            setIsSubmitting(true);
            const data = serialize(form);
            const response = await postOrder.mutateAsync(data as any);

            logEvent('pre-order-step-step5', {
                orderId: response.orderId,
            });
            logEvent('purchase', purchaseData({ ...form, orderId: response.orderId }));
            if (values.partnerId) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.AUTO_BID, 'true');
            }

            if (!auth) {
                setOrderId(response.orderId!);
                await submitWithAuth(values, helpers);
            } else {
                navigate(routerPaths.orderSuccessPartner.route, { replace: true });
            }
        } catch (error: any) {
            setError(error?.errorMessage);
            helpers.setErrors(transformFormErrors(error?.errors));
        } finally {
            if (isMounted) {
                setIsSubmitting(false);
                helpers.setSubmitting(false);
            }
        }
    };

    const getStepSize = (currentStep: OrderSteps, isBack?: boolean) => {
        const skipJobStep = isStandardBiddingOnly(country) ? 2 : 1;
        switch (currentStep) {
        case OrderSteps.DetailsStep: {
            if (!isBack) {
                return 1;
            }

            return skipJobStep;
        }
        case OrderSteps.ServiceStep: {
            return skipJobStep;
        }
        default:
            return 1;
        }
    };

    const goForward = () => {
        setCurrentStep(currentStep + getStepSize(currentStep));
    };

    const submit = (values: CreateOrderFormModel, helpers: FormikHelpers<CreateOrderFormModel>) => {
        if (isLastStep(currentStep)) {
            submitForm(values, helpers);
            return;
        }
        goForward();
        helpers.setTouched({});
        helpers.setSubmitting(false);
    };

    const goBack = () => {
        setIsStepValid(true);
        setCurrentStep(currentStep - getStepSize(currentStep, true));
        clearSearchParams(['serviceId', 'itemId', 'quantity']);
    };

    const renderStep = () => {
        switch (currentStep) {
        case OrderSteps.ServiceStep:
            return <ServiceStep goForward={goForward} servicesGroups={servicesGroups} onSearch={getServicesGroups} isLoading={isServiceGroupsLoading} />;
        case OrderSteps.JobsStep:
            return <JobStep servicesGroups={servicesGroups} />;
        case OrderSteps.InformationStep:
            return <InformationStep servicesGroups={servicesGroups} />;
        case OrderSteps.DetailsStep:
            return <DetailsStep onValidationChange={setIsStepValid} />;
        case OrderSteps.SummaryStep:
            return (
                <SummaryStep servicesGroups={servicesGroups} />
            );
        default:
            return <></>;
        }
    };

    return (
        <CardStyled size={'medium'}>
            <GlobalComponent />
            {(isServicesLoading || isInitialServiceGroupsLoading || !initialValues) ? <ContentLoading /> : (
                <FormStyled
                    key="order-creation-form"
                    values={initialValues}
                    schema={getCurrentSchema(currentStep, Boolean(auth?.clientId))}
                    submit={submit}
                    validateOnMount={true}
                    validateOnChange={true}
                    disableEnterSubmit={currentStep === OrderSteps.ServiceStep}
                >
                    <TitleContainer>
                        <SubtitleStyled>{`${currentStep.toString()}/${OrderSteps.SummaryStep.toString()}`}</SubtitleStyled>
                        <TitleStyled
                            type="inner-page"
                            titleText={i18next.t(getTitleText(currentStep).titleText)}
                        />
                    </TitleContainer>

                    {/* overflow needs to be disabled for steps that take up more height than one screen */}
                    <StepContainer ref={stepContainerRef} $isOverflowDisabled={isOverflowDisabled(currentStep)}>{renderStep()}</StepContainer>

                    <Alert content={i18next.t(error)} visible={!!error} />

                    <StepFooter>
                        <ProgressLineContainer>
                            <ProgressLine completed={(currentStep / OrderSteps.SummaryStep) * 100} />
                        </ProgressLineContainer>

                        <ButtonsContainer>
                            {!isFirstStep(currentStep) && (
                                <Button
                                    iconLeft={ArrowLeft}
                                    styleType="text-secondary"
                                    title={i18next.t(translates.OrderStepsButtonsBack)}
                                    onClick={goBack}
                                />
                            )}
                            <ButtonSubmitStyled
                                iconRight={!isLastStep(currentStep) ? ArrowRight : ''}
                                type="submit"
                                styleType="primary"
                                title={i18next.t(
                                    windowSize.width! > 768
                                        ? getSubmitButtonText(currentStep)
                                        : getMobileSubmitButtonText(currentStep)
                                )}
                                loading={isSubmitting || loadingAssignedPartner}
                                disabled={!isStepValid}
                            />
                        </ButtonsContainer>
                    </StepFooter>
                </FormStyled>
            )}
        </CardStyled>
    );
};
