import { FC, useContext, useState } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button } from '../..';
import { translates, validateUserData } from '../../../utilities';
import { ReactComponent as StarFilledIcon } from '../../../assets/images/star-filled-icon.svg';
import { FavoritePartnerModelService } from '../../../types/api';
import { IncompleteProfileModal } from '../../../pages';
import { AuthContext, ConfigContext } from '../../../contexts';
import PlusIcon from '../../../assets/images/plus-icon-white.svg';
import { routerPaths } from '../../../configs';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;

const StarFilledIconStyled = styled(StarFilledIcon)`
    margin-right: 7px;
    width: 24px;
    height: 24px;
`;

const PartnerName = styled.span`
    font-size: 0.9375rem;
    font-weight: 500;
`;

const ServicesLabel = styled.span`
    display: block;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey6};
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const PlaceholderLabel = styled.span`
    text-align: center;
    margin-top:auto
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

const ButtonStyled = styled(Button)`
    margin-top: 1.5rem;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
`;

interface BottomContentProps {
    asPlaceholder?: boolean;
    services?: FavoritePartnerModelService[] | null;
    isAlreadyInvited?: boolean;
    onInvite(): void;
    onDelete(): void;
    onDismiss?(): void;
}

const BottomContent: FC<BottomContentProps> = ({ asPlaceholder, services, isAlreadyInvited, onDelete, onInvite, onDismiss }) => {
    const { auth } = useContext(AuthContext);
    const {
        country,
    }: {
        country: string;
    } = useContext(ConfigContext);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const navigate = useNavigate();

    const openModal = async () => {
        try {
            if (auth && auth.clientId && !auth.phone) {
                navigate(routerPaths.profileEdit.route);
                return;
            }
            if (auth?.email || auth?.phone) {
                await validateUserData(auth!, country);
            }
            navigate(routerPaths.createOrder.route);
        } catch (error) {
            setShowProfileModal(true);
        }
    };

    const formatServiceLabel = (serviceName?: string | null, serviceRegions?: string[] | null) => {
        let fullString = '';
        if (serviceName) {
            fullString += serviceName;
        }
        if (serviceRegions) {
            fullString += ` (${serviceRegions?.join(', ')})`;
        }

        return fullString;
    };

    return (
        <BottomContainer>
            {asPlaceholder && <PlaceholderLabel>{i18next.t(translates.InvitePartnerCardPlaceholderText)}</PlaceholderLabel>}
            {!asPlaceholder && services?.slice(0, 5).map((item) => (
                <ServicesLabel key={item.name}>{formatServiceLabel(item.name, item.regions)}</ServicesLabel>
            ))}
            {(asPlaceholder || onDismiss) ? (
                <>
                    {!isAlreadyInvited && (
                        <ButtonStyled title={i18next.t(translates.InvitePartnerCardInviteButton)} styleType="primary" onClick={onInvite} />
                    )}
                    {onDismiss && (
                        <ButtonStyled title={i18next.t(translates.InvitePartnerCardDismissButton)} styleType="text-danger" onClick={onDismiss} />
                    )}
                </>
            ) : (
                <ButtonsContainer>
                    <ButtonStyled
                        iconLeft={PlusIcon}
                        styleType="primary"
                        title={i18next.t(translates.MenuButtonsCreateOrder)}
                        onClick={openModal}
                    />
                    <ButtonStyled title={i18next.t(translates.InvitePartnerCardDeleteButton)} styleType="text-primary" onClick={onDelete} />
                    <IncompleteProfileModal showModal={showProfileModal} setShowModal={setShowProfileModal} />
                </ButtonsContainer>
            )}
        </BottomContainer>
    );
};

interface FavoritePartnerCardContentProps {
    name?: string;
    asPlaceholder?: boolean;
    services?: FavoritePartnerModelService[] | null;
    isAlreadyInvited?: boolean;
    onInvitePartner(): void;
    onDeletePartner(): void;
    onDismissPartner?(): void;
}

export const FavoritePartnerCardContent: FC<FavoritePartnerCardContentProps> = ({
    name,
    asPlaceholder,
    services,
    isAlreadyInvited,
    onInvitePartner,
    onDeletePartner,
    onDismissPartner,
    ...props
}) => {
    return (
        <Container {...props}>
            {Boolean(name) && (
                <Row>
                    <StarFilledIconStyled />
                    <PartnerName>
                        {name}
                    </PartnerName>
                </Row>
            )}
            <BottomContent
                asPlaceholder={asPlaceholder}
                onInvite={onInvitePartner}
                onDelete={onDeletePartner}
                onDismiss={onDismissPartner}
                services={services}
            />
        </Container>
    );
};
