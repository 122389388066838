import { useContext, FC, useEffect, useMemo } from 'react';
import { useField } from 'formik';

import { ConfigContext, AuthContext } from '../../../../contexts';
import { PartnerModel } from '../../../../types/api';
import { BiddingTypes, WorkTypes } from '../../../../utilities';
import { ContentLoading } from '../../../../components';
import { DescriptionField, WorkTypeSelect } from '../components';
import { ServiceGroupModel } from '../../../../types/apigw';
import { getDefaultBiddingTypeForCompany, getPriceListEnabled } from '../../../../utilities/countryDefaults';
import { JobList } from '../components/JobList';
import { logEvent } from '../../../../utilities/analytics';
import { useGetServiceItems } from '../queries';
import { useUrlQuery } from '../../../../hooks/useUrlQuery';

interface NonStandardWorkContentProps {
    servicesGroups: ServiceGroupModel[];
}

const NonStandardWorkContent: FC<NonStandardWorkContentProps> = ({ servicesGroups }) => {
    return <DescriptionField tooltip servicesGroups={servicesGroups} />;
};

interface JobStepProps {
    servicesGroups: ServiceGroupModel[];
    availablePartners?: PartnerModel[];
}

export const JobStep: FC<JobStepProps> = ({ servicesGroups }) => {
    const [{ value: serviceIdValue }] = useField('serviceId');
    const [{ value: workTypeValue }, wt2, { setValue: setWorkType }] = useField('workType');
    const [cv1, cv2, { setValue: setCountryVat }] = useField('countryVat');
    const [bt1, bt2, { setValue: setBiddingType }] = useField('biddingType');
    const [si1, si2, { setValue: setServiceItems }] = useField('serviceItems');
    const { country, language } = useContext(ConfigContext);
    const { auth } = useContext(AuthContext);
    const { isServiceItemsLoading, data } = useGetServiceItems(serviceIdValue, language);
    const query = useUrlQuery();

    useEffect(() => {
        if (servicesGroups) {
            logEvent('job-step-step2', {
                serviceGroup:
                    servicesGroups.find((service) => service.defaultServiceId === serviceIdValue)?.serviceGroupCode ||
                    'SER',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCountryVat(data.countryVat);
    }, [data?.countryVat]);

    useEffect(() => {
        switch (workTypeValue) {
        case WorkTypes.STANDARD:
            setBiddingType(BiddingTypes.fixed);
            break;
        default:
            setServiceItems({});
            setBiddingType(getDefaultBiddingTypeForCompany(country, !!auth?.isCompany));
        }
    }, [workTypeValue, country]);

    const serviceItems = useMemo(() => {
        if (query.itemId && data.serviceItems) {
            return data.serviceItems.filter((items) => items.id === query.itemId);
        }

        return data.serviceItems ?? [];
    }, [data.serviceItems, query]);

    useEffect(() => {
        if (!isServiceItemsLoading && !serviceItems.length && workTypeValue !== WorkTypes.NON_STANDARD) {
            setWorkType(WorkTypes.NON_STANDARD);
        }
    }, [isServiceItemsLoading, serviceItems, workTypeValue, setWorkType]);

    const renderContent = useMemo(() => {
        switch (workTypeValue) {
        case WorkTypes.STANDARD:
            return <JobList serviceJobs={serviceItems} />;
        default:
            return <NonStandardWorkContent servicesGroups={servicesGroups} />;
        }
    }, [workTypeValue, serviceItems, servicesGroups]);

    const isPriceListEnabled = getPriceListEnabled(country);

    if (isServiceItemsLoading) return <ContentLoading />;

    return (
        <>
            {renderContent}
            {isPriceListEnabled && serviceItems.length > 0 && <WorkTypeSelect />}
        </>
    );
};
