import { toast as toastify, Slide } from 'react-toastify';

const showErrorToast = (text: string) => {
    toastify.error(text, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
    });
};

export const Toast = {
    error: showErrorToast,
};
