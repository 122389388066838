import { FC, useContext, useEffect } from 'react';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import useRoutingInstrumentation from 'react-router-v6-instrumentation';
import { Helmet } from 'react-helmet';
import { AppRouter } from './routes';
import Theme from './theme';
import { features } from './utilities/features';
import {
    getCountry,
    getCountryApiUrl,
    getCountryNotificationsWsUrl,
    getCountryNotificationsUrl,
} from './utilities/countryDefaults';
import { initialize as initializeSentry } from './utilities/sentry';
import { ConfigContext } from './contexts';

const App: FC = () => {
    const routingInstrumentation = useRoutingInstrumentation();
    const country = getCountry();
    const apiUrl = getCountryApiUrl(country);
    const notificationsUrl = getCountryNotificationsUrl(country);
    const notificationsWsUrl = getCountryNotificationsWsUrl(country);
    const { language } = useContext(ConfigContext);

    useEffect(() => {
        initializeSentry(routingInstrumentation);
    }, [routingInstrumentation]);

    useEffect(() => {
        const logoutEvent = (event: StorageEvent) => {
            if (event.key === 'logout') {
                window.location.reload();
                localStorage.removeItem('logout');
            }
        };

        window.addEventListener('storage', logoutEvent);

        return () => {
            window.removeEventListener('storage', logoutEvent);
        };
    }, []);

    return (
        <FeatureToggles features={features}>
            <>
                <Helmet>
                    <meta
                        httpEquiv="Content-Security-Policy"
                        content={`
                            default-src 'self' 'unsafe-inline' data: blob: ${window.ENVIRONTMENT_VARIABLES.REACT_APP_CSP_UNSAFE_INLINE}
                            ${window.ENVIRONTMENT_VARIABLES.REACT_APP_API_LOCALIZATION_URL} ${apiUrl} https://*.portalpro.lt https://*.portalpro.ru
                            ${notificationsUrl} ${notificationsWsUrl}
                            https://*.intercomcdn.com https://*.intercomassets.com wss://*.intercom.io https://*.intercom.io https://intercom-sheets.com
                            https://*.hs-scripts.com https://*.hs-banner.com https://*.hs-analytics.net https://*.usemessages.com https://*.hscollectedforms.net
                            https://*.hubspot.com https://*.hsforms.com
                            https://*.gstatic.com
                            https://*.cookiebot.com
                            https://www.google-analytics.com 
                            https://region1.google-analytics.com 
                            https://www.google.lt https://www.googletagmanager.com https://*.googleapis.com https://www.google.com https://www.googleadservices.com
                            https://optimize.google.com https://tagmanager.google.com
                            https://*.hotjar.com https://*.hotjar.io wss://*.hotjar.com
                            https://*.doubleclick.net
                            https://*.facebook.net https://www.facebook.com https://*.atdmt.com https://accounts.google.com/o/oauth2
                            https://*.sentry.io ${window.ENVIRONTMENT_VARIABLES.REACT_APP_API_GW_URL}
                        `}
                    />
                    <script
                        src={`https://maps.googleapis.com/maps/api/js?key=${
                            window.ENVIRONTMENT_VARIABLES.REACT_APP_GOOGLE_API_KEY
                        }&libraries=places&hl=${language.toLowerCase()}&language=${language.toLowerCase()}&callback=initMap`}></script>
                </Helmet>

                <Theme>
                    <AppRouter />
                </Theme>
            </>
        </FeatureToggles>
    );
};

export default App;
