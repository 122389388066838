import { FC } from 'react';
import { useField, useFormikContext } from 'formik';

import { FieldWrapper } from './components/wrapper';
import { Checkbox } from '../checkbox';

interface FormFieldCheckboxProps {
    label?: string;
    labelCheckbox: string | JSX.Element;
    clickableLabel?: string;
    name: string;
    value?: boolean;
    onChange?: () => void;
    onClickLabel?: () => void;
    disabled?: boolean;
}

export const FormFieldCheckbox: FC<FormFieldCheckboxProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const { isSubmitting } = useFormikContext();

    return (
        <FieldWrapper name={props.name} label={props.label}>
            <Checkbox
                name={props.name}
                label={props.labelCheckbox}
                clickableLabel={props.clickableLabel}
                value={field.value}
                onChange={(value) => helpers.setValue(value)}
                onClickLabel={props.onClickLabel}
                disabled={props.disabled || isSubmitting}
            />
        </FieldWrapper>
    );
};
