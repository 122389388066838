import { FC } from 'react';
import { ReactComponent as DefaultPartnerIcon } from '../../assets/images/partner-icon.svg';

interface PartnerIconProps {
    partnerPictureUrl?: string | null
    style?: React.CSSProperties
}

export const PartnerIcon: FC<PartnerIconProps> = ({
    partnerPictureUrl,
    ...props
}) => {
    const icon = partnerPictureUrl
        ? <img src={partnerPictureUrl} alt="partner" {...props}></img>
        : <DefaultPartnerIcon {...props}/>;

    return (
        icon
    );
};
