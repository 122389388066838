import { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import OtpInput from 'react18-input-otp';
import { theme } from '../../theme';

interface PinCodeInputProps {
    autoComplete?: string;
    length: number;
    onComplete: (value: string, index?: number) => void;
    autoFocus?: boolean;
    disabled?: boolean;
    onClear?: Function;
    signalToClearInput?: number;
}

interface PinCodeInputRef {
    setCode: (code: string) => void;
}

export const PinCodeInput = forwardRef<PinCodeInputRef, PinCodeInputProps>(({ autoComplete, length, autoFocus, onComplete, disabled, signalToClearInput }, ref) => {
    const [code, setCode] = useState('');

    useEffect(() => {
        if (signalToClearInput) {
            setCode('');
        }
    }, [signalToClearInput]);

    useEffect(() => {
        if (code.length === length) {
            onComplete(code);
        }
    }, [code, onComplete, length]);

    useImperativeHandle(ref, () => ({
        setCode: (code: string) => setCode(code)
    }));

    return (
        <OtpInput
            isInputNum
            inputStyle={{
                border: 'none',
                borderBottom: `1px solid ${theme.colors.grey4}`,
                margin: '0 8px 0 0',
                width: '50px',
                height: '50px',
            }}
            focusStyle={{
                borderColor: `${theme.colors.primary}`,
                outline: 'none',
            }}
            autoComplete={autoComplete}
            numInputs={length}
            shouldAutoFocus={autoFocus}
            isDisabled={disabled}
            value={code}
            onChange={setCode}
        />
    );
});

export type { PinCodeInputRef };
