import { FC } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { OrderProgress } from './OrderProgress';
import { OrderPartners } from './OrderPartners';
import { OrderCardPartner } from './OrderCardPartner';
import OrderSearchGif from '../../../../assets/images/search-animated.gif';
import { InfoPanel } from '../../../../components';
import { ImageModel, OrderBidModel, OrderModel, OrderSelfModel } from '../../../../types/api';
import { BiddingTypes, CanceledOrderStatuses, ConstantsOrderStatus, translates } from '../../../../utilities';

const Hr = styled.div`
    height: 1px;
    background: ${({ theme }) => theme.colors.grey1};
`;

const AnimationContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    @media ${({ theme }) => theme.query.medium} {
        justify-content: center;
    }
`;

const SearchAnimatedImg = styled.img.attrs({
    src: OrderSearchGif,
    alt: 'Order success animated image'
})`
    margin-top: 16px;
    max-height: 176px;
    @media ${({ theme }) => theme.query.medium} {
        width: 100%;
    }
`;

const Content = styled.div`
    padding: 32px;

    @media ${({ theme }) => theme.query.small} {
        padding: 32px 16px;
    }
`;

interface ProgressTabProps {
    order: OrderModel & OrderSelfModel;
    clientImages: ImageModel[];
    partnerImages: ImageModel[];
    materialsImages: ImageModel[];
    acceptBid: (partnerId: number) => void;
    approvedBid?: OrderBidModel;
    bidCount?: number;
}

export const ProgressTab: FC<ProgressTabProps> = ({
    order,
    clientImages,
    partnerImages,
    materialsImages,
    bidCount,
    approvedBid,
    acceptBid,
}) => {
    const getCancelReasonDescription = () => {
        return order.cancelReason ?
            `${order.cancelReason}Description` :
            translates.OrderCancelNoReasonDescription;
    };

    return (
        <>
            <OrderProgress order={order} />
            {![...CanceledOrderStatuses].includes(order.statusLabel!) &&
                !approvedBid &&
                order.biddingType === BiddingTypes.fixed && (
                <AnimationContainer>
                    <Hr/>
                    <SearchAnimatedImg />
                </AnimationContainer>
            )}
            {![...CanceledOrderStatuses].includes(order.statusLabel!) &&
                !approvedBid &&
                order.biddingType === BiddingTypes.standard &&
                (bidCount ?? 0) > 0 && (
                <>
                    <Hr/>
                    <OrderPartners order={order} clientImages={clientImages} acceptBid={acceptBid} />
                </>
            )}
            {![...CanceledOrderStatuses].includes(order.statusLabel!) && approvedBid && (
                <>
                    <Hr/>
                    <Content>
                        <OrderCardPartner order={order} images={[...partnerImages, ...materialsImages]} approvedBid={approvedBid} />
                    </Content>
                </>
            )}

            {order.statusLabel === ConstantsOrderStatus.Cancelled && (
                <>
                    <Hr/>
                    <Content>
                        <InfoPanel type="alert">{i18next.t(getCancelReasonDescription())}</InfoPanel>
                    </Content>
                </>
            )}
        </>
    );
};
