import i18next from 'i18next';
import { FC } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { Modal } from '../../../../components/modal';
import { useApi } from '../../../../hooks/useApi';
import { Button } from '../../../../components';
import { translates } from '../../../../utilities';

const ModalStyled = styled(Modal).attrs({
    modalHeight: 'calc(87vh - 120px)',
})``;

const Title = styled.p`
    margin: 10px 0px 20px;
    font-size: 1rem;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px;
`;

const CancelButton = styled(Button).attrs({
    styleType: 'outline',
})``;

const SubmitButton = styled(Button).attrs({
    styleType: 'primary',
})``;

interface DeleteFavoritePartnerProps {
    partnerId?: number | null;
    onSuccess(): void;
}

const useDeleteFavoritePartner = ({ partnerId, onSuccess }: DeleteFavoritePartnerProps) => {
    const api = useApi();
    return useQuery({
        queryKey: ['delete-favorite', partnerId],
        queryFn: () => api({ url: 'favorite-partners', method: 'DELETE', params: { partnerId } }),
        onSuccess,
        enabled: false,
    });
};

interface InvitationModalProps {
    onClose(): void;
    partnerId?: number | null;
    onDeleteSuccess(): void;
}

export const ConfirmRemoveModal: FC<InvitationModalProps> = ({
    onClose,
    partnerId,
    onDeleteSuccess,
}) => {
    const { isFetching, refetch: deletePartner } = useDeleteFavoritePartner({ partnerId, onSuccess: onDeleteSuccess });
    return (
        <ModalStyled open={Boolean(partnerId)} onClose={onClose}>
            <Title>{i18next.t(translates.InvitePartnerRemoveTitle)}</Title>
            <Row>
                <CancelButton title={i18next.t(translates.InvitePartnerRemoveCancelButton)} onClick={onClose} />
                <SubmitButton title={i18next.t(translates.InvitePartnerRemoveSubmitButton)} loading={isFetching} onClick={() => deletePartner()} />
            </Row>
        </ModalStyled>
    );
};
