import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import i18next from 'i18next';
import { HtmlContent } from '../htmlContent';
import { translates } from '../../utilities';
import { useModal } from '../../hooks/useModal';

interface FormAgreeModalProps {
    name: string;
    contentKey: string;
    linkText: string;
}

export const FormAgreeModal: FC<FormAgreeModalProps> = ({ name, contentKey, linkText }) => {
    const { setFieldValue } = useFormikContext();
    const [openModal, Modal] = useModal();

    const handleClick = () => {
        openModal({
            children: <HtmlContent contentKey={contentKey} />,
            okButtonTitle: i18next.t(translates.RegisterButtonsAcceptConditions),
            onOk: () => setFieldValue(name, true),
        });
    };

    return (
        <>
            <Link
                to={''}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClick();
                }}>
                {linkText}
            </Link>
            {Modal}
        </>
    );
};
