import i18next from 'i18next';
import { FC } from 'react';
import styled from 'styled-components';

import { AddressBookForm, NewAddressBookModel } from '../../../../components/addressBookForm';
import { Modal, Title } from '../../../../components';
import { translates } from '../../../../utilities';

const ModalInnerPadding = styled.div`
    padding: 6px;
`;

interface AddressBookModalProps {
    showModal: boolean;
    setShowModal: (shouldShow: boolean) => void;
    onSuccess: (payload: NewAddressBookModel) => void;
}

export const AddressBookModal: FC<AddressBookModalProps> = ({ showModal, setShowModal, onSuccess }) => {
    const closeModal = () => {
        setShowModal(false);
    };

    const handleSuccess = (payload: NewAddressBookModel) => {
        closeModal();
        onSuccess(payload);
    };

    return (
        <Modal
            open={showModal}
            onClose={closeModal}
            modalHeight='97vh'
            Header={() =>
                <Title
                    type="modal"
                    titleText={i18next.t(translates.AddressBookTitlesCreate)}
                />
            }
        >
            <ModalInnerPadding>
                <AddressBookForm
                    onSuccess={handleSuccess}
                    onCancel={closeModal}
                />
            </ModalInnerPadding>
        </Modal>
    );
};
