import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Title } from '../title';

type CardSize = 'small' | 'medium' | 'large';

interface CardStyledProps {
    $size: CardSize;
}

const CardStyled = styled.div<CardStyledProps>`
    min-height: 100%;
    margin: 0 auto;
    margin-top: 3rem;
    padding: 32px;
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.white};

    @media ${({ theme }) => theme.query.medium} {
        margin-top: 2rem;
    }

    ${({ $size }) => {
        switch ($size) {
        case 'small':
            return css`
                max-width: 768px;
            `;
        case 'medium':
            return css`
                max-width: 1024px;
            `;
        case 'large':
            return css`
                width: 100%;
                margin-top: 0;

                @media ${({ theme }) => theme.query.medium} {
                    margin-top: 0;
                }
            `;
        default:
            return css``;
        }
    }}

    @media ${({ theme }) => theme.query.medium} {
        padding: 16px !important;
    }
`;

const Seperator = styled.div`
    /* To ignore parent padding */
    width: calc(100% + 32px * 2);
    margin-left: -32px;

    @media ${({ theme }) => theme.query.medium} {
        width: calc(100% + 16px * 2);
        margin-left: -16px;
    }
    /*  */
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grey1};
`;

interface CardProps {
    size?: CardSize;
    titleText?: string
    titleError?: boolean
    subtitleText?: string
    children: ReactNode;
    seperator?: boolean;
}

export const Card: FC<CardProps> = ({ size = 'small', seperator, titleText, titleError, subtitleText, children, ...props }) => (
    <CardStyled $size={size} {...props}>
        {titleText && <Title
            error={titleError}
            type="inner-page"
            titleText={titleText}
            subtitleText={subtitleText}
        />}
        {seperator && <Seperator />}
        {children}
    </CardStyled>
);
