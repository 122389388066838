import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from '../spinner';
import CaretLeftIcon from '../../assets/images/caret-left-white-icon.svg';

type StyleType = 'primary' | 'primary-inverted' | 'primary-outline' | 'outline' | 'danger' | 'danger-outline' | 'text-primary' | 'text-secondary' | 'text-danger';
type ButtonType = 'button' | 'submit' | 'reset';

interface ButtonStyledProps {
    $styleType: StyleType
    disabled?: boolean
    $iconLeft?: string
    $iconRight?: string
    type?: ButtonType
}

const ButtonStyled = styled.button<ButtonStyledProps>`
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0 56px;
    border-radius: 24px;
    position: relative;
    text-align: center;
    word-break: normal;
    font-family: inherit;

    @media ${({ theme }) => theme.query.small} {
        padding: 0 36px;
        width: 100%;
    }

    ${({ $styleType: styleType, disabled, $iconLeft: iconLeft, $iconRight: iconRight, theme }) => {
        switch (styleType) {
        case 'primary':
            return css`
                    background: ${disabled ? theme.colors.grey3 : theme.colors.primary};
                    color: ${disabled ? theme.colors.grey5 : theme.colors.white};
                    border: 1px solid ${disabled ? theme.colors.grey3 : theme.colors.primary};
                `;
        case 'primary-inverted':
            return css`
                    background: ${theme.colors.white};
                    color: ${theme.colors.black};
                    border: 1px solid ${theme.colors.white};
                `;
        case 'primary-outline':
            return css`
                background: ${theme.colors.white};
                color: ${disabled ? theme.colors.grey3 : theme.colors.primary};
                border: 1px solid ${disabled ? theme.colors.grey3 : theme.colors.primary};
            `;
        case 'outline':
            return css`
                    background: ${theme.colors.white};
                    color: ${theme.colors.black};
                    border: 1px solid ${theme.colors.grey4};
                `;
        case 'danger':
            return css`
                background: ${theme.colors.danger};
                color: ${theme.colors.white};
                border: 1px solid ${theme.colors.danger};
            `;
        case 'danger-outline':
            return css`
                background: ${theme.colors.white};
                color: ${theme.colors.danger};
                border: 1px solid ${theme.colors.grey4};
            `;
        case 'text-primary':
            return css`
                background: none;
                border: none;
                color: ${theme.colors.primary};
                min-height: 16px;
                padding: 0;
                padding: ${iconLeft && '0 0 0 44px !important;'};
                padding: ${iconRight && '0 44px 0 0 !important;'};
                width: auto !important;
                font-size: .875rem;

                @media ${({ theme }) => theme.query.small} {
                    padding: 0;
                }
            `;
        case 'text-secondary':
            return css`
                background: none;
                border: none;
                color: ${theme.colors.grey10};
                min-height: 16px;
                padding: 0;
                padding: ${iconLeft && '0 0 0 44px !important;'};
                padding: ${iconRight && '0 44px 0 0 !important;'};
                width: auto !important;

                @media ${({ theme }) => theme.query.small} {
                    padding: 0;
                }
            `;
        case 'text-danger':
            return css`
                background: none;
                border: none;
                color: ${theme.colors.danger};
                min-height: 16px;
                padding: 0;
                padding: ${iconLeft && '0 0 0 44px !important;'};
                padding: ${iconRight && '0 44px 0 0 !important;'};
                width: auto !important;

                @media ${({ theme }) => theme.query.small} {
                    padding: 0;
                }
            `;
        default:
            return '';
        }
    }}
`;

interface IconContainerProps {
    styleType: StyleType
    $iconRight?: string
}

const IconContainer = styled.div<IconContainerProps>`
    position: absolute;
    top: 0;
    left: ${({ $iconRight }) => $iconRight ? 'auto' : '0'};
    right: ${({ $iconRight }) => $iconRight ? '0' : 'auto'};
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ styleType }) => {
        switch (styleType) {
        case 'text-primary':
            return css`
                width: 44px;
            `;
        case 'text-secondary':
            return css`
                width: 44px;
            `;
        default:
            return '';
        }
    }}
`;

const CaretLeft = styled.img.attrs({
    src: CaretLeftIcon,
    alt: 'back icon'
})``;

export interface ButtonProps {
    styleType: StyleType
    title: string | React.ReactNode
    disabled?: boolean
    loading?: boolean
    iconLeft?: string
    iconRight?: string
    type?: ButtonType
    onClick?: Function
    back?: boolean
}

export const Button: FC<ButtonProps> = ({
    title,
    type = 'button',
    styleType,
    disabled,
    loading,
    iconLeft,
    iconRight,
    onClick,
    back,
    ...props
}) => {
    const handleClick = () => {
        if (!onClick || disabled || loading) {
            return;
        }

        onClick();
    };

    return (
        <ButtonStyled type={type} $styleType={styleType} disabled={disabled || loading} $iconLeft={iconLeft} $iconRight={iconRight} {...props} onClick={handleClick}>
            {loading && (
                <IconContainer styleType={styleType}>
                    <Spinner size={16} />
                </IconContainer>
            )}
            {!loading && iconLeft && (
                <IconContainer styleType={styleType}>
                    <img src={iconLeft} alt="button icon left" />
                </IconContainer>
            )}
            {!loading && !iconLeft && back && (
                <IconContainer styleType={styleType}>
                    <CaretLeft />
                </IconContainer>
            )}
            {title}
            {!loading && iconRight && (
                <IconContainer styleType={styleType} $iconRight={iconRight}>
                    <img src={iconRight} alt="button icon right" />
                </IconContainer>
            )}
        </ButtonStyled>
    );
};
