import { FC } from 'react';
import styled from 'styled-components';

const OrderMetaItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.grey6};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const Icon = styled.img`
  margin-right: 10px;
  width: 12px;
  min-height: 1.25rem;
`;

interface OrderMetaItemProps {
    icon: string
    label: string
    value: string
}

export const OrderMetaItem: FC<OrderMetaItemProps> = ({ icon, label, value, ...props }) => (
    <OrderMetaItemContainer {...props}>
        <Icon src={icon} />
        <span>
            {label}:&nbsp;<strong>{value}</strong>
        </span>
    </OrderMetaItemContainer>
);
