import { useApi } from './useApi';

const localizationApiUrl = window.ENVIRONTMENT_VARIABLES.REACT_APP_API_LOCALIZATION_URL!;

export const useLocalizationApi = () => {
    const api = useApi();

    const localizationApi: typeof api = (params) => api({
        baseURL: localizationApiUrl,
        ...params
    });

    return localizationApi;
};
