import { useState, FC } from 'react';
import { useField, useFormikContext } from 'formik';
import i18next from 'i18next';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { FieldWrapper } from './components/wrapper';

import { theme } from '../../theme';
import HelpImg from '../../assets/images/help.svg';
import { Select } from '../select';
import { HelpPopover } from '../helpPopover';

const InfoButton = styled.span`
    cursor: pointer;
    margin-right: 6px;
`;

interface FormFieldSelectProps {
    name: string;
    autoFocus?: boolean;
    help?: string;
    label: string;
    options: any[];
    optionsValue?: string;
    withoutTranslate?: boolean;
    optionsLabel?: string;
    disabled?: boolean;
}

export const FormFieldSelect: FC<FormFieldSelectProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const [filled, setFilled] = useState(!!field.value);
    const [focused, setFocused] = useState(!!props.autoFocus);
    const [showHelp, setShowHelp] = useState(false);
    const { isSubmitting } = useFormikContext();
    const isMobile = useMediaQuery({
        query: theme.query.medium,
    });

    const renderFormFieldLabel = () =>
        props.help ? (
            <>
                <InfoButton>
                    <img src={HelpImg} alt="info" width={24} height={24} onClick={() => setShowHelp(!showHelp)} />
                </InfoButton>
                {props.label}
            </>
        ) : (
            props.label
        );

    const handleFocus = () => {
        setFilled(true);
        setFocused(true);
    };

    const handleBlur = () => {
        setTimeout(() => { // hack to wait for value set
            setFilled(!!field.value);
            helpers.setTouched(true);
            setFocused(false);
        });
    };

    const change = (value: string) => {
        helpers.setValue(value);
    };

    return (
        <FieldWrapper
            name={props.name}
            label={renderFormFieldLabel()}
            filled={filled}
            submitting={isSubmitting}
            focused={focused}>
            <Select
                {...props}
                name={props.name}
                error={meta.touched && !!meta.error}
                options={props.options.map((o) => ({
                    value: o[props.optionsValue || 'value'],
                    label: props.withoutTranslate
                        ? o[props.optionsLabel || 'label']
                        : i18next.t(o[props.optionsLabel || 'label']),
                }))}
                value={field.value}
                onChange={change}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {showHelp && (
                <HelpPopover
                    isMobile={isMobile}
                    onClose={() => setShowHelp(false)}
                >
                    {props.help}
                </HelpPopover>
            )}
        </FieldWrapper>
    );
};
