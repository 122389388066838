import i18next from 'i18next';
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { translates } from '../../../utilities';
import { Tabs, Tab, Alert, Card, TitleTextProps } from '../../../components';
import { LoginByEmail } from './email/LoginByEmail';
import { RegistrationContainer } from './email/Registration';
import { LoginByPhone } from './phone/LoginByPhone';
import { ConfigContext } from '../../../contexts';
import { routerPaths } from '../../../configs';
import { PhoneStateModel } from './phone/phoneStateModel';
import { LoginByGoogle } from './google/LoginByGoogle';
import { ReactComponent as ArrowLeft } from '../../../assets/images/arrow-left.svg';
import { getPlatform } from '../../../configs/Platform';
import { useUrlQuery } from '../../../hooks/useUrlQuery';
import { Toast } from '../../../components/toast';
import { useLoginHandler } from './utilities';

const SelectCountryLinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
`;

const SelectCountryLink = styled.span`
    text-decoration: underline;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const ArrowLeftStyled = styled(ArrowLeft)`
    margin-right: 8px;
`;

const PageWrapper = styled.div`
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    max-width: 764px;
    position: relative;
`;

export type LoginByEmailPageType = 'login' | 'registration';

const enum LoginTabStates {
    ByPhone = 0,
    ByGoogle = 1,
    ByEmail = 2,
}

interface GetTitleTextParams {
    selectedTab: LoginTabStates;
    loginByEmailPage: LoginByEmailPageType;
}

const getTitleText = (params: GetTitleTextParams): TitleTextProps => {
    if (params.selectedTab === LoginTabStates.ByEmail && params.loginByEmailPage === 'registration') {
        return {
            titleText: i18next.t(translates.RegisterTitlesMain),
            subtitleText: i18next.t(translates.RegisterSubTitlesMain),
        };
    }

    return {
        titleText: i18next.t(translates.LoginTitlesMain),
        subtitleText: i18next.t(translates.LoginSubTitlesMain),
    };
};

export const LoginContainer = () => {
    const { contactInfo } = useContext(ConfigContext);
    const [selectedTab, setSelectedTab] = useState<LoginTabStates>(LoginTabStates.ByPhone);
    const [isLoading, setIsLoading] = useState(true);
    const [loginByEmailPage, setLoginByEmailPage] = useState<LoginByEmailPageType>('login');
    const { state } = useLocation();
    const phone = contactInfo?.phone || state?.phone || null;
    const [phoneState, setPhoneState] = useState<PhoneStateModel | null>({
        phone
    });
    const navigate = useNavigate();
    const [afterLogin, error] = useLoginHandler('email');
    const query = useUrlQuery();
    const location = useLocation();

    useEffect(() => {
        if (query.error === 'googleLogin') {
            Toast.error(i18next.t(translates.LoginErrorCrossCountry));
        }

        if (query.error === 'userBlocked') {
            Toast.error(i18next.t(translates.LoginErrorUserBlocked));
        }

        if (query.tab === 'registration') {
            setSelectedTab(LoginTabStates.ByEmail);
            setLoginByEmailPage('registration');
        }

        if (query.tab === 'email') {
            setSelectedTab(LoginTabStates.ByEmail);
        }
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToCountrySelect = () => {
        localStorage.removeItem('country');
        navigate(routerPaths.selectCountry.route, { replace: true });
    };

    const handleSelectTab = (tabIndex: number) => {
        if (loginByEmailPage === 'registration' && tabIndex === LoginTabStates.ByEmail) {
            setLoginByEmailPage('login');
        }

        setSelectedTab(tabIndex);
    };

    return (
        <PageWrapper>
            {!isLoading && <Card
                titleText={getTitleText({ selectedTab, loginByEmailPage }).titleText}
                subtitleText={
                    getTitleText({ selectedTab, loginByEmailPage }).subtitleText
                }>
                <Tabs
                    selectedTab={selectedTab}
                    setSelectedTab={handleSelectTab}>
                    <Tab title={i18next.t(translates.LoginLabelsWithPhone)}>
                        <LoginByPhone
                            phoneState={phoneState}
                            handlePhoneState={setPhoneState}
                        />
                    </Tab>
                    <Tab title="Google">
                        <LoginByGoogle />
                    </Tab>
                    <Tab title={i18next.t(translates.LoginLabelsWithEmail)}>
                        {loginByEmailPage === 'login' && (
                            <LoginByEmail
                                handleChangeLoginByEmailPage={setLoginByEmailPage}
                                handleAfterLogin={afterLogin}
                            />
                        )}
                        {loginByEmailPage === 'registration' && (
                            <RegistrationContainer handleChangeLoginByEmailPage={setLoginByEmailPage} />
                        )}
                    </Tab>
                </Tabs>
                <Alert content={i18next.t(error)} visible={!!error} />
                {getPlatform() === 'APP' && (
                    <SelectCountryLinkWrapper>
                        <SelectCountryLink onClick={goToCountrySelect}>
                            <ArrowLeftStyled width={16} />
                            {i18next.t(translates.LoginButtonsSelectCountry)}
                        </SelectCountryLink>
                    </SelectCountryLinkWrapper>
                )}
            </Card>}
        </PageWrapper>
    );
};

export * from './google/GoogleAuth';
