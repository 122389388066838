import * as Sentry from '@sentry/react';
import { ReactRouterInstrumentation } from '@sentry/react/dist/types';
import { Integrations } from '@sentry/tracing';
import { getEnvironment } from '../configs/environment';

export const initialize = (routingInstrumentation: ReactRouterInstrumentation) => {
    const ENV = getEnvironment();
    const SENTRY_DSN = window.ENVIRONTMENT_VARIABLES.REACT_APP_SENTRY_DSN;

    if (!SENTRY_DSN) {
        return;
    }
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENV,
        integrations: [new Integrations.BrowserTracing({
            routingInstrumentation
        })],
        tracesSampleRate: ENV === 'PROD' ? 0.2 : 1,
    });
};
