import { FC } from 'react';
import styled, { css } from 'styled-components';

interface HeadingProps {
    $type: TitleType;
}

const Heading = styled.div<HeadingProps>`
    ${({ $type }) => {
        switch ($type) {
        case 'page':
            return css`
                margin: 2rem; 0;

                @media ${({ theme }) => theme.query.small} {
                    margin: 1.75rem 0;
                }
            `;
        case 'inner-page':
            return css`
                margin: 0 0 1.75rem;

                @media ${({ theme }) => theme.query.small} {
                    margin: 0 0 1rem;
                }
            `;
        case 'modal':
            return css`
                margin: 0;
            `;
        case 'header':
            return css`
                margin: 1.5rem 0;
            `;
        default:
            return '';
        }
    }}
    `;

interface TitleStyledProps {
    $error?: boolean
}

const TitleStyled = styled.h1<TitleStyledProps>`
    color: ${({ theme, $error }) => theme.colors[$error ? 'danger' : 'black']};
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
`;

const SubtitleStyled = styled.p`
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 0;
    font-size: .875rem;
`;

type TitleType = 'page' | 'inner-page' | 'modal' | 'header';

interface TitleProps {
    type: TitleType
    titleText: string;
    subtitleText?: string;
    error?: boolean
}

export const Title: FC<TitleProps> = ({ type, titleText, subtitleText, error, ...props }) => (
    <Heading $type={type} {...props}>
        <TitleStyled $error={error}>{titleText}</TitleStyled>
        {!!subtitleText && <SubtitleStyled>{subtitleText}</SubtitleStyled>}
    </Heading>
);

export interface TitleTextProps {
    titleText: string;
    subtitleText: string;
}
