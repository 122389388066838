import { FC, useCallback } from 'react';
import styled from 'styled-components';

interface TabTitleProps {
    title: string;
    index: number;
    selectedTab: number;
    setSelectedTab: (index: number) => void
}

interface ButtonStyledProps {
    $isSelectedTab: boolean
}

const ButtonStyled = styled.button<ButtonStyledProps>`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.grey7};
    background: white;
    border-radius: 8px;
    padding: 12px 0;
    font-size: .875rem;
    color: ${({ $isSelectedTab, theme }) => $isSelectedTab ? theme.colors.primary : theme.colors.grey11};
    background: ${({ $isSelectedTab, theme }) => $isSelectedTab ? theme.colors.white : theme.colors.grey7};
    box-shadow: ${({ $isSelectedTab }) => $isSelectedTab ? '0px 3px 5px 5px rgba(191,191,191,0.16)' : 'none'};
`;

export const TabTitle: FC<TabTitleProps> = ({ title, setSelectedTab, index, selectedTab, ...props }) => {
    const onClick = useCallback(() => {
        setSelectedTab(index);
    }, [setSelectedTab, index]);

    return (
        <li {...props}>
            <ButtonStyled $isSelectedTab={selectedTab === index} onClick={onClick}>{title}</ButtonStyled>
        </li>
    );
};
