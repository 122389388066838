/* eslint-disable react/prop-types */
import { ReactNode, useEffect, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from '../button';
import { ReactComponent as XDangerIcon } from '../../assets/images/x-danger-icon.svg';
import { ContentLoading } from '../contentLoading';

const GlobalModalStyle = createGlobalStyle`
  body.modal-open {
    overflow: hidden;
  }
`;

const ModalStyled = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const ModalHeader = styled.div`
    padding: 0rem 1rem 0 0.5rem;
`;

const ModalBody = styled.div<ContentProps>`
    padding: 0 0.5rem;
    margin-top: 0.5rem;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
`;

const ModalFooter = styled.div`
    margin-top: 1rem;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.45);
`;

interface ContentProps {
    $modalHeight?: string;
}

const Content = styled.div<ContentProps>`
    position: relative;
    max-width: calc(100vw - 32px);
    width: 768px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 1000;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    max-height: ${({ $modalHeight }) => $modalHeight || '90vh'};
`;

const ButtonStyled = styled(Button)``;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${ButtonStyled} + ${ButtonStyled} {
        margin-left: 0.75rem;
    }
`;

const CloseIconContainer = styled.div`
    padding: 0.5rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
`;

const Hr = styled.div`
    height: 1px;
    background: ${({ theme }) => theme.colors.grey8};
    margin: 1.5rem -2rem 0 -1.5rem;
`;

export interface ModalProps {
    open: boolean;
    children: ReactNode;
    cancelButtonTitle?: string;
    okButtonTitle?: string;
    onClose(cancelButtonClicked?: boolean): void;
    onOk?(): void;
    Header?: () => JSX.Element;
    Footer?: () => JSX.Element;
    isLoading?: boolean;
    modalHeight?: string;
    hideClose?: boolean;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>(({
    open,
    cancelButtonTitle,
    okButtonTitle,
    children,
    onClose,
    onOk,
    Header,
    Footer,
    isLoading,
    modalHeight,
    hideClose,
    ...props
}, ref) => {
    const enableBodyScroll = () => {
        document.querySelector('body')?.classList.remove('modal-open');
        document.removeEventListener('wheel', () => {});
    };

    const disableBodyScroll = () => {
        const container = document.querySelectorAll('#modalId');
        if (container) {
            document.addEventListener('wheel', (e) => {
                e.stopPropagation();
                if (container?.[container.length - 1]?.scrollTop !== undefined) {
                    container[container.length - 1].scrollTop += e.deltaY;
                }
            });
        }
        document.querySelector('body')?.classList.add('modal-open');
    };

    useEffect(() => {
        if (open) {
            disableBodyScroll();
        }

        return () => {
            enableBodyScroll();
        };
    }, [open]);

    const handleClose = (cancelButtonClicked?: boolean) => {
        if (!hideClose) {
            enableBodyScroll();
            onClose(cancelButtonClicked);
        }
    };

    return createPortal(
        <>
            <GlobalModalStyle />
            {open && (
                <ModalStyled {...props}>
                    <Overlay onClick={() => handleClose()} />
                    <Content $modalHeight={modalHeight}>
                        {!hideClose && (
                            <CloseIconContainer onClick={() => onClose()}>
                                <XDangerIcon />
                            </CloseIconContainer>
                        )}
                        {isLoading ? (
                            <ContentLoading />
                        ) : (
                            <>
                                {Header && (
                                    <ModalHeader>
                                        <Header />
                                        <Hr />
                                    </ModalHeader>
                                )}
                                <ModalBody ref={ref} id={'modalId'}>{children}</ModalBody>
                                {(Footer || cancelButtonTitle || okButtonTitle) && (
                                    <ModalFooter>
                                        {Footer && <Footer />}
                                        {(cancelButtonTitle || okButtonTitle) && (
                                            <ButtonsContainer>
                                                {cancelButtonTitle && (
                                                    <ButtonStyled
                                                        title={cancelButtonTitle}
                                                        styleType="outline"
                                                        onClick={() => onClose(true)}
                                                    />
                                                )}
                                                {okButtonTitle && (
                                                    <ButtonStyled
                                                        title={okButtonTitle}
                                                        styleType="primary"
                                                        onClick={onOk}
                                                    />
                                                )}
                                            </ButtonsContainer>
                                        )}
                                    </ModalFooter>
                                )}
                            </>
                        )}
                    </Content>
                </ModalStyled>
            )}
        </>,
        document.getElementById('root')!
    );
});
