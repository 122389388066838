import { useContext, useEffect } from 'react';
import { ConfigContext, OrderingContext } from '../../../../contexts';
import { getCountryApiUrl } from '../../../../utilities/countryDefaults';

export const LoginByGoogle = () => {
    const { language, country } = useContext(ConfigContext);
    const { orderId } = useContext(OrderingContext);
    const apiUrl = getCountryApiUrl(country);

    const googleAuth = async () => {
        const url = apiUrl.concat(`auth/external?country=${country}&language=${language}&orderId=${orderId ?? ''}`);
        window.location.href = url;
    };

    useEffect(() => {
        googleAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<form action={apiUrl}></form>);
};
