import { RcFile } from 'rc-upload/lib/interface';
import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueries, useMutation } from 'react-query';
import i18next from 'i18next';
import { AuthContext, ConfigContext } from '../../../contexts';
import { useApi } from '../../../hooks/useApi';
import { useUrlQuery } from '../../../hooks/useUrlQuery';
import { ApiErrorModel, ImageModel, OrderModel, OrderSummaryRequest, PartnerModel, ServiceModel } from '../../../types/api';
import * as apiResponses from '../../../types/apigw';
import { Toast } from '../../../components/toast';
import { ResultModel, ServiceGroupModel } from '../../../types/apigw';

export function useGetServices() {
    const { country } = useContext(ConfigContext);
    const api = useApi();
    const url = `services/${country}`;

    const { data, isLoading } = useQuery<ServiceModel[], Error>(url, () =>
        api<ServiceModel[]>({
            url,
            method: 'GET',
        })
    );

    return {
        services: data || [],
        isServicesLoading: isLoading,
    };
}

export function useGetFavoritePartner(serviceId: string, longitude: number, latitude: number) {
    const api = useApi();
    const { auth } = useContext(AuthContext);
    // eslint-disable-next-line max-len
    const url = `/services/${serviceId}/favorite-partners?longitude=${longitude}&latitude=${latitude}`;
    const { data, isLoading } = useQuery<PartnerModel[], Error>(url, () =>
        api<PartnerModel[]>({
            url,
            method: 'GET',
        }),
    { enabled: Boolean(auth?.clientId) }
    );

    return { data: data || [], isLoading };
}

export function useGetServiceGroups() {
    const [isInitialFetchLoading, setIsInitialFetchLoading] = useState(true);
    const api = useApi();
    const { data, isLoading, mutate } = useMutation<apiResponses.ServiceGroupResponseModel, Error, string>((search) =>
        api<apiResponses.ServiceGroupResponseModel>({
            baseURL: window.ENVIRONTMENT_VARIABLES.REACT_APP_API_GW_URL,
            url: search ? `service-groups?searchText=${search?.toLowerCase()}` : 'service-groups',
            method: 'GET',
        })
    );

    useEffect(() => {
        if (isInitialFetchLoading && !isLoading) {
            setIsInitialFetchLoading(false);
        }
    }, [isLoading, isInitialFetchLoading]);

    return {
        servicesGroups: data?.result ?? [],
        isServiceGroupsLoading: isLoading,
        isInitialServiceGroupsLoading: isInitialFetchLoading,
        getServicesGroups: mutate,
    };
}

export function useGetImages() {
    const query = useUrlQuery();
    const api = useApi();
    const images = query.clientImages ? (JSON.parse(query.clientImages as string) as ImageModel[]) : [];

    const results = useQueries<any>(
        images.map((image) => ({
            queryKey: image.fileName || image.url,
            enabled: Boolean(images.length),
            queryFn: () =>
                api<Blob>({
                    url: image.url,
                    responseType: 'blob',
                }),
        }))
    );

    return {
        isImagesLoading: results.some((r) => r.isLoading),
        images: results.map((blob, i) => {
            const { id } = images[i];
            const ext = blob.type.split('/')[1];
            const name = `${id}.${ext}`;
            const file = new File([blob], name, { type: blob.type }) as RcFile;
            file.uid = id!;
            return file;
        }),
    };
}

export function useGetAvailability(
    serviceId: string,
    longitude: string | number,
    latitude: string | number,
    isEnabled: boolean
) {
    const { country } = useContext(ConfigContext);
    const api = useApi();
    const url = `/services/${country}/${serviceId}/availability?longitude=${longitude}&latitude=${latitude}`;
    const { data, isLoading, isError } = useQuery<{ isAvailable: boolean }, Error>(
        url,
        () =>
            api<{ isAvailable: boolean }>({
                url,
                method: 'GET',
            }),
        { enabled: isEnabled && !!longitude && !!latitude && !!serviceId }
    );

    return {
        isAvailable: data?.isAvailable ?? !isError,
        isAvailabilityLoading: isLoading,
    };
}

export function useGetServiceItems(
    serviceId: string,
    language: string
): {
        data: Partial<OrderSummaryRequest>;
        isServiceItemsLoading: boolean;
    } {
    const url = `services/${serviceId}/service-items`;
    const api = useApi();

    const { data, isLoading } = useQuery<apiResponses.ServiceGroupResponseModel, ApiErrorModel>({
        queryKey: [serviceId, language],
        queryFn: () =>
            api({
                baseURL: window.ENVIRONTMENT_VARIABLES.REACT_APP_API_GW_URL,
                url,
                method: 'GET',
            }),
        enabled: Boolean(serviceId.length),
        onError(e) {
            Toast.error(i18next.t((e as ApiErrorModel)?.errorMessage!));
        },
    });

    return {
        isServiceItemsLoading: isLoading,
        data: {
            ...(data?.result || {}),
        } as Partial<OrderSummaryRequest>,
    };
}

export function usePostOrder() {
    const api = useApi();
    const url = 'orders';

    return useMutation<OrderModel, ApiErrorModel>((data: any) => {
        return api<OrderModel>({
            url,
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    });
}

export function useGetServiceItem() {
    const api = useApi();
    const getUrl = (itemId: string) => `services/service-items/${itemId}`;

    return useMutation<apiResponses.ServiceGroupItemResponseModel, unknown, string>((serviceItemId) => {
        return api({
            baseURL: window.ENVIRONTMENT_VARIABLES.REACT_APP_API_GW_URL,
            url: getUrl(serviceItemId),
        }).catch(() => {
            return {};
        });
    });
}

export function useIsServiceAvailableById() {
    const api = useApi();
    const getUrl = (serviceId: string) => `services/${serviceId}`;

    return useMutation<boolean, unknown, string>(async (serviceId) => {
        try {
            const data = await api<ResultModel<ServiceGroupModel>>({
                baseURL: window.ENVIRONTMENT_VARIABLES.REACT_APP_API_GW_URL,
                url: getUrl(serviceId),
            });
            return !data?.hasError || false;
        } catch {
            return false;
        }
    });
}
