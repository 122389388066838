import { FC, useState } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { useMediaQuery } from 'react-responsive';
import { PartnerIcon } from './PartnerIcon';
import { ButtonProps } from '..';
import { PartnerExperienceModal } from '../../pages/auth/orders/components/partnerExperienceModal/PartnerExperienceModal';
import { PartnerCardContent } from './partnerCardContent';
import { FavoritePartnerCardContent } from './favoritePartnerCardContent';
import { FavoritePartnerModelService } from '../../types/api';
import { translates } from '../../utilities';
import { theme } from '../../theme';
import { Modal } from '../modal';
import { useApi } from '../../hooks/useApi';

interface PartnerCardContainerProps {
    $asPlaceholder?: boolean;
    $asFavorite?: boolean;
}
const ButtonContainer = styled.div<{ $isMobile: boolean }>`
    margin-left: auto;
    ${({ $isMobile }) => {
        if ($isMobile) {
            return `
             position: absolute;
             bottom: 0px;
             
             & p {
                margin-bottom: 0px !important;
             }
            `;
        }
        return '';
    }}
`;

const Selected = styled.p`
  color: ${({ theme }) => theme.colors.primary};
`;

const PartnerCardContainer = styled.div<PartnerCardContainerProps>`
    padding: 48px 28px 28px;
    border: 1px solid ${({ theme, $asFavorite }) => $asFavorite ? theme.colors.gold : theme.colors.grey9};
    border-radius: 10px;
    margin-top: 24px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme, $asPlaceholder }) => $asPlaceholder ? theme.colors.grey2 : theme.colors.white};
`;

const PartnerCardPartnerIcon = styled(PartnerIcon)`
    position: absolute;
    top: -24px;
    left: 50%;
    margin-left: -24px;
    width: 48px;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
`;

const ModalStyled = styled(Modal).attrs({
    modalHeight: 'calc(87vh - 120px)',
})``;

const Title = styled.p`
    margin: 10px 0px 20px;
    font-size: 1.2rem;
    font-weight: 500;
`;

const Row = styled.div`
    flex-direction: row;
    align-items: center;
    display: flex;
`;

const File = styled.a`
 margin-bottom: 1em;
 margin-left: 0.2em;
 color: ${({ theme }) => theme.colors.primary};
`;

interface PartnerCardProps {
    id: number;
    name: string;
    partnerPictureUrl?: string | null;
    priceLabel?: string;
    orderId?: string;
    price?: string | null;
    serviceId: number | string;
    totalVotes: number;
    rating: number;
    plannedMaterialExpenses?: string;
    plannedStartDate?: string | null;
    buttonProps?: ButtonProps[] | ButtonProps;
    commentsCount: number;
    doneWorksAmount?: number | null;
    doneWorksAmountLabel?: string;
    isFavoritePartner?: boolean;
    selected?: boolean
    useSelected?: boolean
    jobScope?: {
        description: string
        fileName: string
    }
}

export const PartnerCard: FC<PartnerCardProps> = ({
    id,
    name,
    partnerPictureUrl,
    priceLabel,
    price,
    serviceId,
    buttonProps,
    totalVotes,
    rating,
    commentsCount,
    plannedMaterialExpenses,
    plannedStartDate,
    doneWorksAmount,
    doneWorksAmountLabel,
    isFavoritePartner,
    selected = false,
    useSelected = false,
    jobScope,
    orderId,
    ...props
}) => {
    const [showModal, setShowModal] = useState(false);
    const [showJobScope, setShowJobScope] = useState(false);
    const [shouldShowCommetsTab, setShouldShowCommetsTab] = useState(false);
    const isMobile = useMediaQuery({
        query: theme.query.medium,
    });
    const api = useApi();

    const openModal = (showCommentsTab: boolean) => {
        setShowModal(true);
        setShouldShowCommetsTab(showCommentsTab);
    };

    const openJobScope = () => {
        setShowJobScope((p) => !p);
    };

    const getJobScopeFile = async () => {
        const url = await api<string>({
            url: `orders/${orderId}/bids/${id}/job-scope-file`
        });
        const data = await fetch(url);
        const blob = await data.blob();
        const objectUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.setAttribute('href', objectUrl);
        link.setAttribute('download', jobScope?.fileName);
        link.style.display = 'none';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    return (
        <>
            <PartnerCardContainer $asFavorite={isFavoritePartner} {...props}>
                <PartnerCardPartnerIcon partnerPictureUrl={partnerPictureUrl} />
                <PartnerCardContent
                    name={name}
                    priceLabel={priceLabel}
                    price={price}
                    totalVotes={totalVotes}
                    rating={rating}
                    plannedMaterialExpenses={plannedMaterialExpenses}
                    plannedStartDate={plannedStartDate}
                    buttonProps={buttonProps}
                    commentsCount={commentsCount}
                    openModal={openModal}
                    isFavoritePartner={isFavoritePartner}
                    openJobScope={jobScope?.description || jobScope?.fileName ? openJobScope : undefined}
                />
                {useSelected && (
                    <ButtonContainer $isMobile={isMobile}>
                        <Selected>
                            {i18next.t(
                                selected
                                    ? translates.CreateOrderFavoritePartnerSelected
                                    : translates.CreateOrderFavoritePartnerSelect
                            )}
                        </Selected>
                    </ButtonContainer>
                )}
            </PartnerCardContainer>
            <PartnerExperienceModal
                id={id}
                serviceId={serviceId}
                name={name}
                partnerPictureUrl={partnerPictureUrl}
                priceLabel={priceLabel}
                price={price}
                onClose={() => setShowModal(false)}
                open={showModal}
                buttonProps={buttonProps}
                shouldShowCommentsTab={commentsCount > 0 ? shouldShowCommetsTab : false}
                plannedMaterialExpenses={plannedMaterialExpenses}
                plannedStartDate={plannedStartDate}
                doneWorksAmount={doneWorksAmount}
                doneWorksAmountLabel={doneWorksAmountLabel}
            />
            <ModalStyled cancelButtonTitle={i18next.t(translates.BidJobScopeClose)} open={showJobScope} onClose={openJobScope}>
                <Title>{i18next.t(translates.BidJobScopeTitle)}</Title>
                {jobScope?.description ? <p>{jobScope?.description}</p> : null}
                {jobScope?.fileName ? (
                    <Row>
                        <p>{i18next.t(translates.BidJobScopeDescription)}</p>
                        <File onClick={getJobScopeFile}>{jobScope?.fileName}</File>
                    </Row>
                ) : null}
            </ModalStyled>
        </>
    );
};

interface FavoritePartnerCardProps {
    placeholder?: boolean;
    name?: string;
    partnerId?: number | null;
    partnerPictureUrl?: string | null;
    services?: FavoritePartnerModelService[] | null;
    isAlreadyInvited?: boolean;
    onInvitePress(): void;
    onDeletePress?(partnerId: number): void;
    onDismissPress?(): void;
}

export const FavoritePartnerCard: FC<FavoritePartnerCardProps> = ({
    placeholder,
    name,
    partnerId,
    partnerPictureUrl,
    services,
    isAlreadyInvited,
    onDeletePress,
    onInvitePress,
    onDismissPress,
}) => {
    const handleDeletePartner = (id?: number | null) => {
        if (id && onDeletePress) {
            onDeletePress(id);
        }
    };

    return (
        <PartnerCardContainer $asPlaceholder={placeholder}>
            <PartnerCardPartnerIcon partnerPictureUrl={partnerPictureUrl} />
            <FavoritePartnerCardContent
                asPlaceholder={placeholder}
                name={name}
                services={services}
                onInvitePartner={onInvitePress}
                onDeletePartner={() => handleDeletePartner(partnerId)}
                onDismissPartner={onDismissPress}
                isAlreadyInvited={isAlreadyInvited}
            />
        </PartnerCardContainer>
    );
};
