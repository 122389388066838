import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import i18next from 'i18next';
import { useMediaQuery } from 'react-responsive';
import PlusIcon from '../../assets/images/plus-icon-white.svg';
import { AuthContext, ConfigContext } from '../../contexts';
import LogoImg from '../../assets/images/logo.svg';
import { ReactComponent as PartnerIcon } from '../../assets/images/partner-icon.svg';
import { ReactComponent as LTIcon } from '../../assets/images/languages/LT.svg';
import { ReactComponent as ENIcon } from '../../assets/images/languages/EN.svg';
import { ReactComponent as PLIcon } from '../../assets/images/languages/PL.svg';
import { ReactComponent as RUIcon } from '../../assets/images/languages/RU.svg';
import { ReactComponent as ESIcon } from '../../assets/images/languages/ES.svg';
import { ReactComponent as CZIcon } from '../../assets/images/languages/CZ.svg';
import { ReactComponent as HUIcon } from '../../assets/images/languages/HU.svg';
import { ReactComponent as ROIcon } from '../../assets/images/languages/RO.svg';
import { ReactComponent as CAIcon } from '../../assets/images/languages/CA.svg';
import { ReactComponent as PTIcon } from '../../assets/images/languages/PT.svg';
import { useApi } from '../../hooks/useApi';
import { theme } from '../../theme';
import { MenuDropdown } from './MenuDropdown';
import { Item, MobileMenu } from './MobileMenu';
import { HamburgerMenuIcon } from './HamburgerMenuIcon';
import { translates, validateUserData } from '../../utilities';
import { Container } from '../../components/container';
import { IncompleteProfileModal } from '../../pages/auth/profile';
import { getCountryUrl } from '../../utilities/countryDefaults';
import { Button } from '../../components';
import { routerPaths } from '../../configs';

const LANGUAGES_IMG_MAP = {
    EN: <ENIcon />,
    LT: <LTIcon />,
    ES: <ESIcon />,
    PL: <PLIcon />,
    RU: <RUIcon />,
    CZ: <CZIcon />,
    HU: <HUIcon />,
    RO: <ROIcon />,
    CA: <CAIcon />,
    PT: <PTIcon />,
};

export const Logo = styled.img.attrs({ src: LogoImg, alt: 'portalpro' })`
    max-height: 50px;
    cursor: pointer;

    @media ${({ theme }) => theme.query.small} {
        height: 40px;
    }
`;

const HeaderStyled = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 110px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey8};
    z-index: 99;

    @media ${({ theme }) => theme.query.small} {
        height: 85px;
    }
`;

const InnerContainer = styled(Container)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const RightContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MenuItem = styled.span`
    cursor: pointer;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.darkBlack};
    margin-left: 48px;
`;

const UserItem = styled(MenuItem)`
    margin-left: 0;
    display: flex;
    align-items: center;
`;

const LanguageItem = styled(MenuItem)`
    margin-left: 0;
    display: flex;
    align-items: center;
`;

const Separator = styled.div`
    background-color: ${({ theme }) => theme.colors.grey8};
    height: 110px;
    width: 1px;
    margin: 0 30px;
`;

const Space = styled.div`
    margin-bottom: 110px;

    @media ${({ theme }) => theme.query.small} {
        margin-bottom: 85px;
    }
`;

const HamburgerMenuIconStyled = styled(HamburgerMenuIcon)`
    margin-left: 32px;
`;

const PartnerIconStyled = styled(PartnerIcon)`
    width: 15px;
    height: 15px;
    margin-right: 8px;
`;

const LanguageIconContainer = styled.div`
    height: 24px;
    margin-right: 8px;
`;

type Language = 'LT' | 'EN';

export const Header = () => {
    const { auth, setAuth } = useContext(AuthContext);
    const {
        country,
        language,
        languages,
        setLanguage,
    }: {
        country: string;
        language: string;
        languages: string[];
        setLanguage: (language: string) => void;
    } = useContext(ConfigContext);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({
        query: theme.query.medium,
    });
    const api = useApi();
    const location = useLocation();

    const changeLanguage = async (language: Language) => {
        if (auth) {
            await api({ url: 'clients/self', method: 'PATCH', data: { language } });
        }
        setLanguage(language);
    };

    const logout = async () => {
        await api({
            url: 'auth/logout',
            method: 'POST',
        });

        setAuth(null);
        localStorage.removeItem('auth');
        localStorage.setItem('logout', Date.now().toString());
        navigate(routerPaths.login.route, { replace: true });
    };

    const navigateToExternalMain = () => {
        window.location.href = getCountryUrl(country, 'externalMain');
    };

    const handleProfileNavigation = () => {
        if (!(auth?.isCompany ? auth?.companyName : auth?.firstName)) {
            setShowProfileModal(true);
        } else {
            navigate(routerPaths.profile.route);
        }
    };

    const openModal = async () => {
        try {
            if (auth && auth.clientId && !auth.phone) {
                navigate(routerPaths.profileEdit.route);
                return;
            }
            if (auth?.email || auth?.phone) {
                await validateUserData(auth!, country);
            }
            navigate(routerPaths.createOrder.route);
        } catch (error) {
            setShowProfileModal(true);
        }
    };

    const isNewOrderButtonVisible = () => {
        return !!auth && ![routerPaths.createOrder.route, routerPaths.editOrder.route].includes(location.pathname);
    };

    type DesktopMenuItemType = { title: string, onClick: () => void };

    const menuItems = [
        {
            title: i18next.t(translates.MenuButtonsProfile),
            onClick: handleProfileNavigation,
        },
        auth?.isCompany && {
            title: i18next.t(translates.MenuButtonsAddressBook),
            onClick: () => navigate(routerPaths.addressBooks.route),
        },
        {
            title: i18next.t(translates.MenuButtonsLogout),
            onClick: logout,
        },
    ].filter((item): item is DesktopMenuItemType => !!item);

    const mobileMenuItems: Item[] = [
        {
            component: auth ? (
                <>
                    <PartnerIconStyled />
                    {auth.firstName || auth.phone || auth.email}
                </>
            ) : undefined,
            children: menuItems.map((item) => ({ component: item.title, onClick: item.onClick }))
        },
        {
            component: (
                <>
                    <LanguageIconContainer>{LANGUAGES_IMG_MAP[language as Language]}</LanguageIconContainer>
                    {i18next.t(`Labels.Languages.${language}`)}
                </>
            ),
            children: languages
                .filter((e: string) => e !== language)
                .map((item) => ({
                    component: (
                        <>
                            <LanguageIconContainer>{LANGUAGES_IMG_MAP[item as Language]}</LanguageIconContainer>
                            {i18next.t(`Labels.Languages.${item}`)}
                        </>
                    ),
                    onClick: () => changeLanguage(item as Language),
                })),
        },
        {
            component: auth ? i18next.t(translates.MenuLinksInvitePartner) as string : undefined,
            onClick: () => navigate(routerPaths.invitePartner.route),
        },
        {
            component: auth ? i18next.t(translates.MenuLinksOrders) as string : undefined,
            onClick: () => navigate(routerPaths.orders.route),
        },
        {
            component: isNewOrderButtonVisible() ? (
                <Button
                    iconLeft={PlusIcon}
                    styleType="primary"
                    title={i18next.t(translates.MenuButtonsCreateOrder)}
                    onClick={openModal}
                />
            ) : undefined,
            onClick: () => {},
        },
        {
            component: !auth ? i18next.t(translates.MenuButtonsLogin) as string : undefined,
            onClick: () => navigate(routerPaths.login.route),
        },
    ];

    return (
        <>
            <HeaderStyled>
                <InnerContainer>
                    <Logo onClick={navigateToExternalMain} />
                    <RightContent>
                        {!isMobile && (
                            <>
                                {isNewOrderButtonVisible() && (
                                    <Button
                                        iconLeft={PlusIcon}
                                        styleType="primary"
                                        title={i18next.t(translates.MenuButtonsCreateOrder)}
                                        onClick={openModal}
                                    />
                                )}
                                {auth && (
                                    <MenuItem onClick={() => navigate(routerPaths.invitePartner.route)}>
                                        {i18next.t(translates.MenuLinksInvitePartner)}
                                    </MenuItem>
                                )}
                                {auth && (
                                    <MenuItem onClick={() => navigate(routerPaths.orders.route)}>
                                        {i18next.t(translates.MenuLinksOrders)}
                                    </MenuItem>
                                )}
                            </>
                        )}
                        {!isMobile && (
                            <MenuDropdown
                                style={{ marginLeft: 48 }}
                                items={languages
                                    .filter((e: string) => e !== language)
                                    .map((item) => ({
                                        language: item,
                                        onClick: () => changeLanguage(item as Language),
                                    }))}
                                renderItem={(item) => (
                                    <>
                                        <LanguageIconContainer key={item.language}>
                                            {LANGUAGES_IMG_MAP[item.language as Language]}
                                        </LanguageIconContainer>
                                        {i18next.t(`Labels.Languages.${item.language}`)}
                                    </>
                                )}>
                                <LanguageItem>{LANGUAGES_IMG_MAP[language as Language]}</LanguageItem>
                            </MenuDropdown>
                        )}
                        {!isMobile && (
                            <>
                                <Separator />
                                {auth ? (
                                    <MenuDropdown
                                        items={menuItems}
                                        renderItem={(item) => <div onClick={item.onClick}>{item.title}</div>}>
                                        <UserItem>
                                            <PartnerIconStyled />
                                            {auth.firstName || auth.phone || auth.email}
                                        </UserItem>
                                    </MenuDropdown>
                                ) : (
                                    <UserItem onClick={() => navigate(routerPaths.login.route)}>
                                        {i18next.t(translates.MenuButtonsLogin)}
                                    </UserItem>
                                )}
                            </>
                        )}
                        {isMobile && (
                            <HamburgerMenuIconStyled
                                open={mobileMenuOpen}
                                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            />
                        )}
                    </RightContent>
                </InnerContainer>
            </HeaderStyled>
            <Space />
            {mobileMenuOpen && <MobileMenu items={mobileMenuItems} onClose={() => setMobileMenuOpen(false)} />}
            <IncompleteProfileModal showModal={showProfileModal} setShowModal={setShowProfileModal} />
        </>
    );
};
