import i18next from 'i18next';
import { FC, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { ButtonProps, Spinner } from '../../../../components';
import { ConfigContext } from '../../../../contexts';
import { ImageModel, OrderBidModel, OrderModel } from '../../../../types/api';
import { AssignedPartnerStatusTypes, BiddingTypes, ConstantsOrderStatus, translates } from '../../../../utilities';
import { getCountryCurrency, getCurrencyLabel, getCountryCurrencyLabel } from '../../../../utilities/countryDefaults';
import { ImagesSlider } from './ImagesSlider';
import { PartnerCard } from '../../../../components/partnerCard';
import { getPlannedMaterialExpensesText, getPlannedStartDate } from './orderUtils';
import useCallPhone from '../../../../hooks/useCallPhone';

const Content = styled.div`
    display: flex;

    @media ${({ theme }) => theme.query.small} {
        flex-direction: column;
    }
`;

const ContentLeft = styled.div`
    display: flex;
    padding-right: 28px;
    align-items: flex-start;

    @media ${({ theme }) => theme.query.small} {
        padding-right: 0;
    }
`;

const ContentRight = styled.div`
    flex: 1;
    overflow: hidden;
    padding-left: 28px;

    @media ${({ theme }) => theme.query.small} {
        padding-left: 0;
        padding-top: 32px;
    }
`;

const PartnerCardStyled = styled(PartnerCard)`
    width: 320px;

    @media ${({ theme }) => theme.query.small} {
        width: 100%;
    }
`;

const Title = styled.p`
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1rem;
`;

const Description = styled.p`
    color: ${({ theme }) => theme.colors.grey6};
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: 0 0 16px;
`;

const PriceChanges = styled.div`
    margin-top: 1rem;
`;

interface OrderCardPartnerProps {
    order: OrderModel;
    images: ImageModel[];
    approvedBid?: OrderBidModel;
}

export const OrderCardPartner: FC<OrderCardPartnerProps> = ({ order, images, approvedBid }) => {
    const { country } = useContext(ConfigContext);
    const { isCallPressed, callPhone } = useCallPhone();
    const price = approvedBid?.price;
    const isPriceHourly = order.biddingType === BiddingTypes.factual;

    const priceLabel = useMemo(() => {
        if (order.biddingType === BiddingTypes.fixed && !order.pricing) {
            return null;
        }

        if (order.currency) {
            return getCurrencyLabel(order.currency, price!, isPriceHourly);
        }

        return getCountryCurrency(country, price!, isPriceHourly);
    }, [order, country, price, isPriceHourly]);

    const callButtonProps = useMemo<ButtonProps | undefined>(() => {
        if (
            !approvedBid
            || (order.biddingType === BiddingTypes.fixed && order.assignedPartnerStatus !== AssignedPartnerStatusTypes.ACCEPTED)
        ) {
            return undefined;
        }

        return {
            styleType: 'primary',
            title: isCallPressed ? approvedBid.partner?.phone! : i18next.t(translates.PartnerCardButtonsCall),
            disabled: order.statusLabel === ConstantsOrderStatus.Paid,
            onClick: () => callPhone(approvedBid.partner?.phone!, order.orderId),
        };
    }, [approvedBid, order, callPhone, isCallPressed]);

    return (
        <Content>
            <ContentLeft>
                {approvedBid ? (
                    <PartnerCardStyled
                        id={approvedBid.partnerId!}
                        name={approvedBid.partner?.fullName!}
                        partnerPictureUrl={approvedBid.partnerPictureUrl}
                        priceLabel={i18next.t(translates.PartnerCardLabelsConfirmedOrderPrice)}
                        price={priceLabel}
                        plannedMaterialExpenses={getPlannedMaterialExpensesText(
                            getCountryCurrencyLabel(country),
                            approvedBid.plannedMaterialExpenses
                        )}
                        plannedStartDate={getPlannedStartDate(approvedBid.plannedStartDate)}
                        rating={approvedBid.rating!}
                        totalVotes={approvedBid.totalVotes!}
                        commentsCount={approvedBid.totalComments!}
                        buttonProps={callButtonProps}
                        serviceId={order.serviceId!}
                        doneWorksAmount={approvedBid.totalJobsFinished}
                        doneWorksAmountLabel={i18next.t(translates.OrderCardPartnerLabelsTotalWorkCount)}
                        jobScope={order?.jobScope}
                        orderId={order?.orderId}
                    />
                ) : (
                    <Spinner />
                )}
            </ContentLeft>

            <ContentRight>
                {(order.partnerComment || images.length > 0) && (
                    <>
                        <Title>{i18next.t(translates.OrderTabsPartnerTabTitlesComment)}</Title>
                        <Description>{order.partnerComment}</Description>
                        <ImagesSlider images={images} />
                    </>
                )}
                {order.priceChanges && order.priceChanges.length > 0 && (
                    <PriceChanges>
                        <Title>{i18next.t(translates.OrderTabsPartnerTabTitlesPriceChanges)}</Title>
                        {order.priceChanges.map((p, i) => (
                            <Description key={i}>{p.comment!}</Description>
                        ))}
                    </PriceChanges>
                )}
            </ContentRight>
        </Content>
    );
};
