import i18next from 'i18next';
import moment from 'moment';
import { translates } from './translates';

export const formatCountdown = (isoDate: string) => {
    const end = moment(isoDate);
    const now = moment();
    const duration = moment.duration(moment(end).diff(moment(now)));
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const daysLabel = i18next.t(translates.GlobalLabelsTimeUnitsDays);
    const hoursLabel = i18next.t(translates.GlobalLabelsTimeUnitsHours);
    const minutesLabel = i18next.t(translates.GlobalLabelsTimeUnitsMinutes);
    const secondsLabel = i18next.t(translates.GlobalLabelsTimeUnitsSeconds);

    if (duration.asMilliseconds() <= 0) {
        return null;
    }

    if (days) {
        return `${days}${daysLabel} ${hours}${hoursLabel}`;
    }

    if (hours) {
        return `${hours}${hoursLabel} ${minutes}${minutesLabel}`;
    }

    if (minutes) {
        return `${minutes}${minutesLabel} ${seconds}${secondsLabel}`;
    }

    if (seconds) {
        return `${seconds}${secondsLabel}`;
    }

    return null;
};
