import { FC } from 'react';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

interface WrapperProps {
    $error?: boolean
    $active?: boolean
}

const Wrapper = styled.div<WrapperProps>`
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.grey12};
    border-radius: 10px;
    overflow: hidden;

    ${({ $active, $error, theme }) => {
        if ($error) {
            return css`
                border: 1px solid ${theme.colors.danger};
            `;
        }

        if ($active) {
            return css`
                border: 1px solid ${theme.colors.primary};
            `;
        }

        return '';
    }};
`;

const AddonBeforeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.grey7};
    height: 46px;
`;

const Icon = styled.img`
    margin-right: 16px;
`;

const StyledNumberFormat = styled(NumberFormat)`
    margin: 0;
    padding: 12px 16px;
    width: 100%;
    min-height: 46px;
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    font-size: 0.875rem;
    outline: none;
`;

export const NUMBER_INPUTS_FORMATS = {
    price: {
        allowNegative: false,
        decimalScale: 2,
    },
};

interface InputProps {
    name?: string;
    autoComplete?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    active?: boolean;
    error?: boolean;
    addonBefore?: React.ReactNode;
    value: string;
    onChange: (value: string) => void;
    onFocus?(): void;
    onBlur?: (e: any) => void;
    maxLength?: number;
    icon?: string;
    inputType?: keyof typeof NUMBER_INPUTS_FORMATS;
}

export const NumberInput: FC<InputProps> = ({
    name,
    autoComplete,
    autoFocus,
    disabled,
    active,
    error,
    addonBefore,
    value,
    onChange,
    onFocus,
    onBlur,
    icon,
    inputType,
    ...props
}) => {
    return (
        <Wrapper
            $active={active}
            $error={error}
        >
            {addonBefore && (
                <AddonBeforeWrapper>
                    {addonBefore}
                </AddonBeforeWrapper>
            )}
            <StyledNumberFormat
                name={name}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                disabled={disabled}
                value={value}
                onValueChange={({ value }) => onChange(value)}
                onFocus={onFocus}
                onBlur={onBlur}
                type='tel'
                {...inputType ? NUMBER_INPUTS_FORMATS[inputType] : {}}
                {...props}
            />
            {icon && <Icon src={icon} />}
        </Wrapper>
    );
};
