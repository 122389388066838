import { useSearchParams } from 'react-router-dom';

export const useClearSearchParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const remove = (queries: string[]) => {
        queries.forEach((q) => {
            if (searchParams.has(q)) {
                searchParams.delete(q);
            }
        });

        setSearchParams(searchParams);
    };

    return remove;
};
