import * as Yup from 'yup';
import { ClientReadModel } from '../types/api';
import { CountryEnum } from './countryDefaults';

export const validateUserData = (auth: ClientReadModel, country: string) =>
    new Promise<void>((resolve, reject) => {
        let baseSchema = Yup.object({
            firstName: Yup.string().nullable().when('isCompany', {
                is: false,
                then: Yup.string().required(),
            }),
            lastName: Yup.string().nullable().when('isCompany', {
                is: false,
                then: Yup.string().required(),
            }),
            isCompany: Yup.boolean(),
            companyRegistrationAddressText: Yup.string()
                .nullable()
                .when('isCompany', {
                    is: true,
                    then: Yup.string().required(),
                }),
            companyName: Yup.string()
                .nullable()
                .when('isCompany', {
                    is: true,
                    then: Yup.string().required(),
                }),
            acceptedPortalProContractTerms: Yup.boolean().required().oneOf([true]),
            acceptedPortalProPrivacy: Yup.boolean().required().oneOf([true]),
        });

        if (country === CountryEnum.LT || country === CountryEnum.PL) {
            const schema = Yup.object({
                companyCode: Yup.string()
                    .nullable()
                    .when('isCompany', {
                        is: true,
                        then: Yup.string().required(),
                    }),
            });
            baseSchema = baseSchema.concat(schema);
        }
        if (country === CountryEnum.ES) {
            const schema = Yup.object({
                invoiceAddress: Yup.string()
                    .nullable()
                    .when('isCompany', {
                        is: false,
                        then: Yup.string().required(),
                    })
            });
            baseSchema = baseSchema.concat(schema);
        }

        baseSchema.validate(auth).then(() => resolve()).catch(reject);
    });
