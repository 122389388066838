import i18next from 'i18next';
import { ChangeEvent, FC, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as SearchSvg } from '../../../../assets/images/search-white.svg';
import { ReactComponent as RemoveSvg } from '../../../../assets/images/remove-photo.svg';
import { ReactComponent as CaretUpIcon } from '../../../../assets/images/caret-up-icon.svg';
import { Text } from '../../../../components';
import { translates } from '../../../../utilities';
import { InitialTabbedOrdersModelType } from './orderUtils';

const StyledUpIcon = styled(CaretUpIcon)`
    transition: all .4s ease;
`;

const Item = styled.div`
    position: relative;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.light};
    height: 80%;
`;

const PillItemLabel = styled(Text).attrs({
    type: 'default',
    color: 'black',
})`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const rotateMenu = keyframes`
    0% { transform: rotateX(-90deg) }
    70% { transform: rotateX(20deg) }
    100% { transform: rotateX(0deg) }
`;

interface StatusNameProps {
    $isActive?: boolean;
}

const StatusName = styled.span<StatusNameProps>`
    color: ${({ theme, $isActive }) => $isActive ? theme.colors.black : theme.colors.grey6};
`;

interface StatusCountProps {
    $isAction?: boolean;
}

const StatusCount = styled.span<StatusCountProps>`
    color: ${({ theme, $isAction }) => $isAction ? theme.colors.danger : theme.colors.grey6};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ActionCircle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.danger};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.white};
    margin-right: 9px;
`;

const StatusMenu = styled.ul`
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    z-index: 1;
    animation: ${rotateMenu} 400ms ease-in-out forwards;
    transform-origin: top center;
    display: none;
    padding-inline-start: 0;
    list-style-type: none;
    border: 1px solid ${({ theme }) => theme.colors.shadow};
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;

    li {
        background-color: ${({ theme }) => theme.colors.white};
        padding: 5px 16px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        &:last-child {
            border-bottom-left-radius: 28px;
            border-bottom-right-radius: 28px;
            padding-bottom: 20px;
        }
    }
`;

const StatusSelect = styled.div`
    display: flex;
    align-items: center;
    span {
        margin-right: 7px;
        color: ${({ theme }) => theme.colors.grey6};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    flex: .2;
    flex-direction: column;
    padding: 5px 15px;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    border-top-left-radius: 56px;
    border-bottom-left-radius: 56px;
    min-width: 0;

    &:hover ${PillItemLabel} {
        color: ${({ theme }) => theme.colors.primary};
    }

    &:hover ${StyledUpIcon} {
        transform: rotateZ(-180deg);
    }

    &:hover ${StatusMenu} {
        display: block;
    }

    &:hover ${StatusSelect} span {
        color: ${({ theme }) => theme.colors.black};
        font-weight: 700;
    }

    &:hover {
        border-bottom-left-radius: 0;
        border-top-left-radius: calc(56px / 2)
    }

    @media ${({ theme }) => theme.query.medium} {
        flex: 1;
    }
`;

const Container = styled.div`
    display: flex;
    min-height: 56px;
    padding: 5px;
    flex: 1;
    align-items: center;
    margin-top: 32px;

    @media ${({ theme }) => theme.query.small} {
        &:focus-within {
            ${FilterContainer} {
                display:none;
            }
        }
    }
`;

const SearchContainer = styled.div`
    display: flex;
    padding: 0 5px;
    flex: 1;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-top-right-radius: 56px;
    border-bottom-right-radius: 56px;
    min-width: 0;

    @media ${({ theme }) => theme.query.small} {
        &:focus-within {
            border-radius: 56px;
        }
    }
`;

const SearchLeft = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5px 15px;
`;

const SearchInput = styled.input`
    margin: 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.grey6};
    padding: 0;
    border: 0;
    font-size: 0.875rem;
    outline: none;
`;

const SearchIconContainer = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;

    @media ${({ theme }) => theme.query.medium} {
        display: none;
    }
`;

const StyledRemoveIcon = styled(RemoveSvg)`
    margin-right: 15px;
    cursor: pointer;
`;

type TabKeys = keyof InitialTabbedOrdersModelType;

interface OrdersFilterProps {
    options: InitialTabbedOrdersModelType[TabKeys][];
    activeKey: TabKeys;
    onChange: (newKey: TabKeys) => void;
    tabsCount: { [s in TabKeys]: number } | null;
}

const OrdersFilter: FC<OrdersFilterProps> = ({ options, activeKey, onChange, tabsCount }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleOver = () => {
        setIsOpen((e) => !e);
    };

    const onItemSelect = (key) => {
        setIsOpen(false);
        onChange(key);
    };

    return (
        <FilterContainer onMouseEnter={handleOver} onMouseLeave={handleOver}>
            <PillItemLabel>
                {i18next.t(translates.OrdersHeaderSearchTitlesFilter)}
            </PillItemLabel>
            <StatusSelect>
                {(tabsCount?.waitingForAction || 0) > 0 && (
                    <ActionCircle>!</ActionCircle>
                )}
                <span>
                    {options.find((item) => item.key === activeKey)?.title || ''}
                </span>
                <StyledUpIcon />
            </StatusSelect>
            {isOpen && (
                <StatusMenu>
                    {options.map((item, idx) => (
                        <li key={idx + item.key} onClick={() => onItemSelect(item.key)}>
                            <StatusName $isActive={activeKey === item.key}>{item.title}</StatusName>
                            {item.key !== 'allOrders' && tabsCount !== null && (
                                <StatusCount $isAction={item.key === 'waitingForAction'}>
                                    ({tabsCount[item.key]})
                                </StatusCount>
                            )}
                        </li>
                    ))}
                </StatusMenu>
            )}
        </FilterContainer>
    );
};

interface SearchIconProps {
    onClick: () => void;
}

const SearchIcon: FC<SearchIconProps> = ({ onClick }) => (
    <SearchIconContainer onClick={() => onClick()}>
        <SearchSvg />
    </SearchIconContainer>
);

interface OrdersSearchProps {
    value: string;
    onChange: (search: string) => void;
    onClear: () => void;
    onClickSearch: () => void;
}

const OrdersSearch: FC<OrdersSearchProps> = ({ value, onChange, onClear, onClickSearch }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleInputFocus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    return (
        <SearchContainer>
            <SearchLeft onClick={handleInputFocus}>
                <PillItemLabel>
                    {i18next.t(translates.OrdersHeaderSearchTitlesSearch)}
                </PillItemLabel>
                <SearchInput
                    ref={inputRef}
                    placeholder={i18next.t(translates.OrdersHeaderSearchLabelsSearchPlaceholder)}
                    value={value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                />
            </SearchLeft>
            {!!value && <StyledRemoveIcon onClick={() => onClear()} />}
            <SearchIcon onClick={onClickSearch} />
        </SearchContainer>
    );
};

interface OrdersHeaderProps {
    searchValue: string;
    onSearch: (search: string) => void;
    onClearSearch: () => void;
    onClickSearch: () => void;
    filterOptions: InitialTabbedOrdersModelType[TabKeys][];
    onChangeFilter: (filterKey: TabKeys) => void;
    activeFilterKey: TabKeys;
    tabsCount: { [s in TabKeys]: number } | null;
}

export const OrdersHeader: FC<OrdersHeaderProps> = ({
    searchValue,
    onSearch,
    onClearSearch,
    onClickSearch,
    filterOptions,
    onChangeFilter,
    activeFilterKey,
    tabsCount,
}) => {
    return (
        <Container>
            <OrdersFilter options={filterOptions} onChange={onChangeFilter} activeKey={activeFilterKey} tabsCount={tabsCount} />
            <Item />
            <OrdersSearch value={searchValue} onChange={onSearch} onClear={onClearSearch} onClickSearch={onClickSearch} />
        </Container>
    );
};
