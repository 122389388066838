import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { RcFile } from 'rc-upload/lib/interface';
import i18next from 'i18next';
import { ReactComponent as AddIcon } from '../../../assets/images/add-photo.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/images/remove-photo.svg';
import { ReactComponent as PdfIcon } from '../../../assets/images/pdf.svg';
import { translates, isPdfFileType } from '../../../utilities';

const Square = styled.div`
    width: 103px;
    height: 103px;
    margin: 12px;
    background: #F4F4F4;
    border: 1px solid #EDEDED;
    border-radius: 5px;
    pointer-events: none;
`;

interface ThumbnailProps {
    $url: string;
}

const Thumbnail = styled(Square)<ThumbnailProps>`
    background-position: center;
    background-size: cover;
    background-image: url(${({ $url }) => $url});
    position: relative;
`;

const PdfSquare = styled(Square)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 2px;
`;

const PdfIconStyled = styled(PdfIcon)`
    display: flex;
    flex: 1;
`;

const AddButton = styled(Square)`
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    cursor: pointer;
`;

const RemoveIconStyled = styled(RemoveIcon)`
    position: absolute;
    right: -12px;
    top: -12px;
    cursor: pointer;
    pointer-events: auto;
`;

const HelpText = styled.p`
    font-size: 0.875rem;
    color: #6C6C6C;
    display: flex;
    flex: 1;
    align-self: center;
    margin: 0 12px;
    pointer-events: none;
`;

const PdfItemName = styled.div`
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    color: #6C6C6C;
`;

interface ImageProps {
    file: RcFile
    onRemove?(id: string): void
}

const Image: FC<ImageProps> = ({ file, onRemove }) => {
    const [previewImg, setPreviewImg] = useState<string>();

    useEffect(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => setPreviewImg(reader.result as string);
    }, [file]);

    return previewImg ? (
        <Thumbnail $url={previewImg}>
            {onRemove && (
                <RemoveIconStyled onClick={() => onRemove(file.uid)}/>
            )}
        </Thumbnail>
    ) : null;
};

interface ItemProps {
    file: RcFile;
    onRemove?: (id: string) => void;
}

const Item: FC<ItemProps> = ({ file, onRemove }) => {
    if (isPdfFileType(file.type)) {
        return (
            <PdfSquare>
                {onRemove && (
                    <RemoveIconStyled onClick={() => onRemove(file.uid)}/>
                )}
                <PdfIconStyled />
                <PdfItemName>{file.name}</PdfItemName>
            </PdfSquare>
        );
    }

    return (
        <Image
            file={file}
            onRemove={onRemove}
        />
    );
};

interface UploadItemsProps {
    items: RcFile[];
    onRemove: (id: string) => void;
    onAdd: () => void;
}

export const UploadItems: FC<UploadItemsProps> = ({
    items = [],
    onRemove,
    onAdd,
}) => (
    <>
        {items.length < 10 && (
            <AddButton onClick={onAdd}>
                <AddIcon />
            </AddButton>
        )}
        {items.length > 0
            ? items.map((item, idx) => (
                <Item
                    key={`${item.uid}-${idx}`}
                    file={item}
                    onRemove={item.uid?.includes('rc-upload') ? onRemove : undefined}
                />
            ))
            : <HelpText>{i18next.t(translates.UploadHelperText)}</HelpText>
        }
    </>
);
