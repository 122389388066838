import { Children, FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Text } from '..';
import { Arrow } from '../icons';

type Types = 'default' | 'icon';
type Direction = 'left' | 'right';
type ClosedColor = 'black' | 'dark' | 'light' | 'lighter' | 'success' | 'danger' | 'white';
type OpenColor = 'black' | 'dark' | 'light' | 'lighter' | 'success' | 'danger' | 'white';

interface StyledDropdownContainerProps {
    $type: Types;
}

const StyledDropdownContainer = styled.div<StyledDropdownContainerProps>`
    position: relative;
    height: ${({ theme }) => theme.button.size.medium};
    ${({ $type }) => ($type === 'default') && 'min-width: 190px;'}
`;

interface StyledDropdownProps {
    $direction: Direction;
    $type: Types;
    $isOpen: boolean;
    $openColor: OpenColor;
    $closedColor: ClosedColor;
}

const StyledDropdown = styled.div<StyledDropdownProps>`
    ${({ $direction: direction }) => direction && 'position:absolute;'}
    ${({ $direction: direction }) => direction === 'left' && 'right:0;'}
    ${({ $direction: direction }) => direction === 'right' && 'left:0;'}
    ${({ theme, $type: type, $isOpen: isOpen }) =>
        type === 'default' && `
            border: 1px solid ${isOpen ? theme.colors.success : theme.colors.light};
            border-radius: 28px;
            background-color: ${theme.colors.white};
            &:hover {
                border-color: ${theme.colors.success};
            }
        `};
    ${({ theme, $type: type, $isOpen: isOpen, $openColor: openColor, $closedColor: closedColor }) =>
        type === 'icon' && `
            border: none;
            border-radius: 16px;
            background-color: ${isOpen ? theme.colors[openColor] : theme.colors[closedColor]};
        `};
`;

interface StyledDropdownButtonProps {
    $type: Types
}

const StyledDropdownButton = styled.button<StyledDropdownButtonProps>`
    color: black;
    font-size: 16px;
    height: ${({ theme }) => theme.button.size.medium};
    border: none;
    width: 100%;
    background-color: transparent;
    ${({ $type }) => $type === 'icon' && `
        display: flex;
        flex-direction: row-reverse;
        padding: 12px 16px;
    `}
`;

interface StyledDropdownContentProps {
    $isOpened: boolean;
}

const StyledDropdownContent = styled.div<StyledDropdownContentProps>`
    display: ${({ $isOpened }) => $isOpened ? 'flex;' : 'none;'};
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

interface StyledNameProps {
    $title: string;
}

const StyledName = styled(Text)<StyledNameProps>`
    white-space: nowrap;
    padding: 0px 10px;
    margin-bottom: ${({ $title }) => !$title && '2px;'};
`;

interface StyledItemProps {
    $type: Types;
}

const StyledItem = styled.div<StyledItemProps>`
    color: black;
    width: 100%;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
    font-weight: 700;
    ${({ $type }) => ($type === 'icon') && 'padding: 7px 0px;'}
`;

interface StyledDropdownButtonContentContainerProps {
    $title: string;
}

const StyledDropdownButtonContentContainer = styled.div<StyledDropdownButtonContentContainerProps>`
    display: flex;
    flex-direction: ${({ $title }) => $title ? 'row;' : 'column;'};
    margin-right: ${({ $title }) => $title && '12px;'};
    justify-content: space-between;
    align-items: center;
`;

const DividerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
`;

const StyledDivider = styled.div`
    width: 100%;
    border-radius: 5px;
    border-bottom: 1.5px solid white;
`;

interface DropdownProps {
    type: Types;
    className: string;
    children: ReactNode;
    icon: ReactNode;
    title: string;
    direction: Direction;
    closedColor: ClosedColor;
    openColor: OpenColor;
}

export const Dropdown: FC<DropdownProps> = ({ icon, title, children, direction, type = 'default', openColor = 'white', closedColor = 'white', className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [shouldCloseOnBlur, setShouldCloseOnBlur] = useState(true);

    return (
        <StyledDropdownContainer $type={type} className={className}>
            <StyledDropdown $type={type} $isOpen={isOpen} $openColor={openColor} $direction={direction} $closedColor={closedColor}>
                <StyledDropdownButton
                    $type={type}
                    onClick={() => setIsOpen(!isOpen)}
                    onBlur={() => {
                        if (shouldCloseOnBlur) {
                            setIsOpen(false);
                        }
                    }}
                >
                    <StyledDropdownButtonContentContainer $title={title}>
                        {icon}
                        <StyledName type='button' className='dropdown-button-text' color='black' $title={title} cursor="pointer" >{title}</StyledName>
                        <Arrow
                            direction={isOpen ? 'up' : 'down'}
                            size={0.5}
                            color={(isOpen && (type === 'icon') ? 'white' : 'black')}
                        />
                    </StyledDropdownButtonContentContainer>
                </StyledDropdownButton>
                {type === 'icon' && isOpen &&
                    <DividerContainer>
                        <StyledDivider/>
                    </DividerContainer>
                }
                <StyledDropdownContent $isOpened={isOpen}>
                    {
                        Children.map(children, (child) =>
                            <StyledItem
                                $type={type}
                                onMouseDown={() => setShouldCloseOnBlur(false)}
                                onClick={() => { setShouldCloseOnBlur(true); setIsOpen(false); }}
                            >
                                {child}
                            </StyledItem>
                        )
                    }
                </StyledDropdownContent>
            </StyledDropdown>
        </StyledDropdownContainer>
    );
};
