import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import { AuthProvider, ConfigProvider, OrderingProvider } from './contexts';

import './utilities/i18';
import './styles/main.less';
import './styles/helpers.less';
import './styles/overrides.less';

const AppTranslated = withTranslation()(App);

const container = document.getElementById('root');
const root = createRoot(container!);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 3,
        }
    }
});

root.render(
    <BrowserRouter>
        <Suspense fallback={<body />}>
            <AuthProvider>
                <ConfigProvider>
                    <OrderingProvider>
                        <QueryClientProvider client={queryClient}>
                            <AppTranslated />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </QueryClientProvider>
                    </OrderingProvider>
                </ConfigProvider>
            </AuthProvider>
            <ToastContainer />
        </Suspense>
    </BrowserRouter>
);
