import i18next from 'i18next';
import { FC } from 'react';
import styled from 'styled-components';

import { Button } from '..';
import { Card, CardImage } from '.';

const ButtonsContainer = styled.div`
    margin: 3rem 0 1rem;
    display: flex;
    flex-direction: column;
`;

interface FailureCardProps {
    title: string;
    subTitle: string;
    buttonTitle: string;
    onClick: Function;
}

export const FailureCard: FC<FailureCardProps> = ({ title, subTitle, onClick, buttonTitle }) => {
    return (
        <Card titleError titleText={i18next.t(title)} subtitleText={i18next.t(subTitle)}>
            <CardImage type='failedCard' />
            <ButtonsContainer>
                <Button back type="button" styleType="primary" onClick={onClick} title={i18next.t(buttonTitle) as string} />
            </ButtonsContainer>
        </Card>
    );
};
