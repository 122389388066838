import i18next from 'i18next';
import React, { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    ApiErrorModel,
    OrderDocumentResultItem,
    ImageModel,
    OrderModel,
    OrderSelfModel,
    OrderWithImagesModel,
    PaymentProviderAvailabilityResponse,
    MTCModel,
    InitiatePaymentResponseModel,
} from '../../../../types/api';
import {
    BiddingTypes,
    CompletedOrderStatuses,
    ConstantsOrderStatus,
    content,
    Features,
    translates,
    validateUserData,
} from '../../../../utilities';
import { OrderMetaItem } from './OrderMetaItem';
import { OrderPricingMetaItem } from './OrderPricingMetaItem';
import { useApi } from '../../../../hooks/useApi';
import { OrderTabs } from './OrderTabs';
import AddressIcon from '../../../../assets/images/address-icon.svg';
import IdIcon from '../../../../assets/images/id-icon.svg';
import CreditCardIcon from '../../../../assets/images/cc-icon.svg';
import TickIcon from '../../../../assets/images/tick-icon.svg';
import CaretUpIcon from '../../../../assets/images/caret-up-icon.svg';
import CaretDownIcon from '../../../../assets/images/caret-down-icon.svg';
import EditIcon from '../../../../assets/images/edit-icon.svg';
import XDangerIcon from '../../../../assets/images/x-danger-icon.svg';
import CallIcon from '../../../../assets/images/phone.svg';
import { OrderCardHeader } from './OrderCardHeader';
import { Button, HtmlContent } from '../../../../components';
import { useModal } from '../../../../hooks/useModal';
import { useNotification } from '../../../../hooks/useNotification';
import { Toast } from '../../../../components/toast';
import { IncompleteProfileModal } from '../..';
import { AuthContext, ConfigContext, OrderingContext } from '../../../../contexts';
import { hasPreliminaryPricing, OrderCardRef } from './orderUtils';
import useCallPhone from '../../../../hooks/useCallPhone';

const Container = styled.div`
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    box-shadow: 0 4px 10px ${({ theme }) => theme.colors.shadow};
`;

const Content = styled.div`
    padding: 32px;

    @media ${({ theme }) => theme.query.small} {
        padding: 32px 16px;
    }
`;

const ContentTop = styled(Content)`
    display: flex;

    @media ${({ theme }) => theme.query.small} {
        flex-direction: column;
    }
`;

const ContentLeft = styled.div`
    flex: 1;
    div:not(:last-child) {
        margin-bottom: 16px;
    }
`;

const ContentRight = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContentExpanded = styled.div`
    flex-direction: column;
    padding: 0 32px 32px;

    @media ${({ theme }) => theme.query.small} {
        padding: 0 16px 32px;
    }
`;

const ContentExpandedInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: inherit;

    @media ${({ theme }) => theme.query.small} {
        flex-direction: column-reverse;
    }
`;

const Title = styled.p`
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 20px;
`;

const ButtonActionStyled = styled(Button)`
    @media ${({ theme }) => theme.query.small} {
        margin-top: 28px;
    }
`;

const ButtonOutlineStyled = styled(Button)`
    margin-top: auto;

    @media ${({ theme }) => theme.query.small} {
        margin-top: 28px;
    }
`;

const TextButton = styled(Button)``;

const CancelButtonContainer = styled.div`
    position: absolute;
    top: 0;
    right: 32px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${({ theme }) => theme.query.medium} {
        position: relative;
        top: initial;
        right: initial;
        height: auto;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        ${TextButton} + ${TextButton} {
            margin-top: 10px;
        }
    }

    @media ${({ theme }) => theme.query.small} {
        margin-bottom: 0;
    }
`;

const getPaymentButtonTitle = (showPaymentButtonLoader: boolean, isOrderRecent: boolean) => {
    if (!showPaymentButtonLoader) {
        return i18next.t(translates.OrderCardButtonsPay);
    }
    return isOrderRecent
        ? i18next.t(translates.OrderCardButtonsPaymentProcessing)
        : i18next.t(translates.OrderCardButtonsAwaitingPay);
};

const getAcceptWorkButtonTitle = (isValidProfile: boolean, isInvoiceGeneratable: boolean) => {
    if (!isValidProfile && !isInvoiceGeneratable) {
        return i18next.t(translates.OrderCardButtonsFinishProfile);
    }
    if (isValidProfile && !isInvoiceGeneratable) {
        return i18next.t(translates.OrderCardButtonsProfileSavingProcessing);
    }

    return i18next.t(translates.OrderCardButtonsAcceptWork);
};

interface OrderCardProps {
    outterOrder: OrderSelfModel;
}

export const OrderCard = React.forwardRef<OrderCardRef, OrderCardProps>(({ outterOrder, ...props }, ref) => {
    const [innerOrder, setInnerOrder] = useState<OrderModel & OrderSelfModel>(outterOrder);
    const [expanded, setExpanded] = useState(false);
    const [clientImages, setClientImages] = useState<ImageModel[]>([]);
    const [partnerImages, setPartnerImages] = useState<ImageModel[]>([]);
    const [materialsImages, setMaterialsImages] = useState<ImageModel[]>([]);
    const [mtcDetails, setMtcDetails] = useState<MTCModel>({});
    const [openAcceptModal, AcceptModal] = useModal();
    const [openConfirmationModal, ConfirmationModal] = useModal();
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [actionProfileCompletion, setActionProfileCompletion] = useState<() => void>();
    const [partnerId, setPartnerId] = useState(0);
    const { auth } = useContext(AuthContext);
    const { recentPaidOrders } = useContext(OrderingContext);
    const [isRecentOrder, setIsRecentOrder] = useState(false);
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const api = useApi();
    const features = useFeatures();
    const { country } = useContext(ConfigContext);
    const { isCallPressed, callPhone } = useCallPhone();
    const queryClient = useQueryClient();

    const order = useMemo(() => {
        return { ...innerOrder, ...outterOrder } as OrderModel & OrderSelfModel;
    }, [innerOrder, outterOrder]);

    const details = useQuery(
        `orders/${order.orderId!}`,
        () =>
            api<OrderWithImagesModel>({
                url: `orders/${order.orderId!}`,
            }),
        {
            enabled: false,
        }
    );

    const cancel = useMutation(() =>
        api({
            url: `orders/${order.orderId}/cancel`,
            method: 'POST',
        })
    );

    const accept = useMutation(() =>
        api({
            url: `orders/${order.orderId}/bids/${partnerId}/accept`,
            method: 'POST',
        })
    );

    const complete = useMutation(() =>
        api({
            url: `orders/${order.orderId}/complete`,
            method: 'POST',
        })
    );

    const payments = useMutation<InitiatePaymentResponseModel, ApiErrorModel>(() => api<InitiatePaymentResponseModel>({
        url: `orders/${order.orderId}/payments`,
        method: 'POST'
    }));

    const { data: documents, refetch: refetchDocuments, isLoading: isDocumentsLoading, isRefetching: isDocumentsRefetching } = useQuery(
        `orders/${order.orderId!}/documents`,
        () =>
            api<OrderDocumentResultItem[]>({
                url: `orders/${order.orderId!}/documents`,
            }),
        {
            enabled: false,
        }
    );

    const payOrder = useQuery(
        `orders/${order.orderId}/payments/providers/status`,
        () =>
            api<PaymentProviderAvailabilityResponse>({
                url: `orders/${order.orderId}/payments/providers/status`,
            }),
        {
            enabled: false,
        }
    );

    const showPaymentButton =
        [
            ConstantsOrderStatus.AwaitingCompletion,
            ConstantsOrderStatus.Completed,
            ConstantsOrderStatus.ReadyForPayment,
        ].includes(order.statusLabel!) || isRecentOrder;
    const showPaymentButtonLoader =
        [ConstantsOrderStatus.AwaitingCompletion, ConstantsOrderStatus.Completed].includes(order.statusLabel!) ||
        isRecentOrder;
    const showEditButton =
        order.statusLabel === ConstantsOrderStatus.Created &&
        moment(order.biddingDeadline).isSameOrAfter(moment()) &&
        features.includes(Features.orderEdit);
    const showPricing = CompletedOrderStatuses.includes(order.statusLabel!) || hasPreliminaryPricing(order);

    const getOrderDetails = useCallback(async (preventExpand?: boolean) => {
        const { data } = await details.refetch();
        setInnerOrder(data?.order as OrderModel);
        setPartnerImages(data?.partnerImages ?? []);
        setMaterialsImages(data?.materialsImages ?? []);
        setMtcDetails(data?.mtcDetails ?? {});
        setClientImages(data?.clientImages ?? []);
        refetchDocuments();

        if (!preventExpand) {
            setExpanded(true);
        }
        return data!;
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            expanded,
            getOrderData: () => getOrderDetails(true),
        }),
        [expanded, getOrderDetails]
    );

    useEffect(() => {
        const paidDate = recentPaidOrders[order!.orderId!];
        if (!paidDate) {
            return () => {};
        }
        const orderPaidSecondsAgo = paidDate && moment().diff(moment(paidDate), 'seconds');
        if (orderPaidSecondsAgo <= 120) {
            setIsRecentOrder(true);
        }

        const timer = setTimeout(() => {
            setIsRecentOrder(false);
        }, (120 - orderPaidSecondsAgo) * 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [recentPaidOrders, order]);

    const recreateOrder = useCallback(
        async (data) => {
            let order = data;
            if (!data.latitude || !data.longitude) {
                order = data.order;
            }
            const searchParams = new URLSearchParams();
            searchParams.append('serviceId', order.serviceId!.toString());
            searchParams.append('description', order.description!);
            searchParams.append('biddingType', order.biddingType!.toString());
            searchParams.append('address', order.address!);
            searchParams.append('latitude', order.latitude!.toString());
            searchParams.append('longitude', order.longitude!.toString());
            searchParams.append('clientImages', JSON.stringify(clientImages));
            searchParams.append('phone', order.phone!);
            searchParams.append('email', order.email!);
            searchParams.append('recreate', 'true');

            if (order.additionalInformation) {
                searchParams.append('additionalInformation', order.additionalInformation);
            }

            navigate(`/create-order?${searchParams}`);
        },
        [clientImages, navigate]
    );

    const handleRecreateOrder = async () => {
        let data = order;
        if (!order.latitude || !order.longitude) {
            data = (await getOrderDetails(true)) as OrderModel;
        }

        recreateOrder(data);
    };

    const bidCount = useMemo(() => {
        return order?.bids?.length ?? order?.bidCount ?? 0;
    }, [order]);

    const approvedBid = useMemo(() => {
        return order?.bids?.find(({ isApproved }) => isApproved);
    }, [order]);

    const isProfileValid = useMemo(() => {
        if (auth?.isCompany) {
            return !!auth.companyCode;
        }

        return !!auth?.firstName;
    }, [auth]);

    useNotification((orderId) => {
        if (orderId === order.orderId) {
            queryClient.invalidateQueries([`orders/${order.orderId!}`, `orders/${order.orderId!}/documents`]);
            getOrderDetails();
        }
    }, 'ReloadOrderImages');

    const handleExpandToggleClick = async (ignoreToggle?: boolean) => {
        if (expanded && !ignoreToggle) {
            setExpanded(false);
            return;
        }

        getOrderDetails();
    };

    const editOrder = () => {
        const searchParams = new URLSearchParams();
        searchParams.append('orderId', order.orderId!.toString());
        searchParams.append('description', order.description!);
        searchParams.append('clientImages', JSON.stringify(clientImages));
        searchParams.append('deadline', order.deadline!.toString());
        searchParams.append('biddingDeadline', order.biddingDeadline!.toString());

        if (order.additionalInformation) {
            searchParams.append('additionalInformation', order.additionalInformation);
        }

        navigate(`/edit-order?${searchParams}`);
    };

    const confirmEditOrder = () => {
        editOrder();
    };

    const cancelOrder = async () => {
        openConfirmationModal({
            children: i18next.t(translates.OrderCardLabelsCancelModalContent),
            cancelButtonTitle: i18next.t(translates.GlobalLabelsNo),
            okButtonTitle: i18next.t(translates.GlobalLabelsYes),
            onOk: async () => {
                await cancel.mutateAsync({} as any, {
                    onError(e) {
                        Toast.error(i18next.t((e as ApiErrorModel).errorMessage!));
                    },
                    onSuccess() {
                        getOrderDetails(true);
                    },
                });
            },
        });
    };

    const handleAcceptOrder = useCallback(async () => {
        await accept.mutateAsync({} as any, {
            onError(e) {
                Toast.error(i18next.t((e as ApiErrorModel).errorMessage!));
            },
            onSuccess() {
                getOrderDetails(true);
            },
        });
    }, [getOrderDetails]);

    const openAcceptAgreementModal = useCallback(async () => {
        if ([BiddingTypes.factual, BiddingTypes.fixed].includes(order.biddingType!)) {
            await handleAcceptOrder();
            return;
        }

        openAcceptModal({
            children: <HtmlContent contentKey={content.OrderAcceptBidAgreeWithTerms} />,
            cancelButtonTitle: i18next.t(translates.OrderCardButtonsAgreeModalCancel),
            okButtonTitle: i18next.t(translates.OrderCardButtonsAgreeModalOk),
            onOk: async () => {
                await handleAcceptOrder();
            },
        });
    }, [openAcceptModal, order, handleAcceptOrder]);

    const onProfileComplete = useCallback(() => {
        openAcceptAgreementModal();
    }, [openAcceptAgreementModal, partnerId]);

    const acceptBid = useCallback(
        async (partnerId: number) => {
            setPartnerId(partnerId);
            try {
                await validateUserData(auth!, country);
                openAcceptAgreementModal();
            } catch {
                setShowProfileModal(true);
                setActionProfileCompletion(() => onProfileComplete);
            }
        },
        [auth, openAcceptAgreementModal, country, onProfileComplete]
    );

    const acceptWork = async () => {
        setConfirmButtonDisabled(true);
        await complete.mutateAsync({} as any, {
            onError(e) {
                Toast.error(i18next.t((e as ApiErrorModel).errorMessage!));
            },
            onSuccess() {
                getOrderDetails(true);
            },
        });
    };

    const pay = async () => {
        try {
            const { data } = await payOrder.refetch();
            if (data!.isPaymentProviderAvailable) {
                payments.mutate({} as any, {
                    onSuccess(payData) {
                        window.location.href = payData.confirmLink!;
                    },
                    onError(e) {
                        Toast.error(i18next.t(e.errorMessage!));
                    }
                });
            } else {
                navigate('/payment', { state: {
                    orderId: order.orderId,
                    isProviderAvailable: data!.isPaymentProviderAvailable,
                } });
            }
        } catch (e) {
            Toast.error(i18next.t((e as ApiErrorModel).errorMessage!));
        }
    };

    const isCallButtonVisible = useMemo(() => {
        return order.statusLabel === ConstantsOrderStatus.InProgress && order?.approvedPartnerPhone;
    }, [order]);

    const isCancelVisible = [
        ConstantsOrderStatus.Created,
        ConstantsOrderStatus.CancelInitiated,
        ConstantsOrderStatus.AwaitingApproval,
    ].includes(order.statusLabel!);

    const actionLoading =
        details.isLoading || cancel.isLoading || accept.isLoading || complete.isLoading || payOrder.isLoading;
    const isOrderDetailsLoading = details.isLoading || details.isRefetching || isDocumentsLoading || isDocumentsRefetching;
    const isExpandedForData = expanded && !isOrderDetailsLoading;

    return (
        <Container {...props}>
            <OrderCardHeader order={order} />
            <ContentTop>
                <ContentLeft>
                    <Title>{i18next.t(order.serviceName!)}</Title>
                    <OrderMetaItem
                        icon={AddressIcon}
                        label={i18next.t(translates.OrderCardLabelsAddress)}
                        value={order.address!}
                    />
                    <OrderMetaItem
                        icon={IdIcon}
                        label={i18next.t(translates.OrderCardLabelsOrderCode)}
                        value={order.orderCode!}
                    />
                    {showPricing && <OrderPricingMetaItem order={order} />}
                </ContentLeft>
                <ContentRight>
                    {isCallButtonVisible && (
                        <ButtonActionStyled
                            styleType="primary"
                            iconLeft={CallIcon}
                            title={
                                isCallPressed
                                    ? order.approvedPartnerPhone
                                    : i18next.t(translates.PartnerCardButtonsCall)
                            }
                            onClick={() => callPhone(order.approvedPartnerPhone, order.orderId)}
                        />
                    )}
                    {order.statusLabel === ConstantsOrderStatus.Cancelled && (
                        <ButtonActionStyled
                            styleType="primary"
                            iconLeft={TickIcon}
                            title={i18next.t(translates.OrderCardButtonsRecreateOrder)}
                            loading={actionLoading}
                            onClick={handleRecreateOrder}
                        />
                    )}
                    {order.statusLabel === ConstantsOrderStatus.Resolved && (
                        <ButtonActionStyled
                            styleType="primary"
                            iconLeft={TickIcon}
                            title={getAcceptWorkButtonTitle(isProfileValid, order.isClientEligibleForInvoice!)}
                            loading={actionLoading || (isProfileValid && !order.isClientEligibleForInvoice)}
                            onClick={() => (isProfileValid ? acceptWork() : setShowProfileModal(true))}
                            disabled={confirmButtonDisabled}
                        />
                    )}
                    {order.statusLabel === ConstantsOrderStatus.Created && !approvedBid && bidCount > 0 && (
                        <ButtonActionStyled
                            styleType="primary"
                            title={i18next.t(translates.OrderCardButtonsPreviewOffer)}
                            loading={actionLoading}
                            onClick={() => handleExpandToggleClick(true)}
                        />
                    )}
                    {showPaymentButton && (
                        <ButtonActionStyled
                            styleType="primary"
                            title={getPaymentButtonTitle(showPaymentButtonLoader, isRecentOrder)}
                            iconLeft={CreditCardIcon}
                            loading={actionLoading || showPaymentButtonLoader}
                            onClick={pay}
                        />
                    )}
                    <ButtonOutlineStyled
                        styleType="outline"
                        title={
                            isExpandedForData
                                ? i18next.t(translates.OrderCardButtonsLessInfo)
                                : i18next.t(translates.OrderCardButtonsMoreInfo)
                        }
                        loading={isOrderDetailsLoading}
                        iconLeft={isExpandedForData ? CaretUpIcon : CaretDownIcon}
                        onClick={handleExpandToggleClick}
                    />
                </ContentRight>
            </ContentTop>

            {isExpandedForData && (
                <ContentExpanded>
                    <ContentExpandedInner>
                        <CancelButtonContainer>
                            {showEditButton && (
                                <TextButton
                                    styleType="text-secondary"
                                    iconLeft={EditIcon}
                                    title={i18next.t(translates.OrderCardButtonsEditOrder)}
                                    loading={actionLoading}
                                    onClick={confirmEditOrder}
                                />
                            )}
                            {isCancelVisible && (
                                <TextButton
                                    styleType="text-secondary"
                                    iconLeft={XDangerIcon}
                                    title={i18next.t(translates.OrderCardButtonsCancelOrder)}
                                    loading={
                                        actionLoading || order.statusLabel === ConstantsOrderStatus.CancelInitiated
                                    }
                                    onClick={cancelOrder}
                                />
                            )}
                        </CancelButtonContainer>
                        <OrderTabs
                            order={order}
                            clientImages={clientImages}
                            partnerImages={partnerImages}
                            materialsImages={materialsImages}
                            acceptBid={acceptBid}
                            approvedBid={approvedBid}
                            bidCount={bidCount}
                            documents={documents}
                            mtcDetails={mtcDetails}
                        />
                    </ContentExpandedInner>
                </ContentExpanded>
            )}

            <IncompleteProfileModal
                showModal={showProfileModal}
                setShowModal={setShowProfileModal}
                onOK={() => {
                    if (typeof actionProfileCompletion === 'function') {
                        actionProfileCompletion();
                        setActionProfileCompletion(undefined);
                    }
                }}
            />
            {AcceptModal}
            {ConfirmationModal}
        </Container>
    );
});
