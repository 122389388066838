import { FC, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { FieldWrapper } from './components/wrapper';
import { Textarea } from '../input';

interface FormFieldTextareaProps {
    rows?: number;
    label: string;
    name: string;
    maxLength: number;
    size?: string;
    fillHeight?: boolean;
    autoFocus?: boolean;
}

export const FormFieldTextarea: FC<FormFieldTextareaProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const [filled, setFilled] = useState(!!field.value);
    const [focused, setFocused] = useState(false);
    const { isSubmitting } = useFormikContext();

    const handleFocus = () => {
        setFilled(true);
        setFocused(true);
    };

    const handleBlur = (e: any) => {
        setFilled(!!e.target.value);
        helpers.setTouched(true);
        setFocused(false);
    };

    return (
        <FieldWrapper
            name={props.name}
            label={props.label}
            filled={filled}
            submitting={isSubmitting}
            focused={focused}
            style={{ height: '100%' }}>
            <Textarea
                onChange={(value) => helpers.setValue(value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={field.value}
                disabled={isSubmitting}
                active={focused}
                style={{ height: props.fillHeight ? '100%' : 'auto' }}
                error={meta.touched && !!meta.error}
                {...props}
            />
        </FieldWrapper>
    );
};
