import { useContext, useMemo, useState } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { translates } from '../../../utilities';
import { Card } from '../../../components/cards';
import { Banner } from './components/Banner';
import { FavoritePartnerCard } from '../../../components/partnerCard';
import { InvitationModal } from './components/InvitationModal';
import { useApi } from '../../../hooks/useApi';
import { ConfirmRemoveModal } from './components/ConfirmRemoveModal';
import { FavoritePartnerModel } from '../../../types/api';
import { ConfigContext } from '../../../contexts';

const CardContainer = styled.div`
    display: grid;
    
    grid-template-columns: repeat(4, 1fr);
    @media ${({ theme }) => theme.query.medium} {
        grid-template-columns: repeat(1, 1fr);
    }
    margin-top: 25px;
    gap: 15px;
`;

const Description = styled.span``;

type MappedFavoritePartners = FavoritePartnerModel & { asPlaceholder: boolean, key: string };

export function useInvitePartner() {
    const api = useApi();
    const { language } = useContext(ConfigContext);

    const { data, ...rest } = useQuery({
        queryKey: ['favorite-partners', language],
        queryFn: () => api<FavoritePartnerModel[]>({
            url: 'favorite-partners',
        })
    });

    const dataWithPlaceholder = useMemo<MappedFavoritePartners[]>(() => {
        const placeholderItem = { key: 'placeholder', asPlaceholder: true };
        if (!data) {
            return [placeholderItem];
        }

        const mappedData = data.map((item, index) => ({
            key: String(item.partnerId || index),
            asPlaceholder: false,
            ...item,
        })).sort();

        mappedData.push(placeholderItem);

        return mappedData;
    }, [data]);

    return { data: dataWithPlaceholder, ...rest };
}

export const InvitePartner = () => {
    const [showModal, setShowModal] = useState(false);
    const [partnerIdToDelete, setPartnerIdToDelete] = useState<number | null>(null);
    const { data, refetch, isLoading } = useInvitePartner();

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleRemoveCancel = () => setPartnerIdToDelete(null);

    const handleDeletePartnerConfirm = () => {
        refetch();
        setPartnerIdToDelete(null);
    };

    const handleDeletePartner = (partnerId: number) => {
        setPartnerIdToDelete(partnerId);
    };

    const getDescriptionLabel = () => {
        if (isLoading) {
            return i18next.t(translates.InvitePartnerDescriptionLoading);
        }
        return data.length > 1
            ? i18next.t(translates.InvitePartnerDescriptionMain)
            : i18next.t(translates.InvitePartnerDescriptionPartnerless);
    };

    const alreadyInvitedPartnersList = useMemo(() => {
        const map = data.map((item) => item.partnerId ?? null);
        return map.filter((item) => item !== null) as unknown as number[];
    }, [data]);

    return (
        <>
            <Banner onCreateClick={handleOpenModal} />
            <Card titleText={i18next.t(translates.InvitePartnerTitlesMain)} size='large'>
                <Description>{getDescriptionLabel()}</Description>
                <CardContainer>
                    {data.map((item) => (
                        <FavoritePartnerCard
                            key={item.key}
                            placeholder={item.asPlaceholder}
                            partnerId={item.partnerId}
                            name={item.partnerName!}
                            partnerPictureUrl={item.imageUrl}
                            services={item.services}
                            onInvitePress={handleOpenModal}
                            onDeletePress={handleDeletePartner}
                        />
                    ))}
                </CardContainer>
            </Card>
            <InvitationModal open={showModal} onClose={handleCloseModal} handleRefreshPartnersList={() => refetch()} currentPartnersList={alreadyInvitedPartnersList} />
            <ConfirmRemoveModal partnerId={partnerIdToDelete} onClose={handleRemoveCancel} onDeleteSuccess={handleDeletePartnerConfirm} />
        </>
    );
};
