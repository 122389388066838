import { FC, useContext, useMemo } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { RadioButtonGroup } from '../../../../components';
import { translates } from '../../../../utilities';
import { ExpectedTimeEnum, generateDeadlineOptions } from '../utilities';
import { ConfigContext } from '../../../../contexts';
import { theme } from '../../../../theme';
import { Title } from './Title';
import { PopoverButton } from './PopoverButton';

const AdditionalInfoStyled = styled.div``;

const RadioItemContainer = styled.div`
    border-radius: 10px;
    border: ${({ theme }) => `1px solid ${theme.colors.grey8}`};
    display: flex;
    flex: 1;
    min-height: 113px;
    padding: 23px 16px 23px 26px;
    margin-bottom: 16px;
    ${({ theme }) => `background-color: ${theme.colors.white}`};

    #radioButtonContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    span {
        color: ${({ theme }) => theme.colors.black};
    }
`;

const DeadlineContainer = styled.div`
    display: inline-flex;
    align-items: center;
`;

const PopoverContentLabel = styled.span`
    color: ${({ theme }) => theme.colors.white};
`;

export const AdditionalInfo: FC = () => {
    const { language } = useContext(ConfigContext);

    const expectedTimeOptions = useMemo(
        () => [
            { label: i18next.t(translates.OrderStepsLabelsExpectedTimeAfterWork), value: ExpectedTimeEnum.AfterWork },
            {
                label: i18next.t(translates.OrderStepsLabelsExpectedTimeWorkingHours),
                value: ExpectedTimeEnum.WorkingHours,
            },
            { label: i18next.t(translates.OrderStepsLabelsExpectedTimeWeekend), value: ExpectedTimeEnum.Weekend },
            { label: i18next.t(translates.OrderStepsLabelsExpectedTimeAnytime), value: ExpectedTimeEnum.Anytime },
        ],
        [language]
    );

    const deadlineOptions = useMemo(() => {
        return generateDeadlineOptions();
    }, [language]);

    const PopoverContent = (
        <PopoverContentLabel>
            {i18next.t(translates.OrderStepsLabelsDeadlineInputHelp)}
        </PopoverContentLabel>
    );

    return (
        <AdditionalInfoStyled>
            <Title>{i18next.t(translates.OrderStepsLabelsExpectedTimeInput)}</Title>
            <RadioItemContainer>
                <RadioButtonGroup name="expectedTime" options={expectedTimeOptions} />
            </RadioItemContainer>
            <DeadlineContainer>
                <Title>
                    {i18next.t(translates.OrderStepsLabelsDeadlineInput)}
                </Title>
                <PopoverButton>{PopoverContent}</PopoverButton>
            </DeadlineContainer>
            <RadioItemContainer>
                <RadioButtonGroup name="deadline" options={deadlineOptions} />
            </RadioItemContainer>
        </AdditionalInfoStyled>
    );
};
