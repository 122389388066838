import { useLocation, useNavigate } from 'react-router-dom';

import { translates } from '../../../utilities';
import { FailureCard } from '../../../components';
import { routerPaths } from '../../../configs';
import { useUrlQuery } from '../../../hooks/useUrlQuery';
import { OutsideProvider, Feedback } from './components';

const isSuccess = (status: string) => status !== 'failed';

const resolveText = (status: string) => {
    switch (status) {
    case 'completed':
        return {
            title: translates.PaymentTitlesSuccess,
            subTitle: translates.PaymentSubTitlesSuccess
        };
    case 'failed':
        return {
            title: translates.PaymentTitlesFailed,
            subTitle: translates.PaymentSubTitlesFailed
        };
    case 'started':
    case 'pending':
    default:
        return {
            title: translates.PaymentTitlesPending,
            subTitle: translates.PaymentSubTitlesPending
        };
    }
};

export const PaymentContainer = () => {
    const { statusGroup } = useUrlQuery();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { title, subTitle } = resolveText(statusGroup as string);

    const goToOrders = () => {
        navigate(routerPaths.orders.route);
    };
    const goToFeedback = () => {
        navigate(`?statusGroup=completed&orderId=${state.orderId}`);
    };

    if (typeof state?.isProviderAvailable !== 'boolean') {
        return isSuccess(statusGroup as string) ?
            <Feedback /> :
            <FailureCard
                title={title}
                subTitle={subTitle}
                buttonTitle={translates.PaymentButtonsOrders}
                onClick={goToOrders} />;
    }

    return <OutsideProvider orderId={state.orderId!} onClick={goToFeedback} />;
};
