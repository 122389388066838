import { useCallback, useEffect } from 'react';
import { FormikErrors, useFormikContext } from 'formik';

const getFieldErrorNames = (formikErrors: FormikErrors<any>) => {
    const transformObjectToDotNotation = (obj: FormikErrors<any>, prefix: string = '', result: string[] = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (typeof value === 'object') {
                transformObjectToDotNotation(value as FormikErrors<any>, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(formikErrors);
};

type ScrollElementType = HTMLDivElement | undefined | null;

export const useScrollToFieldError = (shouldScroll = true, scrollElement: ScrollElementType) => {
    const { submitCount, isValid, errors } = useFormikContext();

    const scrollToError = useCallback(() => {
        if (isValid || !shouldScroll) {
            return;
        }

        const fieldErrorNames = getFieldErrorNames(errors);
        if (fieldErrorNames.length <= 0) {
            return;
        }

        const element = document.getElementById(`form-item-${fieldErrorNames[0]}`);
        if (!element) {
            return;
        }

        if (!scrollElement) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }

        const { top } = element.getBoundingClientRect();

        scrollElement.scrollTo({ top, behavior: 'smooth' });
    }, [isValid, shouldScroll, scrollElement, errors]);

    useEffect(() => {
        scrollToError();
    // Should fire only on submit count change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount]);
};
