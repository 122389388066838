import { lazy } from 'react';
import {
    CreateOrderContainer,
    GoogleAuth,
    LoginContainer,
    OrdersContainer,
    ProfileContainer,
    PaymentContainer,
    EditOrderContainer,
    CreateOrderSuccessContainer,
    ManageAddressBook,
    AddressBooks,
    InvitePartner,
} from '../pages';

const RegistrationConfirmContainer = lazy(() => import('../pages/public/registrationConfirm'));
const RegistrationErrorContainer = lazy(() => import('../pages/public/registrationError'));
const RegistrationSuccessContainer = lazy(() => import('../pages/public/registrationSuccess'));
const ResetPasswordConfirmContainer = lazy(() => import('../pages/public/resetPasswordConfirm'));
const ResetPasswordContainer = lazy(() => import('../pages/public/resetPassword'));
const ResetPasswordErrorContainer = lazy(() => import('../pages/public/resetPasswordError'));
const ResetPasswordSuccessContainer = lazy(() => import('../pages/public/resetPasswordSuccess'));
const SelectCountryContainer = lazy(() => import('../pages/public/selectCountry'));
const ProfileEditContainer = lazy(() => import('../pages/auth/profile/ProfileEdit'));
const SmsConfirmationContainer = lazy(() => import('../pages/public/smsConfirmation'));

export const routerPaths = {
    login: {
        route: '/' as string,
        component: LoginContainer,
        isAuth: false
    },
    createOrder: {
        route: '/create-order' as string,
        component: CreateOrderContainer,
        isAuth: false
    },
    smsConfirmation: {
        route: '/sms-confirmation' as string,
        component: SmsConfirmationContainer,
        isAuth: false
    },
    registrationConfirm: {
        route: '/registration-confirm' as string,
        component: RegistrationConfirmContainer,
        isAuth: false
    },
    registrationError: {
        route: '/registration-error' as string,
        component: RegistrationErrorContainer,
        isAuth: false
    },
    registrationSuccess: {
        route: '/registration-success' as string,
        component: RegistrationSuccessContainer,
        isAuth: false
    },
    resetPassword: {
        route: '/reset-password' as string,
        component: ResetPasswordContainer,
        isAuth: false
    },
    resetPasswordConfirm: {
        route: '/reset-password-confirm' as string,
        component: ResetPasswordConfirmContainer,
        isAuth: false
    },
    resetPasswordError: {
        route: '/reset-password-error' as string,
        component: ResetPasswordErrorContainer,
        isAuth: false
    },
    resetPasswordSuccess: {
        route: '/reset-password-success' as string,
        component: ResetPasswordSuccessContainer,
        isAuth: false
    },
    selectCountry: {
        route: '/select-country' as string,
        component: SelectCountryContainer,
        isAuth: false
    },
    google: {
        route: '/google' as string,
        component: GoogleAuth,
        isAuth: false
    },
    orderSuccessPartner: {
        route: '/order-success' as string,
        component: CreateOrderSuccessContainer,
        isAuth: true
    },
    orders: {
        route: '/orders' as string,
        component: OrdersContainer,
        isAuth: true
    },
    profile: {
        route: '/profile' as string,
        component: ProfileContainer,
        isAuth: true
    },
    profileEdit: {
        route: '/profile-edit' as string,
        component: ProfileEditContainer,
        isAuth: true
    },
    payment: {
        route: '/payment' as string,
        component: PaymentContainer,
        isAuth: true
    },
    editOrder: {
        route: '/edit-order' as string,
        component: EditOrderContainer,
        isAuth: true
    },
    manageAddressBook: {
        route: '/manage-address-book' as string,
        component: ManageAddressBook,
        isAuth: true,
    },
    addressBooks: {
        route: '/address-books' as string,
        component: AddressBooks,
        isAuth: true,
    },
    invitePartner: {
        route: '/invite-partner' as string,
        component: InvitePartner,
        isAuth: true,
    },
} as const;
