import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card } from '../../../components';
import OrderSuccessIcon from '../../../assets/images/order-success.svg';
import { routerPaths } from '../../../configs';
import { LOCAL_STORAGE_KEYS, translates } from '../../../utilities';
import { useLogEvent } from '../createOrder/utilities';

const ButtonStyled = styled(Button)`
    width: 100%;
`;

const SuccessImg = styled.img.attrs({
    src: OrderSuccessIcon,
    alt: 'Order success',
})``;

const IconContainer = styled.div`
    margin: 2.5rem 0 3rem;
    display: flex;
    justify-content: center;

    @media ${({ theme }) => theme.query.small} {
        margin: 1.5rem 0 2rem;
    }
`;

const OrderText = styled.p`
    white-space: pre-wrap;
`;

const getTranslations = (isAutoBid: boolean) => {
    return {
        title: i18next.t(translates.OrderSuccessTitlesMultiPartners),
        firstParagraph: isAutoBid
            ? i18next.t(translates.OrderSuccessTextAutoBidFirst)
            : i18next.t(translates.OrderSuccessTextMultiPartnersFirst),
        secondParagraph: isAutoBid
            ? i18next.t(translates.OrderSuccessTextAutoBidSecond)
            : i18next.t(translates.OrderSuccessTextMultiPartnersSecond),
    };
};

export const CreateOrderSuccessContainer = () => {
    const navigate = useNavigate();
    useLogEvent('clients-order-success');
    const [isAutoBidded, setIsAutoBidded] = useState(false);

    useEffect(() => {
        const isAutoBid = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTO_BID);
        setIsAutoBidded(isAutoBid === 'true');
        return () => {
            localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTO_BID);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.ORDER_ID);
        };
    }, []);

    return (
        <Card titleText={getTranslations(isAutoBidded).title}>
            <OrderText>{getTranslations(isAutoBidded).firstParagraph}</OrderText>
            <OrderText>{getTranslations(isAutoBidded).secondParagraph}</OrderText>
            <IconContainer>
                <SuccessImg/>
            </IconContainer>
            <ButtonStyled
                type="submit"
                styleType="primary"
                title={i18next.t(translates.OrderSuccessButtonsClose)}
                onClick={() => navigate(routerPaths.orders.route, { replace: true })}
            />
        </Card>
    );
};
