import { ChangeEvent, CSSProperties, FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from '../spinner';
import { PasswordEye } from './components/PasswordEye';

interface WrapperProps {
    $error?: boolean
    $active?: boolean
}

const Wrapper = styled.div<WrapperProps>`
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.grey12};
    border-radius: 10px;
    overflow: hidden;

    ${({ $active, $error, theme }) => {
        if ($error) {
            return css`
                border: 1px solid ${theme.colors.danger};
            `;
        }

        if ($active) {
            return css`
                border: 1px solid ${theme.colors.primary};
            `;
        }

        return '';
    }};
`;

const AddonBeforeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.grey7};
  height: 46px;
`;

const InputStyled = styled.input`
  margin: 0;
  padding: 12px 16px;
  width: 100%;
  min-height: 46px;
  color: ${({ theme }) => theme.colors.black};
  background: ${({ disabled, theme }) => disabled ? theme.colors.grey1 : theme.colors.white};
  border: 0;
  font-size: 0.875rem;
  outline: none;
`;

const Icon = styled.img`
    margin-right: 16px;
`;

const StyledSpinner = styled(Spinner).attrs({
    size: 24
})`
    margin-right: 16px;
    align-self: center;
`;

interface BaseProps {
    name?: string;
    autoCapitalize?: string;
    autoComplete?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    active?: boolean;
    error?: boolean;
    addonBefore?: React.ReactNode;
    value: string;
    onChange(value: string): void;
    onFocus?(): void;
    onBlur?: (e: any) => void;
    maxLength?: number;
    icon?: string;
    isLoading?: boolean;
}

interface InputProps extends BaseProps {
    type?: string;
}

export const Input: FC<InputProps> = ({
    name,
    type = 'text',
    autoCapitalize,
    autoComplete,
    autoFocus,
    disabled,
    active,
    error,
    addonBefore,
    value,
    onChange,
    onFocus,
    onBlur,
    icon,
    isLoading,
    ...props
}) => {
    const isPassword = type === 'password';
    const [visible, setVisible] = useState(false);
    const inputType = visible ? 'text' : 'password';

    const handleVisibleToggle = () => {
        setVisible((prev) => !prev);
    };

    return (
        <Wrapper
            $active={active}
            $error={error}
        >
            {addonBefore && (
                <AddonBeforeWrapper>
                    {addonBefore}
                </AddonBeforeWrapper>
            )}
            <InputStyled
                name={name}
                type={isPassword ? inputType : type}
                autoCapitalize={autoCapitalize}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                disabled={disabled}
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
                {...props}
            />
            {isPassword && <PasswordEye onClick={handleVisibleToggle} />}
            {!isLoading && icon && <Icon src={icon} />}
            {isLoading && <StyledSpinner />}
        </Wrapper>
    );
};

interface TextareaProps extends BaseProps {
    rows?: number
    style?: CSSProperties
}

export const Textarea: FC<TextareaProps> = ({
    name,
    rows,
    autoComplete,
    autoFocus,
    disabled,
    active,
    error,
    value,
    onChange,
    onFocus,
    onBlur,
    style,
    ...props
}) => {
    return (
        <Wrapper
            $active={active}
            $error={error}
            style={style}
        >
            <InputStyled
                name={name}
                rows={rows}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                disabled={disabled}
                value={value}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
                as="textarea"
                {...props}
            />
        </Wrapper>
    );
};
