import * as Yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

// eslint-disable-next-line func-names
Yup.addMethod<Yup.StringSchema>(Yup.string, 'phone', function (translate) {
    return this.test('phone',
        translate,
        (value, context) => {
            if (!value) {
                return true;
            }
            const numberRegex = /^\d+$/;
            const totalLength = context.parent.phoneCode?.length + (value?.length ?? 0);
            return numberRegex.test(value) && totalLength >= 10 && totalLength <= 13;
        });
});

// eslint-disable-next-line func-names
Yup.addMethod(Yup.boolean, 'isTrue', function (translate) {
    return this.oneOf([true], translate);
});

declare module 'yup' {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType
    > extends Yup.BaseSchema<TType, TContext, TOut> {
        phone(message: string): StringSchema<TType, TContext>;
    }
}
