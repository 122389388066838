import { ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../assets/images/check-icon.svg';

interface WrapperProps {
    $disabled?: boolean;
}

const Wrapper = styled.label<WrapperProps>`
    display: inline-flex;
    align-items: baseline;
    cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
`;

const CheckboxWrapper = styled.span`
    position: relative;
    top: 0.2em;
`;

const CheckboxInput = styled.input`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

interface CheckboxInnerProps {
    $checked: boolean;
    $disabled?: boolean;
}

const CheckboxInner = styled.span<CheckboxInnerProps>`
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${({ $disabled, $checked, theme }) => {
        if ($disabled) {
            return theme.colors.grey1;
        }
        return $checked ? theme.colors.primary : theme.colors.white;
    }};
    border: 1px solid ${({ $disabled, theme }) => $disabled ? theme.colors.grey1 : theme.colors.primary};
`;

const CheckIconStyled = styled(CheckIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const CheckboxLabel = styled.div`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.black};
    padding: 0 8px;

    >span {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    padding: 0;
    margin-left: .25em;
    }
`;

interface CheckboxProps {
    name?: string;
    label?: string | JSX.Element;
    clickableLabel?: string
    value: boolean;
    onChange?(value: boolean): void;
    onClickLabel?(): void;
    disabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({ name, label, clickableLabel, onClickLabel, value, onChange, disabled }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            return;
        }

        onChange?.(e.target.checked);
    };

    return (
        <Wrapper $disabled={disabled}>
            <CheckboxWrapper>
                <CheckboxInput
                    type="checkbox"
                    name={name}
                    checked={value}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <CheckboxInner
                    $checked={value}
                    $disabled={disabled}
                >
                    {value && <CheckIconStyled />}
                </CheckboxInner>
            </CheckboxWrapper>
            {Boolean(label) && (
                <CheckboxLabel>
                    {label}
                    {Boolean(clickableLabel) && (
                        <span onClick={onClickLabel}>{clickableLabel}</span>
                    )}
                </CheckboxLabel>
            )}
        </Wrapper>
    );
};
