import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import i18next from 'i18next';
import { Input } from '../../../../components/input';
import { OrderStepsPartnerCard } from './OrderStepsPartnerCard';
import { CreateOrderFormModel } from '../utilities';
import { PartnerModel } from '../../../../types/api';
import SearchIcon from '../../../../assets/images/search.svg';
import { BiddingTypes, translates } from '../../../../utilities';

const List = styled.div`
    margin-top: 20px;
    max-height: 450px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
`;

interface SelectFavoritePartnerProps {
    partners: PartnerModel[];
}
export const SelectFavoritePartner: FC<SelectFavoritePartnerProps> = ({ partners }) => {
    const { values, setFieldValue } = useFormikContext<CreateOrderFormModel>();
    const [search, setSearch] = useState('');
    const [temp, setTemp] = useState(partners || []);

    useEffect(() => {
        setTemp(partners);
    }, [partners]);

    const onSearch = (v: string) => {
        setSearch(v);
        if (v.length >= 3) {
            setTemp(partners.filter((p) => p.fullName?.match(new RegExp(v, 'ig'))));
        } else {
            setTemp(partners);
        }
    };

    const handlePress = (partner: PartnerModel) => {
        setFieldValue('isPartnerSelectedFromFavorites', true);
        setFieldValue('partnerId', partner.partnerId!);
        setFieldValue('biddingType', BiddingTypes.fixed);
    };

    if (partners.length === 0) {
        return null;
    }

    return (
        <>
            {partners.length > 3 && (
                <Input
                    value={search}
                    onChange={onSearch}
                    placeholder={i18next.t(translates.OrderStepsSummarySearchPartner)}
                    icon={SearchIcon}
                />
            )}
            <List>
                {temp.map((partner) => (
                    <span key={partner.partnerId} onClick={() => handlePress(partner)}>
                        <OrderStepsPartnerCard
                            key={partner.partnerId}
                            id={partner.partnerId!}
                            commentsCount={partner.totalComments!}
                            name={partner.fullName!}
                            rating={partner.rating!}
                            serviceId={values.serviceId!}
                            totalVotes={partner.totalVotes!}
                            partnerPictureUrl={partner.partnerPictureUrl}
                            selected={partner.partnerId === values.partnerId && values.isPartnerSelectedFromFavorites}
                        />
                    </span>
                ))}
            </List>
        </>
    );
};
