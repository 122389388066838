import { useField } from 'formik';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { ClickableCard } from '.';

const CardContainer = styled.div`
    margin: -0.5rem -0.5rem;
    display: flex;
    flex-wrap: wrap;
`;

const ClickableCardStyled = styled(ClickableCard)`
    margin: 8px;
    max-width: calc(33.333% - 1rem);
    min-height: 5rem;

    @media ${({ theme }) => theme.query.medium} {
        max-width: calc(50% - 1rem);
    }

    @media ${({ theme }) => theme.query.small} {
        max-width: 100%;
    }
`;

interface ClickableCardFormProps {
    name: string;
    bindId: string;
    children: JSX.Element[];
    onSelect?: () => void;
}

export const ClickableCardForm: FC<ClickableCardFormProps> = ({ name, bindId, children, onSelect }) => {
    const [field, meta, helpers] = useField(name);
    const [selectedCardId, setSelectedCardId] = useState(field.value);

    const onSelected = (id: string) => {
        setSelectedCardId(id);
        helpers.setValue(id);
        if (onSelect) {
            onSelect();
        }
    };

    return (
        <CardContainer>
            {children.map((child, i) => (
                <ClickableCardStyled
                    id={child.props[bindId]}
                    key={i}
                    onSelected={onSelected}
                    isActive={selectedCardId === child.props[bindId]}>
                    <child.type {...child.props} />
                </ClickableCardStyled>
            ))}
        </CardContainer>
    );
};
