import { FC } from 'react';
import styled from 'styled-components';

import EyeSvg from '../../../assets/images/password-eye.svg';

const IconWrapper = styled.div`
    position: absolute;
    top: -2px;
    right: 0;
    padding: 1rem;
`;

interface PasswordEyeProps {
    onClick: (e: unknown) => void
}

export const PasswordEye: FC<PasswordEyeProps> = ({ onClick }) => (
    <IconWrapper onClick={onClick}>
        <img src={EyeSvg} alt="toggle password visibility" />
    </IconWrapper>
);
