import { FC, useState } from 'react';
import styled from 'styled-components';

import StarFilledIcon from '../../assets/images/star-filled-icon.svg';
import StarOutlinedIcon from '../../assets/images/star-outlined-icon.svg';

const StyledButton = styled.button`
    background: none;
    border: none;
    padding: 0 8px;
`;

const Container = styled.div``;

interface StarRatingProps {
    rating: number;
    setRating: (rating: number) => void
}

export const StarRating: FC<StarRatingProps> = ({ rating, setRating, ...props }) => {
    const [hover, setHover] = useState(0);
    return (
        <Container {...props} >
            {[...Array(5)].map((star, index) => {
                const uiIndex = index + 1;
                return (
                    <StyledButton
                        type="button"
                        key={uiIndex}
                        onClick={() => setRating(uiIndex)}
                        onMouseEnter={() => setHover(uiIndex)}
                        onMouseLeave={() => setHover(rating)}
                    >
                        {uiIndex <= (hover || rating) ? <img src={StarFilledIcon} alt="button icon right" /> : <img src={StarOutlinedIcon} alt="button icon right" />}
                    </StyledButton>
                );
            })}
        </Container>
    );
};
