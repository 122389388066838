import { Children, ComponentProps, FC } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

SwiperCore.use([Navigation]);

const SwiperStyled = styled(Swiper)`
    --swiper-navigation-size: 56px;
    --swiper-navigation-color: ${({ theme }) => theme.colors.primary};

    .swiper-button-disabled {
        display: none;
    }
`;

export const Slider: FC<ComponentProps<typeof Swiper>> = ({ children, ...props }) => (
    <SwiperStyled
        navigation
        threshold={5}
        {...props}
    >
        {Children.map(children, (child, index) => (
            <SwiperSlide key={index}>
                {child}
            </SwiperSlide>
        ))}
    </SwiperStyled>
);
