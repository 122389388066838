import { FC } from 'react';
import styled from 'styled-components';
import CommentsIcon from '../../../assets/images/comments-green.svg';

const Count = styled.span`
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
    text-decoration: ${({ onClick }) => (onClick ? 'underline' : 'none')};

    &:hover {
        text-decoration: none;
    }
`;

const Comments = styled.div`
    color: ${({ theme }) => theme.colors.grey6};
    display: flex;
    align-items: center;
`;

const Icon = styled.img`
    margin-right: 7px;
    width: 17px;
`;

interface PartnerCommentsProps {
    commentsCount: number;
    showPartner?: () => void;
}

export const PartnerComments: FC<PartnerCommentsProps> = ({ commentsCount, showPartner }) => {
    return (
        <Comments>
            <Icon src={CommentsIcon} />({<Count onClick={showPartner && (() => showPartner()) }>{commentsCount}</Count>})
        </Comments>
    );
};
