import i18next from 'i18next';
import { FC } from 'react';
import { PartnerServiceExperienceModel } from '../../../../../types/api';
import { translates } from '../../../../../utilities';
import { Tabs } from '../Tabs';
import { PartnerCommentsTab } from './PartnerCommentsTab';
import { PartnerInformationTab } from './PartnerInformationTab';

interface PartnerTabsProps {
    experience: PartnerServiceExperienceModel;
    shouldShowCommentsTab?: boolean;
}

export const PartnerTabs: FC<PartnerTabsProps> = ({ experience, shouldShowCommentsTab }) => {
    const tabs = [
        {
            title: i18next.t(translates.PartnerCardLabelsAbout),
            render: () => <PartnerInformationTab experience={experience} />,
        },
    ];

    if (experience?.feedback && experience?.feedback.length > 0) {
        tabs.push({
            title: `${i18next.t(translates.PartnerCardLabelsCustomersFeedback)} (${experience.feedback.length})`,
            render: () => <PartnerCommentsTab experience={experience} />,
        });
    }

    return <Tabs activeTabIndex={shouldShowCommentsTab ? 1 : 0} tabs={tabs} />;
};
