import { useEffect, useContext } from 'react';
import { ConfigContext } from '../contexts';

export const useNotification = (callback: (id: any) => void, eventName: string) => {
    const { signalRConnection } = useContext(ConfigContext);

    return useEffect(() => {
        if (!signalRConnection) {
            return () => {};
        }

        signalRConnection.on(eventName, (id: any) => {
            callback(id);
        });

        return () => {
            signalRConnection.off(eventName);
        };
    }, [signalRConnection, callback, eventName]);
};
