import { ClientReadModel } from '../types/api';
import { LOCAL_STORAGE_KEYS } from './constants';
import { getIsIncompleteProfileModalForced } from './countryDefaults';

export const wait = (duration: number = 0) => {
    return new Promise((resolve) => {
        setTimeout(resolve, duration);
    });
};

export enum NavigationOptionsEnum {
    ORDER_SUCCESS,
    ORDER_CREATED,
    ORDERLESS,
}

interface ContactInfoModel {
    email: string;
    phone: string;
}

export const getLocalContactInfo = () => {
    const storageItem = localStorage.getItem(LOCAL_STORAGE_KEYS.ORDER_CREATED);
    const contactInfo = storageItem ? JSON.parse(storageItem) : { email: '', phone: '' };
    return contactInfo as ContactInfoModel;
};

export const getPostLoginNavigationOption = (client: ClientReadModel | null, country: string) => {
    const localItem = localStorage.getItem(LOCAL_STORAGE_KEYS.ORDER_ID);
    const orderId = localItem ? JSON.parse(localItem) : null;
    if (orderId) {
        if (!(client?.firstName || !client?.phone) && getIsIncompleteProfileModalForced(country)) {
            return NavigationOptionsEnum.ORDER_CREATED;
        }
        return NavigationOptionsEnum.ORDER_SUCCESS;
    }
    return NavigationOptionsEnum.ORDERLESS;
};
