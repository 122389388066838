import { FC } from 'react';
import styled from 'styled-components';
import { ImageModel } from '../../../../types/api';
import { Spinner } from '../../../../components';

const OrderPdfItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PdfItemContainer = styled.div`
    margin: 10px 0;
    display: flex;
    align-items: center;
`;

const PdfInactiveItemName = styled.span`
    color: ${({ theme }) => theme.colors.grey6};
    margin-right: 6px;
`;

const PdfActiveItemName = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})``;

const StyledSpinner = styled(Spinner).attrs({
    size: 14,
})``;

interface OrderPdfItemsProps {
    items: ImageModel[]
}

export const OrderPdfItems: FC<OrderPdfItemsProps> = ({ items, ...props }) => (
    <OrderPdfItemsContainer {...props}>
        {items.map((item, idx) => (
            <PdfItemContainer key={`${item.id}-${idx}`}>
                {item.isAttached ? (
                    <PdfActiveItemName href={item.url}>
                        {item.fileName}
                    </PdfActiveItemName>
                ) : (
                    <>
                        <PdfInactiveItemName>
                            {item.fileName}
                        </PdfInactiveItemName>
                        <StyledSpinner />
                    </>
                )}
            </PdfItemContainer>
        ))}
    </OrderPdfItemsContainer>
);
