import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { translates } from './translates';
import { CountryEnum } from './countryDefaults';

export const getPersonalCodeSchema = (country: CountryEnum, baseSchema: Yup.ObjectSchema<ObjectShape>) => {
    switch (country) {
    case CountryEnum.ES:
        return baseSchema.shape({
            personalCode: Yup.string()
                .nullable()
        });
    default:
        return baseSchema.shape({});
    }
};

export const getInvoiceAddressSchema = (country: CountryEnum, baseSchema: Yup.ObjectSchema<ObjectShape>) => {
    switch (country) {
    case CountryEnum.ES:
        return baseSchema.shape({
            invoiceAddress: Yup.string().nullable().when('isCompany', {
                is: (val: boolean) => val === false,
                then: Yup.string().required(translates.FormsErrorsRequired)
            })
        });
    case CountryEnum.PL:
        return baseSchema.shape({
            invoiceAddress: Yup.string().nullable()
        });
    default:
        return baseSchema.shape({});
    }
};
