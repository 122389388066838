import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ClickableCardStyledProps {
    $isActive: boolean;
}

const ClickableCardStyled = styled.div<ClickableCardStyledProps>`
    padding: ${({ $isActive }) => ($isActive ? 'calc(1rem - 1px)' : '1rem')};
    width: 100%;
    border-radius: 10px;
    border: ${({ theme, $isActive }) =>
        $isActive ? `2px solid ${theme.colors.primary}` : `1px solid ${theme.colors.grey9}`};
    display: flex;
    align-items: stretch;

    @media ${({ theme }) => theme.query.small} {
        width: 100%;
    }
`;

interface ClickableCardProps {
    children: ReactNode;
    isActive: boolean;
    onSelected: (id: string) => void;
    id: string;
}

export const ClickableCard: FC<ClickableCardProps> = ({ children, isActive, onSelected, id, ...props }) => {
    return (
        <ClickableCardStyled onClick={() => onSelected(id)} $isActive={isActive} {...props}>
            {children}
        </ClickableCardStyled>
    );
};
