import { createContext, FC, useReducer } from 'react';
import PropTypes from 'prop-types';
import { ClientReadModel } from '../types/api';

type InitialStatetype = {
    auth: ClientReadModel | null,
    setAuth: (auth: ClientReadModel | null) => void;
};

const enum Actions {
    SET_AUTH = 'SET_AUTH',
}

type Action =
    { type: Actions.SET_AUTH; value: ClientReadModel | null };

const reducer = (state: InitialStatetype, action: Action) => {
    switch (action.type) {
    case Actions.SET_AUTH:
        localStorage.setItem('auth', JSON.stringify(action.value));

        return {
            ...state,
            auth: action.value
        };
    default:
        return state;
    }
};

const initialState = {
    auth: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')!) : null,
    setAuth: () => {}
};

export const AuthContext = createContext<InitialStatetype>({
    auth: null,
    setAuth: () => {}
});

export const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        auth: state.auth,
        setAuth: async (value: ClientReadModel | null) => {
            dispatch({
                type: Actions.SET_AUTH,
                value
            });
        }
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.any
};
